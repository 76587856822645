import { enhancedMakeFetchAction } from '@stagapps/redux-utils'

import { API_BASE_URL } from 'config/env.config'

export const POST_VERIFY_PHONE = 'EMPLOYEE/POST_VERIFY_PHONE'

const apiCall = enhancedMakeFetchAction(POST_VERIFY_PHONE, payload => ({
  endpoint: `${API_BASE_URL}/investcore-api/app/v1/users/verify-phone`,
  method: 'POST',
  body: payload
}))

export default apiCall
