import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_EMPLOYEE_PROGRAM_PORTFOLIO =
  'EMPLOYEE/FETCH_EMPLOYEE_PROGRAM_PORTFOLIO'

const apiCall = enhancedMakeFetchAction(
  FETCH_EMPLOYEE_PROGRAM_PORTFOLIO,
  () => ({
    endpoint: `${API_BASE_URL}/investcore-api/app/v2/portfolios/employee-programs`,
    method: 'GET'
  })
)

export default apiCall
