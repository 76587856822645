import detailApiCall from './detail'
import listApiCall from './list'
import portfolio from './portfolio'

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  portfolio
}

export default ApiCall
