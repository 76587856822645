import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils'
import { Button, Divider, Form } from 'antd'
import productApiCall from 'app/apiCalls/fundProduct'
import orderApiCall from 'app/apiCalls/userProgramOrder'
import useAccountVerifiedStatus from 'app/hooks/useAccountVerifiedStatus'
import PageTitle from 'common/layout/PageTitle'
import ErrorMessage from 'common/ui/ErrorMessage'
import GobackButton from 'common/ui/GobackButton'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import UIInputFormatNumber from 'common/ui/display/UIInputFormatNumber'
import ValidButton from 'common/ui/form/ValidButton'
import { getChangeValueClassColor } from 'config/color'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import { MuiOtpInput } from 'mui-one-time-password-input'

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  formatCurrency,
  formatPercentage,
  formatUnitQuantity
} from 'utils/formatAmount'
import { getErrorMessage } from 'utils/getErrorMessage'

const OrderBuyPage = () => {
  const [current, setCurrent] = useState(0)

  const [form] = Form.useForm()
  const { id, productId } = useParams()
  const formData = Form.useWatch([], form) || {}
  const { amount } = formData

  useEffect(() => {
    const handleBuyEstimate = e => {
      const amount = parseFloat(e)
      postRedeemEstimate({
        id,
        amount,
        product_code: code,
        product_id: productId
      })
    }

    if (amount) {
      handleBuyEstimate(amount)
    }
  }, [amount])

  const {
    data: product,
    load: fetchProduct,
    isLoading: isLoadingProduct,
    error: productError
  } = useFetchApiGet(productApiCall.detail, { resourceName: 'data' })

  const {
    data: buyOrderData,
    post: createBuyOrder,
    isLoading: isSubmitting,
    error: buyError
  } = usePostApi(orderApiCall.createBuy, {
    resourceName: 'data'
  })
  const {
    data: otpBuyData,
    post: createBuyOrderOTP,
    isLoading: isOTPSubmitting,
    error: buyOTPError
  } = usePostApi(orderApiCall.createBuyOTP, {
    resourceName: 'data'
  })

  const {
    data: estimate,
    post: postRedeemEstimate,
    isLoading: isSubmittingRedeemEstimate,
    error: errorEstimate
  } = usePostApi(orderApiCall.buyEstimate, {
    resourceName: 'data'
  })

  const { isCompleteAllKYC } = useAccountVerifiedStatus()

  useEffectSkipFirstRender(() => {
    if (!!otpBuyData) {
      setCurrent(1)
    }
  }, [otpBuyData])

  useEffect(() => {
    fetchProduct({ id: productId })
  }, [])

  if (!isCompleteAllKYC) {
    return null
  }

  if (isLoadingProduct) {
    return <LoadingSpinner />
  }

  if (productError) {
    return <ErrorMessage errors={productError} />
  }

  if (!product) {
    return <div>Product not found</div>
  }

  const {
    name,
    code,
    logo,
    alias,
    fund_type_display,
    change_percentage_1y,
    buy_minimum_amount,
    nav
  } = product

  const { estimate_quantity, estimate_fee_amount } = estimate || {}

  return (
    <div>
      <PageTitle title={'Tăng tốc'} />
      <div className="md:w-fit md:mx-auto">
        <GobackButton />
        <Form
          name="buy-form"
          requiredMark={false}
          form={form}
          onFinish={values => {
            const payload = {
              ...values,
              user_program_id: id,
              product_id: productId
            }
            if (payload.otp) {
              createBuyOrder({ id, payload })
            } else {
              createBuyOrderOTP({ id })
            }
          }}
          labelCol={{ span: 24 }}
          size="large"
        >
          <UICard
            style={current !== 0 ? { display: 'none' } : undefined}
            title={'Mua ' + code}
          >
            <div>
              <div className="flex justify-between items-center md:gap-4 gap-1">
                <div className="flex gap-2 items-center">
                  <img className="md:w-14 w-8" src={logo} alt={name} />
                  <div>
                    <div className="font-semibold text-base">
                      {`${name} (${alias || code})`}
                    </div>
                    <div>{fund_type_display}</div>
                  </div>
                </div>
                <div className="text-right">
                  <div className="text-gray-500">12 tháng</div>
                  <div className="text-gray-500">
                    {change_percentage_1y ? (
                      <div
                        className={
                          getChangeValueClassColor(change_percentage_1y) +
                          ' text-xl font-semibold'
                        }
                      >
                        {(change_percentage_1y > 0 ? '+' : '-') +
                          formatPercentage(change_percentage_1y)}
                      </div>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              </div>
              <Divider />

              <Form.Item
                name="amount"
                label="Nhập số tiền mua"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập số tiền'
                  }
                ]}
              >
                <UIInputFormatNumber
                  className="w-full"
                  addonAfter="VND"
                  min={product.buy_minimum_amount}
                  precision={0}
                  step={1000}
                  notice={'Tối thiểu: ' + formatCurrency(buy_minimum_amount)}
                />
              </Form.Item>

              <Divider />

              <div className="flex flex-col  gap-4">
                <div className="flex justify-between">
                  <div>NAV gần nhất</div>
                  <div>{formatCurrency(nav)}</div>
                </div>
                <div className="flex justify-between">
                  <div>Ước tính số CCQ</div>
                  <div>
                    {estimate_quantity
                      ? formatUnitQuantity(estimate_quantity)
                      : '-'}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>Phí mua</div>
                  <div>
                    {estimate_fee_amount
                      ? formatCurrency(estimate_fee_amount)
                      : '-'}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>Tổng tiền</div>
                  <div className="font-semibold text-base">
                    {amount ? formatCurrency(amount) : '-'}
                  </div>
                </div>
              </div>
            </div>
          </UICard>

          <UICard
            style={current !== 1 ? { display: 'none' } : undefined}
            title={'Nhập mã OTP'}
            className="max-w-lg"
          >
            <div className="text-xs text-gray-500 mb-4">
              Nhập mã OTP được gửi đến email của bạn
            </div>
            <Form.Item
              validateStatus={!!buyError ? 'error' : null}
              hasFeedback
              help={!!buyError && getErrorMessage(buyError)}
              name="otp"
            >
              <MuiOtpInput
                autoFocus
                length={6}
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 }
                }}
              />
            </Form.Item>
            <Button type="link" onClick={() => setCurrent(0)}>
              Gửi lại OTP
            </Button>
          </UICard>
          <Form.Item>
            <ValidButton
              type="primary"
              htmlType="submit"
              className="w-full mt-4"
              disabled={isSubmittingRedeemEstimate}
              loading={isSubmitting || isOTPSubmitting}
              btnTitle={current === 0 ? 'Đặt lệnh mua' : 'Xác thực OTP'}
              form={form}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default OrderBuyPage
