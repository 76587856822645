import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const GET_EMPLOYEE_PROGRAM_ORDER_LIST =
  'EMPLOYEE/GET_EMPLOYEE_PROGRAM_ORDER_LIST'

const apiCall = enhancedMakeFetchAction(
  GET_EMPLOYEE_PROGRAM_ORDER_LIST,
  ({ ...params }) => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/investcore-api/app/v3/employee-program-orders`,
      params
    }),
    method: 'GET'
  }),
  { paramKeys: ['status'] }
)

export default apiCall
