import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

export const STAFF_ACCOUNT_UPDATE = 'BUSINESS/STAFF_ACCOUNT_UPDATE'

const apiCall = enhancedMakeFetchAction(STAFF_ACCOUNT_UPDATE, payload => ({
  endpoint: `${API_BASE_URL}/investcore-api/flex/v1/account`,
  method: 'PATCH',
  body: payload
}))

export default apiCall
