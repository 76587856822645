export const userProgramLabels = {
  buy: 'MUA',
  sell: 'BÁN'
}

export const userProgramColors = {
  1001: 'yellow',
  1002: 'green',
  1003: 'blue',
  1004: 'orange',
  1005: 'grey'
}
