import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

export const POST_CREATE_API_ID = 'POST_SIMULATOR_LAB_PROGRAM_PROJECTION'

const apiCall = enhancedMakeFetchAction(POST_CREATE_API_ID, ({ payload }) => ({
  endpoint: `${API_BASE_URL}/investcore-api/flex/v1/programs/order-estimation`,
  method: 'POST',
  body: payload
}))

export default apiCall
