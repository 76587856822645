import { Card, Empty } from 'antd'

const EmptyProfileCard = ({ children }) => {
  return (
    <Card>
      <Empty
        imageStyle={{
          height: 200
        }}
        description={
          <>
            <p className="font-semibold">Chưa có thông tin</p>
            <span>Bạn cần hoàn tất định danh tài khoản </span>
          </>
        }
      >
        {children}
      </Empty>
    </Card>
  )
}

export default EmptyProfileCard
