import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import ErrorBoundary from 'ErrorBoundary'
import { App as AntdApp, ConfigProvider } from 'antd'
import enUS from 'antd/locale/en_US'
import ScrollToTop from 'common/ui/ScrollToTop'
import { API_BASE_URL, FLEX_APP } from 'config/env.config'
import useCheckMobileDevice from 'hooks/useCheckMobileDevice'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './AppRouter'
import store from './state/store'

import 'tailwindcss/tailwind.css'
import './styles/custom.css'

const MOMENT_DEFAULT_LOCALE = 'en'

const App = () => {
  const { isMobile } = useCheckMobileDevice()

  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = '/favicon.png'
  document.getElementsByTagName('head')[0].appendChild(link)

  const mobileProps = isMobile
    ? {
        fontSizeHeading1: 34,
        fontSizeHeading2: 26,
        fontSizeHeading3: 22,
        fontSizeHeading4: 18
      }
    : {}
  return (
    <React.StrictMode>
      <ErrorBoundary>
        {console.log(API_BASE_URL, FLEX_APP, new Date())}
        <HelmetProvider>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: 'Lexend Deca',
                colorFillSecondary: '#EAF1FD',
                colorPrimary: '#0836E6',
                ...mobileProps
              }
            }}
            locale={enUS}
          >
            <AntdApp>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale={MOMENT_DEFAULT_LOCALE}
              >
                <Provider store={store}>
                  <BrowserRouter>
                    <ScrollToTop />
                    <AppRouter />
                  </BrowserRouter>
                </Provider>
              </LocalizationProvider>
            </AntdApp>
          </ConfigProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.StrictMode>
  )
}

export default App
