import { Tag } from 'antd'
import UIDate from 'common/ui/display/UIDate'
import {
  employeeProgramStatus,
  employeeProgramStatusColors
} from 'data/employeeProgram'
import { Link } from 'react-router-dom'
import { formatNumber, formatUnitQuantity } from 'utils/formatAmount'

export const columns = [
  {
    title: 'Mã chương trình',
    dataIndex: 'code',
    render: (code, values) =>
      code ? <Link to={`/employee-programs/${values?.id}`}>{code}</Link> : null
  },
  {
    title: 'Họ tên',
    dataIndex: 'employee',
    render: employee => (
      <div>
        <Link to={`/employees/${employee?.id}`}>{employee?.full_name}</Link>
        <div>{employee?.email}</div>
      </div>
    )
  },
  {
    title: 'Bắt đầu',
    dataIndex: 'start_date',
    render: value => <UIDate timeData={value} />
  },
  {
    title: 'Chuyển giao',
    dataIndex: 'end_date',
    render: value => <UIDate timeData={value} />
  },
  {
    title: 'Số kỳ',
    dataIndex: 'investment_interval',
    align: 'right'
  },
  {
    title: 'Công ty đã góp',
    dataIndex: 'sum_company_contributed_amount',
    render: text => (text || text === 0 ? <p> {formatNumber(text)}</p> : null),
    align: 'right'
  },
  {
    title: 'NV đã tích lũy',
    dataIndex: 'sum_employee_contributed_amount',
    render: text => (text || text === 0 ? <p> {formatNumber(text)}</p> : null),
    align: 'right'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: value => {
      return (
        <Tag color={employeeProgramStatusColors[value]}>
          {employeeProgramStatus[value]}
        </Tag>
      )
    }
  }
]

export const redistributionColumns = [
  {
    title: 'Tên quỹ',
    dataIndex: 'product_code'
  },
  {
    title: 'Số lượng CCQ',
    dataIndex: 'undistributed_quantity',
    render: value =>
      value || value === 0 ? <p> {formatUnitQuantity(value)}</p> : null
  }
]
