import { DELETE_USER_PROGRAM_API_ID } from 'app/apiCalls/userProgram/delete'
import { UPDATE_USER_PROGRAM_API_ID } from 'app/apiCalls/userProgram/update'
import { eq, flow, get } from 'lodash/fp'
import { ACTIONS } from 'redux-api-call'
import { takeEvery } from 'redux-saga/effects'
import { history } from 'routerHistory'

function* onUserProgramUpdateSuccess(action) {
  if (flow(get('payload.name'), eq(UPDATE_USER_PROGRAM_API_ID))(action)) {
    try {
      const userProgram = get('payload.json.data')(action)
      const { id } = userProgram
      if (id) {
        history.push(`/goals/${id}`)
      } else {
        history.push(`/goals`)
      }
    } catch (error) {
      console.log('onUserProgramUpdateError', error)
    }
  }
}

function* onUserProgramDeleteSuccess(action) {
  if (flow(get('payload.name'), eq(DELETE_USER_PROGRAM_API_ID))(action)) {
    try {
      history.push(`/goals`)
    } catch (error) {
      console.log('onUserProgramDeleteError', error)
    }
  }
}

function* watchOnUserProgram() {
  yield takeEvery(ACTIONS.COMPLETE, onUserProgramUpdateSuccess)
  yield takeEvery(ACTIONS.COMPLETE, onUserProgramDeleteSuccess)
}

export function* userProgramSaga() {
  yield watchOnUserProgram()
}
