import { Alert } from 'antd'
import { isString } from 'lodash'

import { formatDate } from 'utils/formatDate'

function UIDate({ timeData, dateFormat = 'YYYY-MM-DD' }) {
  try {
    if (!isString(timeData)) {
      return (
        <Alert
          className="w-fit"
          message={`Received ${timeData} - Require time string format ${dateFormat}`}
          type="warning"
        />
      )
    }
    const date = formatDate(timeData)

    if (!timeData || date === 'Invalid date') {
      return (
        <Alert
          className="w-fit"
          message={`Invalid date: Received ${timeData} - Require time string format ${dateFormat}`}
          type="warning"
        />
      )
    }
    return <span>{date}</span>
  } catch (error) {
    return <Alert className="w-fit" message={error.message} type="error" />
  }
}

export default UIDate
