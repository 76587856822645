import { usePostApi } from '@stagapps/redux-utils'
import { Button, Form, Input, Typography } from 'antd'
import apiCall from 'app/apiCalls/account/signup'
import AuthLayout from 'app/components/layouts/AuthLayout'
import StagLogoSVG from 'common/svg/logo/stag'
import ValidButton from 'common/ui/form/ValidButton'
import useSignOut from 'hooks/auth/useSignOut'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import { useSearchParams } from 'react-router-dom'
import { history } from 'routerHistory'

const { Title } = Typography

function CreatePasswordPage() {
  const [form] = Form.useForm()
  let [searchParams] = useSearchParams()
  const signOut = useSignOut()

  const { post, isLoading: isSubmitting, error, data } = usePostApi(apiCall, {
    resourceName: 'data'
  })

  useEffectSkipFirstRender(() => {
    if (data) {
      const urlQuery = searchParams.get('url')

      if (!urlQuery) {
        history.push('/welcome/questions/1')
      } else {
        history.push(urlQuery)
      }
    }
  }, [data])

  const { error: errorMessage, error_code } = error || {}

  const onFinish = values => {
    post(values)
  }

  return (
    <AuthLayout imgUrlPath={'/futureflex/images/login_employee_v2.jpeg'}>
      <div className="flex flex-row gap-2 items-center">
        <div className="flex md:w-[134px] w-32 aspect-[134/31]">
          <StagLogoSVG />
        </div>
      </div>
      <div>
        <Title level={4}>Chúc mừng</Title>
        <p className="text-gray-500">
          {'Bạn vừa đăng ký tài khoản thành công.'}
          <br />
          {'Hãy tạo một mật khẩu đăng nhập cho tài khoản của mình.'}
        </p>
      </div>

      <Form
        requiredMark={false}
        initialValues={{
          remember: true
        }}
        onFinish={onFinish}
        form={form}
        labelCol={{ span: 24 }}
      >
        <Form.Item
          label="Mật khẩu"
          name="password"
          rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          dependencies={['password']}
          validateStatus={!!error ? 'error' : null}
          hasFeedback
          help={errorMessage || error_code}
          label="Xác nhận mật khẩu"
          name="password_confirmation"
          rules={[
            { required: true, message: 'Vui lòng nhập mật khẩu' },

            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error('Mật khẩu không giống mật khẩu cài đặt!')
                )
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <ValidButton
            size="large"
            type="primary"
            htmlType="submit"
            className="w-full"
            btnTitle="Tiếp tục"
            form={form}
            loading={isSubmitting}
          />
        </Form.Item>
        <Button block onClick={() => signOut()}>
          Quay lại
        </Button>
      </Form>
    </AuthLayout>
  )
}

export default CreatePasswordPage
