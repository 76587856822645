export function convertMonthsToYearsAndMonths(months) {
  if (months < 12) {
    return `${months} tháng`
  } else {
    const years = Math.floor(months / 12)
    const remainingMonths = months % 12
    if (remainingMonths === 0) return `${years} năm`
    return `${years} năm, ${remainingMonths} tháng`
  }
}
