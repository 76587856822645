import { useFetchApiList } from '@stagapps/redux-utils'
import { Empty, Tag } from 'antd'
import { useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import apiCall from 'app/apiCalls/employeeProgramOrder'
import MetaDecorator from 'common/layout/MetaDecorator'
import DataTable from 'common/ui/DataTable'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import UIDate from 'common/ui/display/UIDate'
import { accountType } from 'data'
import { orderTypeLabels } from 'data/fundOrder'
import { orderStatus, orderStatusColors } from 'data/programOrder'
import { formatNumber } from 'utils/formatAmount'

const columnsGenerator = ({ pathname }) => {
  return [
    {
      title: 'Ngày tạo',
      dataIndex: 'order_date',
      render: value => <UIDate timeData={value} />
    },
    {
      title: 'Phân loại',
      dataIndex: 'order_side',
      render: text => <p>{accountType[text]}</p>
    },
    {
      title: 'Mã lệnh',
      dataIndex: 'code',
      render: (text, record) => (
        <Link to={pathname + '/' + record?.id}> {text}</Link>
      )
    },
    {
      title: 'Kỳ thanh toán',
      dataIndex: 'interval',
      render: text => <p> {formatNumber(text)}</p>,
      align: 'center'
    },
    {
      title: 'Loại lệnh',
      dataIndex: 'order_type',
      render: text => <p>{orderTypeLabels[text]}</p>
    },
    {
      title: 'Sản phẩm',
      dataIndex: 'product_code'
    },
    {
      title: 'Giá trị (VND)',
      dataIndex: 'amount',
      render: text => <p> {formatNumber(text)}</p>,
      align: 'right'
    },

    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: value => (
        <Tag color={orderStatusColors[value]}>{orderStatus[value]}</Tag>
      )
    }
  ]
}

const locale = {
  emptyText: (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={'Hiện tại nhân viên chưa có tích lũy.'}
    />
  )
}

function ProgramOrderListPage() {
  const { id, programId } = useParams()

  const {
    data: items = [],
    load: fetchItems,
    isLoading,
    paging,
    error
  } = useFetchApiList(apiCall.list, { resourceName: 'data' })

  const { pathname } = useLocation()

  useEffect(() => {
    fetchItems({ id, programId })
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }
  const { count, page, page_size } = paging || {}

  return (
    <>
      <MetaDecorator title={'Lịch sử giao dịch'} />
      <UICard>
        {!!error ? (
          <ErrorMessage errors={error} />
        ) : (
          <DataTable
            className="overflow-x-scroll"
            locale={locale}
            columns={columnsGenerator({ pathname })}
            dataSource={items}
            loading={isLoading}
            showHeader={items?.length > 0 ? true : false}
            totalItems={count}
            currentPage={page}
            defaultCurrent={1}
            defaultPageSize={page_size}
            pageSize={page_size}
            dataFetcher={fetchItems}
          />
        )}
      </UICard>
    </>
  )
}

export default ProgramOrderListPage
