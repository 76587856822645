import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

export const FETCH_CREATE_API_ID = 'EMPLOYEE/USER_PROGRAM_CREATE_API'

const apiCall = enhancedMakeFetchAction(FETCH_CREATE_API_ID, payload => ({
  endpoint: `${API_BASE_URL}/investcore-api/app/v2/user-programs/create`,
  method: 'POST',
  body: payload,
  notify: true,
  isStay: true
}))

export default apiCall
