import { Button, Space, Steps, Typography } from 'antd'
import useCheckMobileDevice from 'hooks/useCheckMobileDevice'
import { Link } from 'react-router-dom'

const { Title } = Typography

function OnboardingStepsDetail() {
  const { isMobile } = useCheckMobileDevice()
  return (
    <div className="flex mx-auto items-center flex-col md:gap-10 gap-4 text-center md:max-w-7xl">
      <div>
        <Title level={3}>Xây dựng kế hoạch đạt mục tiêu tài chính</Title>
        <p className="text-sm">
          Chúng tôi có thể giúp bạn hoạch định kế hoạch giúp bạn đạt được mục
          tiêu mong muốn
        </p>
      </div>
      {isMobile ? (
        <Steps items={data} direction="vertical" current={4} />
      ) : (
        <div className="flex ">
          {data.map(({ icon, title }, index) => (
            <div
              key={index}
              className="flex flex-grow flex-col gap-3 items-center"
            >
              <div className="w-24">{icon}</div>
              <div className="flex self-stretch gap-1 items-center">
                <Line
                  borderClass={
                    'rounded-r-full ' + (index === 0 ? 'bg-transparent' : '')
                  }
                />
                <Dot />
                <Line
                  borderClass={
                    'rounded-l-full ' + (index === 4 ? 'bg-transparent' : '')
                  }
                />
              </div>
              <p className="px-16">{title}</p>
            </div>
          ))}
        </div>
      )}

      <Space size="large">
        <Link to="/goals">
          <Button size="large" type="primary" ghost>
            <div className="px-4">Trở về</div>
          </Button>
        </Link>
        <Link to="/goals/new/theme">
          <Button size="large" type="primary">
            <div className="px-4">Tiếp tục</div>
          </Button>
        </Link>
      </Space>
    </div>
  )
}

export default OnboardingStepsDetail

export const Line = ({ borderClass }) => (
  <div className={'w-1/2 h-1 bg-blue-primary-500 ' + borderClass} />
)
export const Dot = () => (
  <div className="w-2 h-2 rounded-full bg-blue-primary-500" />
)

export const GoalSVG = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 96 96"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <circle cx={48} cy={48} r={48} fill="#fff" />
      <path
        fill="#FF9C6E"
        d="M33.799 20.184h1.432l14.184 9.843H33.799v-9.843Z"
      />
      <path
        fill="#0A1A41"
        d="M35.277 18.83c0-.795-.683-1.466-1.477-1.466s-1.465.683-1.465 1.466v21.164c0 .794.682 1.476 1.465 1.476s1.477-.682 1.477-1.476c0-6.108-.112-18.222 0-21.164Z"
      />
      <path
        fill="#0066F5"
        d="M76.24 78.24H19.661l8.49-38.246h18.446l3.278 5.66H62.09c0 .112 14.139 32.473 14.139 32.585h.011Z"
      />
      <path
        fill="#98C3FF"
        d="M20.33 21.66c1.925 0 3.513 1.588 3.513 3.512s-1.588 3.512-3.512 3.512-3.513-1.588-3.513-3.512 1.589-3.513 3.513-3.513ZM59.259 32.969a2.798 2.798 0 0 1 2.83 2.83 2.798 2.798 0 0 1-2.83 2.83 2.798 2.798 0 0 1-2.83-2.83 2.798 2.798 0 0 1 2.83-2.83ZM74.428 21.66c.112.224.224.448.224.683.112.223.112.447.112.794a2.798 2.798 0 0 1-2.83 2.83H62.09c-.794 0-1.477-.336-2.036-.794-.57-.57-.794-1.242-.794-2.036a2.798 2.798 0 0 1 2.83-2.83h1.812c-.112-.224-.224-.448-.224-.683-.112-.223-.112-.458-.112-.794a2.798 2.798 0 0 1 2.83-2.83h9.956c.794 0 1.477.336 2.036.794.56.57.794 1.242.794 2.036a2.798 2.798 0 0 1-2.83 2.83h-1.924Z"
      />
      <path
        fill="#FF9C6E"
        d="m77.593 50.855-1.466-4.407a1.605 1.605 0 0 0-1.476-1.018l-4.072.223c-.794 0-1.353.683-1.353 1.477s.682 1.353 1.476 1.353l1.242-.111-11.197 14.71-4.53-6.79c-.448-.683-1.354-.795-2.037-.336l-14.139 11.32c-.57.448-.682 1.354-.223 2.036.223.335.682.57 1.13.57.335 0 .57-.112.905-.335l12.898-10.291 4.754 7.237c.224.336.682.57 1.13.682.458 0 .906-.223 1.13-.57l12.561-16.298.57 1.7c.225.794 1.019 1.13 1.813.906.682-.447 1.13-1.241.906-2.036l-.022-.022Z"
      />
      <path
        fill="#0A1A41"
        d="M80.535 79.593h-65.07c-.794 0-1.465-.683-1.465-1.477s.682-1.476 1.465-1.476h65.07c.794 0 1.465.682 1.465 1.476-.112.906-.794 1.477-1.465 1.477Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h96v96H0z" />
      </clipPath>
    </defs>
  </svg>
)

export const SpecificSVG = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 96 96"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <circle cx={48} cy={48} r={48} fill="#fff" />
      <g clipPath="url(#b)">
        <path
          fill="#9FC9F7"
          d="M76.05 27.05c0-2.23-1.72-4.05-3.84-4.05s-3.74 1.62-3.74 3.84H19.1v45.52h50.58c3.74 0 6.27-2.93 6.27-6.58.1-.1.1-28.83.1-38.74v.01Z"
        />
        <path
          fill="#E7F3FE"
          d="M72.31 62.15c-2.02 0-3.64 1.82-3.74 3.94V26.84H19.2v45.52h50.58c3.74 0 6.27-2.93 6.27-6.58-.1-2.02-1.82-3.64-3.74-3.64v.01Z"
        />
        <path
          fill="#FF7A45"
          d="M39.43 59.82a3.84 3.84 0 1 0 0-7.68 3.84 3.84 0 0 0 0 7.68ZM63.41 62.35v2.53c0 1.42-1.11 2.53-2.53 2.53-1.42 0-2.53-1.11-2.53-2.53v-2.53h5.06Z"
        />
        <path fill="#9FC9F7" d="M63.41 58.51h-5.06v3.84h5.06v-3.84Z" />
        <path
          fill="#0066F5"
          d="M63.41 42.12v16.39h-5.06V42.12l2.53-6.37 2.53 6.37ZM55.82 32h-2.53v2.53h2.53V32ZM53.29 34.53h-2.53v2.53h2.53v-2.53ZM50.76 62.35h-2.53v2.53h2.53v-2.53ZM48.24 64.88h-2.53v2.53h2.53v-2.53Z"
        />
        <path
          fill="#FF7A45"
          d="M28 35.75a3.841 3.841 0 0 1 0 7.68 3.841 3.841 0 0 1 0-7.68Z"
        />
        <path
          fill="#0A1A41"
          d="M35.59 47.28c5.46-4.25 10.32-5.26 10.32-5.26.71-.1 1.11-.81 1.01-1.52-.1-.71-.81-1.11-1.52-1.01-.2 0-5.36 1.01-11.33 5.56C26.69 50.72 21.83 58.81 19 67.61v4.65h1.31c3.54-13.56 10.22-21.04 15.27-24.99l.01.01Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h96v96H0z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M19 23h57.05v49.37H19z" />
      </clipPath>
    </defs>
  </svg>
)

export const PortSVG = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 96 96"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <circle cx={48} cy={48} r={48} fill="#fff" />
      <g clipPath="url(#b)">
        <path
          fill="#0066F5"
          d="M54.576 34.125a11.614 11.614 0 0 1 0-16.556 11.882 11.882 0 0 1 8.377-3.428V9c-4.369 0-8.728 1.648-12.053 4.935a16.698 16.698 0 0 0 0 23.824c3.325 3.287 7.694 4.935 12.053 4.935v-5.141a11.881 11.881 0 0 1-8.377-3.428Z"
        />
        <path
          fill="#0066F5"
          d="M62.953 9v5.141c3.032 0 6.065 1.143 8.377 3.428a11.614 11.614 0 0 1 0 16.556 11.881 11.881 0 0 1-8.377 3.428v5.14c4.359 0 8.727-1.647 12.053-4.934a16.698 16.698 0 0 0 0-23.824C71.68 10.648 67.312 9 62.953 9Z"
        />
        <path
          fill="#fff"
          d="M71.33 17.57a11.881 11.881 0 0 0-8.377-3.428 11.881 11.881 0 0 0-8.376 3.427 11.614 11.614 0 0 0 0 16.557 11.881 11.881 0 0 0 8.376 3.427c3.032 0 6.065-1.142 8.377-3.427a11.614 11.614 0 0 0 0-16.557Z"
        />
        <path
          fill="#0A1A41"
          d="M66.336 25.575c-.54-.43-1.317-.814-2.35-1.17l-.663-.216c-.133-.046-.247-.093-.37-.14-.521-.206-.919-.43-1.146-.656-.285-.28-.427-.646-.427-1.095 0-.45.142-.852.427-1.143.284-.3.653-.45 1.099-.45h.047c.455 0 .843.15 1.147.422.312.281.493.674.54 1.161h2.947c-.047-.824-.266-1.573-.663-2.238a4.123 4.123 0 0 0-1.62-1.545 4.204 4.204 0 0 0-1.053-.403V15.5h-2.444v2.622c-.36.094-.702.234-1.033.412a4.408 4.408 0 0 0-1.65 1.573 4.052 4.052 0 0 0-.605 2.154c0 .721.18 1.405.54 2.051.36.646.843 1.152 1.459 1.536.313.197.739.393 1.28.59.075.019.283.084.615.197.19.065.37.121.54.187.635.243 1.118.496 1.44.768.465.393.702.927.702 1.592 0 .562-.19 1.04-.578 1.404-.38.375-.872.553-1.46.553h-.104c-.568-.019-1.033-.187-1.393-.515-.379-.347-.578-.815-.625-1.414H57.94c.038.693.152 1.264.351 1.695.209.543.54 1.04.986 1.48.72.72 1.554 1.18 2.52 1.376v2.547h2.445v-2.584a4.591 4.591 0 0 0 1.222-.497 4.827 4.827 0 0 0 1.82-1.77c.445-.749.663-1.573.663-2.472 0-1.395-.54-2.538-1.62-3.409h.009Z"
        />
        <path
          fill="#0066F5"
          d="m45.432 65.14 5.732 15.406c9.97-3.634 17.076-13.11 17.076-24.227H51.686c-.02 4.055-2.616 7.492-6.254 8.822Z"
        />
        <path
          fill="#FF7A45"
          d="M42.115 46.824c5.288 0 9.57 4.232 9.57 9.448v.038H68.24c0-14.253-11.693-25.809-26.115-25.809v16.323h-.01Z"
        />
        <path
          fill="#E7F3FE"
          d="M32.554 56.319v-.037a9.36 9.36 0 0 1 2.786-6.668L23.656 38.067C18.928 42.74 16 49.193 16 56.32h16.554Z"
        />
        <path
          fill="#98C3FF"
          d="m38.837 47.413-5.705-15.339a26.008 26.008 0 0 0-9.476 5.994L35.34 49.614a9.49 9.49 0 0 1 3.497-2.2Z"
        />
        <path
          fill="#0066F5"
          d="M42.116 46.824V30.5a26.26 26.26 0 0 0-8.993 1.573l5.705 15.34a9.553 9.553 0 0 1 3.288-.581v-.01Z"
        />
        <path
          fill="#3C3C3B"
          d="M40.704 71.603a9.584 9.584 0 0 1-6.747-2.753L22.235 80.443C26.964 85.116 33.493 88 40.704 88c3.174 0 6.225-.562 9.04-1.592L44.01 71.004a9.707 9.707 0 0 1-3.307.59v.009Z"
        />
        <path
          fill="#FF7A45"
          d="M32.554 56.32H16c0 7.126 2.928 13.578 7.647 18.251l11.722-11.584a9.376 9.376 0 0 1-2.824-6.668h.01Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h96v96H0z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M16 9h64v79H16z" />
      </clipPath>
    </defs>
  </svg>
)

export const AnalyticSVG = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 96 96"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <circle cx={48} cy={48} r={48} fill="#fff" />
      <path
        fill="#FF7A45"
        d="M25.43 44.793v20.245h7.893V41.322l-1.446-1.017-6.447 4.488ZM38.956 45.176l-1.41-.875-2.12-1.499v22.235h7.893V42.249l-2.909 2.025-1.454.902ZM45.42 65.038h7.893V35.28l-7.893 5.505v24.252ZM55.416 33.809v31.228h7.893V28.304l-7.893 5.505Z"
      />
      <path
        fill="#0066F5"
        d="m62.236 16.687 1.436 2.097L38.006 36.69l-6.19-4.38-.852-.608-.905.607-4.63 3.221v3.739l5.5-3.828 6.199 4.39.878.535.869-.535L65.41 21.309l1.587 2.31L70.59 16l-8.355.687Z"
      />
      <path fill="#0A1A41" d="M76.63 74.603H15V18.73h5.1v50.75h56.53v5.122Z" />
      <path
        fill="#0066F5"
        d="M50.184 42.009h25.045c1.978 0 3.583 1.615 3.583 3.605v30.595c0 1.99-1.605 3.604-3.583 3.604H50.184c-1.978 0-3.575-1.615-3.575-3.596V45.622c0-1.99 1.606-3.595 3.575-3.595v-.018Z"
      />
      <path
        fill="#fff"
        d="M75.824 45.524H49.59v8.102h26.234v-8.102ZM75.833 56.775h-7.29v4.051h7.29v-4.05ZM66.36 56.775h-7.29v4.051h7.29v-4.05ZM56.88 56.775h-7.29v4.051h7.29v-4.05ZM56.88 63.976h-7.29v4.05h7.29v-4.05ZM66.36 63.976h-7.29v4.05h7.29v-4.05Z"
      />
      <path fill="#FFA466" d="M75.833 63.976h-7.29v11.25h7.29v-11.25Z" />
      <path
        fill="#fff"
        d="M56.88 71.167h-7.29v4.05h7.29v-4.05ZM66.36 71.167h-7.29v4.05h7.29v-4.05Z"
      />
      <path
        fill="#3C3C3B"
        d="M73.562 47.3h-2.75v4.55h2.75V47.3ZM69.429 47.3h-2.75v4.55h2.75V47.3Z"
      />
      <path
        fill="#FF6800"
        d="M63.336 57.722h-1.25v2.275h1.25v-2.275ZM53.864 57.722h-1.25v2.275h1.25v-2.275ZM72.807 57.722h-1.25v2.275h1.25v-2.275ZM63.336 64.86h-1.25v2.275h1.25v-2.276ZM53.864 64.86h-1.25v2.275h1.25v-2.276ZM63.336 72.06h-1.25v2.275h1.25V72.06ZM72.807 72.06h-1.25v2.275h1.25V72.06ZM53.864 72.06h-1.25v2.275h1.25V72.06Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h96v96H0z" />
      </clipPath>
    </defs>
  </svg>
)

export const CreateSVG = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 96 96"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <circle cx={48} cy={48} r={48} fill="#fff" />
      <path
        fill="#0066F5"
        d="M57.805 57.209c5.796-10.88 5.55-24.705-1.777-35.585-5.317 1.414-11.58 1.414-16.781 0-5.318 7.69-7.094 17.027-5.434 25.885a36.453 36.453 0 0 0 3.424 9.688h20.568v.012Z"
      />
      <path
        fill="#E7F3FE"
        d="M20.093 33.451a2.923 2.923 0 0 1 2.957 2.957 2.923 2.923 0 0 1-2.957 2.956 2.923 2.923 0 0 1-2.956-2.956c0-1.531 1.297-2.957 2.956-2.957ZM74.715 48.234a2.923 2.923 0 0 1 2.956 2.957 2.923 2.923 0 0 1-2.957 2.957 2.923 2.923 0 0 1-2.956-2.957 2.923 2.923 0 0 1 2.957-2.957ZM76.245 20.094a4.489 4.489 0 0 1 0 8.975 4.489 4.489 0 0 1 0-8.975Z"
      />
      <path
        fill="#fff"
        d="M47.522 30.494c3.903 0 6.976 3.19 6.976 6.977 0 3.786-3.19 6.977-6.976 6.977-3.787 0-6.977-3.19-6.977-6.977 0-3.786 3.073-6.977 6.977-6.977Zm1.063 7.094c0-.596-.467-1.064-1.063-1.064-.596 0-1.064.468-1.064 1.064 0 .596.468 1.063 1.064 1.063.596 0 1.063-.467 1.063-1.063Z"
      />
      <path
        fill="#98C3FF"
        d="M47.52 51.063c1.66 0 3.074 1.413 3.074 3.073v7.444c0 1.66-1.414 3.074-3.073 3.074-.83 0-1.66-.35-2.127-.947-.596-.596-.947-1.297-.947-2.127v-7.444c0-1.66 1.414-3.074 3.074-3.074ZM37.238 57.21v7.444l-12.06-2.957 8.624-14.187c.713 3.307 1.777 6.614 3.424 9.688l.012.011ZM61.24 47.522l8.625 14.187-12.06 2.956v-7.444c1.414-2.84 2.723-6.147 3.424-9.688l.011-.011Z"
      />
      <path
        fill="#FF7A45"
        d="M47.522 13c3.424 2.477 6.264 5.317 8.39 8.625-5.317 1.414-11.58 1.414-16.781 0A34.196 34.196 0 0 1 47.52 13ZM47.522 40.194c-1.414 0-2.606-1.18-2.606-2.607a2.624 2.624 0 0 1 2.606-2.606 2.624 2.624 0 0 1 2.606 2.606 2.624 2.624 0 0 1-2.606 2.607Z"
      />
      <path
        fill="#E7F3FE"
        d="M74.599 82.277h-.234a1.513 1.513 0 0 1-1.18-1.18c-.468-2.01-2.244-3.541-4.37-3.541-1.298 0-2.478.596-3.308 1.53-.468.468-1.064.597-1.66.351-.596-.233-.946-.83-.946-1.414v-.467c0-3.307-2.595-5.913-5.914-5.913a5.97 5.97 0 0 0-5.434 3.54c-.233.468-.712.83-1.297.83-.584 0-1.063-.234-1.297-.713-.83-1.414-2.244-2.243-3.786-2.243-1.18 0-2.361.467-3.19 1.297-.351.35-.714.467-1.181.467-.468 0-.83-.233-1.063-.596-1.064-1.66-2.957-2.606-4.85-2.606-2.957 0-5.318 2.127-5.797 4.967-.117.467-.35.83-.713 1.063-.35.234-.83.234-1.297.117-.35-.117-.713-.234-1.063-.234a2.923 2.923 0 0 0-2.957 2.957c0 .596-.35 1.18-.947 1.297-.595.234-1.18.117-1.659-.234l-5.913-5.539a1.489 1.489 0 0 1 0-2.127 1.489 1.489 0 0 1 2.127 0l4.137 3.904c.946-1.894 2.956-3.074 5.2-3.074h.467c1.18-3.553 4.488-5.913 8.391-5.913 2.36 0 4.733.946 6.381 2.606 1.18-.83 2.606-1.18 4.02-1.18 1.776 0 3.541.712 4.85 1.893 1.66-2.127 4.254-3.308 6.977-3.308 4.137 0 7.56 2.84 8.624 6.627.947-.468 2.127-.713 3.19-.713 2.478 0 4.734 1.18 6.03 3.19l4.488-6.614c.468-.713 1.414-.83 2.01-.351.713.468.83 1.414.35 2.01l-6.03 8.87c-.233.234-.712.468-1.18.468l.024-.024ZM55.562 46.809c-.83 0-1.542.713-1.542 1.542 0 .83.712 1.531 1.542 1.531h5.2c.234-.947.468-2.01.597-2.956h-5.797v-.117ZM41.14 27.538c0-.83-.713-1.531-1.531-1.531h-3.074c-.467.946-.83 2.01-1.297 2.956h4.371c.83.117 1.53-.596 1.53-1.414v-.011ZM37.004 48.234c.83 0 1.53-.713 1.53-1.543s-.712-1.543-1.53-1.543h-2.957c-.234 0-.35 0-.596.117.117.713.234 1.414.35 2.127 0 .234.118.468.118.713h.117l2.956.117.012.012Z"
      />
      <path
        fill="#E7F3FE"
        d="m54.03 82.277-32.51-.35c-.83 0-1.415-.714-1.415-1.532 0-.818.713-1.414 1.415-1.414l32.51.351c.83 0 1.415.713 1.415 1.543 0 .713-.584 1.414-1.414 1.414v-.012Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h96v96H0z" />
      </clipPath>
    </defs>
  </svg>
)

const data = [
  { icon: <GoalSVG />, title: 'Đặt mục tiêu tài chính sẽ thực hiện' },
  {
    icon: <SpecificSVG />,
    title: 'Xác định mong muốn cụ thể cho mục tiêu'
  },
  {
    icon: <PortSVG />,
    title: 'Chọn phân bố danh mục theo khẩu vị rủi ro'
  },
  {
    icon: <AnalyticSVG />,
    title: 'Nhận phân tích kế hoạch đầu tư phù hợp'
  },
  {
    icon: <CreateSVG />,
    title: 'Tạo chương trình đầu tư để đạt tới mục tiêu'
  }
]
