import React from 'react'

const SpeedometterSVG = React.forwardRef((props, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    width="1rem"
    height="1rem"
    viewBox="0 0 20 20"
    fill="currentColor"
    {...props}
  >
    <path
      d="M15.918 5.333a8.336 8.336 0 0 0-11.795 0 8.327 8.327 0 0 0-.542 11.194.825.825 0 0 1-.116 1.167.834.834 0 0 1-1.175-.117c-3.251-3.934-3.043-9.76.641-13.445 3.901-3.909 10.236-3.909 14.146 0 3.667 3.667 3.884 9.485.65 13.42-.3.35-.826.4-1.176.108a.836.836 0 0 1-.116-1.175c2.692-3.276 2.509-8.127-.55-11.187l.033.035ZM15.61 7.3a.83.83 0 0 1 0 1.175l-2.726 2.717c.292.492.458 1.076.458 1.694 0 1.834-1.5 3.334-3.334 3.334a3.346 3.346 0 0 1-3.342-3.334A3.333 3.333 0 0 1 10 9.552a3.34 3.34 0 0 1 1.692.458l2.717-2.725a.816.816 0 0 1 1.175 0l.026.015Zm-5.593 3.917a1.667 1.667 0 1 0 1.659 1.667c0-.925-.751-1.667-1.668-1.667h.009Z"
      clipRule="evenodd"
    />
  </svg>
))

export default SpeedometterSVG
