export const getSimulatorUserProgramData = (
  startDate,
  monthlyContributedAmount,
  initContributedAmount,
  numberOfMonth,
  expectedYearlyReturnPercentage
) => {
  let listDate = [],
    listMonthlyContributedAmount = [],
    listAccumulatedAmount = [],
    listTotalAmount = []

  let currentDate = null
  let currentAccumulatedAmount = 0
  let currentTotalAmount = 0
  for (let i = 0; i < numberOfMonth; i++) {
    if (currentDate == null) {
      currentDate = startDate
    } else {
      let clonedDate = new Date(currentDate)
      currentDate = new Date(clonedDate.setMonth(clonedDate.getMonth() + 1))
    }
    listDate.push(currentDate)

    let currentMonthlyContributedAmount = monthlyContributedAmount
    if (i == 0) {
      currentMonthlyContributedAmount += initContributedAmount
    }
    listMonthlyContributedAmount.push(currentMonthlyContributedAmount)

    currentAccumulatedAmount += currentMonthlyContributedAmount
    listAccumulatedAmount.push(currentAccumulatedAmount)

    const monthlyReturnRatio = 1 + expectedYearlyReturnPercentage / 12 / 100
    currentTotalAmount =
      (currentTotalAmount + currentMonthlyContributedAmount) *
      monthlyReturnRatio
    currentTotalAmount = Math.ceil(currentTotalAmount)
    listTotalAmount.push(currentTotalAmount)
  }

  return {
    listDate: listDate,
    listMonthlyContributedAmount: listMonthlyContributedAmount,
    listAccumulatedAmount: listAccumulatedAmount,
    listTotalAmount: listTotalAmount
  }
}

export const getEstimateYearlyReturnPercentage = investmentPlanOptions => {
  let returnPercentage = 0
  for (let i = 0; i < investmentPlanOptions.length; i++) {
    const investmentPlanOption = investmentPlanOptions[i]
    returnPercentage +=
      (investmentPlanOption.percentage *
        investmentPlanOption.change_percentage_1y) /
      100
  }

  return parseFloat(returnPercentage.toFixed(2))
}

export const getInvestmentPlanPercentageGroupByFundType = investmentPlanOptions => {
  let mapInvestmentPercentageByFundTypeCode = {}
  let mapFundTypeNameByFundTypeCode = {}
  let groupInvestmentPlans = []

  for (let i = 0; i < investmentPlanOptions.length; i++) {
    const investmentPlanOption = investmentPlanOptions[i]
    if (
      mapInvestmentPercentageByFundTypeCode[investmentPlanOption.fund_type] ==
      null
    ) {
      mapInvestmentPercentageByFundTypeCode[investmentPlanOption.fund_type] = 0
    }

    mapFundTypeNameByFundTypeCode[investmentPlanOption.fund_type] =
      investmentPlanOption.fund_type_display
    mapInvestmentPercentageByFundTypeCode[investmentPlanOption.fund_type] +=
      investmentPlanOption.percentage
  }

  for (const fundTypeCode in mapInvestmentPercentageByFundTypeCode) {
    const investmentPercentage =
      mapInvestmentPercentageByFundTypeCode[fundTypeCode]
    const fundTypeName = mapFundTypeNameByFundTypeCode[fundTypeCode]
    groupInvestmentPlans.push({
      fund_type: fundTypeCode,
      fund_type_display: fundTypeName,
      percentage: investmentPercentage
    })
  }
  return groupInvestmentPlans
}

export const getAdjustmentCriteriaSimulatorUserProgram = (
  startDate,
  monthlyContributedAmount,
  initContributedAmount,
  numberOfMonth,
  expectedYearlyReturnPercentage,
  targetAmount
) => {
  const monthlyReturnRatio = 1 + expectedYearlyReturnPercentage / 12 / 100
  let newActualAmount

  const { listTotalAmount } = getSimulatorUserProgramData(
    startDate,
    monthlyContributedAmount,
    initContributedAmount,
    numberOfMonth,
    expectedYearlyReturnPercentage
  )

  const actualAmount = listTotalAmount[listTotalAmount.length - 1]

  let adjustmentCriterias = {
    monthlyContributedAmount: monthlyContributedAmount,
    numberOfMonth: numberOfMonth,
    targetAmount: targetAmount
  }

  // User can achieve 100% target
  if (targetAmount <= actualAmount) {
    adjustmentCriterias.monthlyContributedAmount =
      ((targetAmount -
        initContributedAmount * Math.pow(monthlyReturnRatio, numberOfMonth)) *
        (1 - monthlyReturnRatio)) /
      (monthlyReturnRatio * (1 - Math.pow(monthlyReturnRatio, numberOfMonth)))
    adjustmentCriterias.monthlyContributedAmount = Math.ceil(
      adjustmentCriterias.monthlyContributedAmount
    )
    if (
      adjustmentCriterias.monthlyContributedAmount > monthlyContributedAmount
    ) {
      adjustmentCriterias.monthlyContributedAmount = monthlyContributedAmount
    }

    do {
      let { listTotalAmount } = getSimulatorUserProgramData(
        startDate,
        monthlyContributedAmount,
        initContributedAmount,
        adjustmentCriterias.numberOfMonth,
        expectedYearlyReturnPercentage
      )

      newActualAmount = listTotalAmount[listTotalAmount.length - 1]
      if (newActualAmount < targetAmount) {
        adjustmentCriterias.numberOfMonth++
        break
      }

      adjustmentCriterias.numberOfMonth--
    } while (newActualAmount >= targetAmount)

    adjustmentCriterias.monthlyContributedAmount = Math.max(
      adjustmentCriterias.monthlyContributedAmount,
      0
    )
    adjustmentCriterias.numberOfMonth = Math.max(
      adjustmentCriterias.numberOfMonth,
      1
    )
    return {
      requireAmount: 0,
      targetAchievementPercentage: 100,
      adjustmentCriterias: adjustmentCriterias
    }
  }

  // User can NOT achieve 100% target. Need to adjust target amount
  adjustmentCriterias.targetAmount = actualAmount
  adjustmentCriterias.monthlyContributedAmount =
    ((targetAmount -
      initContributedAmount * Math.pow(monthlyReturnRatio, numberOfMonth)) *
      (1 - monthlyReturnRatio)) /
    (monthlyReturnRatio * (1 - Math.pow(monthlyReturnRatio, numberOfMonth)))
  adjustmentCriterias.monthlyContributedAmount = Math.ceil(
    adjustmentCriterias.monthlyContributedAmount
  )
  adjustmentCriterias.numberOfMonth = numberOfMonth

  do {
    let { listTotalAmount } = getSimulatorUserProgramData(
      startDate,
      monthlyContributedAmount,
      initContributedAmount,
      adjustmentCriterias.numberOfMonth,
      expectedYearlyReturnPercentage
    )

    newActualAmount = listTotalAmount[listTotalAmount.length - 1]
    if (newActualAmount >= targetAmount) {
      break
    }

    adjustmentCriterias.numberOfMonth++
  } while (newActualAmount < targetAmount)

  return {
    requireAmount: targetAmount - actualAmount,
    targetAchievementPercentage: parseFloat(
      ((actualAmount / targetAmount) * 100).toFixed(2)
    ),
    adjustmentCriterias: adjustmentCriterias
  }
}
