import {
  reducers as apiReducers,
  reduxListApiCallReducer
} from '@stagapps/redux-utils'
import { combineReducers } from 'redux'

import { sessionReducer } from 'state/reducer/session.reducers'

const rootReducer = combineReducers({
  ...apiReducers,
  ...reduxListApiCallReducer,

  session: sessionReducer
})

export default rootReducer
