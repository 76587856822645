import { Button, Result, Typography } from 'antd'
import AuthLayout from 'app/components/layouts/AuthLayout'
import { Link } from 'react-router-dom'

const { Title } = Typography

const ResetPasswordSuccess = () => (
  <AuthLayout imgUrlPath={'/futureflex/images/login_employee_v2.jpeg'}>
    <Title level={4}>Chúc mừng</Title>

    <Result
      status="success"
      subTitle="Mật khẩu được cập nhật thành công"
      extra={[
        <Link to={`/`} key="home">
          <Button block type="primary" size="large" key="console">
            Trở về
          </Button>
        </Link>
      ]}
    />
  </AuthLayout>
)

export default ResetPasswordSuccess
