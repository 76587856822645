import { InfoCircleOutlined } from '@ant-design/icons'
import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils'
import {
  Button,
  ConfigProvider,
  Divider,
  Form,
  Image,
  Space,
  Statistic,
  Steps
} from 'antd'
import userProgramOrderApiCall from 'app/apiCalls/userProgramOrder'
import upPortfolioApiCall from 'app/apiCalls/userProgramPortfolio'
import useAccountVerifiedStatus from 'app/hooks/useAccountVerifiedStatus'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UIInputFormatNumber from 'common/ui/display/UIInputFormatNumber'
import { getChangeValueColor } from 'config/color'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { formatCurrency, formatUnitQuantity } from 'utils/formatAmount'
import InputOTP from './InputOTP'
import SellOrderSuccess from './SellOrderSuccess'

const STEPS = [
  {
    title: 'Nhập số lượng',
    content: 'First-content'
  },
  {
    title: 'Xác thực OTP',
    content: 'Second-content'
  },
  {
    title: 'Hoàn tất',
    content: 'Last-content'
  }
]

const UserProgramSellPage = () => {
  const [current, setCurrent] = useState(0)
  const [form] = Form.useForm()
  const params = useParams()
  const { id, portfolioItemId } = params

  const {
    data: portfolioItem,
    load: fetchPortfolioItem,
    isLoading: isLoadingPortfolioItem,
    error: portfolioItemError
  } = useFetchApiGet(upPortfolioApiCall.itemDetail, { resourceName: 'data' })

  const {
    data: sell,
    post: postSell,
    isLoading: isPostSell,
    error: errorSell
  } = usePostApi(userProgramOrderApiCall.createSell, {
    resourceName: 'data'
  })

  const { isCompleteAllKYC } = useAccountVerifiedStatus()

  useEffect(() => {
    fetchPortfolioItem({
      id: portfolioItemId,
      userProgramId: id
    })
  }, [id, portfolioItemId])

  useEffectSkipFirstRender(() => {
    if (!isPostSell) {
      if (!!sell) {
        setCurrent(2)
        refresh()
      }
    }
  }, [isPostSell])

  if (!isCompleteAllKYC) {
    return null
  }

  if (isLoadingPortfolioItem) {
    return <LoadingSpinner />
  }

  if (!!portfolioItemError) {
    return <ErrorMessage errors={portfolioItemError} />
  }

  return (
    <>
      <h1>Bán</h1>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#52C41A'
          }
        }}
      >
        <Steps
          className="mb-3"
          size="small"
          current={current}
          items={STEPS.map(item => ({
            key: item.title,
            title: item.title
          }))}
          progressDot
        />
      </ConfigProvider>
      <Form
        className="relative"
        requiredMark={false}
        onFinish={values => {
          const payload = {
            ...values,
            ...params,
            product_code: portfolioItem.product_code,
            product_id: portfolioItem.product_id
          }

          console.log('payload', payload)
          postSell(payload)
        }}
        labelCol={{ span: 24 }}
        form={form}
      >
        <div style={{ display: current !== 0 ? 'none' : '' }}>
          <SellDetail
            setCurrent={setCurrent}
            form={form}
            params={params}
            portfolioItem={portfolioItem}
          />
        </div>
        <div style={{ display: current !== 1 ? 'none' : '' }}>
          <InputOTP
            setCurrent={setCurrent}
            form={form}
            error={errorSell}
            isSubmitting={isPostSell}
            {...portfolioItem}
          />
        </div>
      </Form>
      <div style={{ display: current !== 2 ? 'none' : '' }}>
        <SellOrderSuccess
          setCurrent={setCurrent}
          form={form}
          {...portfolioItem}
        />
      </div>
    </>
  )
}

export default UserProgramSellPage

const SellDetail = ({ portfolioItem, params, setCurrent }) => {
  const {
    product,
    product_code,
    market_value,
    unrealized_gain_loss,
    available_quantity
  } = portfolioItem

  const inputRef = useRef()
  const { id } = params

  const {
    data: estimate,
    post: postRedeemEstimate,
    isLoading: isSubmittingRedeemEstimate,
    error: errorEstimate
  } = usePostApi(userProgramOrderApiCall.sellEstimate, {
    resourceName: 'data'
  })

  const {
    data: sellOTP,
    post: postSellOTP,
    isLoading: isPostSellOTP,
    error: errorSellOTP
  } = usePostApi(userProgramOrderApiCall.sellOtp, {
    resourceName: 'data'
  })

  useEffect(() => {
    inputRef?.current?.focus({
      cursor: 'start'
    })
  }, [])

  useEffectSkipFirstRender(() => {
    if (!isPostSellOTP) {
      if (!!sellOTP) {
        setCurrent(1)
      }
    }
  }, [isPostSellOTP])

  const error = errorEstimate || errorSellOTP
  const { error: errorMessage, error_code } = error || {}

  const { logo, alias, fund_type_name, redemption_fee, id: product_id } =
    product || {}

  const { estimate_amount, fee_amount, estimate_net_amount } = estimate || {}
  const smallGrayTextClass = 'text-sm text-[#5A6F93]'

  const handleSellEstimate = e => {
    const quantity = parseFloat(e)
    postRedeemEstimate({
      ...params,
      quantity,
      product_code,
      product_id
    })
  }

  return (
    <div className="py-4 flex flex-col gap-4 text-base">
      <div className="flex justify-between">
        <Space size={'large'}>
          <Image width={42} className="object-contain	" src={logo} />
          <div>
            <div className="font-semibold text-blue-primary-500">
              {alias || product_code}
            </div>
            <div className={smallGrayTextClass}>{fund_type_name}</div>
          </div>
        </Space>
        <ConfigProvider
          theme={{
            components: {
              Statistic: {
                contentFontSize: 16
              }
            }
          }}
        >
          <Space size={'large'}>
            <Statistic
              title={
                <div className={smallGrayTextClass + 'w-full text-right'}>
                  {'Số CCQ'}
                </div>
              }
              value={available_quantity}
              formatter={value => formatUnitQuantity(value)}
            />
            <Statistic
              title={
                <div className={smallGrayTextClass + 'w-full text-right'}>
                  {'Giá trị thị trường'}
                </div>
              }
              value={market_value}
              formatter={e => formatCurrency(e, { currencyDisplay: 'symbol' })}
            />
            <Statistic
              valueStyle={{
                color: getChangeValueColor(unrealized_gain_loss)
              }}
              title={
                <div className={smallGrayTextClass + 'w-full text-right'}>
                  {'Lời/Lỗ'}
                </div>
              }
              value={unrealized_gain_loss}
              formatter={e => formatCurrency(e, { currencyDisplay: 'symbol' })}
            />
          </Space>
        </ConfigProvider>
      </div>

      <Form.Item
        validateStatus={!!error ? 'error' : null}
        hasFeedback
        help={errorMessage || error_code}
        label="Số lượng CCQ cần bán"
        name={'quantity'}
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
            type: 'quantity'
          }
        ]}
      >
        <UIInputFormatNumber
          ref={inputRef}
          className="w-full"
          onBlur={handleSellEstimate}
          placeholder={`Tối đa ${formatUnitQuantity(available_quantity)}`}
        />
      </Form.Item>

      <div className="flex flex-row justify-between">
        <div>Tương đương</div>
        <div>
          {estimate_amount
            ? formatCurrency(estimate_amount, { currencyDisplay: 'symbol' })
            : '-'}
        </div>
      </div>

      <div className="flex flex-row justify-between">
        <Space size={'small'}>
          <div>Phí bán</div>
          <InfoCircleOutlined />
        </Space>
        <div>
          {fee_amount
            ? formatCurrency(fee_amount, { currencyDisplay: 'symbol' })
            : '-'}
        </div>
      </div>

      <div className="overflow-hidden rounded-2xl text-sm">
        <div className="flex flex-row justify-between font-semibold text-white bg-blue-400 p-4 mb-0.5">
          <div>Thời gian nắm giữ</div>
          <div>Phí bán</div>
        </div>

        {redemption_fee.map(({ label, fee_text }) => (
          <div
            key={label}
            className="flex flex-row justify-between bg-blue-50 p-4 mb-0.5"
          >
            <div>{label}</div>
            <div>{fee_text}</div>
          </div>
        ))}
      </div>

      <div>
        <div className="text-gray-500 text-sm">
          *Phí bán khác nhau tuỳ thuộc Công ty quản lý quỹ
        </div>
        <Divider />
        <div className="flex flex-row justify-between">
          <div>Ước tính thực nhận</div>
          <div>
            {estimate_net_amount
              ? formatCurrency(estimate_net_amount, {
                  currencyDisplay: 'symbol'
                })
              : '-'}
          </div>
        </div>
      </div>
      <Form.Item className="self-end">
        <Space>
          <Link to={`/goals/${id}/transactions`}>Trở về</Link>
          <Button
            disabled={!!error}
            type="primary"
            loading={isPostSellOTP || isSubmittingRedeemEstimate}
            onClick={() => {
              console.log('sending otp', id)
              postSellOTP({ id })
            }}
          >
            Bán
          </Button>
        </Space>
      </Form.Item>
    </div>
  )
}
