import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils'
import { Alert, Button, Space, Typography } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import apiConfirmIdentificationCall from 'app/apiCalls/verification/confirmIdentification'
import apiCall from 'app/apiCalls/verification/getUserIdentifications'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import UIDate from 'common/ui/display/UIDate'
import { Item } from 'common/ui/display/UIDescriptions'
import { genderLabels } from 'data'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import KYCSteps from './KYCSteps'

const { Title } = Typography

function CompletingIDInfosPage() {
  const navigate = useNavigate()

  const { data, load, isLoading, error } = useFetchApiGet(apiCall, {
    resourceName: 'data'
  })

  const {
    data: responseData,
    post,
    isLoading: isSubmitting,
    error: patchError
  } = usePostApi(apiConfirmIdentificationCall, {
    resourceName: 'data'
  })

  const { error: errorMessage, error_code } = patchError || {}

  useEffect(() => {
    load()
  }, [])

  useEffectSkipFirstRender(() => {
    if (responseData) {
      navigate('/')
    }
  }, [responseData])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  const onClickEdit = () => {
    navigate('/verification/edit')
  }

  const {
    gender,
    full_name,
    date_of_birth,
    id_number,
    issue_date,
    issue_place,
    residential_address_city,
    residential_address_district,
    residential_address_ward,
    residential_address,
    address_city,
    address_district,
    address_ward,
    address,
    bank_account_name,
    bank_account_number,
    bank_name,
    bank_branch_name
  } = data || {}

  return (
    <div>
      <KYCSteps current={2} />
      <UICard title={'Xác nhận thông tin cá nhân'}>
        <div className="flex flex-col gap-4">
          <Alert
            message="Thông tin cung cấp chỉ được dùng để mở tài khoản giao dịch. Vui lòng kiểm tra và xác nhận"
            type="info"
            showIcon
          />
          <div>
            {!!errorMessage || !!error_code ? (
              <Alert
                message={errorMessage || error_code}
                type="error"
                showIcon
              />
            ) : null}
            <Title level={5}>1. Thông tin cơ bản</Title>
            <Item label="Giới tính">{genderLabels[gender]}</Item>
            <Item label="Họ và tên (đầy đủ theo CMND/CCCD)">
              <span className="lowercase ">
                <div className="first-line:capitalize">{full_name}</div>
              </span>
            </Item>
            <Item label="Ngày tháng năm sinh">
              {<UIDate timeData={date_of_birth} />}
            </Item>

            <Title level={5}>2. Thông tin chứng từ</Title>
            <Item label="Số CMND/CCCD">{id_number}</Item>
            <Item label="Ngày cấp">{<UIDate timeData={issue_date} />}</Item>
            <Item label="Nơi cấp">{issue_place}</Item>

            <Title level={5}>3. Địa chỉ thường trú</Title>
            <Item label="Thành phố">{residential_address_city}</Item>
            <Item label="Quận huyện">{residential_address_district}</Item>
            <Item label="Phường/Xã/Thị trấn">{residential_address_ward}</Item>
            <Item label="Địa chỉ thường trú">{residential_address}</Item>

            <Title level={5}>4. Địa chỉ liên hệ</Title>
            <Item label="Thành phố">{address_city}</Item>
            <Item label="Quận huyện">{address_district}</Item>
            <Item label="Phường/Xã/Thị trấn">{address_ward}</Item>
            <Item label="Địa chỉ liên hệ">{address}</Item>

            <Title level={5}>5. Thông tin ngân hàng</Title>
            <Item label="Chủ tài khoản">{bank_account_name}</Item>
            <Item label="Số TKNH">{bank_account_number}</Item>
            <Item label="Tên ngân hàng">{bank_name}</Item>
            <Item label="Chi nhánh">{bank_branch_name}</Item>
          </div>
          <Space className="flex justify-end">
            <Button onClick={onClickEdit}>Chỉnh sửa</Button>
            <Button loading={isSubmitting} onClick={post} type="primary">
              Xác nhận thông tin
            </Button>
          </Space>
        </div>
      </UICard>
    </div>
  )
}

export default CompletingIDInfosPage
