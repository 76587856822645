import { Button, Typography } from 'antd'

import EmptySVG from 'common/svg/EmptySVG'
import { Link } from 'react-router-dom'

const { Title } = Typography

const PageNotFound = () => (
  <div className=" text-center flex flex-col gap-2 justify-center items-center h-screen w-screen ">
    <Title>404</Title>
    <EmptySVG />
    <Title level={2}>Không tìm thấy trang</Title>
    <Link to={'/'}>
      <Button type="primary" size="large">
        Tải lại
      </Button>
    </Link>
  </div>
)

export default PageNotFound
