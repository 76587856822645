import { eq, flow, get } from 'lodash/fp'
import { ACTIONS } from 'redux-api-call'
import { takeEvery } from 'redux-saga/effects'

// import { openNotificationWithIcon } from 'utils/openNotificationWithIcon'

const is403 = flow(get('payload.statusCode'), eq(403))

function* onPermissionError(action) {
  try {
    if (is403(action)) {
      // yield put({
      //   type: SessionAction.UPDATE_STAG_USER,
      //   payload: {
      //     currentUser: null,
      //     currentToken: null,
      //     isLoading: false,
      //     isRefreshing: false
      //   }
      // })
      const resErrors = get(`payload.json.error`)(action)

      // yield openNotificationWithIcon({
      //   type: 'error',
      //   message: 'Something went wrong!',
      //   description: resErrors
      // })
    }
  } catch (error) {
    console.error('403Error', error)
  }
}

export function* watchonPermission() {
  yield takeEvery(ACTIONS.FAILURE, onPermissionError)
}
