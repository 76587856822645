import { flow, get, isNil } from 'lodash/fp'
import { ACTIONS } from 'redux-api-call'
import { takeEvery } from 'redux-saga/effects'
import { history } from 'routerHistory'

const isNetworkError = flow(get('payload.statusCode'), isNil)

function* onNetworkError(action) {
  try {
    if (isNetworkError(action)) {
      history.push('/error')
    }
  } catch (error) {
    console.error('onNetworkError', error)
  }
}

export function* watchonNetworkError() {
  yield takeEvery(ACTIONS.FAILURE, onNetworkError)
}
