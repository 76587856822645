import MetaDecorator from 'common/layout/MetaDecorator'
import PageTitle from 'common/layout/PageTitle'
import CreateProgramWithoutEmployeeInfoForm from './CreateProgramWithoutEmployeeInfoForm'
import EmployeeProgramDetail from './ProgramDetail'
import ProgramOrders from './ProgramOrders'

export function EmployeeProgramDetailPage() {
  return (
    <>
      <MetaDecorator title={'Chi tiết chương trình'} />
      <EmployeeProgramDetail />
    </>
  )
}

export function EmployeeProgramCreateWithoutEmployeePage() {
  const title = 'Tạo chương trình mới'
  return (
    <div className="max-w-6xl mx-auto">
      <MetaDecorator title={title} />
      <PageTitle title={title} />
      <CreateProgramWithoutEmployeeInfoForm />
    </div>
  )
}

export function ProgramOrdersPage() {
  return (
    <>
      <MetaDecorator title={'Lịch sử giao dịch'} />
      <ProgramOrders />
    </>
  )
}
