import { PlusCircleOutlined } from '@ant-design/icons'
import { useFetchApiGet } from '@stagapps/redux-utils'
import {
  Button,
  ConfigProvider,
  Modal,
  Progress,
  Space,
  Statistic,
  Tag
} from 'antd'
import apiUserProgramsCall from 'app/apiCalls/userProgram'
import PageTitle from 'common/layout/PageTitle'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import { getChangeValueColor } from 'config/color'
import { userProgramColors } from 'data/userProgram'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { formatCurrency } from 'utils/formatAmount'
import {
  formatAmountChangeWithPrefix,
  formatCurrencyWithoutCurrencySymbol
} from 'utils/formatAmount/formatUI'
import { formatDate } from 'utils/formatDate'
import { cloudflareCdn } from 'utils/image'

const getTitle = goalDraft => {
  if (!!goalDraft) {
    const urlParams = new URLSearchParams(goalDraft.split('?')[1])
    return urlParams.get('title')
  }
  return ''
}

function UserProgramList() {
  const {
    data: program,
    load: fetchProgram,
    isLoading: isLoadingProgram,
    error: errorProgram
  } = useFetchApiGet(apiUserProgramsCall.list, { resourceName: 'data' })

  const isLoading = isLoadingProgram
  const error = errorProgram

  useEffect(() => {
    fetchProgram()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  if (!program || program.length === 0) {
    return <EmptyUserProgram />
  }

  return (
    <div className="">
      <PageTitle title={'Kế hoạch tài chính'} />
      <UICard
        extra={
          <CreateUserProgramButton>
            <PlusCircleOutlined />
            Tạo kế hoạch
          </CreateUserProgramButton>
        }
      >
        <div className="grid grid-cols-3 gap-4 content-start">
          {program.map((program, index) => (
            <UserProgramItem key={index} program={program} />
          ))}
        </div>
      </UICard>
    </div>
  )
}

const CreateUserProgramButton = ({ children, btnSize = 'middle' }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()
  const goalDraft = localStorage.getItem('goalDraft')

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    navigate(JSON.parse(goalDraft))
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    navigate('/goals/new')
    localStorage.removeItem('goalDraft')
    setIsModalOpen(false)
  }

  const defaultLogo = cloudflareCdn(
    `/futureflex/images/onboarding/8.jpg`,
    90,
    90
  )

  return (
    <>
      <Button
        size={btnSize}
        onClick={() => {
          if (!!goalDraft) {
            showModal()
          } else {
            navigate('/goals/new')
          }
        }}
        type="primary"
      >
        <Space>{children}</Space>
      </Button>
      <Modal
        title="Bạn có một bản nháp"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Xem lại nháp"
        cancelText="Tạo kế hoạch mới"
      >
        <p>Bạn có muốn quay lại bản nháp mục tiêu đã tạo?</p>
        <div className="bg-blue-50 p-4 flex gap-4 items-center">
          <img
            className="w-10 h-10 rounded-lg"
            alt="example"
            src={defaultLogo}
          />
          <div className="text-base font-semibold">{getTitle(goalDraft)}</div>
        </div>
      </Modal>
    </>
  )
}

export default UserProgramList

const EmptyUserProgram = () => {
  const programBg = cloudflareCdn(
    `/futureflex/images/user_program_empty_background.jpg`,
    790,
    550
  )
  return (
    <div className="max-w-3xl mx-auto">
      <PageTitle
        title={'Kế hoạch tài chính'}
        extra={
          <CreateUserProgramButton>
            <PlusCircleOutlined />
            Tạo kế hoạch
          </CreateUserProgramButton>
        }
      />
      <UICard className="bg-amber-100 shadow-white">
        <div className="md:flex ">
          <div>
            <p className="font-semibold text-base italic	">
              Những người đặt mục tiêu cụ thể và theo dõi thực hiện có tỉ lệ
              hoàn thành cao hơn 150% những người chỉ đặt mục tiêu chung chung
              hoặc không theo dõi
            </p>
            <p className="text-xs italic">
              {'(theo nghiên cứu của DUCA, 2007).'}
            </p>
            <p>
              {
                'Tại Stag, chúng tôi giúp bạn lên kế hoạch và đi cùng bạn cho đến khi hoàn thành mục tiêu tài chính của mình.'
              }
            </p>
            <CreateUserProgramButton btnSize="large">
              Lên kế hoạch ngay hôm nay
            </CreateUserProgramButton>
          </div>
          <img className="md:w-2/5 w-full" src={programBg} />
        </div>
      </UICard>
    </div>
  )
}

const UserProgramItem = ({ program }) => {
  const defaultLogo = cloudflareCdn(
    `/futureflex/images/onboarding/8.jpg`,
    150,
    150
  )
  const {
    market_value,
    id,
    unrealized_gain_loss_amount,
    progress,
    program_name,
    target_amount,
    end_date,
    status,
    status_label,
    logo
  } = program || {}
  const programLogo = cloudflareCdn(logo, 150, 150)

  return (
    <Link to={`/goals/${id}`} className="">
      <UICard
        hoverable
        cover={
          <div className="bg-blue-50 p-6">
            <div className="flex flex-row gap-4">
              <img
                className="w-14 h-14 rounded-lg"
                alt="example"
                src={programLogo || defaultLogo}
              />
              <div className="flex flex-col justify-between">
                <div className="text-base font-semibold">{program_name}</div>
                <div>
                  {market_value > 0 ? (
                    <span className="font-semibold">{`${formatCurrencyWithoutCurrencySymbol(
                      market_value
                    )} / `}</span>
                  ) : null}
                  <span className="text-gray-500">{` ${formatCurrency(
                    target_amount
                  )}`}</span>
                </div>
              </div>
            </div>
          </div>
        }
      >
        <div className="flex flex-col gap-3">
          <ConfigProvider
            theme={{
              components: {
                Statistic: {
                  contentFontSize: '1rem',
                  titleFontSize: '0.75rem'
                }
              }
            }}
          >
            <div className="flex">
              <Statistic
                className={'w-1/2'}
                valueStyle={{
                  color: getChangeValueColor(unrealized_gain_loss_amount)
                }}
                title="Lãi/Lỗ"
                value={unrealized_gain_loss_amount}
                formatter={formatAmountChangeWithPrefix}
              />
              {end_date && (
                <Statistic
                  className="w-1/2"
                  title="Thời gian"
                  value={end_date}
                  formatter={formatDate}
                />
              )}
            </div>
          </ConfigProvider>
          {target_amount && (
            <Progress
              percent={progress}
              strokeColor={'#52C41A'}
              trailColor={'#E8F7DE'}
            />
          )}

          <div>
            <Tag color={userProgramColors[status]}>{status_label}</Tag>
          </div>
        </div>
      </UICard>
    </Link>
  )
}
