import { Space } from 'antd'

import UIInfoTooltip from './UIInfoTooltip'

export const Item = ({ label, children, tooltip }) => {
  return (
    <div className="flex flex-row border-0 border-b border-solid	border-b-[#DCE2EC] text-sm items-center">
      <Space className="w-1/2 p-2 font-semibold">
        <p>{label}</p>
        {tooltip ? <UIInfoTooltip title={tooltip} /> : null}
      </Space>
      <div className="w-1/2 p-2">{children}</div>
    </div>
  )
}
