import { FETCH_CREATE_API_ID } from 'business/apiCalls/employee/create'
import { eq, flow, get } from 'lodash/fp'
import { ACTIONS } from 'redux-api-call'
import { takeEvery } from 'redux-saga/effects'
import { history } from 'routerHistory'

function* onBusinessCreateEmployeeSuccess(action) {
  if (flow(get('payload.name'), eq(FETCH_CREATE_API_ID))(action)) {
    try {
      const { id } = get('payload.json.data')(action) || {}

      if (id) {
        history.push(`/employees/${id}`)
      } else {
        history.push(`/employees`)
      }
    } catch (error) {
      console.log('onBusinessCreateEmployeeError', error)
    }
  }
}

function* watchOnBusinessCreateEmployee() {
  yield takeEvery(ACTIONS.COMPLETE, onBusinessCreateEmployeeSuccess)
}

export function* businessCreateEmployeeSaga() {
  yield watchOnBusinessCreateEmployee()
}
