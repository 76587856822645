import { useFetchApiGet } from '@stagapps/redux-utils'
import { Descriptions, Space, Tag } from 'antd'
import { join } from 'lodash'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import PageTitle from 'common/layout/PageTitle'
import CopyToClipboard from 'common/ui/CopyToClipboard'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import UIDate from 'common/ui/display/UIDate'
import UIInfoTooltip from 'common/ui/display/UIInfoTooltip'
import { orderStatusColors, orderTypeLabels } from 'data/fundOrder'
import { formatCurrency } from 'utils/formatAmount'

const { Item } = Descriptions

function FundOrderDetail({ apiCall }) {
  const { id } = useParams()
  const {
    data: order,
    load: fetchOrder,
    isLoading: isLoadingOrder,
    error: errorOrder
  } = useFetchApiGet(apiCall.detail, { resourceName: 'data' })

  const isLoading = isLoadingOrder
  const error = errorOrder

  useEffect(() => {
    fetchOrder({ id })
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  const {
    code,
    created_at,
    intervals,
    user_name,
    employee_program_codes,
    order_side,
    fund_code,
    amount,
    fund_payment_bank_name,
    fund_payment_bank_branch_name,
    fund_payment_message,
    fund_payment_bank_account_name,
    fund_payment_bank_account_number,
    status,
    user,
    status_label
  } = order || {}
  const { account_type } = user || {}
  const isBusiness = account_type === 'business'

  const title = `Chi tiết lệnh ${code}`

  return (
    <>
      <PageTitle title={title} />

      <div className="flex flex-col gap-4">
        <UICard title="Thông tin lệnh">
          <Descriptions
            column={1}
            labelStyle={{ width: 270 }}
            bordered
            size="middle"
          >
            <Item label="Ngày tạo">{<UIDate timeData={created_at} />}</Item>
            {isBusiness ? null : (
              <Item label="Kỳ thanh toán">{join(intervals, ', ')}</Item>
            )}
            <Item label={isBusiness ? 'Tên Doanh nghiệp' : 'Họ Tên'}>
              {user_name}
            </Item>
            {isBusiness ? null : (
              <Item label="Mã chương trình">
                {join(employee_program_codes, ', ')}
              </Item>
            )}
            <Item label="Loại lệnh">{orderTypeLabels[order_side]}</Item>
            <Item label="Sản phẩm">{fund_code}</Item>
            <Item
              label={
                <Space>
                  <div>{'Số tiền cần thanh toán'}</div>
                  <UIInfoTooltip
                    title={
                      isBusiness
                        ? 'Số tiền Doanh nghiệp cần thanh toán sau khi đã lấy (Tổng giá trị cần đóng góp) - (Phân bổ từ quỹ tái phân phối).'
                        : 'Số tiền Doanh nghiệp cần thanh toán cho lệnh tích lũy này của Nhân viên'
                    }
                  />
                </Space>
              }
            >
              <span className="font-semibold">{formatCurrency(amount)}</span>
            </Item>
          </Descriptions>
        </UICard>

        <UICard title={'Thông tin chuyển khoản'}>
          <Descriptions
            column={1}
            labelStyle={{ width: 270 }}
            bordered
            size="middle"
          >
            <Item label="Tên ngân hàng">{fund_payment_bank_name}</Item>
            <Item label="Chi nhánh">{fund_payment_bank_branch_name}</Item>
            <Item label="Nội dung chuyển khoản">
              <Space>
                {fund_payment_message}
                <CopyToClipboard text={fund_payment_message} />
              </Space>
            </Item>
            <Item label="Tên người thụ hưởng">
              <Space>
                {fund_payment_bank_account_name}
                <CopyToClipboard text={fund_payment_bank_account_name} />
              </Space>
            </Item>
            <Item label="Số tài khoản thụ hưởng">
              <Space>
                {fund_payment_bank_account_number}
                <CopyToClipboard text={fund_payment_bank_account_number} />
              </Space>
            </Item>
            <Item label="Trạng thái">
              {<Tag color={orderStatusColors[status]}>{status_label}</Tag>}
            </Item>
          </Descriptions>
        </UICard>
      </div>
    </>
  )
}

export default FundOrderDetail
