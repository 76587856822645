export const isContainNumber = text => {
  return /\d/.test(text)
}

export const isContainLetter = text => {
  return /[a-zA-Z]/g.test(text)
}

export const isContainBothLowercaseAndUpercase = text => {
  return /(?=.*[a-z])(?=.*[A-Z])/g.test(text)
}

export const isContainSpecialCharacter = text => {
  return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g.test(text)
}

export const isEnoughCharacter = text => {
  return text && text.length >= 8 && text.length <= 20
}

export const PasswordValidator = {
  validator(_, value) {
    if (!isContainSpecialCharacter(value)) {
      return Promise.reject(new Error(''))
    }
    if (!isContainBothLowercaseAndUpercase(value)) {
      return Promise.reject(new Error(''))
    }
    if (!isContainLetter(value)) {
      return Promise.reject(new Error(''))
    }
    if (!isContainNumber(value)) {
      return Promise.reject(new Error(''))
    }
    if (!isEnoughCharacter(value)) {
      return Promise.reject(new Error(''))
    }

    return Promise.resolve()
  }
}
