import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_DETAIL_API_ID = 'EMPLOYEE/FETCH_USER_PROGRAMS_PROJECTION_API'

const apiCall = enhancedMakeFetchAction(
  FETCH_DETAIL_API_ID,
  ({ id, ...params }) => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/investcore-api/app/v2/user-programs/${id}/projection`,
      params
    }),
    method: 'GET'
  })
)

export default apiCall
