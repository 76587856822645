import { all } from 'redux-saga/effects'
import { watchonAuthError } from './auth'
import { watchOnException } from './exception'
import { watchonNetworkError } from './network'
import { watchonPermission } from './permission'

export default function* errorsSaga() {
  yield all([
    watchonAuthError(),
    watchOnException(),
    watchonPermission(),
    watchonNetworkError()
  ])
}
