import { usePostApi } from '@stagapps/redux-utils'
import { Button, Form, Space, Statistic } from 'antd'
import apiCall from 'app/apiCalls/userProgramOrder'
import ValidButton from 'common/ui/form/ValidButton'
import useCheckAuth from 'hooks/auth/useCheckAuth'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useEffect, useState } from 'react'
import { convertEmailCharacters } from 'utils/hideCharacter'

const { Countdown } = Statistic

const InputOTP = ({ setCurrent, form, error, isSubmitting }) => {
  const { email } = useCheckAuth().currentUser || {}

  const { data: sellOTP } = usePostApi(apiCall.sellOtp, {
    resourceName: 'data'
  })

  const { expires_at } = sellOTP || {}
  const { error: errorMessage, error_code } = error || {}

  return (
    <div className="text-center flex flex-col gap-6 py-4">
      <div>
        <div>Nhập mã OTP được gửi đến email của bạn</div>
        <div>{convertEmailCharacters(email)}</div>
      </div>

      <Form.Item
        validateStatus={!!error ? 'error' : null}
        hasFeedback
        help={errorMessage || error_code}
        name="otp"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập OTP'
          }
        ]}
      >
        <MuiOtpInput
          autoFocus
          length={6}
          sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
        />
      </Form.Item>

      <CountDownAndResendRequest expires={expires_at} setCurrent={setCurrent} />

      <div className="self-end">
        <Form.Item>
          <Space>
            <Button onClick={e => setCurrent(0)}>Trở về</Button>
            <ValidButton
              btnTitle="Xác thực OTP"
              form={form}
              htmlType="submit"
              type="primary"
              loading={isSubmitting}
            />
          </Space>
        </Form.Item>
      </div>
    </div>
  )
}

export default InputOTP

const CountDownAndResendRequest = ({ expires, setCurrent }) => {
  const [isTimeOut, setIsTimeOut] = useState(
    new Date(expires * 1000) <= new Date()
  )

  useEffect(() => {
    setIsTimeOut(new Date(expires * 1000) <= new Date())
  }, [expires])

  return (
    <div className="flex flex-col justify-center items-center">
      {isTimeOut ? (
        <p onClick={() => setCurrent(0)}>Mã OTP đã hết hạn</p>
      ) : (
        <Countdown
          value={expires * 1000}
          format="s"
          prefix={<p className="text-[#5A6F93]">Mã OTP hết hạn trong</p>}
          suffix="giây"
          valueStyle={{ color: '#EF4444', fontSize: '0.875rem' }}
          onFinish={() => setIsTimeOut(true)}
        />
      )}
    </div>
  )
}
