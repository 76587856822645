import { flow, get, gte, lte } from 'lodash/fp'
import { ACTIONS } from 'redux-api-call'
import { takeEvery } from 'redux-saga/effects'

const is5xxError = flow(get('payload.statusCode'), gte(500), lte(599))

function* onException(action) {
  try {
    if (is5xxError(action)) {
      const errorCode = get('payload.statusCode')(action)
      console.error(`onError: ${errorCode} internal error`)
    }
  } catch (error) {
    console.error('onError', error)
  }
}

export function* watchOnException() {
  yield takeEvery(ACTIONS.FAILURE, onException)
}
