import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_LIST_API_ID = 'BUSINESS/GET_PROGRAM_ORDERS_API'

const apiCall = enhancedMakeFetchAction(
  FETCH_LIST_API_ID,
  ({ userId, programId, ...params }) => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/investcore-api/flex/v1/${
        userId ? 'employees/' + userId : 'programs/' + programId
      }/program-orders`,
      params
    }),
    method: 'GET'
  })
)

export default apiCall
