import QRDownload from 'common/cta/QRDownload'
import StoreDownload from 'common/cta/StoreDownload'
import { cloudflareCdn } from 'utils/image'

const AuthLayout = ({ imgUrlPath, children }) => {
  const backgroundImageUrl = cloudflareCdn(imgUrlPath, 1000, 667)

  const renderForm = () => {
    return (
      <div className="flex flex-col md:gap-6 gap-2 p-5">
        {children}
        <div className="bg-[#EBF3FF] p-5 rounded-md flex flex-row">
          <QRDownload />
          <div className="p-5">
            Chưa có tài khoản? Tải ứng dụng Stag Invest và mở khóa tính năng hấp
            dẫn
            <StoreDownload />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="md:flex relative overflow-visible">
      <div className="md:h-screen md:w-[1/2] bg-[#0A1A41]">
        <div
          style={{
            backgroundImage: `url(${backgroundImageUrl})`
          }}
          className={`bg-center	bg-no-repeat bg-cover w-full flex-shrink md:h-full md:max-h-[51vh]`}
        >
          <div
            className={
              'md:hidden w-full justify-center items-center bg-white bg-opacity-70 backdrop-blur'
            }
          >
            {renderForm()}
          </div>
        </div>

        <div className="md:px-12 p-2 flex flex-col text-white h-fit flex-grow-0 ">
          <p className="text-4xl font-semibold">
            Phúc lợi song hành - Tương Lai Tỏa Sáng
          </p>
          <p>
            Giúp bạn xây dựng kế hoạch tài chính, tạo nền tảng vững chắc để đạt
            được mục tiêu và hưởng một cuộc sống an yên và phồn thịnh.
          </p>
        </div>
      </div>

      <div className="w-[1/2] h-screen justify-center items-center bg-transparent bg-white md:flex hidden">
        {renderForm()}
      </div>
    </div>
  )
}
export default AuthLayout
