import detailApiCall from './detail'
import editApiCall from './edit'
import listApiCall from './list'
import portApiCall from './portfolio'
import redistributionApiCall from './redistribution'
import statisticsApiCall from './statistics'

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  edit: editApiCall,
  portDetail: portApiCall,
  statistics: statisticsApiCall,
  redistribution: redistributionApiCall
}

export default ApiCall
