import { useFetchApiGet } from '@stagapps/redux-utils'
import epPortfolioApiCall from 'business/apiCalls/employeeProgramPortfolio'
import apiCall from 'business/apiCalls/program'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import InvestmentDetail from '../InvestmentDetail'

function EmployeeProgramDetail() {
  const { id } = useParams()

  const {
    data: program,
    load: fetchProgram,
    isLoading: isLoadingProgram,
    error: errorProgram
  } = useFetchApiGet(apiCall.detail, { resourceName: 'data' })

  const {
    data: portfolio,
    load: fetchPort,
    isLoading: isLoadingPort,
    error: errorPort
  } = useFetchApiGet(apiCall.portDetail, { resourceName: 'data' })

  const {
    data: flexPortfolio,
    load: fetchFlexPortfolio,
    isLoading: isLoadingFlexPort,
    error: errorFlexPort
  } = useFetchApiGet(epPortfolioApiCall.flexPortfolio, { resourceName: 'data' })

  const isLoading = isLoadingProgram || isLoadingPort || isLoadingFlexPort
  const error = errorProgram || errorPort || errorFlexPort

  useEffect(() => {
    fetchProgram({ id })
    fetchPort({ id })
    fetchFlexPortfolio({ id })
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  return (
    <InvestmentDetail
      program={program}
      portfolio={portfolio}
      flexPortfolio={flexPortfolio}
    />
  )
}

export default EmployeeProgramDetail
