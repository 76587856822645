import moment from 'moment'

export const getStartDate = ({ monthly_order_payment }) => {
  if (!monthly_order_payment) {
    return null
  }

  const currentDate = new Date()
  const currentDayNumber = currentDate.getDate()

  const newDate = new Date()
  newDate.setDate(parseInt(monthly_order_payment))

  if (parseInt(monthly_order_payment) < currentDayNumber) {
    newDate.setMonth(currentDate.getMonth() + 1)

    return newDate.toISOString()
  } else return newDate.toISOString()
}

export function calculateEndDate(startDate, years, months) {
  const endDate = new Date(startDate)

  if (!(endDate instanceof Date) || isNaN(years) || isNaN(months)) {
    return '-'
  }

  endDate.setFullYear(endDate.getFullYear() + Number(years))
  endDate.setMonth(endDate.getMonth() + Number(months))

  // Format the date as 'MM/YYYY'
  const month = String(endDate.getMonth() + 1).padStart(2, '0') // Months are 0-indexed in JavaScript
  const year = endDate.getFullYear()
  return `${month}/${year}`
}

export const getEndDate = ({ monthly_order_payment, end_month }) => {
  if (!monthly_order_payment) {
    return null
  }
  return moment(monthly_order_payment + '/' + end_month, 'DD/MM/YYYY')
    .add(1, 'months')
    .format('YYYY-MM-DD')
}
