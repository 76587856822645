import { CloseOutlined, ReloadOutlined } from '@ant-design/icons'
import {
  useFetchApiGet,
  useFetchApiList,
  usePostApi
} from '@stagapps/redux-utils'
import { Alert, Button, Form, Image, InputNumber, Space, Table } from 'antd'
import apiCall from 'app/apiCalls/employeeOnboarding'
import apiProductsCall from 'app/apiCalls/products'
import apiSurveysCall from 'app/apiCalls/surveys/list'
import InvestmentDetailDonutChart, {
  pieColors
} from 'app/components/employeeProgram/InvestmentDetailDonutChart'
import { riskValues } from 'app/components/riskAppetite/config'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import ValidButton from 'common/ui/form/ValidButton'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { history } from 'routerHistory'
import { deepEqual } from 'utils/deepEqual'
import { formatCurrency } from 'utils/formatAmount'
import { formatTableColumns } from 'utils/tableConfig'

function convertObjectToArray(inputObject) {
  return Object.entries({ ...inputObject }).map(
    ([product_code, percentage]) => ({
      product_code,
      percentage
    })
  )
}

function sum(inputObject) {
  if (isEmpty(inputObject)) return 0
  const arr = Object.values(inputObject)
  if (!arr || arr.length < 1) return 0
  const sum = arr?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  )

  return sum
}

function getRemaining(inputObject) {
  const total = sum(inputObject)
  if (total >= 100) return {}

  return {
    'Còn lại': 100 - total
  }
}

function OnboardingInvestmentPlan() {
  const params = useParams()
  const [form] = Form.useForm()

  const { id, programId } = params

  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const {
    data: employeeProgram,
    load: loadEmployeeProgram,
    isLoading: isLoadingEmployeeProgram,
    error: employeeProgramError
  } = useFetchApiGet(apiCall.detail, {
    resourceName: 'data'
  })

  const {
    data: surveys,
    load: loadSurveys,
    isLoading: isLoadingSurveys,
    error: errorSurveys
  } = useFetchApiList(apiSurveysCall, {
    resourceName: 'data'
  })

  const {
    data: responseData,
    post,
    isLoading: isSubmitting
  } = usePostApi(apiCall.investmentPlan, { resourceName: 'data' })

  const location = `/employees/${params.id}/programs/${params.programId}`

  useEffectSkipFirstRender(() => {
    if (!!responseData) {
      history.replace(location)
    }
  }, [responseData])

  const formData = Form.useWatch([], form) || {}
  const {
    data,
    load,
    isLoading: isLoadingProducts,
    error: errorProducts
  } = useFetchApiList(apiProductsCall, {
    resourceName: 'data'
  })

  useEffect(() => {
    load()
    loadEmployeeProgram(params)
    loadSurveys({ survey_type: 1002 })
  }, [])

  const isLoading =
    isLoadingProducts || isLoadingEmployeeProgram || isLoadingSurveys
  const error = errorProducts || employeeProgramError || errorSurveys

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  const {
    employee_contribute_amount,
    company_contribute_amount,
    is_set_investment_plan
  } = employeeProgram || {}

  if (is_set_investment_plan) {
    return <Navigate to={location} />
  }

  const { answer_codes } = surveys[0] || {}
  if (!answer_codes) {
    return (
      <Navigate to={`/${id}/employee-programs/${programId}/risk-appetite`} />
    )
  }

  const { defaultConfigValues, defaultSelectedProduct, riskAppetite } =
    riskValues[answer_codes] || {}

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  const totalPercentage = sum(formData)
  const checkTotalPercentage = totalPercentage === 100

  const selectedColumns = selectedColumnsGenerator({
    employee_contribute_amount,
    company_contribute_amount,
    formData,
    setSelectedRowKeys,
    checkTotalPercentage
  })

  const setDefaultSelectedProduct = () => {
    setSelectedRowKeys(defaultSelectedProduct?.split(','))
    form.setFieldsValue(defaultConfigValues)
  }

  const disabledReload = deepEqual(defaultConfigValues, formData)

  const onFinish = values => {
    let investment_details = []
    const tempArr = convertObjectToArray(values)
    tempArr?.map(({ product_code, percentage }) => {
      return data?.filter(({ id: product_id, code }) => {
        if (product_code === code) {
          investment_details.push({ product_id, percentage })
        }
      })
    })
    post({
      investment_details,
      ...params
    })
  }

  const selectedList = data.filter(product => {
    const found = selectedRowKeys.find(id => id === product.id)
    if (found) {
      return product
    }
  })

  return (
    <Form
      className="py-6 md:px-10 px-2"
      requiredMark={false}
      form={form}
      onFinish={onFinish}
    >
      <div className="flex flex-col md:gap-6 gap-2 md:w-full w-[96vw]">
        <UICard
          className="md:w-fit md:mx-auto overflow-x-scroll"
          title="Gợi ý tỉ lệ danh mục phù hợp"
        >
          <div className="flex flex-col gap-4 ">
            <div className="text-[#5A6F93] text-xs">
              Hệ thống sẽ dựa vào tỉ lệ danh mục lựa chọn của bạn để đặt lệnh
              mua tương ứng vào mỗi kỳ đóng góp.
            </div>

            {isEmpty(formData) ? null : (
              <div className="flex md:flex-row flex-col items-center justify-center py-4 gap-4">
                <div className="w-56 h-56">
                  <InvestmentDetailDonutChart
                    style={{ width: '100%', height: '100%' }}
                    data={convertObjectToArray({
                      ...getRemaining(formData),
                      ...formData
                    })}
                    label={false}
                    color={
                      checkTotalPercentage
                        ? pieColors
                        : ['#0A1A410F', ...pieColors]
                    }
                    legend={false}
                    appendPadding={0}
                  />
                </div>
                <div className="flex md:flex-col flex-wrap gap-2 ">
                  {convertObjectToArray(formData)?.map((item, index) => (
                    <div
                      key={item?.product_code}
                      className="flex gap-2 items-center justify-between text-xs font-semibold"
                    >
                      <div
                        className="w-2 h-2 rounded-full"
                        style={{
                          backgroundColor: pieColors[index % pieColors.length]
                        }}
                      />
                      <div className="">{`${item?.product_code}`}</div>
                      <div>:</div>
                      <div className="text-right">{`${item?.percentage}%`}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {isEmpty(formData) ? null : (
              <div className="flex items-center gap-4">
                <div className="flex gap-2">
                  {disabledReload ? <OffLightSVG /> : <OnLightSVG />}
                  <div>
                    {'Áp dụng cho khẩu vị đầu tư: '}
                    <span className="font-semibold">{riskAppetite}</span>
                  </div>
                </div>
                <Button
                  type="primary"
                  ghost
                  onClick={setDefaultSelectedProduct}
                  disabled={disabledReload}
                >
                  <Space>
                    <ReloadOutlined />
                    <div>Thiết lập lại</div>
                  </Space>
                </Button>
              </div>
            )}

            <Table
              className="overflow-x-scroll"
              columns={formatTableColumns(selectedColumns)}
              dataSource={selectedList}
              showHeader={selectedList?.length > 0 ? true : false}
              pagination={false}
              rowKey={'id'}
              locale={localeGenerator({
                ...riskValues[answer_codes],
                setDefaultSelectedProduct
              })}
            />

            {checkTotalPercentage ? null : (
              <Alert
                message="Tổng tỉ lệ phân bổ phải đạt 100%"
                type="error"
                showIcon
              />
            )}
          </div>
        </UICard>
        <UICard title="Sản phẩm chứng chỉ quỹ">
          <Table
            className="overflow-x-scroll"
            columns={formatTableColumns(columns)}
            dataSource={data}
            pagination={false}
            rowSelection={rowSelection}
            rowKey={'id'}
          />
        </UICard>

        <Form.Item>
          <div className="flex justify-center">
            <ValidButton
              form={form}
              type="primary"
              htmlType="submit"
              disabled={!checkTotalPercentage}
              loading={isSubmitting}
            />
          </div>
        </Form.Item>
      </div>
    </Form>
  )
}

export default OnboardingInvestmentPlan

const columns = [
  {
    title: 'Sản phẩm Quỹ',
    dataIndex: 'id',
    render: (_, data) => {
      const { logo, code, alias, fund_type_display } = data || {}

      return (
        <div className="flex md:flex-row flex-col gap-2 md:items-center">
          <Image width={32} src={logo} />
          <Space direction="vertical" size="small">
            <div className="font-semibold">{alias || code}</div>
            <div>{fund_type_display}</div>
          </Space>
        </div>
      )
    }
  },
  {
    title: 'Tổ chức phát hành',
    dataIndex: 'fund_manager',
    render: value => value?.toUpperCase()
  },
  {
    title: 'Ngày thành lập',
    dataIndex: 'inception_date',
    type: 'date'
  },
  {
    title: '12 tháng gần nhất',
    dataIndex: 'change_percentage_1y',
    type: 'percentage'
  },
  {
    title: 'Mức độ rủi ro',
    dataIndex: 'risk_level_label'
  },
  {
    title: 'Mua tối thiểu',
    dataIndex: 'buy_minimum_amount',
    type: 'formatCurrency'
  }
]

const selectedColumnsGenerator = ({
  employee_contribute_amount,
  company_contribute_amount,
  contribute_amount,
  formData,
  setSelectedRowKeys,
  checkTotalPercentage
}) => {
  return [
    {
      title: 'Sản phẩm Quỹ',
      dataIndex: 'code',
      onHeaderCell: () => ({ style: { minWidth: 120 } }),
      render: (_, data) => {
        const { logo, code, alias, fund_type_display } = data || {}

        return (
          <div className="flex md:flex-row flex-col gap-2 md:items-center">
            <Image width={32} src={logo} />
            <Space direction="vertical" size="small">
              <div className="font-semibold">{alias || code}</div>
              <div>{fund_type_display}</div>
            </Space>
          </div>
        )
      }
    },
    {
      title: 'Giá trị mua tối thiểu (VND)',
      dataIndex: 'buy_minimum_amount',
      type: 'formatCurrency',
      onHeaderCell: () => ({ style: { minWidth: 120 } })
    },
    {
      title: 'Tỉ lệ',
      dataIndex: 'code',
      onHeaderCell: () => ({ style: { minWidth: 90 } }),
      render: (code, data) => {
        const { buy_minimum_amount } = data || {}
        return (
          <Form.Item
            validateStatus={checkTotalPercentage ? null : 'error'}
            hasFeedback
            className="m-0 w-20"
            name={code}
            rules={[
              {
                required: true,
                message: ''
              },
              {
                type: 'number',
                min: (buy_minimum_amount / contribute_amount) * 100,
                message: ''
              }
            ]}
          >
            <InputNumber
              className="w-full"
              suffix="%"
              min={1}
              max={100}
              precision={0}
            />
          </Form.Item>
        )
      }
    },
    {
      title: `Nhân viên tích lũy: ${formatCurrency(
        employee_contribute_amount
      )}`,
      dataIndex: 'code',
      render: (code, data) => {
        const { buy_minimum_amount } = data || {}
        const percentage = formData[code]
        const isValid = checkValid({
          buy_minimum_amount,
          contribute_amount: employee_contribute_amount,
          percentage
        })
        return (
          <div className={isValid ? '' : 'text-red-500'}>
            {percentage || percentage === 0
              ? formatCurrency((percentage * employee_contribute_amount) / 100)
              : '-'}
            {isValid ? null : (
              <div className="text-xs">{`Tối thiểu: ${formatCurrency(
                buy_minimum_amount
              )}`}</div>
            )}
          </div>
        )
      },
      align: 'right'
    },
    {
      title: `Doanh nghiệp đóng góp: ${formatCurrency(
        company_contribute_amount
      )}`,
      dataIndex: 'code',
      render: (code, data) => {
        const { buy_minimum_amount } = data || {}
        const percentage = formData[code]
        const isValid = checkValid({
          buy_minimum_amount,
          contribute_amount: company_contribute_amount,
          percentage
        })
        return (
          <div className={isValid ? '' : 'text-red-500'}>
            {percentage || percentage === 0
              ? formatCurrency((percentage * company_contribute_amount) / 100)
              : '-'}
            {isValid ? null : (
              <div className="text-xs">{`Tối thiểu: ${formatCurrency(
                buy_minimum_amount
              )}`}</div>
            )}
          </div>
        )
      },
      align: 'right'
    },
    {
      dataIndex: 'id',
      render: id => (
        <Button
          onClick={() => setSelectedRowKeys(prev => prev.filter(e => e !== id))}
          size="small"
          className="flex justify-center items-center"
          shape="circle"
          icon={<CloseOutlined />}
        />
      ),
      align: 'right'
    }
  ]
}

const checkValid = ({ contribute_amount, buy_minimum_amount, percentage }) => {
  if (contribute_amount === 0) return true
  const minPercentage = (buy_minimum_amount / contribute_amount) * 100
  return minPercentage <= percentage
}

let localeGenerator = ({
  defaultConfigValues,
  riskAppetite,
  riskAppetiteDesc,
  setDefaultSelectedProduct
}) => {
  return {
    emptyText: (
      <div className="flex md:flex-row flex-col gap-6">
        <InvestmentDetailDonutChart
          data={convertObjectToArray(defaultConfigValues)}
          innerRadius={0.63}
          label={{
            autoRotate: false,
            type: 'inner',
            offset: '-50%',
            content: '{value}%',
            style: {
              textAlign: 'center',
              fontSize: 22,
              fontWeight: 'regular'
            }
          }}
          style={{ height: 171, width: 222 }}
          legend={{
            position: 'right',
            showContent: true,
            offsetX: -60,
            itemName: {
              style: {
                fontSize: 12,
                fontWeight: 'regular'
              }
            }
          }}
        />
        <div className="text-[#0A1A41]	text-left max-w-md">
          <p className="text-base">
            {'Khẩu vị đầu tư: '}
            <span className="font-bold">{riskAppetite}</span>
          </p>
          <p className="text-xs">{riskAppetiteDesc}</p>
          <Button onClick={setDefaultSelectedProduct} type="primary">
            Áp dụng đề xuất
          </Button>
        </div>
      </div>
    )
  }
}

const OnLightSVG = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#FADB14"
      d="M16.441 13.237c-.68.931-1.364 1.87-1.691 2.963h-5.5c-.33-1.097-1.014-2.032-1.691-2.963a36.448 36.448 0 0 1-.53-.736 6.05 6.05 0 1 1 9.941-.004c-.171.248-.35.492-.529.736v.004ZM9.25 20.6v-3.3h5.5v3.3h-5.5ZM12 6.3V5.2a3.848 3.848 0 0 0-3.85 3.85h1.1A2.75 2.75 0 0 1 12 6.3ZM4 3.55l.207.138.825.55.688.457-.915 1.375-.687-.458-.825-.55-.206-.137-.688-.457.914-1.375.688.457Zm16.913 1.375-.206.138-.825.55-.687.457-.915-1.375.688-.458.825-.55.206-.137.688-.457.914 1.371-.688.461ZM1.825 8.5H4.3v1.65H1V8.5h.825Zm18.7 0H23v1.65h-3.3V8.5h.825ZM5.032 14.412l-.825.55-.206.138-.688.457-.914-1.375.688-.457.206-.137.825-.55.687-.458.915 1.372-.688.457v.003Zm14.85-1.371.825.55.206.137.688.458-.914 1.371-.688-.457-.206-.137-.825-.55-.688-.458.915-1.371.687.457Z"
    />
  </svg>
)

const OffLightSVG = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#C0CCDB"
      d="M16.441 13.237c-.68.931-1.364 1.87-1.691 2.963h-5.5c-.33-1.097-1.014-2.032-1.691-2.963a36.448 36.448 0 0 1-.53-.736 6.05 6.05 0 1 1 9.941-.004c-.171.248-.35.492-.529.736v.004ZM9.25 20.6v-3.3h5.5v3.3h-5.5ZM12 6.3V5.2a3.848 3.848 0 0 0-3.85 3.85h1.1A2.75 2.75 0 0 1 12 6.3ZM4 3.55l.207.138.825.55.688.457-.915 1.375-.687-.458-.825-.55-.206-.137-.688-.457.914-1.375.688.457Zm16.913 1.375-.206.138-.825.55-.687.457-.915-1.375.688-.458.825-.55.206-.137.688-.457.914 1.371-.688.461ZM1.825 8.5H4.3v1.65H1V8.5h.825Zm18.7 0H23v1.65h-3.3V8.5h.825ZM5.032 14.412l-.825.55-.206.138-.688.457-.914-1.375.688-.457.206-.137.825-.55.687-.458.915 1.372-.688.457v.003Zm14.85-1.371.825.55.206.137.688.458-.914 1.371-.688-.457-.206-.137-.825-.55-.688-.458.915-1.371.687.457Z"
    />
  </svg>
)
