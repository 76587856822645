import confirmApiCall from './confirm'
import detailApiCall from './detail'
import employeeApiCall from './employeeDetail'
import investmentPlanApiCall from './investmentPlan'

const ApiCall = {
  detail: detailApiCall,
  confirm: confirmApiCall,
  employee: employeeApiCall,
  investmentPlan: investmentPlanApiCall
}

export default ApiCall
