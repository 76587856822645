import i18n from 'config/translate/I18n'

export const getErrorMessageWithCode = response => {
  var errorMessage = null
  var error = null
  if (response && response['error'] !== undefined) {
    error = response['error']
    errorMessage = response['error_code']
      ? `${response['error_code']}: ${response['error']}`
      : response['error']
  } else {
    errorMessage = JSON.stringify(response)
  }

  return i18n.t(error, {
    defaultValue: errorMessage
  })
}

export const getErrorMessage = response => {
  var errorMessage = null
  var error = null
  if (response && response['error'] !== undefined) {
    error = response['error']
    errorMessage = response['error']
  } else {
    errorMessage = JSON.stringify(response)
  }

  return i18n.t(error, {
    defaultValue: errorMessage
  })
}

export const renderErrorMessage = response => {
  const { error = '', error_code = '' } = response || {}
  return i18n.t(error_code, {
    defaultValue: error
  })
}
