import { usePostApi } from '@stagapps/redux-utils'
import { Form, Spin, Statistic, Typography } from 'antd'
import apiCall from 'app/apiCalls/account/requestVerifyEmail'
import apiVerifyEmailCall from 'app/apiCalls/account/verifyEmail'
import StagLogoSVG from 'common/svg/logo/stag'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import AuthLayout from 'app/components/layouts/AuthLayout'
import ValidButton from 'common/ui/form/ValidButton'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import { convertEmailCharacters } from 'utils/hideCharacter'

const { Title } = Typography
const { Countdown } = Statistic

function VerifyEmailPage() {
  let [searchParams] = useSearchParams()
  const urlQuery = searchParams.get('url')

  const [form] = Form.useForm()
  const { email, expires } = useParams()

  const {
    data,
    post,
    isLoading: isSubmitting,
    error
  } = usePostApi(apiVerifyEmailCall, { resourceName: 'data' })

  const onFinish = values => {
    post({ email, ...values })
    form.resetFields()
  }

  const [test, setTest] = useState(null)

  const handleChange = value => setTest(value)

  return (
    <AuthLayout imgUrlPath={'/futureflex/images/login_employee_v2.jpeg'}>
      <div className="flex flex-row gap-2 items-center">
        <div className="flex md:w-[134px] w-32 aspect-[134/31]">
          <StagLogoSVG />
        </div>
      </div>
      <div>
        <Title level={4}>Xác thực địa chỉ email</Title>
        <p>
          {`Nhập mã OTP được gửi đến email của bạn ${convertEmailCharacters(
            email
          )}`}
        </p>
      </div>

      <Form
        requiredMark={false}
        initialValues={{
          remember: true
        }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          validateStatus={!!error ? 'error' : null}
          hasFeedback
          help={error?.error_code}
          labelCol={{ span: 24 }}
          name="code"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập OTP'
            }
          ]}
        >
          <MuiOtpInput
            autoFocus
            value={test}
            onChange={handleChange}
            length={6}
            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
          />
        </Form.Item>
        <CountDownAndResendRequest
          expires={expires}
          email={email}
          urlQuery={urlQuery}
        />
        <Form.Item>
          <ValidButton
            size="large"
            type="primary"
            htmlType="submit"
            className="w-full"
            btnTitle="Xác thực OTP"
            form={form}
            loading={isSubmitting}
          />
        </Form.Item>
      </Form>
    </AuthLayout>
  )
}

export default VerifyEmailPage

const CountDownAndResendRequest = ({ expires, email, urlQuery }) => {
  const [isTimeOut, setIsTimeOut] = useState(
    new Date(expires * 1000) <= new Date()
  )
  const navigate = useNavigate()

  const { post, isLoading: isSubmitting, error, data } = usePostApi(apiCall, {
    resourceName: 'data'
  })
  const { error: errorMessage, error_code } = error || {}

  useEffectSkipFirstRender(() => {
    if (data) {
      const { expires_at } = data
      navigate(`/verification/email/${email}/${expires_at}?url=${urlQuery}`, {
        replace: true
      })
    }
  }, [data])

  return (
    <div className="flex flex-col justify-center items-center">
      {isTimeOut ? (
        isSubmitting ? (
          <Spin />
        ) : (
          <>
            <Link>
              <p onClick={() => post({ email })}>Gửi lại OTP</p>
            </Link>
            <p className="text-red-500">{errorMessage}</p>
          </>
        )
      ) : (
        <Countdown
          value={expires * 1000}
          format="s"
          prefix={<p className="text-[#5A6F93]">Mã OTP hết hạn trong</p>}
          suffix="giây"
          valueStyle={{ color: '#EF4444', fontSize: '0.875rem' }}
          onFinish={() => setIsTimeOut(true)}
        />
      )}
    </div>
  )
}
