import { useFetchApiList } from '@stagapps/redux-utils'
import { Empty } from 'antd'
import userProgramOrderApiCall from 'app/apiCalls/userProgramOrder'
import MetaDecorator from 'common/layout/MetaDecorator'
import PageTitle from 'common/layout/PageTitle'
import DataTable from 'common/ui/DataTable'
import ErrorMessage from 'common/ui/ErrorMessage'
import UICard from 'common/ui/display/UICard'
import UIQueryTabs from 'common/ui/display/UIQueryTabs'
import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { formatTableColumns } from 'utils/tableConfig'
import {
  buyOrdersColumnsGenerator,
  ordersColumnsGenerator,
  sellOrdersColumnsGenerator
} from './config'

function UserProgramOrderListPage() {
  const { id } = useParams()
  let [searchParams, setSearchParams] = useSearchParams()
  const activeKey = searchParams.get('key')

  const buyFilter = {
    programId: id,
    'orders.status__in': '1,2',
    order_type: 'buy'
  }
  const sellFilter = {
    programId: id,
    'orders.status__in': '1,2',
    order_type: 'sell'
  }
  const allFilter = { programId: id, 'orders.status__in': '3,4,5' }

  const {
    data: programOrders,
    load: fetchUPOrders,
    isLoading: isLoadingFundOrder,
    error: errorFundOrder
  } = useFetchApiList(userProgramOrderApiCall.list, {
    resourceName: 'data'
  })

  const tabItems = [
    {
      label: 'Lệnh chờ mua',
      key: 'buy',
      children: (
        <FilteredTable
          items={programOrders}
          isLoading={isLoadingFundOrder}
          error={errorFundOrder}
          fetchItems={fetchUPOrders}
          filters={buyFilter}
          columns={buyOrdersColumnsGenerator({ id })}
        />
      )
    },
    {
      label: 'Lệnh chờ bán',
      key: 'sell',
      children: (
        <FilteredTable
          items={programOrders}
          isLoading={isLoadingFundOrder}
          error={errorFundOrder}
          fetchItems={fetchUPOrders}
          filters={sellFilter}
          columns={sellOrdersColumnsGenerator({ id })}
        />
      )
    },
    {
      label: 'Lịch sử giao dịch',
      key: 'all',
      children: (
        <FilteredTable
          items={programOrders}
          isLoading={isLoadingFundOrder}
          error={errorFundOrder}
          fetchItems={fetchUPOrders}
          filters={allFilter}
          columns={ordersColumnsGenerator({ id })}
        />
      )
    }
  ]

  useEffect(() => {
    if (activeKey !== 'buy' && activeKey !== 'sell' && activeKey !== 'all') {
      return setSearchParams({ key: 'buy' }, { replace: true })
    }

    if (activeKey === 'buy') {
      fetchUPOrders(buyFilter)
    } else if (activeKey === 'sell') {
      fetchUPOrders(sellFilter)
    } else if (activeKey === 'all') {
      fetchUPOrders(allFilter)
    }
  }, [activeKey])

  return (
    <>
      <MetaDecorator title={'Chương trình cá nhân - Danh sách lệnh'} />
      <PageTitle title={'Tất cả lệnh'} />
      <UICard>
        <UIQueryTabs defaultQuery="buy" items={tabItems} type="card" />
      </UICard>
    </>
  )
}

export default UserProgramOrderListPage

const FilteredTable = ({
  items,
  isLoading,
  error,
  paging,
  fetchItems,
  filters,
  columns
}) => {
  if (!!error) {
    return <ErrorMessage errors={error} />
  }
  const { count, page, page_size } = paging || {}

  return (
    <DataTable
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={'Hiện tại chưa có lệnh nào.'}
          />
        )
      }}
      columns={formatTableColumns(columns)}
      dataSource={items}
      showHeader={items?.length > 0 ? true : false}
      loading={isLoading}
      totalItems={count}
      currentPage={page}
      defaultCurrent={1}
      defaultPageSize={page_size}
      pageSize={page_size}
      dataFetcher={fetchItems}
      filters={filters}
    />
  )
}
