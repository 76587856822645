import { Card, Space, Typography } from 'antd'
import useCheckMobileDevice from 'hooks/useCheckMobileDevice'

const { Title } = Typography

function UICard({ title, children, extra, extraTitle, className, ...props }) {
  const { isMobile } = useCheckMobileDevice()

  const cardClassName = `shadow-custom ${className || ''}`

  return (
    <Card
      size={isMobile ? 'small' : 'default'}
      {...props}
      className={cardClassName}
    >
      {title || extra ? (
        <div className="flex justify-between mb-5">
          {!title && !extraTitle ? (
            <div></div>
          ) : (
            <Space>
              {title ? <Title level={4}>{title}</Title> : null}
              {extraTitle}
            </Space>
          )}
          {extra}
        </div>
      ) : null}
      <div className="">{children}</div>
    </Card>
  )
}

export default UICard
