import { useFetchApiList } from '@stagapps/redux-utils'
import { Button } from 'antd'
import apiCall from 'business/apiCalls/employee'
import MetaDecorator from 'common/layout/MetaDecorator'
import PageTitle from 'common/layout/PageTitle'
import DataTable from 'common/ui/DataTable'
import EmptyList from 'common/ui/EmptyList'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import DebounceSearchInput from 'common/ui/form/DebounceSearchInput'
import { useEffect } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { columns } from './config'

function EmployeeListPage() {
  const title = 'Quản trị nhân sự'
  let [searchParams] = useSearchParams()
  const location = useLocation()
  const search = searchParams.get('search')

  const {
    data: items,
    load: fetchItems,
    isLoading,
    paging,
    error
  } = useFetchApiList(apiCall.list, { resourceName: 'data' })

  useEffect(() => {
    fetchItems(Object.fromEntries(new URLSearchParams(location.search)))
  }, [searchParams])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  if (!search && !items.length) {
    return (
      <>
        <MetaDecorator title={title} />
        <PageTitle title={title} />
        <EmptyList
          title="Danh sách nhân viên trống"
          desc="Hiện tại chưa có nhân viên nào trong danh sách. Bạn có thể tạo mới nhân viên tại đây."
          CTA={() => (
            <Link to={`/employees/create`}>
              <Button type="primary">Tạo nhân viên mới</Button>
            </Link>
          )}
        />
      </>
    )
  }

  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle
        title={title}
        extra={
          <Link to={`/employees/create`}>
            <Button type="primary">Thêm nhân viên mới</Button>
          </Link>
        }
      />
      <UICard title={'Danh sách nhân sự'}>
        <div className="flex flex-col gap-4">
          <DebounceSearchInput defaultValue={search} />
          <DataTable
            rowKey="code"
            columns={columns}
            dataSource={items}
            totalItems={paging?.count}
            currentPage={paging?.page}
            defaultCurrent={1}
            defaultPageSize={paging?.page_size}
            pageSize={paging?.page_size}
            dataFetcher={fetchItems}
            loading={isLoading}
          />
        </div>
      </UICard>
    </>
  )
}

export default EmployeeListPage
