import { Divider, Space } from 'antd'
import { isNumber } from 'lodash'

import UICard from 'common/ui/display/UICard'
import UIInfoTooltip from 'common/ui/display/UIInfoTooltip'
import { getChangeValueClassColor } from 'config/color'
import { formatCurrency, formatPercentage } from 'utils/formatAmount'
import { formatAmountChangeWithPrefix } from 'utils/formatAmount/formatUI'

function Summary({
  total_summary,
  company_summary,
  employee_summary,
  isSellAvalible
}) {
  const {
    contributed_amount,
    market_value,
    unrealized_gain_loss_amount,
    unrealized_gain_loss_percentage
  } = total_summary || {}
  return (
    <div className="flex-grow flex flex-col gap-6">
      <div className="flex-grow">
        <TitleAndTooltip
          title={'Tổng vốn đã góp'}
          tooltip={
            'Tổng vốn Nhân viên và Doanh nghiệp đã đóng góp vào chương trình này'
          }
        />
        <Value fontSize="text-3xl" value={contributed_amount} />
        {isSellAvalible ? null : (
          <>
            <Divider />
            <Space size={'large'}>
              <MarketValue value={market_value} />

              <UnrealizedGainLoss
                value={unrealized_gain_loss_amount}
                percentage={unrealized_gain_loss_percentage}
              />
            </Space>
          </>
        )}
      </div>
      <div className="flex flex-col gap-4">
        <Contribute
          {...employee_summary}
          title="Vốn Nhân viên đã tích lũy"
          tooltip="Tổng vốn Nhân viên đã tích lũy vào chương trình này"
          isSellAvalible={isSellAvalible}
        />
        <Contribute
          {...company_summary}
          title="Vốn Doanh nghiệp đã góp"
          tooltip="Tổng vốn Doanh nghiệp đã đóng góp vào chương trình này"
          isSellAvalible={isSellAvalible}
        />
      </div>
    </div>
  )
}

export default Summary

const TitleAndTooltip = ({ title, tooltip }) => {
  return (
    <Space>
      <p className="text-xs text-grey-400">{title}</p>
      <UIInfoTooltip title={tooltip} />
    </Space>
  )
}

const Value = ({ value, fontSize = 'text-base' }) => (
  <div className={'font-semibold ' + fontSize}>
    {isNumber(value) && formatCurrency(value)}
  </div>
)

const Contribute = ({
  contributed_amount,
  title,
  tooltip,
  market_value,
  unrealized_gain_loss_amount,
  unrealized_gain_loss_percentage,
  isSellAvalible
}) => (
  <UICard size="small">
    <div className="flex md:flex-row flex-col md:justify-between md:items-center gap-2">
      <div className="md:w-[40%]">
        <TitleAndTooltip title={title} tooltip={tooltip} />
        <Value fontSize="text-xl" value={contributed_amount} />
      </div>
      {isSellAvalible ? null : (
        <>
          <div className="md:w-[30%]">
            <MarketValue value={market_value} />
          </div>
          <div className="md:w-[30%]">
            <UnrealizedGainLoss
              value={unrealized_gain_loss_amount}
              percentage={unrealized_gain_loss_percentage}
            />
          </div>
        </>
      )}
    </div>
  </UICard>
)

const MarketValue = props => (
  <div>
    <div>Giá trị hiện tại</div>
    <Value {...props} />
  </div>
)
const UnrealizedGainLoss = ({ value, percentage }) => (
  <div>
    <div>Lãi/lỗ</div>
    <div className={getChangeValueClassColor(value)}>
      <Space>
        <div className={'font-semibold text-base'}>
          {formatAmountChangeWithPrefix(value)}
        </div>
        {`(${formatPercentage(percentage)})`}
      </Space>
    </div>
  </div>
)
