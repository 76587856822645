import { Link } from 'react-router-dom'

import { Image, Space, Tag } from 'antd'

import { getChangeValueClassColor } from 'config/color'
import { formatPercentage, formatUnitQuantity } from 'utils/formatAmount'

import { orderStatusColors } from 'data/fundOrder'
import { formatCurrencyWithoutCurrencySymbol } from 'utils/formatAmount/formatUI'

export const portColumn = [
  {
    title: 'Mã CCQ',
    dataIndex: 'product',
    render: e => (
      <Space>
        {e?.logo ? (
          <Image width={20} className="object-contain	" src={e.logo} />
        ) : null}
        <div className="font-semibold text-blue-primary-500">
          {e.alias || e.code}
        </div>
      </Space>
    )
  },
  {
    title: 'Số dư CCQ',
    dataIndex: 'quantity',
    render: value => (value ? formatUnitQuantity(value) : '-'),
    align: 'right'
  },
  {
    title: 'Số CCQ khả dụng',
    dataIndex: 'available_quantity',
    render: value => (value ? formatUnitQuantity(value) : '-'),
    align: 'right'
  },
  {
    title: 'Giá trị hiện tại',
    dataIndex: 'market_value',
    type: 'formatCurrency'
  },
  {
    title: 'Lãi/Lỗ (VND)',
    dataIndex: 'unrealized_gain_loss',
    render: value => (
      <p className={getChangeValueClassColor(value)}>
        {formatCurrencyWithoutCurrencySymbol(value)}
      </p>
    ),
    align: 'right'
  },
  {
    title: 'Lãi/Lỗ (%)',
    dataIndex: 'unrealized_gain_loss_percentage',
    render: value => (
      <p className={getChangeValueClassColor(value)}>
        {formatPercentage(value)}
      </p>
    ),
    align: 'right'
  }
]

export const sellOrderColumn = [
  {
    title: 'Mã lệnh',
    dataIndex: 'order',
    align: 'center',
    render: (order, record) => {
      if (!order) return null
      return (
        <Link
          to={`/employees/${record.employee_id}/programs/${record.employee_program_id}/program-orders/${record.id}`}
        >
          {order.fund_order_id}
        </Link>
      )
    }
  },
  {
    title: 'Sản phẩm',
    dataIndex: 'product',
    render: product => (
      <Space>
        {product?.logo ? (
          <Image width={20} className="object-contain	" src={product.logo} />
        ) : null}
        <div className="font-semibold text-blue-primary-500">
          {product?.alias || product?.code}
        </div>
      </Space>
    )
  },
  {
    title: 'Ngày bán',
    dataIndex: 'created_at',
    type: 'dateTime'
  },
  {
    title: 'Số lượng CCQ',
    dataIndex: 'quantity',
    type: 'formatNumber'
  },
  {
    title: 'Giá NAV đã khớp',
    dataIndex: 'order',
    align: 'right',
    render: order => formatCurrencyWithoutCurrencySymbol(order?.matched_price)
  },
  {
    title: 'Giá trị đã khớp',
    dataIndex: 'order',
    align: 'right',
    render: order => formatCurrencyWithoutCurrencySymbol(order?.net_amount)
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: (value, record) => {
      return <Tag color={orderStatusColors[value]}>{record?.status_label}</Tag>
    }
  }
]
