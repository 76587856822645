import { AnalyticsBrowser } from '@segment/analytics-next'

const useAnalytics = () => {
  const isEnabled = !!process.env.REACT_APP_SEGMENT_API_KEY || false
  if (!isEnabled)
    return { analytics: { identify: () => {} }, logPageViewed: () => {} }

  const analytics =
    AnalyticsBrowser.load({
      writeKey: process.env.REACT_APP_SEGMENT_API_KEY
    }) || {}

  function logPageViewed() {
    console.debug(
      'Segment pageview tracked',
      document.title,
      document.location.pathname
    )

    analytics.page(document.title)
  }

  return { analytics, logPageViewed }
}

export default useAnalytics
