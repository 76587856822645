import { FLEX_APP, STAG_APP_NAME } from 'config/env.config'
import { Helmet } from 'react-helmet-async'

const MetaDecorator = ({ title, description = '' }) => {
  return (
    <Helmet>
      <title>{`${title} | ${STAG_APP_NAME[FLEX_APP]}`}</title>
      <meta name="description" content={description} />
    </Helmet>
  )
}

export default MetaDecorator
