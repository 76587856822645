const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'https://m-dev.stagvn.com'
const FLEX_APP = process.env.REACT_APP_FLEX_APP
const STAG_APP_NAME = {
  employee: 'Stag Invest',
  business: 'Stag FutureFlex'
}

const getUrl = url => `${API_BASE_URL}${url}`

export { API_BASE_URL, FLEX_APP, STAG_APP_NAME, getUrl }
