import { DualAxes } from '@ant-design/plots'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import Slider, { SliderThumb } from '@mui/material/Slider'
import { usePostApi } from '@stagapps/redux-utils'
import { maxBy } from 'lodash'
import moment from 'moment'

import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Row,
  Space,
  Statistic,
  Typography
} from 'antd'
import { useEffect, useRef, useState } from 'react'

import { Container } from '@mui/material'
import apiScenarioCall from 'business/apiCalls/simulator/program'
import styled from 'styled-components'
import { convertDateFormData, ensureDateFields } from 'utils/formData'
import { formatCurrency, formatNumber } from 'utils/formatAmount'
import { formatDate } from 'utils/formatDate'

const dateFields = ['start_date']
const scenarioDateFields = ['start_date']

const { Title } = Typography

const ADDING_YASIX = 1000000

const formItemLayout = {
  labelCol: {
    span: 24,

    style: {
      fontSize: '14px',
      lineHeight: '20px',
      color: '#0A1A41'
    }
  }
}

function findBiggestValue(data) {
  return maxBy(data, 'value')
}

function FlexSimulationPage() {
  const ref = useRef()

  const [form] = Form.useForm()
  const [blackList, setBlackList] = useState([])

  const extraData = {
    grow_high: Form.useWatch('grow_high', form),
    grow: Form.useWatch('grow', form),
    grow_low: Form.useWatch('grow_low', form),
    gold: Form.useWatch('gold', form),
    bank: Form.useWatch('bank', form)
  }

  const {
    data,
    isLoading: isSubmitting,
    post,
    error
  } = usePostApi(apiScenarioCall, { resourceName: 'data' })

  const onReview = values => {
    const {
      start_date,
      monthly_order_payment,
      investment_interval,
      company_contribute_amount,
      employee_contribute_amount
    } = values

    post({
      payload: convertDateFormData(
        {
          monthly_order_payment,
          investment_interval,
          company_contribute_amount,
          employee_contribute_amount,
          start_date
        },
        scenarioDateFields
      )
    })
  }

  useEffect(() => {
    form.submit()
  }, [])

  const chartData = convertGroupedBarData(
    data?.projected_program_amount || [],
    removeKeyList(extraData, blackList)
  )

  const { value: maxBufferValue } = findBiggestValue(chartData[1]) || {}
  const { value: maxContributionValue } = findBiggestValue(chartData[0]) || {}

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col items-center">
        <Title level={2}>Minh họa chương trình phúc lợi doanh nghiệp</Title>
      </div>

      {!!data ? (
        <Container
          maxWidth="xl"
          className="bg-white rounded-lg py-6 border-solid border border-gray-100"
        >
          <div className="h-full">
            <div className="h-[60vh] mb-12">
              <DualAxes {...barConfig} data={chartData} />
              <div className="flex justify-center">{'(Bấm để ẩn bớt)'}</div>
            </div>
            <div className="bg-[#F1F4FF] p-6  rounded-lg">
              <div className="font-semibold">
                Tổng giá trị phúc lợi khi hoàn thành chương trình:
              </div>

              <div className="flex flex-row gap-10 mt-6">
                <Statistic
                  title="Lên đến"
                  value={
                    Math.round(maxBufferValue) +
                    (maxContributionValue - ADDING_YASIX)
                  }
                  formatter={formatCurrency}
                  valueStyle={{
                    fontSize: '32px',
                    lineHeigh: '38px',
                    fontWeight: 600,
                    color: '#1E786D'
                  }}
                  className="py-4"
                />
                <div className="self-stretch border-solid border-0 border-r-2 border-r-[#5A6F93]" />

                <div className="py-4">
                  <Statistic
                    title="Trong đó"
                    value={maxContributionValue - ADDING_YASIX}
                    formatter={formatCurrency}
                    valueStyle={{
                      fontWeight: 600,
                      color: '#1E98F4'
                    }}
                  />
                  <span>Tổng giá trị tích lũy và đóng góp</span>
                </div>

                <div className="self-end py-4">
                  <Statistic
                    value={Math.round(maxBufferValue)}
                    formatter={formatCurrency}
                    valueStyle={{
                      fontWeight: 600,
                      color: '#7D3EC4'
                    }}
                  />
                  <span>
                    Lợi nhuận đầu tư dự kiến khi danh mục CCQ sinh lợi ở mức cao
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Container>
      ) : null}

      <Container
        maxWidth="xl"
        className="bg-white rounded-lg py-6 border border-gray-200"
      >
        <Form
          {...formItemLayout}
          initialValues={{
            ...ensureDateFields(initialValues, dateFields)
          }}
          form={form}
          name={'simulator'}
          onFinish={onReview}
          size="middle"
        >
          <Title level={5}>Thông tin đầu tư</Title>
          <Row gutter={24}>
            {config.slice(0, 5).map(
              ({ FormItem, ...props }, index) =>
                !!FormItem && (
                  <Col span={8} key={index}>
                    <FormItem style={{ width: '30%' }} {...props} />
                  </Col>
                )
            )}
          </Row>

          <Space direction="vertical">
            <Title level={5}>Mức lợi nhuận kỳ vọng</Title>
            <Row gutter={24}>
              {config
                .slice(5, 10)
                .map(({ FormItem, label, name, ...props }, index) => {
                  if (!!FormItem) {
                    const checked = !blackList.find(e => e === name)
                    return (
                      <Col span={8} key={index}>
                        <Space direction="vertical">
                          <Space>
                            <Checkbox
                              checked={checked}
                              onChange={e => {
                                const isChecked = e.target.checked
                                if (isChecked) {
                                  setBlackList(prev =>
                                    prev.filter(e => e !== name)
                                  )
                                } else {
                                  setBlackList(prev => [...prev, name])
                                }
                              }}
                            />
                            <span>{label}</span>
                          </Space>
                          <FormItem
                            name={name}
                            disabled={!checked}
                            style={{ width: '30%' }}
                            {...props}
                          />
                        </Space>
                      </Col>
                    )
                  }
                })}
            </Row>
          </Space>
          <Form.Item className="flex justify-center">
            <Button type="primary" size="large" htmlType="submit" ref={ref}>
              Cập nhật bảng minh họa
            </Button>
          </Form.Item>
        </Form>
      </Container>
      <Container maxWidth="xl" style={{ padding: 0 }} className="mb-20">
        <Alert
          className="italic"
          message="Lưu ý quan trọng:"
          description="Bảng minh họa chương trình phúc lợi doanh nghiệp chỉ mang tính chất tham khảo, minh họa, không phải cam kết lợi nhuận hoặc tư vấn đầu tư."
          type="warning"
          showIcon
        />
      </Container>
    </div>
  )
}

export default FlexSimulationPage

const config = [
  {
    name: 'employee_contribute_amount',
    label: 'Số tiền tích lũy hàng tháng:',
    formatter: formatNumber,
    FormItem: ({ label, name }) => (
      <Form.Item name={name} label={label}>
        <InputNumber
          className="w-full"
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          min={0}
          step={1000}
          suffix="VND"
        />
      </Form.Item>
    )
  },
  {
    name: 'company_contribute_amount',
    label: 'Số tiền đóng góp hàng tháng:',
    formatter: formatNumber,
    FormItem: ({ label, name }) => (
      <Form.Item name={name} label={label}>
        <InputNumber
          className="w-full"
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          min={0}
          step={1000}
          suffix="VND"
        />
      </Form.Item>
    )
  },
  {
    name: 'investment_interval',
    label: 'Thời gian chương trình ( số kỳ tham gia )',
    FormItem: ({ label, name }) => (
      <Form.Item name={name} label={label}>
        <InputNumber
          className="w-full"
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          min={0}
          step={1}
          suffix="kỳ"
        />
      </Form.Item>
    )
  },
  {
    name: 'start_date',
    label: 'Thời điểm bắt đầu:',
    formatter: t => t.format('DD/MM/YYYY'),
    FormItem: ({ label, name }) => (
      <Form.Item name={name} label={label}>
        <DatePicker
          sx={{ px: 1 }}
          openTo="year"
          views={['year', 'month', 'day']}
          format="DD/MM/YYYY"
          slotProps={{
            textField: { variant: 'standard', size: 'small' }
          }}
        />
      </Form.Item>
    )
  },
  {
    name: 'monthly_order_payment',
    label: 'Thời điểm đặt lệnh:',
    FormItem: ({ label, name }) => (
      <Form.Item name={name} label={label}>
        <InputNumber
          className="w-full"
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          min={1}
          max={28}
          step={1}
          prefix="Ngày"
          suffix="mỗi tháng"
        />
      </Form.Item>
    )
  },

  {
    name: 'grow_high',
    label: 'CCQ lợi tức cao',
    FormItem: ({ disabled, name }) => (
      <Form.Item name={name}>
        <SliderWithInputNumber disabled={disabled} />
      </Form.Item>
    )
  },
  {
    name: 'grow',
    label: 'CCQ lợi tức trung bình',
    FormItem: ({ disabled, name }) => (
      <Form.Item name={name}>
        <SliderWithInputNumber disabled={disabled} />
      </Form.Item>
    )
  },
  {
    name: 'grow_low',
    label: 'CCQ lợi tức thấp',
    FormItem: ({ disabled, name }) => (
      <Form.Item name={name}>
        <SliderWithInputNumber disabled={disabled} />
      </Form.Item>
    )
  },
  {
    name: 'gold',
    label: 'Vàng',
    FormItem: ({ disabled, name }) => (
      <Form.Item name={name}>
        <SliderWithInputNumber disabled={disabled} />
      </Form.Item>
    )
  },
  {
    name: 'bank',
    label: 'Tiết kiệm',
    FormItem: ({ disabled, name }) => (
      <Form.Item name={name}>
        <SliderWithInputNumber disabled={disabled} />
      </Form.Item>
    )
  }
]

const SliderWithInputNumber = ({ ...props }) => {
  const { onChange, value, disabled } = props
  return (
    <Space size={'large'}>
      <div className={`w-56 ${disabled ? 'opacity-40' : ''}`}>
        <AirbnbSlider
          {...props}
          slots={{ thumb: AirbnbThumbComponent }}
          styles={{ backgroundColor: 'red' }}
          min={1}
          max={100}
          onChange={onChange}
          value={typeof value === 'number' ? value : 0}
          disabled={disabled}
        />
      </div>
      <InputNumber
        {...props}
        value={value}
        onChange={onChange}
        className="w-full"
        suffix={'%/ năm'}
        disabled={disabled}
      />
    </Space>
  )
}

const initialValues = {
  company_contribute_amount: 1000000,
  start_date: '2023-10-30',
  employee_contribute_amount: 1000000,
  investment_interval: 12,
  monthly_order_payment: 12,
  grow_high: 26.82,
  grow: 16.9,
  grow_low: 12.68,
  gold: 6.29,
  bank: 5.15
}

// function calculateAnnualRate(monthlyRate) {
//   return Math.pow(1 + monthlyRate, 12) - 1;
// }

function calculateMonthlyRate(annualRate) {
  return Math.pow(1 + annualRate, 1 / 12) - 1
}

const convertGroupedBarData = (
  arr,
  { grow_high, grow, grow_low, bank, gold }
) => {
  const customSortOrder = [
    'Tiền gửi',
    'Vàng',
    'CCQ Thấp',
    'CCQ Trung Bình',
    'CCQ Cao',
    'Công ty',
    'Nhân viên'
  ]

  let stackBarArray = []
  let lineArray = []
  arr.map(
    (
      {
        accumulated_company_contributed_amount,
        accumulated_employee_contributed_amount,
        order_date
      },
      index
    ) => {
      const format_order_date = formatDate(order_date)

      const caculateGrow = (percentage1Y, name) => {
        const { value: previousValue } = [...lineArray]
          .reverse()
          .find(e => e.name === name) || { value: 0 }
        const percentage1M = calculateMonthlyRate(percentage1Y / 100) * 100

        return lineArray.push({
          name,
          order_date: format_order_date,
          value:
            ((accumulated_company_contributed_amount +
              accumulated_employee_contributed_amount +
              previousValue) *
              percentage1M) /
              100 +
            previousValue
        })
      }

      stackBarArray.push({
        name: 'Nhân viên',
        order_date: format_order_date,
        value: accumulated_employee_contributed_amount
      }),
        stackBarArray.push({
          name: 'Công ty',
          order_date: format_order_date,
          value: accumulated_company_contributed_amount
        }),
        index === arr.length - 1 &&
          stackBarArray.push({
            name: '',
            value:
              accumulated_employee_contributed_amount +
              accumulated_company_contributed_amount +
              ADDING_YASIX
          }),
        grow_high && caculateGrow(grow_high, 'CCQ Cao')
      grow && caculateGrow(grow, 'CCQ Trung Bình')
      grow_low && caculateGrow(grow_low, 'CCQ Thấp')
      gold && caculateGrow(gold, 'Vàng')
      bank && caculateGrow(bank, 'Tiền gửi')
    }
  )
  // return stackBarArray.sort((a, b) => {
  //   return customSortOrder.indexOf(a.name) - customSortOrder.indexOf(b.name)
  // })

  return [stackBarArray, lineArray]
}

const barConfig = {
  xField: 'order_date',
  yField: ['value', 'value'],
  xAxis: {
    label: {
      offsetY: 5,
      formatter: (a, b) =>
        moment(a, 'DD/MM/YYYY')
          .format('MMM/YY')
          .split('/')
          .join('\n')
    }
  },

  legend: {
    layout: 'horizontal',
    position: 'bottom',
    showContent: true,
    offsetY: 4
  },

  padding: 'auto',
  geometryOptions: [
    {
      isStack: true,
      geometry: 'column',
      seriesField: 'name',
      minColumnWidth: 24,
      maxColumnWidth: 36,
      color: ['#1E98F4', '#061669'],

      locale: 'en-US',
      meta: {
        value: {
          autoHide: true
        }
      },
      tooltip: {
        formatter: e => {
          const { name, value } = e || {}
          return { name, value: formatCurrency(value) }
        }
      }
    },
    {
      geometry: 'line',
      seriesField: 'name',
      tooltip: {
        formatter: e => {
          const { name, value } = e || {}
          return { name, value: formatCurrency(Math.round(value)) }
        }
      },
      lineStyle: {
        lineWidth: 2
      }
    }
  ],
  yAxis: {
    value: {
      label: {
        style: {
          fill: '#5A6F93',
          fontSize: 14
        },
        formatter: a => formatNumber(a)
      }
    }
  }
}

function AirbnbThumbComponent(props) {
  const { children, ...other } = props
  return (
    <SliderThumb {...other}>
      <SVGCoin />
      {children}
    </SliderThumb>
  )
}

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: '#0066F5',
  height: 3,
  padding: '12px 0',
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#FEE05D',
    boxShadow: '0 0 0 3px #FEE05D36',
    '&:hover': {
      boxShadow: '0 0 0 6px #FEE05D66'
    },

    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1
    }
  },
  '& .MuiSlider-track': {
    height: 3
  }
}))

const SVGCoin = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <circle
      cx={10.667}
      cy={10}
      r={9}
      fill="#FDB70E"
      stroke="#FEE05D"
      strokeWidth={2}
    />
    <path
      fill="#fff"
      d="M13.667 8.412c0-1.47-1.38-2.587-3-2.587v1.5c.855 0 1.5.518 1.5 1.087h1.5Zm-3-2.587c-1.628 0-3 1.117-3 2.587 0 1.358 1.117 2.168 2.827 2.565 1.073.248 1.665.668 1.665 1.103 0 .563-.645 1.088-1.5 1.088v1.5c1.62 0 3-1.125 3-2.588 0-1.365-1.155-2.182-2.835-2.572-1.11-.263-1.672-.668-1.672-1.11 0-.57.637-1.088 1.5-1.088v-1.5l.015.015Zm.75.75V5h-1.5v1.575h1.5Zm-.75 6.6c-.863 0-1.5-.525-1.5-1.088h-1.5c0 1.463 1.372 2.588 3 2.588v-1.5Zm-.75.75V15.5h1.5v-1.575h-1.5Z"
    />
  </svg>
)

export const removeKeyList = (object, list) => {
  const newObject = { ...object }
  list.map(e => delete newObject[e])
  return newObject
}
