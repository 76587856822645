import {
  CheckCircleFilled,
  CloseCircleFilled,
  SyncOutlined
} from '@ant-design/icons'
import { Space } from 'antd'

function VerificationStatus({ status }) {
  if (!status) {
    return null
  }

  if (status === 'processing') {
    return (
      <Space>
        <SyncOutlined style={{ color: '#EAB308' }} spin />
        <span>{'Đang kiểm tra ảnh'} </span>
      </Space>
    )
  }

  if (status === 'succeeded') {
    return (
      <Space>
        <CheckCircleFilled style={{ color: '#22C55E' }} />
        <span>{'Hình ảnh hợp lệ'} </span>
      </Space>
    )
  }

  if (status === 'failed') {
    return (
      <Space>
        <CloseCircleFilled style={{ color: '#EF4444' }} />
        <span>{'Vui lòng thử lại'} </span>
      </Space>
    )
  }

  return null
}

export default VerificationStatus
