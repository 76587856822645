import { Navigate } from 'react-router-dom'

import LoadingSpinner from 'common/ui/LoadingSpinner'
import useCheckAuth from 'hooks/auth/useCheckAuth'

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useCheckAuth()

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!isAuthenticated) {
    return <Navigate to={'/login'} />
  }

  return children
}

const GuestRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useCheckAuth()

  if (isLoading) {
    return <LoadingSpinner />
  }
  if (isAuthenticated) {
    return <Navigate to={'/'} />
  }

  return children
}

export { GuestRoute, PrivateRoute }
