import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'
export const POST_CHECKING_SELL_ORDER_PRICE_ESTIMATE =
  'POST_CHECKING_SELL_ORDER_PRICE_ESTIMATE'

const apiCall = enhancedMakeFetchAction(
  POST_CHECKING_SELL_ORDER_PRICE_ESTIMATE,
  ({ id, programId, ...payload }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/flex/employee/v1/employees/${id}/employee-programs/${programId}/program-orders/redeem-estimate`,
    method: 'POST',
    body: payload
  })
)

export default apiCall
