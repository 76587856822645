import { Button, Typography } from 'antd'
import { Link } from 'react-router-dom'

import useAccountVerifiedStatus from 'app/hooks/useAccountVerifiedStatus'
import ErrorMessage from 'common/ui/ErrorMessage'
import UICard from 'common/ui/display/UICard'
import VerificationSteps from '../VerificationSteps'

const { Title } = Typography

function VerificationStatus() {
  const {
    isLoading,
    isError,
    error,
    linkTo,
    current,
    isCompleteAllKYC
  } = useAccountVerifiedStatus()

  if (isLoading) {
    return
  }

  if (isError) {
    return <ErrorMessage errors={error} />
  }

  if (isCompleteAllKYC) {
    return
  }

  return (
    <UICard bordered className="bg-red-50 border-red-500 mb-4">
      <div className="flex justify-between md:items-center">
        <div>
          <Title level={4}>Chưa định danh tài khoản</Title>
          <p>
            Bạn cần thực hiện định danh để đủ điều kiện tham gia chương trình
          </p>
          <Button type="primary">
            <Link to={linkTo}>Định danh ngay</Link>
          </Button>
        </div>
        <div>
          <VerificationSteps size={'small'} type="default" current={current} />
        </div>
      </div>
    </UICard>
  )
}

export default VerificationStatus
