import { Layout } from 'antd'
import { Link } from 'react-router-dom'

import StagLogoSVG from 'common/svg/logo/stag'
import useCheckAuth from 'hooks/auth/useCheckAuth'
import UserMenu from './UserMenu'

const { Header } = Layout

const UIHeader = () => {
  const { currentUser } = useCheckAuth()

  return (
    <Header className="bg-white flex flex-row items-center justify-between ">
      <Link to={`/`}>
        <div className="flex md:w-[134px] w-32 aspect-[134/31]">
          <StagLogoSVG />
        </div>
      </Link>

      {!!currentUser ? <UserMenu user={currentUser} /> : null}
    </Header>
  )
}

export default UIHeader
