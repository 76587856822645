export const genderLabels = {
  male: 'Nam',
  female: 'Nữ'
}

export const accountType = {
  company: 'Doanh nghiệp',
  business: 'Doanh nghiệp',
  employee: 'Nhân viên'
}

export const EMPTY_ID_STRING = '00000000-0000-0000-0000-000000000000'
