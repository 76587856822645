import { all } from 'redux-saga/effects'

import { resetPasswordSaga } from './resetPassword'
import { signinSaga } from './signin'
import { signupSaga } from './signup'
import { updatePasswordSaga } from './updatePassword'
import { verifyEmailSaga } from './verifyEmail'

export default function* authSaga() {
  yield all([
    signinSaga(),
    verifyEmailSaga(),
    signupSaga(),
    updatePasswordSaga(),
    resetPasswordSaga()
  ])
}
