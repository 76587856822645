import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

export const POST_CONFIRM_API_ID =
  'EMPLOYEE/POST_CONFIRM_INVESTMENT_PLAN_EMPLOYEE_PROGRAM_API'

const apiCall = enhancedMakeFetchAction(
  POST_CONFIRM_API_ID,
  ({ id, programId, ...payload }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/flex/employee/v1/onboarding/${id}/employee-programs/${programId}/investment-plans`,
    method: 'POST',
    body: payload,
    notify: true
  })
)

export default apiCall
