import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

export const POST_REQUEST_VERIFY_EMAIL = 'EMPLOYEE/POST_REQUEST_RESET_PASSWORD'

const apiCall = enhancedMakeFetchAction(POST_REQUEST_VERIFY_EMAIL, payload => ({
  endpoint: `${API_BASE_URL}/investcore-api/app/v1/users/send-reset-pwd-verification-code`,
  method: 'POST',
  body: payload,
  notify: true
}))

export default apiCall
