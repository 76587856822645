import { Column } from '@ant-design/plots'

import { formatCurrency, nFormatter } from 'utils/formatAmount'
import { formatDate } from 'utils/formatDate'

function ContributedColumnChart({ projected_program_amount }) {
  if (!projected_program_amount || projected_program_amount.length < 1) {
    return <div>Đang cập nhật...</div>
  }

  const count = projected_program_amount?.length
  const isComplete = projected_program_amount?.some(e => e.is_projected === 0)
  const barConfig = barConfigGenerator({
    isComplete,
    count
  })

  return (
    <div className="w-full">
      <Column
        scrollbar={count > 4 ? scrollbar : null}
        data={convertGroupedBarData(projected_program_amount)}
        {...barConfig}
      />
    </div>
  )
}

export default ContributedColumnChart

const convertGroupedBarData = arr => {
  let groupedBarArray = []
  arr.map(
    ({
      accumulated_company_contributed_amount,
      accumulated_employee_contributed_amount,
      order_date,
      is_projected,
      interval
    }) => {
      const format_order_date = formatDate(order_date)
      groupedBarArray.push({
        name: is_projected ? 'Nhân viên' : 'Nhân viên đã tích lũy',
        order_date: format_order_date,
        value: accumulated_employee_contributed_amount,
        interval
      })
      groupedBarArray.push({
        name: is_projected ? 'Doanh nghiệp' : 'Doanh nghiệp đã đóng góp',
        order_date: format_order_date,
        value: accumulated_company_contributed_amount,
        interval
      })
    }
  )
  return groupedBarArray
}

const barConfigGenerator = ({ isComplete, count }) => {
  return {
    minColumnWidth: 24,
    // maxColumnWidth: 32,
    // intervalPadding: 32,
    dodgePadding: 0,
    autoFit: false,
    height: 350,
    appendPadding: 3,

    isGroup: true,
    xField: 'order_date',
    yField: 'value',
    seriesField: 'name',
    color: isComplete
      ? ['#0066F5', '#36CFC9', '#C0CCDB', '#DCE2EC']
      : ['#C0CCDB', '#DCE2EC'],

    padding: 'auto',
    locale: 'en-US',
    meta: {
      value: {
        autoHide: true
      }
    },

    xAxis: {
      label: {
        offsetY: 5,
        formatter: (value, _, index) =>
          count > 4 && index % 3 ? '' : formatDate(value, 'DD/MM/YYYY')
      }
    },
    yAxis: {
      label: {
        formatter: a => nFormatter(a)
      }
    },

    legend: {
      layout: 'horizontal',
      position: 'bottom',
      showContent: true,
      offsetY: 5
    },

    tooltip: {
      position: 'top',
      showTitle: true,

      customContent: (title, data, index) => {
        const detail = data[0]?.data || {}
        const { interval } = detail
        const total = data?.reduce(function(acc, obj) {
          return Number(acc) + Number(obj?.value)
        }, 0)

        return (
          <div
            style={{
              fontFamily: 'CustomFont'
            }}
            className="text-sm"
          >
            <p>{`Kỳ ${interval} (${title})`}</p>
            <div className="flex flex-col gap-4">
              <div key={index} className="flex gap-4 justify-between">
                <div>{'Tổng vốn'}:</div>
                <div>{formatCurrency(total)}</div>
              </div>
              {data.map(({ name, value }, index) => (
                <div
                  key={index}
                  className="text-xs text-gray-400 flex justify-between gap-4"
                >
                  <div>
                    {name.includes('Nhân viên') ? 'Nhân viên' : 'Doanh nghiệp'}:
                  </div>
                  <div>{formatCurrency(value)}</div>
                </div>
              ))}
            </div>
          </div>
        )
      },
      domStyles: {
        'g2-tooltip': {
          backgroundColor: '#ffffffdf',
          borderRadius: '0.25rem',
          padding: '1rem'
        }
      }
    },

    state: {
      interactions: [{ type: 'element-active' }],
      state: {
        active: {
          animate: { duration: 500, easing: 'easeLinear' },
          style: {
            lineWidth: 2,
            stroke: '#000'
          }
        }
      }
    }
  }
}

const scrollbar = {
  type: 'horizontal',
  categorySize: 72,

  style: {
    thumbColor: '#DCE2EC',
    thumbHighlightColor: '#C0CCDB'
  }
}
