import { CopyOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import copyTextToClipboard from 'utils/clipboard'
import { openNotificationWithIcon } from 'utils/openNotificationWithIcon'

function CopyToClipboard({ text, children }) {
  return (
    <span>
      <Button
        block
        type="text"
        onClick={() => {
          copyTextToClipboard(text)
          openNotificationWithIcon({
            type: 'success',
            message: 'Đã sao chép',
            duration: 0.9
          })
        }}
      >
        {children ? children : <CopyOutlined />}
      </Button>
    </span>
  )
}

export default CopyToClipboard
