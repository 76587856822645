import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'

import { API_BASE_URL } from 'config/env.config'

export const PATCH_UPDATE_ID_INFO = 'EMPLOYEE/PATCH_UPDATE_ID_INFO'

const apiCall = enhancedMakeFetchAction(PATCH_UPDATE_ID_INFO, payload => ({
  endpoint: generateEndpoint({
    host: `${API_BASE_URL}/investcore-api/app/v2/identifications`
  }),

  method: 'PATCH',
  body: payload
}))

export default apiCall
