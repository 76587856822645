import { useFetchApiGet } from '@stagapps/redux-utils'
import { Checkbox, Form, Typography } from 'antd'
import apiCall from 'app/apiCalls/employeeOnboarding'
import UICard from 'common/ui/display/UICard'

import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import ValidButton from 'common/ui/form/ValidButton'
import { useEffect } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

const { Title } = Typography

function OpenFundAccount() {
  const [form] = Form.useForm()
  const params = useParams()
  const navigate = useNavigate()
  const {
    data: employeeProgram,
    load: loadEmployeeProgram,
    isLoading: isLoadingEmployeeProgram,
    error: employeeProgramError
  } = useFetchApiGet(apiCall.detail, {
    resourceName: 'data'
  })

  const location = `/employees/${params.id}/programs/${params.programId}`

  const onFinish = () => {
    navigate(location)
  }

  useEffect(() => {
    loadEmployeeProgram(params)
  }, [])

  if (isLoadingEmployeeProgram) {
    return <LoadingSpinner />
  }
  if (employeeProgramError) {
    return <ErrorMessage errors={employeeProgramError} />
  }

  const { required_employee_fund_accounts } = employeeProgram || {}
  if (
    !required_employee_fund_accounts ||
    required_employee_fund_accounts.length < 1
  ) {
    return <Navigate to={location} />
  }

  return (
    <div className="md:py-6 p-2">
      <UICard className="md:max-w-md mx-auto">
        <Title level={4}>Mở tài khoản giao dịch chứng chỉ quỹ</Title>
        <p>
          Để tham gia đầu tư vào các quỹ bạn đã chọn, thông tin của bạn sẽ được
          sử dụng để mở tài khoản giao dịch với công ty quản lý quỹ VCAM
        </p>

        <Form
          requiredMark={false}
          initialValues={{
            remember: true
          }}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error('Vui lòng chọn'))
              }
            ]}
          >
            <div className="flex items-start gap-2">
              <Checkbox className="mt-4" />
              <p>
                Tôi đã xem, hiểu, và đồng ý với Điều khoản và Chính sách bảo mật
                của VCAM
              </p>
            </div>
          </Form.Item>

          <Form.Item>
            <ValidButton
              type="primary"
              htmlType="submit"
              className="w-full"
              btnTitle="Tiếp tục"
              form={form}
              // loading={isSubmitting}
            />
          </Form.Item>
        </Form>
      </UICard>
    </div>
  )
}

export default OpenFundAccount
