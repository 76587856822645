import { InputNumber } from 'antd'
import { formatNumber } from 'utils/formatAmount'

function UIInputFormatNumber(props) {
  const { value, notice = '', onChange, onBlur = () => {} } = props || {}

  const isSafe = String(Math.abs(value)).length <= 15

  const formatter = value => {
    if (!value) {
      return ''
    }
    if (!isSafe) {
      const sliceValue = value > 0 ? value.slice(0, 15) : value.slice(0, 16)
      return formatNumber(sliceValue)
    }
    return formatNumber(value)
  }

  const parser = value =>
    value ? Number.parseFloat(value.replace(/(,*)/g, '')) : ''

  const handleBlur = event => {
    if (!event?.target?.value) return
    const parsedValue = parser(event.target.value)
    onBlur(parsedValue)
  }

  return (
    <>
      <InputNumber
        status={isSafe ? '' : 'error'}
        formatter={formatter}
        parser={parser}
        value={value}
        {...props}
        onBlur={handleBlur}
      />
      {isSafe ? (
        <span className="text-xs text-gray-500">{notice} </span>
      ) : (
        <span
          className="text-red-500 text-xs absolute w-full
        top-11 left-0"
        >
          Không thể vượt quá 15 chữ số
        </span>
      )}
    </>
  )
}

export default UIInputFormatNumber
