import { PageHeader } from '@ant-design/pro-layout'
import { useFetchApiGet } from '@stagapps/redux-utils'
import { Tabs, Tag, Typography } from 'antd'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import apiCall from 'app/apiCalls/employeeProgram'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import {
  employeeProgramStatus,
  employeeProgramStatusColors
} from 'data/employeeProgram'
import { checkIsSellAvalible } from './checkEmployeeProgramProgress'

const { Title } = Typography

function EmployeeProgramLayout() {
  const { id, programId } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const {
    data: program,
    load: fetchProgram,
    isLoading: isLoadingProgram,
    error: errorProgram
  } = useFetchApiGet(apiCall.detail, { resourceName: 'data' })

  const isLoading = isLoadingProgram
  const error = errorProgram

  useEffect(() => {
    fetchProgram({ id, programId })
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  const { code, status, progress } = program || {}
  const activeKey = pathname.includes('program-orders')
    ? 2
    : pathname.includes('transactions')
    ? 3
    : 1

  const programCode = `Chương trình ${code}`

  const isSellAvalible = checkIsSellAvalible(progress)

  const tabItems = [
    {
      label: 'Tổng quan',
      key: 1
    },
    {
      label: 'Lịch sử đóng góp',
      key: 2
    },
    isSellAvalible && {
      label: 'Giao dịch',
      key: 3
    }
  ]

  const onChange = key => {
    const path = `/employees/${id}/programs/${programId}`

    if (key == 1) {
      navigate(path)
    }
    if (key == 2) {
      navigate(path + '/program-orders')
    }
    if (key == 3) {
      navigate(path + '/transactions')
    }
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={
          <div className="flex md:flex-row md:items-center flex-col gap-2">
            <Title level={2}>{programCode}</Title>
            <Tag className="w-fit" color={employeeProgramStatusColors[status]}>
              <span className="font-normal">
                {employeeProgramStatus[status]}
              </span>
            </Tag>
          </div>
        }
      />
      <Tabs
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        items={tabItems}
        mode="horizontal"
        onChange={onChange}
      />
      <Outlet />
    </>
  )
}

export default EmployeeProgramLayout
