import jwtDecode from 'jwt-decode'
import { useSelector } from 'react-redux'

import { sessionSelector } from 'state/reducer/session.reducers'

const useCheckAuth = () => {
  const { currentUser, currentToken, isLoading } =
    useSelector(sessionSelector) || {}

  const isTokenTimeout = () => {
    if (!currentToken) {
      return true
    }
    const { exp } = jwtDecode(currentToken) || {}
    if (!exp) {
      return true
    }
    return new Date(exp * 1000) <= new Date()
  }

  return {
    isAuthenticated: !isTokenTimeout(),
    isLoading,
    currentUser,
    currentToken
  }
}

export default useCheckAuth
