import { DatePicker } from '@mui/x-date-pickers'
import { Form, Input, Radio } from 'antd'

export const formItems = [
  {
    name: 'full_name',
    label: 'Họ và tên',
    FormItem: ({ label, name }) => (
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
            type: 'string'
          }
        ]}
      >
        <Input className="w-full" />
      </Form.Item>
    )
  },
  {
    name: 'date_of_birth',
    label: 'Ngày sinh',
    formatter: t => t.format('DD/MM/YYYY'),
    FormItem: ({ label, name }) => (
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: true,
            type: 'date',
            message: 'Vui lòng nhập'
          }
        ]}
      >
        <DatePicker
          openTo="year"
          views={['year', 'month', 'day']}
          format="DD/MM/YYYY"
          slotProps={{
            textField: { variant: 'standard', size: 'small' }
          }}
        />
      </Form.Item>
    )
  },
  {
    name: 'department',
    label: 'Phòng ban',
    FormItem: ({ label, name }) => (
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập'
          }
        ]}
      >
        <Input className="w-full" />
      </Form.Item>
    )
  },
  {
    name: 'role',
    label: 'Vị trí',
    FormItem: ({ label, name }) => (
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập'
          }
        ]}
      >
        <Input className="w-full" />
      </Form.Item>
    )
  },
  {
    name: 'nationality',
    label: 'Quốc tịch',
    FormItem: ({ label, name }) => (
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn Quốc tịch'
          }
        ]}
      >
        <Radio.Group>
          <Radio value={'Việt Nam'}>Việt Nam</Radio>
          <Radio value={'Khác'}>Khác</Radio>
        </Radio.Group>
      </Form.Item>
    )
  },
  {
    name: 'phone',
    label: 'Điện thoại',
    FormItem: ({ label, name }) => (
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập'
          }
        ]}
      >
        <Input className="w-full" />
      </Form.Item>
    )
  },
  {
    name: 'email',
    label: 'Email làm việc',
    FormItem: ({ label, name }) => (
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập Email',
            type: 'email'
          }
        ]}
      >
        <Input className="w-full" />
      </Form.Item>
    )
  },
  {
    name: 'id_number',
    label: 'Số Chứng minh nhân dân hoặc Căn cước công dân',
    FormItem: ({ label, name }) => (
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập thông tin'
          }
        ]}
      >
        <Input className="w-full" />
      </Form.Item>
    )
  }
]
