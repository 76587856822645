import { useEffect, useRef } from 'react'

function useEffectSkipFirstRender(effect, dependencies) {
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    return effect()
  }, dependencies)
}

export default useEffectSkipFirstRender
