import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const CANCEL_EMPLOYEE_PROGRAM_ORDER_API =
  'EMPLOYEE/CANCEL_EMPLOYEE_PROGRAM_ORDER_API'

const apiCall = enhancedMakeFetchAction(
  CANCEL_EMPLOYEE_PROGRAM_ORDER_API,
  ({ id, programId, programOrderId }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/flex/employee/v1/employees/${id}/employee-programs/${programId}/program-orders/${programOrderId}/cancel`,
    method: 'POST'
  })
)

export default apiCall
