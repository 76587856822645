import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils'
import { Button, Result, Spin, Statistic, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import apiUserProfileCall from 'app/apiCalls/verification/getProfile'
import apiCall from 'app/apiCalls/verification/requestVerifyPhone'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import { convertPhoneCharacters } from 'utils/hideCharacter'
import VerificationSteps from './KYCSteps'

const { Title } = Typography
const { Countdown } = Statistic

function refreshPage() {
  window.location.reload()
}

function RequestVerifyPhone() {
  const { phone, expires } = useParams()
  const { data, isLoading, load, error } = useFetchApiGet(apiUserProfileCall, {
    resourceName: 'data'
  })

  useEffect(() => {
    load()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorMessage errors={error} />
  }

  const { phone_verified } = data || {}

  return (
    <div>
      <VerificationSteps />
      <UICard className="w-fit mx-auto">
        <Title level={4}>Xác thực số điện thoại</Title>
        {phone_verified ? (
          <Result
            className="md:w-96"
            title="Xác thực thành công"
            status="success"
            extra={
              <Link to={'/verification/idcard'}>
                <Button
                  disabled={!phone_verified}
                  type="primary"
                  size="large"
                  className="w-full"
                >
                  Tiếp tục
                </Button>
              </Link>
            }
          />
        ) : (
          <>
            <p className="md:w-96">
              {`Vui lòng nhấn vào đường dẫn xác thực được gửi từ Stag đến số điện
        thoại ${convertPhoneCharacters(phone)}`}
            </p>
            <CountDownAndResendRequest expires={expires} phone={phone} />
            <Button className="w-full" onClick={refreshPage}>
              Đã xác thực
            </Button>
          </>
        )}
      </UICard>
    </div>
  )
}

export default RequestVerifyPhone

const CountDownAndResendRequest = ({ expires, phone }) => {
  const [isTimeOut, setIsTimeOut] = useState(
    new Date(expires * 1000) <= new Date()
  )
  const navigate = useNavigate()

  const { post, isLoading: isSubmitting, error, data } = usePostApi(apiCall, {
    resourceName: 'data'
  })
  const { error: errorMessage, error_code } = error || {}

  useEffectSkipFirstRender(() => {
    if (data) {
      const { expires_at } = data
      navigate(`/verification/phone/${phone}/${expires_at}`, {
        replace: true
      })
    }
  }, [data])

  return (
    <div className="flex flex-col justify-center items-center">
      {isTimeOut ? (
        isSubmitting ? (
          <Spin />
        ) : (
          <>
            <Link>
              <p onClick={() => post({ phone })}>Gửi lại đường dẫn xác thực</p>
            </Link>
            <p className="text-red-500">{errorMessage}</p>
          </>
        )
      ) : (
        <Countdown
          value={expires * 1000}
          format="s"
          prefix={
            <p className="text-[#5A6F93]">Đường dẫn xác thực hết hạn trong</p>
          }
          suffix="giây"
          valueStyle={{ color: '#EF4444', fontSize: '0.875rem' }}
          onFinish={() => setIsTimeOut(true)}
        />
      )}
    </div>
  )
}
