import { middleware as apiMiddleware } from '@stagapps/redux-utils'
import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore
} from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootSaga from 'sagas'
import requestHeaderMiddleware from './middlewares/requestHeaderMiddleware'
import rootReducer from './reducer/rootReducer'

const sagaMiddleware = createSagaMiddleware()

// eslint-disable-next-line
const reduxCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const initialState = {
  session: JSON.parse(localStorage.getItem('session'))
}

const enhancer = reduxCompose(
  applyMiddleware(requestHeaderMiddleware, sagaMiddleware, apiMiddleware)
)

// Redux: Store
const store = createStore(rootReducer, initialState, enhancer)

// Middleware: Redux Saga
sagaMiddleware.run(rootSaga)

export default store
