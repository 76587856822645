import { ACTIONS } from '@stagapps/redux-utils'
import { flow, get } from 'lodash/fp'
import { takeEvery } from 'redux-saga/effects'

import { getErrorMessage } from 'utils/getErrorMessage'
import { openNotificationWithIcon } from 'utils/openNotificationWithIcon'

function* onNotificationSuccess(action) {
  try {
    if (flow(get('payload.notify'))(action)) {
      const method = flow(get('payload.method'))(action)
      openNotificationWithIcon({
        type: 'success',
        message: method === 'POST' ? 'Thành công' : 'Cập nhật thành công',
        duration: 2
      })
    }
  } catch (error) {
    console.error('error', error)
  }
}

function* onNotificationFailure(action) {
  try {
    if (flow(get('payload.notify'))(action)) {
      const response = get(`payload.json`)(action)
      const errorMessage = getErrorMessage(response)

      openNotificationWithIcon({
        type: 'info',
        message: 'Vui lòng thử lại',
        description: errorMessage,
        duration: 2
      })
    }
  } catch (error) {
    console.error('error', error)
  }
}

function* watchonNotification() {
  yield takeEvery(ACTIONS.COMPLETE, onNotificationSuccess)
  yield takeEvery(ACTIONS.FAILURE, onNotificationFailure)
}

export default function* notificationSaga() {
  yield watchonNotification()
}
