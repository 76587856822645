import { usePostApi } from '@stagapps/redux-utils'
import { Form, Spin, Statistic, Typography } from 'antd'
import apiRequestCall from 'app/apiCalls/account/requestResetPassword'
import apiVerifyPasswordCall from 'app/apiCalls/account/verifyResetPassword'
import AuthLayout from 'app/components/layouts/AuthLayout'
import StagLogoSVG from 'common/svg/logo/stag'
import ValidButton from 'common/ui/form/ValidButton'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { renderErrorMessage } from 'utils/getErrorMessage'
import { convertEmailCharacters } from 'utils/hideCharacter'

const { Title } = Typography
function ForgetPasswordOTPPage() {
  const resetPassword = sessionStorage.getItem('resetPassword') || {}
  const { email, expiresAt } = JSON.parse(resetPassword) || {}
  const [form] = Form.useForm()
  const [OTP, setOTP] = useState(null)

  const handleChangeOTP = value => setOTP(value)

  let [searchParams] = useSearchParams()
  const urlQuery = searchParams.get('url')
  const query = urlQuery ? `?url=${urlQuery}` : ''

  const {
    data: responseVerifyData,
    post: postVerify,
    isLoading: isSubmittingVerify,
    error: errorVerify
  } = usePostApi(apiVerifyPasswordCall, {
    resourceName: 'data'
  })

  const onFinish = values => {
    postVerify({ ...values, email })
  }

  return (
    <AuthLayout imgUrlPath={'/futureflex/images/login_employee_v2.jpeg'}>
      <div className="flex flex-row gap-2 items-center">
        <div className="flex md:w-[134px] w-32 aspect-[134/31]">
          <StagLogoSVG />
        </div>
      </div>
      <div>
        <Title level={4}>Quên mật khẩu</Title>
        <p>
          Đã có tài khoản?<Link to={`/login${query}`}> Đăng nhập</Link>
        </p>
      </div>

      <Form
        requiredMark={false}
        initialValues={{
          remember: true
        }}
        onFinish={onFinish}
        form={form}
        labelCol={{ span: 24 }}
      >
        <p>
          {`Nhập mã OTP được gửi đến email của bạn ${convertEmailCharacters(
            email
          )}`}
        </p>
        <Form.Item
          validateStatus={!!errorVerify ? 'error' : null}
          hasFeedback
          help={renderErrorMessage(errorVerify)}
          labelCol={{ span: 24 }}
          name="code"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập OTP'
            }
          ]}
        >
          <MuiOtpInput
            autoFocus
            value={OTP}
            onChange={handleChangeOTP}
            length={6}
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 }
            }}
          />
        </Form.Item>
        <CountDownAndResendRequest email={email} expiresAt={expiresAt} />
        <Form.Item>
          <ValidButton
            size="large"
            type="primary"
            htmlType="submit"
            className="w-full"
            btnTitle="Xác thực OTP"
            form={form}
            loading={isSubmittingVerify}
          />
        </Form.Item>
      </Form>
    </AuthLayout>
  )
}

export default ForgetPasswordOTPPage

const { Countdown } = Statistic

const CountDownAndResendRequest = ({ email, expiresAt }) => {
  const [isTimeOut, setIsTimeOut] = useState(new Date(expiresAt) <= new Date())
  useEffect(() => {
    setIsTimeOut(new Date(expiresAt * 1000) <= new Date())
  }, [expiresAt])

  const { post, isLoading: isSubmitting, error } = usePostApi(apiRequestCall, {
    resourceName: 'data'
  })

  return (
    <div className="flex flex-col justify-center items-center">
      {isTimeOut ? (
        isSubmitting ? (
          <div className="py-2">
            <Spin />
          </div>
        ) : (
          <>
            <Link>
              <p onClick={() => post({ email })}>Gửi lại OTP</p>
            </Link>
            <p className="text-red-500">{renderErrorMessage(error)}</p>
          </>
        )
      ) : (
        <Countdown
          value={expiresAt * 1000}
          format="s"
          prefix={<p className="text-[#5A6F93]">Mã OTP hết hạn trong</p>}
          suffix="giây"
          valueStyle={{ color: '#EF4444', fontSize: '0.875rem' }}
          onFinish={() => setIsTimeOut(true)}
        />
      )}
    </div>
  )
}
