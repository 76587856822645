import { DualAxes } from '@ant-design/plots'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { usePostApi } from '@stagapps/redux-utils'
import { maxBy } from 'lodash'
import moment from 'moment'

import { ArrowLeftOutlined, MailOutlined } from '@ant-design/icons'
import {
  Alert,
  Button,
  Form,
  InputNumber,
  Spin,
  Statistic,
  Typography
} from 'antd'
import { useEffect, useState } from 'react'

import { Container } from '@mui/material'
import apiScenarioCall from 'business/apiCalls/simulator/program'
import useCheckMobileDevice from 'hooks/useCheckMobileDevice'
import { Link } from 'react-router-dom'
import { convertDateFormData, ensureDateFields } from 'utils/formData'
import {
  formatCurrency,
  formatNumber,
  formatPercentage
} from 'utils/formatAmount'
import { formatCurrencyWithoutCurrencySymbol } from 'utils/formatAmount/formatUI'
import { formatDate } from 'utils/formatDate'
import { cloudflareCdn } from 'utils/image'

const dateFields = ['start_date']
const scenarioDateFields = ['start_date']
const currentDate = moment().format('YYYY-MM-DD')
const currentDateNumber = moment().date()

const { Title } = Typography

const formItemLayout = {
  labelCol: {
    span: 24,
    style: {
      fontSize: '14px',
      lineHeight: '20px',
      color: '#0A1A41'
    }
  }
}

function findBiggestValue(data) {
  return maxBy(data, 'value')
}

function FlexSimulationBundlePage() {
  const [form] = Form.useForm()
  const [isBundle, setIsBundle] = useState(true)
  const [selected, setSelected] = useState(-1)
  const { isMobile } = useCheckMobileDevice()

  const extraData = {
    grow: Form.useWatch('grow', form),
    bank: Form.useWatch('bank', form)
  }

  const {
    data,
    isLoading: isSubmitting,
    post,
    error
  } = usePostApi(apiScenarioCall, { resourceName: 'data' })

  useEffect(() => {
    form.submit()
  }, [])

  const onReview = values => {
    const {
      start_date,
      monthly_order_payment,
      investment_interval,
      company_contribute_amount,
      employee_contribute_amount
    } = values

    post({
      payload: convertDateFormData(
        {
          monthly_order_payment,
          investment_interval,
          company_contribute_amount,
          employee_contribute_amount,
          start_date
        },
        scenarioDateFields
      )
    })
  }

  const chartData = convertGroupedBarData(
    data?.projected_program_amount || [],
    extraData,
    isMobile
  )
  const { value: maxBufferValue } = findBiggestValue(chartData[1]) || {}
  const { value: maxContributionValue } = findBiggestValue(chartData[0]) || {}

  return (
    <>
      <Header />
      <div className="bg-[#F3F4F6]">
        <div className="flex flex-col gap-10 max-w-screen-xl py-4 mx-auto">
          <div className="flex flex-col items-center">
            <Title
              style={{ color: '#041B73' }}
              className="text-center"
              level={2}
            >
              Minh họa chương trình phúc lợi doanh nghiệp
            </Title>
          </div>

          <Form
            {...formItemLayout}
            initialValues={{
              ...ensureDateFields(initialValues, dateFields)
            }}
            form={form}
            name={'simulator'}
            onFinish={onReview}
            size="middle"
            className="flex flex-col gap-6"
          >
            <div className="flex md:flex-row flex-col gap-4">
              <Container
                style={isMobile ? null : { width: 'fit-content' }}
                className="bg-white rounded-lg py-6 border border-gray-200 text-primary h-fit w-fit"
              >
                <div className="md:w-80 w-full">
                  <div
                    className={
                      'flex flex-col gap-4 ' + (isBundle ? '' : 'hidden')
                    }
                  >
                    <div className="flex justify-between items-center">
                      <div className="text-lg font-semibold">
                        Chọn gói phù hợp
                      </div>
                      <Contact />
                    </div>

                    <div className="flex flex-col gap-2">
                      {bundleItems.map((item, index) => (
                        <BundleItem
                          key={index}
                          {...item}
                          isActived={selected === index}
                          onClick={() => {
                            setSelected(index)
                            form.setFieldsValue(item.values)
                            form.submit()
                          }}
                          setIsBundle={setIsBundle}
                        />
                      ))}
                    </div>
                    <div>
                      <div className="text-xs text-center gap-2">
                        <span>Đối tác quỹ đồng hành cùng </span>
                        <span className="text-blue-primary-500 font-bold">
                          FutureFlex
                        </span>
                      </div>
                      <div className="flex gap-6 items-center justify-center">
                        <img
                          width={80}
                          height={56}
                          src={cloudflareCdn(
                            '/futureflex/icons/VCAM_logo.png',
                            80,
                            56
                          )}
                          alt="VCAM_logo"
                        />
                        <img
                          width={80}
                          height={56}
                          src={cloudflareCdn(
                            '/futureflex/icons/Mirae_Asset_logo.png',
                            80,
                            56
                          )}
                          alt="Mirae_Asset_logo"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      'flex flex-col gap-4 ' + (isBundle ? 'hidden' : '')
                    }
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex gap-2 items-center">
                        <Button
                          style={{ color: '#041B73' }}
                          icon={<ArrowLeftOutlined />}
                          onClick={() => setIsBundle(true)}
                        />
                        <div className="text-lg font-semibold">Tùy chỉnh</div>
                      </div>
                      <Contact />
                    </div>

                    <div className="flex flex-col gap-3">
                      <Title level={5}>Thông tin đầu tư</Title>
                      <div>
                        {config.slice(0, 5).map(
                          ({ FormItem, ...props }, index) =>
                            !!FormItem && (
                              <div key={index} className="-my-3">
                                <FormItem {...props} />
                              </div>
                            )
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-3">
                      <Title level={5}>Mức lợi nhuận kỳ vọng</Title>
                      <div>
                        {config.slice(5, 7).map(
                          ({ FormItem, ...props }, index) =>
                            !!FormItem && (
                              <div key={index} className="-my-3">
                                <FormItem style={{ width: '50%' }} {...props} />
                              </div>
                            )
                        )}
                      </div>
                    </div>
                    <Form.Item className="flex justify-center">
                      <Button type="primary" onClick={() => form.submit()}>
                        Cập nhật bảng minh họa
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Container>
              {!!data ? (
                <Container className="bg-white rounded-lg py-6 border-solid border border-gray-100">
                  <div className="md:h-full">
                    <div className="md:h-[60vh] h-[40vh] mb-12 -mx-3">
                      {isSubmitting ? (
                        <div className="flex justify-center items-center h-full">
                          <Spin size="large" />
                        </div>
                      ) : (
                        <>
                          <div className="text-right text-secondary">
                            triệu VND
                          </div>

                          <DualAxes
                            {...barConfig}
                            data={chartData}
                            xAxis={{
                              label: {
                                style: {
                                  fill: '#5A6F93',
                                  fontSize: isMobile ? 9 : 12
                                },
                                offsetY: 5,
                                formatter: (a, b) =>
                                  moment(a, 'DD/MM/YYYY')
                                    .format('MMM/YY')
                                    .split('/')
                                    .join('\n')
                              }
                            }}
                            yAxis={{
                              value: {
                                label: {
                                  style: {
                                    fill: '#5A6F93',
                                    fontSize: isMobile ? 9 : 12
                                  },
                                  formatter: a => formatNumber(a / 1000000)
                                }
                              }
                            }}
                          />
                          <div className="flex justify-center">
                            {'(Bấm để ẩn bớt)'}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="bg-[#F1F4FF] p-6  rounded-lg">
                      <div className="font-semibold">
                        Tổng giá trị phúc lợi khi hoàn thành chương trình:
                      </div>

                      <div className="flex md:flex-row flex-col md:gap-10 mt-6">
                        <Statistic
                          title="Lên đến"
                          value={
                            Math.round(maxBufferValue) + maxContributionValue
                          }
                          formatter={formatCurrency}
                          valueStyle={{
                            fontSize: '32px',
                            lineHeigh: '38px',
                            fontWeight: 600,
                            color: '#1E786D'
                          }}
                          className="md:py-4 py-1"
                        />
                        <div className="md:block hidden self-stretch border-solid border-0 border-r-2 border-r-[#5A6F93]" />

                        <div className="md:py-4 py-1">
                          <Statistic
                            title="Trong đó"
                            value={maxContributionValue}
                            formatter={formatCurrency}
                            valueStyle={{
                              fontWeight: 600,
                              color: '#1E98F4'
                            }}
                          />
                          <span>Tổng giá trị tích lũy</span>
                        </div>

                        <div className="md:self-end md:py-4 py-1">
                          <Statistic
                            value={Math.round(maxBufferValue)}
                            formatter={formatCurrency}
                            valueStyle={{
                              fontWeight: 600,
                              color: '#7D3EC4'
                            }}
                          />
                          <span>Lợi nhuận đầu tư dự kiến</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              ) : null}
            </div>
          </Form>

          <Container style={{ padding: 0 }} className="mb-16">
            <Alert
              className="italic"
              message="Lưu ý quan trọng:"
              description="Bảng minh họa chương trình phúc lợi doanh nghiệp chỉ mang tính chất tham khảo, minh họa, không phải cam kết lợi nhuận hoặc tư vấn đầu tư."
              type="warning"
              showIcon
            />
          </Container>
        </div>
      </div>
    </>
  )
}

export default FlexSimulationBundlePage

const config = [
  {
    name: 'employee_contribute_amount',
    label: 'Số tiền nhân viên tích lũy hàng tháng:',
    formatter: formatNumber,
    FormItem: ({ label, name }) => (
      <Form.Item name={name} label={label}>
        <InputNumber
          className="w-full"
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          min={0}
          step={1000}
          suffix="VND"
        />
      </Form.Item>
    )
  },
  {
    name: 'company_contribute_amount',
    label: 'Số tiền công ty đóng góp hàng tháng:',
    formatter: formatNumber,
    FormItem: ({ label, name }) => (
      <Form.Item name={name} label={label}>
        <InputNumber
          className="w-full"
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          min={0}
          step={1000}
          suffix="VND"
        />
      </Form.Item>
    )
  },
  {
    name: 'investment_interval',
    label: 'Thời gian chương trình (số kỳ tham gia)',
    FormItem: ({ label, name }) => (
      <Form.Item name={name} label={label}>
        <InputNumber
          className="w-full"
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          min={0}
          step={1}
          suffix="kỳ"
        />
      </Form.Item>
    )
  },
  {
    name: 'start_date',
    label: 'Thời điểm bắt đầu:',
    formatter: t => t.format('DD/MM/YYYY'),
    FormItem: ({ label, name }) => (
      <Form.Item name={name} label={label}>
        <DatePicker
          sx={{ px: 1 }}
          openTo="year"
          views={['year', 'month', 'day']}
          format="DD/MM/YYYY"
          slotProps={{
            textField: { variant: 'standard', size: 'small' }
          }}
        />
      </Form.Item>
    )
  },
  {
    name: 'monthly_order_payment',
    label: 'Thời điểm đặt lệnh:',
    FormItem: ({ label, name }) => (
      <Form.Item name={name} label={label}>
        <InputNumber
          className="w-full"
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          min={1}
          max={28}
          step={1}
          prefix="Ngày"
          suffix="mỗi tháng"
        />
      </Form.Item>
    )
  },

  {
    name: 'grow',
    label: 'Chứng chỉ quỹ',
    FormItem: ({ label, name }) => (
      <Form.Item name={name} label={label}>
        <InputNumber
          className="w-full"
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          min={0}
          step={1}
          suffix="%/năm"
        />
      </Form.Item>
    )
  },

  {
    name: 'bank',
    label: 'Tiết kiệm',
    FormItem: ({ label, name }) => (
      <Form.Item name={name} label={label}>
        <InputNumber
          className="w-full"
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          min={0}
          step={1}
          suffix="%/năm"
        />
      </Form.Item>
    )
  }
]

const initialValues = {
  company_contribute_amount: 1000000,
  start_date: currentDate,
  employee_contribute_amount: 0,
  investment_interval: 36,
  monthly_order_payment: currentDateNumber < 29 ? currentDateNumber : 1,
  grow: 10,
  bank: 5.15
}

function calculateMonthlyRate(annualRate) {
  return Math.pow(1 + annualRate, 1 / 12) - 1
}

const convertGroupedBarData = (arr, { grow, bank }, isMobile) => {
  let stackBarArray = []
  let lineArray = []
  arr.map(
    (
      {
        accumulated_company_contributed_amount,
        accumulated_employee_contributed_amount,
        order_date
      },
      index
    ) => {
      const format_order_date = formatDate(order_date)

      const caculateGrow = (percentage1Y, name) => {
        const { value: previousValue } = [...lineArray]
          .reverse()
          .find(e => e.name === name) || { value: 0 }
        const percentage1M = calculateMonthlyRate(percentage1Y / 100) * 100

        return lineArray.push({
          name,
          order_date: format_order_date,
          value:
            ((accumulated_company_contributed_amount +
              accumulated_employee_contributed_amount +
              previousValue) *
              percentage1M) /
              100 +
            previousValue
        })
      }

      // if (index !== 0 && index !== arr.length - 1) {
      //   if (arr.length > 50) {
      //     if (isMobile) {
      //       if (index % 4 !== 0) {
      //         return
      //       }
      //     } else {
      //       if (index % 2 === 0) {
      //         return
      //       }
      //     }
      //   } else if (arr.length > 30) {
      //     if (isMobile) {
      //       if (index % 3 !== 0) {
      //         return
      //       }
      //     } else {
      //       if (index % 4 === 0) {
      //         return
      //       }
      //     }
      //   }
      // }

      stackBarArray.push({
        name: 'Nhân viên',
        order_date: format_order_date,
        value: accumulated_employee_contributed_amount
      }),
        stackBarArray.push({
          name: 'Công ty',
          order_date: format_order_date,
          value: accumulated_company_contributed_amount
        }),
        grow && caculateGrow(grow, 'CCQ')

      bank && caculateGrow(bank, 'Tiền gửi')
    }
  )

  return [stackBarArray, lineArray]
}

const barConfig = {
  xField: 'order_date',
  yField: ['value', 'value'],

  legend: {
    layout: 'horizontal',
    position: 'bottom',
    showContent: true,
    offsetY: 4
  },

  padding: 'auto',
  geometryOptions: [
    {
      isStack: true,
      geometry: 'column',
      seriesField: 'name',
      minColumnWidth: 3,
      maxColumnWidth: 36,
      color: ['#1E98F4', '#061669'],
      locale: 'en-US',
      meta: {
        value: {
          autoHide: true
        }
      },
      tooltip: {
        formatter: e => {
          const { name, value } = e || {}
          return { name, value: formatCurrency(value) }
        }
      }
    },
    {
      geometry: 'line',
      seriesField: 'name',
      tooltip: {
        formatter: e => {
          const { name, value } = e || {}
          return { name, value: formatCurrency(Math.round(value)) }
        }
      },
      color: ['#73E1FB', '#7D3EC4'],

      lineStyle: {
        lineWidth: 3
      }
    }
  ]
}

export const removeKeyList = (object, list) => {
  const newObject = { ...object }
  list.map(e => delete newObject[e])
  return newObject
}

const BundleItem = ({
  roi,
  risk,
  description,
  target,
  name,
  min,
  duration,
  onClick,
  role = 'business',
  isActived,
  setIsBundle
}) => {
  const bg = isActived ? 'bg-[#F0F3FE]' : 'bg-[#F9FAFB]'
  return (
    <div
      className={
        'max-w-sm p-4 bg-[#F0F3FE] border border-blue-primary-500 rounded-lg shadow ' +
        bg
      }
    >
      <div className="flex flex-col gap-3">
        <div className="flex gap-4 justify-between items-center">
          <div>
            <div className="text-xl font-semibold">{name}</div>
            <div>{target}</div>
          </div>
          <button
            onClick={onClick}
            type="button"
            className={`text-blue-primary-500 bg-white border border-blue-primary-500  
            focus:ring-2 focus:outline-none focus:ring-offset-blue-primary-200 focus:bg-blue-primary-600 focus:text-white
            hover:opacity-50 font-medium rounded-lg text-xs px-3 py-2`}
          >
            Chọn
          </button>
        </div>

        {isActived ? (
          <>
            <div>
              <div className="text-2xl font-semibold">
                <span>{formatCurrencyWithoutCurrencySymbol(min)}</span>
                <span>đ</span>
                <span className="ms-1">/tháng</span>
              </div>
              <div className="text-secondary">
                {role === 'business'
                  ? 'Công ty tham gia tối thiểu (*)'
                  : 'Nhân viên tham gia tối thiểu (*)'}
              </div>
            </div>

            <div className="h-px bg-gray-300" />

            {description ? (
              <div className="text-justify text-secondary">{description}</div>
            ) : null}

            {[
              {
                name: 'Thời gian tham gia tối thiểu:',
                value: `${duration} năm`
              },
              { name: 'Mức độ rủi ro:', value: risk },
              { name: 'Lợi nhuận dự kiến:', value: formatPercentage(roi) }
            ].map(({ name, value }, index) => (
              <div key={index} className="flex gap-4 justify-between">
                <span>{name}</span>
                <span>{value}</span>
              </div>
            ))}
            <Button type="primary" ghost onClick={() => setIsBundle(false)}>
              Tùy chỉnh
            </Button>
          </>
        ) : null}
      </div>
    </div>
  )
}

const bundleItems = [
  {
    role: 'employee',
    roi: 8,
    risk: 'Thấp',
    name: 'Saving+',
    target: 'Dành cho mọi đối tượng',
    min: 1000000,
    values: {
      company_contribute_amount: 0,
      employee_contribute_amount: 1000000,
      investment_interval: 12,
      grow: 8
    },
    duration: 1
  },
  {
    roi: 8,
    risk: 'Thấp',
    name: 'Foundation',
    target: 'Dành cho mọi đối tượng',
    description:
      'Phù hợp với nhóm nhân sự có thu nhập ổn định, có nhu đầu bắt đầu tích luỹ tài sản thông qua kênh đầu tư an toàn.',
    min: 1000000,
    values: {
      company_contribute_amount: 1000000,
      employee_contribute_amount: 0,
      investment_interval: 36,
      grow: 8
    },
    duration: 3
  },
  {
    roi: 10,
    risk: 'Trung bình cao',
    name: 'Growth',
    target: 'Dành cho Quản lý Trung cấp',
    description:
      'Phù hợp với nhóm nhân sự có thu nhập và mức sống cao hơn, có nhu cầu gia tăng tài sản qua các kênh tiềm năng.',
    min: 3000000,
    values: {
      company_contribute_amount: 3000000,
      employee_contribute_amount: 0,
      investment_interval: 60,
      grow: 10
    },
    duration: 5
  },
  {
    roi: 12,
    risk: 'Cao',
    name: 'Wealth',
    target: 'Dành cho Quản lý Cao cấp',
    description:
      'Phù hợp với nhóm nhân sự nền tảng tài chính vững chắc, muốn gia tăng tài sản nhanh để theo đuổi các mục tiêu khác.',
    min: 10000000,
    values: {
      company_contribute_amount: 10000000,
      employee_contribute_amount: 0,
      investment_interval: 60,
      grow: 12
    },
    duration: 5
  }
]

export const Header = () => {
  return (
    <nav>
      <div className="bg-white">
        <div className="flex items-center justify-between max-w-screen-xl mx-auto py-2 px-4 gap-4">
          <Link href="/">
            <img
              className="object-cover min-w-[109px] max-w-[125px]"
              alt="STAG VIETNAM Logo"
              src={cloudflareCdn('/stagvn/assets/svg/stagvn.svg')}
            />
          </Link>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://stag.vn/app"
            className="bg-blue-primary-500 hover:bg-blue-primary-300 text-white md:text-base text-sm py-2 px-4 rounded"
          >
            Tải ứng dụng
          </a>
        </div>
      </div>
    </nav>
  )
}

const Contact = () => {
  return (
    <a
      href="https://stag.vn/futureflex#contact"
      target="_blank"
      rel="noopener noreferrer"
      style={{ display: 'inline-block' }}
    >
      <Button ghost type="primary" icon={<MailOutlined />}>
        <span>Nhận tư vấn</span>
      </Button>
    </a>
  )
}
