import { notification } from 'antd'
import { eq, flow, get, includes } from 'lodash/fp'
import { ACTIONS } from 'redux-api-call'
import { put, takeEvery } from 'redux-saga/effects'

import SessionAction from 'state/action/session'
import { getErrorMessage } from 'utils/getErrorMessage'
import { openNotificationWithIcon } from 'utils/openNotificationWithIcon'

const WHITELISTED_API_CALLS = []

const is401Error = flow(get('payload.statusCode'), eq(401))
const whitelistedApiCall = flow(get('payload.name'), name =>
  includes(name)(WHITELISTED_API_CALLS)
)

function* onAuthError(action) {
  try {
    if (is401Error(action) && !whitelistedApiCall(action)) {
      yield localStorage.removeItem('goalDraft')

      yield put({
        type: SessionAction.UPDATE_STAG_USER,
        payload: {
          currentUser: null,
          currentToken: null,
          isLoading: false
        }
      })

      const response = get(`payload.json`)(action)
      const errorMessage = getErrorMessage(response)
      yield notification.destroy()

      yield openNotificationWithIcon({
        type: 'error',
        message: 'Something went wrong!',
        description: errorMessage,
        duration: 2
      })
    }
  } catch (error) {
    console.log('OnAuthError', error)
  }
}

export function* watchonAuthError() {
  yield takeEvery(ACTIONS.FAILURE, onAuthError)
}
