import { PageHeader } from '@ant-design/pro-layout'
import { useFetchApiGet } from '@stagapps/redux-utils'
import { Descriptions, Space, Typography } from 'antd'
import apiProgramOrderCall from 'app/apiCalls/employeeProgramOrderV2'
import MetaDecorator from 'common/layout/MetaDecorator'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import UIDate from 'common/ui/display/UIDate'
import UIInfoTooltip from 'common/ui/display/UIInfoTooltip'
import { orderTypeLabels } from 'data/fundOrder'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { formatCurrency } from 'utils/formatAmount'

const { Item } = Descriptions
const { Title } = Typography

function ProgramOrderDetailPageV2() {
  const { id, programId, programOrderId } = useParams()
  const {
    data: programOrder,
    load: fetchOrder,
    isLoading,
    error
  } = useFetchApiGet(apiProgramOrderCall.detail, { resourceName: 'data' })

  useEffect(() => {
    fetchOrder({ id, programId, programOrderId })
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  const { code: POCode } = programOrder || {}
  const programOrdersCode = `Chi tiết lệnh ${POCode}`
  const {
    order,
    order_date,
    company,
    order_type,
    order_side,
    product_code,
    amount,
    employee
  } = programOrder || {}
  const { name } = company || {}
  const { full_name } = employee || {}
  const isEmployee = order_side === 'employee'

  return (
    <>
      <MetaDecorator title={'Lịch sử giao dịch'} />
      <PageHeader
        className="site-page-header"
        title={
          <Title ellipsis level={2}>
            {programOrdersCode}
          </Title>
        }
      />
      <div className="flex flex-col gap-4">
        <UICard title="Thông tin lệnh">
          <Descriptions column={1} bordered>
            <Item label="Ngày tạo">{<UIDate timeData={order_date} />}</Item>
            <Item label={'Phân loại lệnh'}>
              {isEmployee ? 'Nhân viên tích lũy' : 'Doanh nghiệp đóng góp'}
            </Item>
            <Item label={isEmployee ? 'Họ Tên' : 'Tên Doanh nghiệp'}>
              {isEmployee ? full_name : name}
            </Item>
            <Item label="Loại lệnh">{orderTypeLabels[order_type]}</Item>
            <Item label="Sản phẩm">{product_code}</Item>
            <Item
              label={
                <Space>
                  {isEmployee ? (
                    <>
                      <div>{'Giá trị tích lũy'}</div>
                      <UIInfoTooltip
                        title={
                          'Giá trị Nhân viên cần tích lũy vào chương trình hiện tại.'
                        }
                      />
                    </>
                  ) : (
                    <>
                      <div>{'Giá trị đóng góp'}</div>
                      <UIInfoTooltip
                        title={
                          'Giá trị Doanh nghiệp cần đóng góp vào chương trình hiện tại.'
                        }
                      />
                    </>
                  )}
                </Space>
              }
            >
              {formatCurrency(amount)}
            </Item>
            {isEmployee ? null : (
              <Item
                label={
                  <Space>
                    <p>{'Phân bổ từ mục tái phân phối'}</p>
                    <UIInfoTooltip
                      title={
                        <>
                          <div>
                            Ở mỗi kỳ, phần đóng góp của Doanh nghiệp có thể được
                            cấn trừ từ mục tái phân phối trong Danh mục của
                            Doanh nghiệp. Doanh nghiệp sẽ không cần thanh toán
                            cho các lệnh được tái phân phối này.
                          </div>
                          <br />
                          <div>
                            Mục tái phân phối bao gồm những phần CCQ mà Doanh
                            nghiệp đã mua để đóng góp cho chương trình của Nhân
                            viên nhưng sau đó những chương trình này bị huỷ. Để
                            giúp Doanh nghiệp tối ưu phí mua bán, hệ thống sẽ sử
                            dụng số dư này để khớp tiếp cho các chương trình còn
                            đang tham gia vào các kỳ tiếp theo.
                          </div>
                        </>
                      }
                    />
                  </Space>
                }
              >
                <RedistributionDescription {...programOrder} />
              </Item>
            )}
          </Descriptions>
        </UICard>
      </div>
    </>
  )
}

const RedistributionDescription = ({
  matched_source,
  amount,
  status,
  quantity,
  estimate_quantity
}) => {
  const renderQuantity = status === 1005 ? quantity : estimate_quantity

  if (matched_source === 1002) {
    return (
      <>
        <p>{formatCurrency(amount)}</p>
        <div className="text-xs text-slate-500">
          {`Ước tính tương đương ${formatNumber(
            renderQuantity
          )} CCQ (dựa trên giá NAV gần nhất)`}
        </div>
      </>
    )
  }
  return (
    <>
      <p>Không áp dụng</p>
      <div className="text-xs text-slate-500">
        Số dư CCQ không đủ để tái phân phối
      </div>
    </>
  )
}

export default ProgramOrderDetailPageV2
