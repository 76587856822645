import { usePostApi } from '@stagapps/redux-utils'
import { Alert, Form, Typography } from 'antd'

import apiCall from 'app/apiCalls/account/resetPassword'
import AuthLayout from 'app/components/layouts/AuthLayout'
import UpdatePasswordForm from 'business/components/setting/UpdatePasswordForm'
import { Link } from 'react-router-dom'
import { getErrorMessage } from 'utils/getErrorMessage'

const { Title } = Typography

function ResetPasswordPage() {
  const [form] = Form.useForm()

  const { post, isLoading: isSubmitting, error } = usePostApi(apiCall, {
    resourceName: 'data'
  })

  const onFinish = values => {
    post(values)
  }

  return (
    <AuthLayout imgUrlPath={'/futureflex/images/login_employee_v2.jpeg'}>
      <div>
        <Title level={4}>Chúc mừng</Title>
        <p className="text-gray-500">
          {'Bạn vừa xác thực email thành công.'}
          <br />
          {'Hãy tạo mật khẩu đăng nhập mới cho tài khoản của mình.'}
        </p>
      </div>

      {!!error && (
        <Alert
          message={getErrorMessage(error)}
          type="error"
          showIcon
          action={<Link to={`/login`}>Trở về</Link>}
        />
      )}

      <UpdatePasswordForm
        onFinish={onFinish}
        isSubmitting={isSubmitting}
        form={form}
        isReset
      />
      <p>
        Đã có tài khoản?<Link to={`/login`}> Đăng nhập</Link>
      </p>
    </AuthLayout>
  )
}

export default ResetPasswordPage
