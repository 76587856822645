import { useFetchApiList, usePostApi } from '@stagapps/redux-utils'
import { Button, Form, Radio, Space, Typography } from 'antd'
import apiCall from 'app/apiCalls/surveys'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import ValidButton from 'common/ui/form/ValidButton'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { renderErrorMessage } from 'utils/getErrorMessage'
import { riskOptions } from './config'

const { Title } = Typography

function RiskAppetiteOptions() {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { data, load, isLoading, error } = useFetchApiList(apiCall.list, {
    resourceName: 'data'
  })
  const {
    data: responseData,
    post,
    isLoading: isPost,
    error: errorPost
  } = usePostApi(apiCall.create, { resourceName: 'data' })

  useEffect(() => {
    load({ survey_type: 1002 })
  }, [])

  useEffectSkipFirstRender(() => {
    if (responseData) {
      dispatch(apiCall.create.disposer())
      navigate(`/risk-appetite`)
    }
  }, [responseData])

  if (isLoading) {
    return <LoadingSpinner />
  }
  if (error) {
    return <ErrorMessage errors={error} />
  }

  const { answer_codes } = data[0] || {}

  const onFinish = values => {
    post({
      ...values,
      question_code: 'risk_appetite',
      survey_type: 1002,
      question_type: 1001
    })
  }

  return (
    <div className="flex items-center flex-col md:gap-8 gap-4 md:max-w-2xl mx-auto">
      <div>
        <Title level={3}>Đánh giá mức độ chấp nhận rủi ro</Title>
        <div className="text-xs text-[#5A6F93]">
          Câu hỏi được dùng để đề xuất sản phẩm chứng chỉ quỹ phù hợp cho bạn
          vào thời điểm hiện tại. Bạn có thể thay đổi câu trả lời trong mục ‘Tài
          Khoản’ của mình bất kỳ lúc nào.
        </div>
      </div>

      <Form
        initialValues={{ answer_codes }}
        requiredMark={false}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          validateStatus={!!errorPost ? 'error' : null}
          hasFeedback
          help={errorPost ? renderErrorMessage(errorPost) : ''}
          name="answer_codes"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn'
            }
          ]}
        >
          <Radio.Group>
            <div className="flex flex-col md:gap-4 gap-2">
              {riskOptions.map(({ description, title, value }, index) => (
                <UICard key={index} size="small" hoverable>
                  <Radio value={value}>
                    <Space direction="vertical">
                      <div className="text-base font-semibold">{title}</div>
                      <div>{description}</div>
                    </Space>
                  </Radio>
                </UICard>
              ))}
            </div>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Space className="flex justify-center">
            <Link to={'/risk-appetite'}>
              <Button size="large" ghost type="primary">
                Trở về
              </Button>
            </Link>
            <ValidButton
              size="large"
              type="primary"
              htmlType="submit"
              className="w-full"
              btnTitle="Tiếp tục"
              form={form}
              loading={isPost}
            />
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}

export default RiskAppetiteOptions
