import { FETCH_CREATE_API_ID } from 'business/apiCalls/employee/program/create'
import { eq, flow, get } from 'lodash/fp'
import { ACTIONS } from 'redux-api-call'
import { takeEvery } from 'redux-saga/effects'
import { history } from 'routerHistory'

function* onBusinessCreateEmployeeProgramSuccess(action) {
  if (flow(get('payload.name'), eq(FETCH_CREATE_API_ID))(action)) {
    try {
      const { employee_id: employeeId, id: programId } =
        get('payload.json.data')(action) || {}

      if (employeeId && programId) {
        return yield history.push(
          `/employees/${employeeId}/employee-programs/${programId}`
        )
      }
      if (employeeId) {
        return yield history.push(`/employees/${employeeId}`)
      }

      return yield history.push(`/employees`)
    } catch (error) {
      console.log('onBusinessCreateEmployeeProgramError', error)
    }
  }
}

function* watchOnBusinessCreateEmployeeProgram() {
  yield takeEvery(ACTIONS.COMPLETE, onBusinessCreateEmployeeProgramSuccess)
}

export function* businessCreateEmployeeProgramSaga() {
  yield watchOnBusinessCreateEmployeeProgram()
}
