import MetaDecorator from 'common/layout/MetaDecorator'
import UserProgramList from './UserProgramList'
import UserProgramTransaction from './UserProgramTransaction'

export function UserProgramListPage() {
  return (
    <>
      <MetaDecorator title={'Danh sách chương trình cá nhân'} />
      <UserProgramList />
    </>
  )
}

export function UserProgramTransactionPage() {
  return (
    <>
      <MetaDecorator title={'Chương trình cá nhân - Các giao dịch'} />
      <UserProgramTransaction />
    </>
  )
}
