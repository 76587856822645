import { isNumber } from 'lodash'
import { formatCurrency } from 'utils/formatAmount'

export function formatCurrencyWithoutCurrencySymbol(value, options = {}) {
  if (value == 0) {
    return 0
  }
  if (!value) return null

  const amount = formatCurrency(value, (options = {}))
  return amount?.replace(/[^0-9.,]/g, '')
}

export function formatAmountChangeWithPrefix(value, options = {}) {
  if (value == 0) {
    return 0
  }

  if (!value) return null

  if (!isNumber) return null

  const amount = formatCurrency(value, (options = {}))
  const numberPrefix = value > 0 ? '+' : value < 0 ? '-' : ''
  return numberPrefix + amount?.replace(/[^0-9.,]/g, '')
}
