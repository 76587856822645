import { isNaN } from 'lodash/fp'

export const getChangeValueClassColor = value => {
  if (isNaN(value)) return
  if (value > 0) return 'text-green-500'
  if (value < 0) return 'text-red-500'
  if (value == 0) return 'text-yellow-500'

  return
}

export const getChangeValueColor = value => {
  if (isNaN(value)) return
  if (value > 0) return '#22c55e'
  if (value < 0) return '#ef4444'
  if (value == 0) return '#eab308'

  return
}
