import { usePostApi } from '@stagapps/redux-utils'
import { Button, Form, Input, Space } from 'antd'
import { apiCallGeneratorByName } from 'app/apiCalls/surveys/create'
import ValidButton from 'common/ui/form/ValidButton'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import { useNavigate } from 'react-router-dom'
import { renderErrorMessage } from 'utils/getErrorMessage'
import WelcomeTemplate from './WelcomeTemplate'

function Question3() {
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const { data, post, isLoading, error } = usePostApi(
    apiCallGeneratorByName('survey3'),
    { resourceName: 'data' }
  )

  useEffectSkipFirstRender(() => {
    if (data) {
      navigate(`/`)
    }
  }, [data])

  const onFinish = values => {
    post({
      ...values,
      question_code: '1003',
      survey_type: 1001,
      question_type: 1003
    })
  }

  return (
    <WelcomeTemplate
      quotes={
        'Từ bước đầu tiên học tập, tìm hiểu kiến thức tài chính đến thực hành và đầu tư trực tiếp - Chúng tôi luôn bên cạnh bạn!'
      }
      author={'Stag Invest'}
    >
      <Form
        form={form}
        requiredMark={false}
        onFinish={onFinish}
        labelCol={{ span: 24 }}
      >
        <Form.Item
          validateStatus={!!error ? 'error' : null}
          hasFeedback
          help={error ? renderErrorMessage(error) : ''}
          label={'Bạn đang làm việc tại tổ chức nào?'}
          className="md:max-w-[78%] mx-auto text-left"
          name="answer_codes"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn 1 câu trả lời'
            }
          ]}
        >
          <Input placeholder="Công ty ABC" />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" ghost>
              Trở về
            </Button>
            <ValidButton
              form={form}
              type="primary"
              htmlType="submit"
              btnTitle="Tiếp tục"
            />
          </Space>
        </Form.Item>
      </Form>
    </WelcomeTemplate>
  )
}

export default Question3

const options = [
  {
    label: 'Lãnh đạo/ Giám đốc cấp cao',
    value: 1
  },
  {
    label: 'Quản lý / Trưởng bộ phận',
    value: 2
  },
  {
    label: 'Nhân viên',
    value: 3
  },
  {
    label: 'Hành nghề tự do',
    value: 4
  },
  {
    label: 'Tự kinh doanh',
    value: 5
  },
  {
    label: 'Khác',
    value: 6
  }
]
