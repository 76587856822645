export const employeeProgramOrderStatusColor = {
  1001: 'yellow',
  1002: 'red',
  1003: 'yellow',
  1004: 'yellow',
  1005: 'green',
  1006: ''
}

export const employeeProgramOrderStatusNew = 1001
export const employeeProgramOrderStatusWaitingPayment = 1002
export const employeeProgramOrderStatusWaitingForApprove = 1003
export const employeeProgramOrderStatusProcessing = 1004
export const employeeProgramOrderStatusCompleted = 1005
export const employeeProgramOrderStatusCancelled = 1006

// // ProgramOrderStatusNew represents status once program orders just created.
// ProgramOrderStatusNew ProgramOrderStatus = 1001

// // ProgramOrderStatusWaitingPayment represents status program order is waiting for payment.
// ProgramOrderStatusWaitingPayment ProgramOrderStatus = 1002

// // ProgramOrderStatusWaitingForApprove represents status employee confirm payment and waiting for fund to confirm.
// ProgramOrderStatusWaitingForApprove ProgramOrderStatus = 1003

// // ProgramOrderStatusProcessing represents status once program orders is bundled to fund order.
// ProgramOrderStatusProcessing ProgramOrderStatus = 1004

// // ProgramOrderStatusCompleted represents end status once program order is completed.
// ProgramOrderStatusCompleted ProgramOrderStatus = 1005

// // ProgramOrderStatusCancelled represents end status once program order is cancelled.
// ProgramOrderStatusCancelled ProgramOrderStatus = 1006
