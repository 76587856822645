import ReviewBeforeSubmitForm from 'common/ui/form/ReviewBeforeSubmitForm'
import { formItems } from './formItems'

function EmployeeCreateForm({ apiCall }) {
  const dateFields = ['date_of_birth']

  return (
    <div>
      <ReviewBeforeSubmitForm
        formName={'create-program'}
        apiCall={apiCall}
        config={formItems}
        reviewTitle={'Kiểm tra lại thông tin nhân viên'}
        formTitle={'Thông tin chung'}
        submitButtonTitle={'Xác nhận tạo nhân viên'}
        dateFields={dateFields}
      />
    </div>
  )
}

export default EmployeeCreateForm
