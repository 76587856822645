import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

export const EMPLOYEE_POST_SIGNIN = 'EMPLOYEE/POST_SIGNIN'

const apiCall = enhancedMakeFetchAction(EMPLOYEE_POST_SIGNIN, payload => ({
  endpoint: `${API_BASE_URL}/investcore-api/app/v1/users/signin`,
  method: 'POST',
  body: payload,
  isStay: true
}))

export default apiCall
