import { formatCurrency } from 'utils/formatAmount'

export const userProgramConfig = {
  xField: 'date',
  yField: 'total_amount',
  seriesField: 'name',
  color: ['#52C41A', '#E378E4', '#031467', '#a8ddb5', '#d62728', '#2ca02c'],

  style: {
    marginTop: 24,
    marginBottom: 24,
    marginRight: 12
  },
  xAxis: {
    range: [0, 1],
    tickCount: 6,
    label: {
      offsetY: 5,
      style: {
        fill: '#5A6F93',
        fontWeight: 'regular'
      }
    }
  },
  tooltip: {
    position: 'top',

    formatter: e => {
      const { total_amount, name } = e || {}
      return { name, value: formatCurrency(total_amount) }
    },
    domStyles: {
      'g2-tooltip': {
        backgroundColor: '#ffffffaa',
        borderRadius: '0.25rem'
      }
    }
  },
  legend: false
}
