import { POST_REQUEST_VERIFY_EMAIL } from 'app/apiCalls/account/requestResetPassword'
import { POST_RESET_PASSWORD } from 'app/apiCalls/account/resetPassword'
import { POST_VERIFY_RESET_PASSWORD } from 'app/apiCalls/account/verifyResetPassword'

import { eq, flow, get } from 'lodash/fp'
import { ACTIONS } from 'redux-api-call'
import { takeEvery } from 'redux-saga/effects'
import { history } from 'routerHistory'

function* onRequestResetPasswordSuccess(action) {
  if (flow(get('payload.name'), eq(POST_REQUEST_VERIFY_EMAIL))(action)) {
    try {
      const { email } = JSON.parse(get('payload.body')(action)) || {}
      const expiresAt = get('payload.json.data.expires_at')(action)
      yield sessionStorage.setItem(
        'resetPassword',
        JSON.stringify({ email, expiresAt })
      )

      yield history.push('/forget-password/otp')
    } catch (error) {
      console.log('onRequestResetPasswordSuccess', error)
    }
  }
}

function* onVerifyEmailResetPasswordSuccess(action) {
  if (flow(get('payload.name'), eq(POST_VERIFY_RESET_PASSWORD))(action)) {
    try {
      const currentToken = get('payload.json.data.access_token')(action)
      yield sessionStorage.setItem(
        'resetPassword',
        JSON.stringify({ currentToken })
      )

      yield history.push('/forget-password/reset-password')
    } catch (error) {
      console.log('onVerifyEmailResetPasswordSuccess', error)
    }
  }
}

function* onResetPasswordSuccess(action) {
  if (flow(get('payload.name'), eq(POST_RESET_PASSWORD))(action)) {
    try {
      yield history.push('/forget-password/success')
      yield sessionStorage.clear()
    } catch (error) {
      console.log('onResetPasswordSuccess', error)
    }
  }
}

function* watchOnVerifyEmailResetPassword() {
  yield takeEvery(ACTIONS.COMPLETE, onRequestResetPasswordSuccess)
  yield takeEvery(ACTIONS.COMPLETE, onVerifyEmailResetPasswordSuccess)
  yield takeEvery(ACTIONS.COMPLETE, onResetPasswordSuccess)
}

export function* resetPasswordSaga() {
  yield watchOnVerifyEmailResetPassword()
}
