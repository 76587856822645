import { Button, Space, Tag } from 'antd'
import { orderStatusColors, orderTypeLabels } from 'data/fundOrder'
import { Link } from 'react-router-dom'
import { formatUnitQuantity } from 'utils/formatAmount'

const OrderAction = ({ order }) => {
  if (!order) return null

  const { id } = order
  return (
    <Link to={`/orders/${id}`}>
      {order.order_side == 'buy' && order.status == 1 ? (
        <Button type="primary">Thanh toán ngay</Button>
      ) : (
        'Chi tiết'
      )}
    </Link>
  )
}

const ProductDetailColumn = ({ product }) => {
  if (!product) return null
  return (
    <Space>
      {product.logo ? (
        <img width={20} className="object-contain" src={product.logo} />
      ) : null}
      <div className="font-semibold text-blue-primary-500">
        {product.alias || product.code}
      </div>
    </Space>
  )
}

const OrderStatusColumn = ({ order }) => {
  if (!order) return null
  return <Tag color={orderStatusColors[order.status]}>{order.status_label}</Tag>
}

export const pendingOrdersColumnsGenerator = ({ id }) => {
  return [
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      type: 'dateTime'
    },
    {
      title: 'Sản phẩm',
      dataIndex: 'product',
      render: product => <ProductDetailColumn product={product} />
    },
    {
      title: 'Số tiền mua (VND)',
      dataIndex: 'amount',
      type: 'formatCurrency'
    },
    {
      title: 'Trạng thái',
      dataIndex: 'order',
      render: order => <OrderStatusColumn order={order} />
    },
    {
      title: '',
      dataIndex: 'order',
      render: (order, record) => (
        <OrderAction programId={id} order={order} programOrder={record} />
      )
    }
  ]
}

export const buyOrdersColumnsGenerator = ({ id }) => {
  return [
    {
      title: 'Ngày đặt lệnh',
      dataIndex: 'created_at',
      type: 'dateTime'
    },
    {
      title: 'Sản phẩm',
      dataIndex: 'product',
      render: product => <ProductDetailColumn product={product} />
    },
    {
      title: 'Số tiền mua (VND)',
      dataIndex: 'amount',
      type: 'formatCurrency'
    },
    {
      title: 'Số CCQ ước tính',
      dataIndex: 'order',
      align: 'right',
      render: order =>
        order && order.estimate_quantity
          ? formatUnitQuantity(order.estimate_quantity)
          : '-'
    },
    {
      title: 'Trạng thái',
      dataIndex: 'order',
      render: order => <OrderStatusColumn order={order} />
    },
    {
      title: '',
      dataIndex: 'order',
      render: (order, record) => (
        <OrderAction programId={id} order={order} programOrder={record} />
      )
    }
  ]
}

export const sellOrdersColumnsGenerator = ({ id }) => {
  return [
    {
      title: 'Ngày đặt lệnh',
      dataIndex: 'created_at',
      type: 'dateTime'
    },
    {
      title: 'Sản phẩm',
      dataIndex: 'product',
      render: product => <ProductDetailColumn product={product} />
    },
    {
      title: 'Số lượng CCQ',
      dataIndex: 'quantity',
      align: 'right',
      render: quantity => (quantity ? formatUnitQuantity(quantity) : '-')
    },
    {
      title: 'Giá trị ước tính (VND)',
      dataIndex: 'estimate_net_amount',
      type: 'formatCurrency'
    },
    {
      title: 'Trạng thái',
      dataIndex: 'order',
      render: order => <OrderStatusColumn order={order} />
    },
    {
      title: '',
      dataIndex: 'order',
      render: (order, record) => (
        <OrderAction programId={id} order={order} programOrder={record} />
      )
    }
  ]
}

export const ordersColumnsGenerator = ({ id }) => {
  return [
    {
      title: 'Loại lệnh',
      dataIndex: 'order',
      render: order => orderTypeLabels[order.order_side]
    },
    {
      title: 'Mã lệnh',
      dataIndex: 'order',
      align: 'center',
      render: order =>
        order && order.fund_order_id ? order.fund_order_id : '-'
    },
    {
      title: 'Sản phẩm',
      dataIndex: 'product',
      render: product => <ProductDetailColumn product={product} />
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      type: 'dateTime'
    },
    {
      title: 'Số lượng CCQ',
      dataIndex: 'quantity',
      type: 'formatNumber'
    },
    {
      title: 'Giá NAV đã khớp (VND)',
      dataIndex: 'matched_price',
      type: 'formatCurrency'
    },
    {
      title: 'Tổng tiền (VND)',
      dataIndex: 'amount',
      type: 'formatCurrency'
    },
    {
      title: 'Trạng thái',
      dataIndex: 'order',
      render: order => <OrderStatusColumn order={order} />
    },
    {
      title: '',
      dataIndex: 'order',
      render: (order, record) => (
        <OrderAction programId={id} order={order} programOrder={record} />
      )
    }
  ]
}
