import { Image, Space, Tag } from 'antd'
import { Link } from 'react-router-dom'

import UIDate from 'common/ui/display/UIDate'
import {
  employeeProgramStatus,
  employeeProgramStatusColors
} from 'data/employeeProgram'
import { formatPercentage, formatUnitQuantity } from 'utils/formatAmount'
import { formatCurrencyWithoutCurrencySymbol } from 'utils/formatAmount/formatUI'
import { getChangeValueClassColor } from './color'

export const employeeProgramsColumns = [
  {
    title: 'Mã chương trình',
    dataIndex: 'code'
  },
  {
    title: 'Bắt đầu',
    dataIndex: 'start_date',
    render: value => <UIDate timeData={value} />
  },
  {
    title: 'Kết thúc',
    dataIndex: 'end_date',
    render: value => <UIDate timeData={value} />
  },
  {
    title: 'Tổng vốn đã góp (VND)',
    dataIndex: 'company_contribute_amount',
    render: (text, record) => (
      <p>
        {new Intl.NumberFormat('en-US').format(
          record.sum_company_contributed_amount +
            record.sum_employee_contributed_amount
        )}
      </p>
    ),
    align: 'right'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: value => {
      return (
        <Tag color={employeeProgramStatusColors[value]}>
          {employeeProgramStatus[value]}
        </Tag>
      )
    }
  }
]

export const businessEmployeeProgramDetailLink = {
  dataIndex: 'id',
  render: (text, record) => (
    <Link
      className="text-blue-primary-500"
      to={`/employees/${record.employee_id}/employee-programs/${record.id}`}
    >
      Chi tiết
    </Link>
  )
}

export const renderProductColumn = e => (
  <Space>
    {e?.logo ? (
      <Image width={20} className="object-contain	" src={e.logo} />
    ) : null}
    <div className="font-semibold text-blue-primary-500">
      {e?.alias || e?.code}
    </div>
  </Space>
)

export const portColumn = [
  {
    title: 'Mã CCQ',
    dataIndex: 'product',
    render: renderProductColumn
  },
  {
    title: 'Số dư CCQ',
    dataIndex: 'quantity',
    render: value => (value ? formatUnitQuantity(value) : '-'),
    align: 'right'
  },
  {
    title: 'Số CCQ khả dụng',
    dataIndex: 'available_quantity',
    render: value => (value ? formatUnitQuantity(value) : '-'),
    align: 'right'
  },
  {
    title: 'Giá trị hiện tại (VND)',
    dataIndex: 'market_value',
    type: 'formatCurrency'
  },
  {
    title: 'Lãi/Lỗ (VND)',
    dataIndex: 'unrealized_gain_loss',
    render: value => (
      <p className={getChangeValueClassColor(value)}>
        {formatCurrencyWithoutCurrencySymbol(value)}
      </p>
    ),
    align: 'right'
  },
  {
    title: 'Lãi/Lỗ (%)',
    dataIndex: 'unrealized_gain_loss_percentage',
    render: value => (
      <p className={getChangeValueClassColor(value)}>
        {formatPercentage(value)}
      </p>
    ),
    align: 'right'
  }
]
