import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils'
import { Alert, Button, Card, Form, Input, Typography } from 'antd'
import { useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { history } from 'routerHistory'

import apiCall from 'app/apiCalls/employeeOnboarding'
import MetaDecorator from 'common/layout/MetaDecorator'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import { Item } from 'common/ui/display/UIDescriptions'
import useCheckAuth from 'hooks/auth/useCheckAuth'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import UnauthorizedStatePage from './UnauthorizedStatePage'

const { Title } = Typography

function EmployeeProgramConfirmationPage() {
  const [form] = Form.useForm()
  const { id, programId } = useParams()
  const { isAuthenticated, currentUser } = useCheckAuth()

  const {
    data: employeeProgram,
    load: loadEmployeeProgram,
    isLoading: isLoadingEmployeeProgram,
    error: employeeProgramError
  } = useFetchApiGet(apiCall.detail, {
    resourceName: 'data'
  })

  const {
    data: confirmData,
    post,
    isLoading: isSubmitting,
    error
  } = usePostApi(apiCall.confirm, {
    resourceName: 'data'
  })

  const isLoading = isLoadingEmployeeProgram
  const isError = !!employeeProgramError

  useEffect(() => {
    loadEmployeeProgram({ id, programId })
  }, [])

  useEffectSkipFirstRender(() => {
    if (!!confirmData) {
      history.replace(`/${id}/employee-programs/${programId}/investment-plans`)
    }
  }, [confirmData])

  if (!isAuthenticated) {
    return <UnauthorizedStatePage />
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError) {
    const { error: errorMessage } = employeeProgramError
    return <Title level={4}>{errorMessage}</Title>
  }

  const {
    employee,
    company,
    joined_at,
    code,
    is_set_investment_plan
  } = employeeProgram

  if (!!joined_at && is_set_investment_plan) {
    return (
      <Navigate to={`/${id}/employee-programs/${programId}/fund-accounts`} />
    )
  }

  if (!!joined_at) {
    return (
      <Navigate to={`/${id}/employee-programs/${programId}/investment-plans`} />
    )
  }

  const { full_name, department, role } = employee || {}
  const { name } = company || {}

  return (
    <>
      <MetaDecorator title="Xác nhận tham gia chương trình nhân viên" />
      <Form
        className="flex flex-col gap-4 items-stretch max-w-2xl mx-auto"
        requiredMark={false}
        initialValues={{
          remember: true
        }}
        onFinish={values => {
          post({
            id,
            programId,
            payload: values
          })
        }}
        form={form}
      >
        <Card className="flex flex-col gap-4 items-stretch">
          <div>
            <Title level={4}>Chào mừng bạn đến chương trình FutureFlex</Title>
            <p>
              Vui lòng kiểm tra lại thông tin và nhập mã tham gia được gửi kèm
              email.
            </p>
          </div>

          <Item label="Doanh nghiệp"> {name}</Item>
          <Item label="Họ tên nhân viên"> {full_name}</Item>
          <Item label="Phòng ban"> {department}</Item>
          <Item label="Vị trí"> {role}</Item>
          <Item label="Số chương trình"> {code}</Item>

          <Form.Item
            className="p-2"
            validateStatus={!!error ? 'error' : null}
            hasFeedback
            help={error?.error}
            labelCol={{ span: 24 }}
            label={
              <p className="text-sm font-semibold">
                Mã tham gia chương trình từ email
              </p>
            }
            name="code"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập mã'
              }
            ]}
          >
            <Input placeholder="Nhập mã tham gia đính kèm email" size="large" />
          </Form.Item>
        </Card>
        <div className="text-left">
          <Alert
            showIcon
            type="warning"
            message={
              <div>
                Lưu ý quan trọng: bằng việc xác nhận tham gia, chương trình của
                nhân viên <strong>{full_name}</strong> sẽ được gắn với thư điện
                tử <strong>{currentUser.email}</strong>.
              </div>
            }
          />
        </div>
        <Form.Item className="flex justify-end">
          <Button htmlType="submit" type="primary" loading={isSubmitting}>
            Xác nhận tham gia
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default EmployeeProgramConfirmationPage
