import { Button } from 'antd'
import EmptyProfileCard from 'app/components/cards/EmptyProfileCard'
import useAccountVerifiedStatus from 'app/hooks/useAccountVerifiedStatus'
import MetaDecorator from 'common/layout/MetaDecorator'
import PageTitle from 'common/layout/PageTitle'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import UIDate from 'common/ui/display/UIDate'
import { Item } from 'common/ui/display/UIDescriptions'
import { Link } from 'react-router-dom'
import AccountNavigation from './AccountNavigation'

const PAGE_TITLE = 'Giấy tờ tùy thân'

function AccountIDPage() {
  const {
    isLoading,
    isError,
    error,
    linkTo,
    user,
    isCompleteAllKYC
  } = useAccountVerifiedStatus()

  if (isLoading) {
    return (
      <div className="flex flex-col gap-4">
        <MetaDecorator title={PAGE_TITLE} />
        <PageTitle title={PAGE_TITLE} />
        <UICard>
          <AccountNavigation key="identification" />
          <LoadingSpinner />
        </UICard>
      </div>
    )
  }

  if (isError) {
    return (
      <div className="flex flex-col gap-4">
        <MetaDecorator title={PAGE_TITLE} />
        <PageTitle title={PAGE_TITLE} />
        <UICard>
          <AccountNavigation key="identification" />
          <ErrorMessage errors={error} />
        </UICard>
      </div>
    )
  }

  if (!isCompleteAllKYC) {
    return (
      <div className="flex flex-col gap-4">
        <MetaDecorator title={PAGE_TITLE} />
        <PageTitle title={PAGE_TITLE} />
        <UICard>
          <AccountNavigation key="identification" />

          <EmptyProfileCard>
            <Button type="primary">
              <Link to={linkTo}>Định danh ngay</Link>
            </Button>
          </EmptyProfileCard>
        </UICard>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      <MetaDecorator title={PAGE_TITLE} />
      <PageTitle title={PAGE_TITLE} />
      <UICard>
        <AccountNavigation key="identification" />
        <Item label="Số CMND/CCCD">{user.id_number}</Item>
        <Item label="Ngày cấp">{<UIDate timeData={user.issue_date} />}</Item>
        <Item label="Nơi cấp">{user.issue_place}</Item>
        <Item label="Địa chỉ thường trú">{user.residential_address}</Item>
      </UICard>
    </div>
  )
}

export default AccountIDPage
