import { Layout, Space } from 'antd'
import { Link } from 'react-router-dom'

const Footer = () => (
  <Layout.Footer className="mx-auto text-center">
    <Space size={'small'}>
      <p>Cần trợ giúp? Liên hệ</p>
      <Link
        to="#"
        onClick={e => {
          e.preventDefault()
          window.location.href = `mailto:support@stag.vn`
        }}
      >
        support@stag.vn
      </Link>
    </Space>

    <div>STAG VIETNAM © {new Date().getFullYear()}</div>
  </Layout.Footer>
)

export default Footer
