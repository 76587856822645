import { ConfigProvider, Steps } from 'antd'

const VerificationSteps = ({ current = 0, size }) => (
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#52C41A'
      }
    }}
  >
    <Steps
      size={size}
      current={current}
      labelPlacement="vertical"
      items={[
        {
          title: 'Email'
        },
        {
          title: 'Số điện thoại'
        },
        {
          title: 'Danh tính'
        },
        {
          title: 'Thông tin ngân hàng'
        }
      ]}
    />
  </ConfigProvider>
)
export default VerificationSteps
