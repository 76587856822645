import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'

import { API_BASE_URL } from 'config/env.config'

export const PATCH_CONFIRM_IDENTIFICATION =
  'EMPLOYEE/PATCH_CONFIRM_IDENTIFICATION'

const apiCall = enhancedMakeFetchAction(PATCH_CONFIRM_IDENTIFICATION, () => ({
  endpoint: generateEndpoint({
    host: `${API_BASE_URL}/investcore-api/app/v1/identifications/confirm`
  }),

  method: 'PATCH'
}))

export default apiCall
