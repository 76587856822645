import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

function UIInfoTooltip({ title, Icon, size = 16 }) {
  return (
    <Tooltip title={title} color="#0A1A41" className="my-0">
      {Icon ? (
        <Icon />
      ) : (
        <InfoCircleOutlined style={{ color: '#0A1A41', fontSize: size }} />
      )}
    </Tooltip>
  )
}

export default UIInfoTooltip
