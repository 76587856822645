import cancelApiCall from './cancel'
import detailApiCall from './detail'
import listApiCall from './list'

const ApiCall = {
  list: listApiCall,
  cancel: cancelApiCall,
  detail: detailApiCall
}

export default ApiCall
