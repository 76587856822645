import { Button, Result } from 'antd'
import React from 'react'
import { history } from 'routerHistory'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, errorMessages: [] }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo)
    this.setState(prevState => ({
      errorMessages: [...prevState.errorMessages, error.toString()]
    }))
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{ minHeight: '100vh' }}
          className="flex items-center justify-center"
        >
          <Result
            status="500"
            title="Oops"
            subTitle="Lỗi đã được gửi về hệ thống, Stag sẽ xử lý trong thời gian sớm nhất. Vui lòng thử lại."
            extra={[
              <>
                <Button
                  size="large"
                  type="primary"
                  key="console"
                  onClick={() => history.push('/')}
                >
                  Quay lại
                </Button>

                {this.state.errorMessages.map((errorMessage, index) => (
                  <p key={index} className="text-gray-400 text-sm italic">
                    {errorMessage}
                  </p>
                ))}
              </>
            ]}
          />
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
