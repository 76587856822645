import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_USER_PROGRAM_ORDERS_API = 'EMPLOYEE/FETCH_USER_PROGRAM_ORDERS_API'

const apiCall = enhancedMakeFetchAction(
  FETCH_USER_PROGRAM_ORDERS_API,
  ({ programId, ...params }) => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/investcore-api/app/v2/user-programs/${programId}/program-orders`,
      params
    }),
    method: 'GET'
  })
)

export default apiCall
