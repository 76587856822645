import createApiCall from './create'
import detailApiCall from './detail'
import editApiCall from './edit'
import listApiCall from './list'
import statisticApiCall from './statistic'

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  create: createApiCall,
  edit: editApiCall,
  statistic: statisticApiCall
}

export default ApiCall
