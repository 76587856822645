import { enhancedMakeFetchAction } from '@stagapps/redux-utils'

import { API_BASE_URL } from 'config/env.config'

const FETCH_DETAIL_API_ID = 'COMPANY_FUND_ACCOUNTS_DETAIL_API'

const apiCall = enhancedMakeFetchAction(FETCH_DETAIL_API_ID, () => ({
  endpoint: `${API_BASE_URL}/investcore-api/flex/v1/business/fund-accounts`,
  method: 'GET'
}))

export default apiCall
