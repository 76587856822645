import { UnorderedListOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Drawer, Steps } from 'antd'
import qs from 'qs'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { generateEndpoint } from '@stagapps/redux-utils'
import PageTitle from 'common/layout/PageTitle'
import StagLogoSVG from 'common/svg/logo/stag'
import useCheckMobileDevice from 'hooks/useCheckMobileDevice'
import { useEffect, useState } from 'react'

const LayoutOnboarding = () => (
  <div>
    <div className="md:block flex items-center">
      <PageTitle title={'Kế hoạch tài chính'} />
      <OnboardingSteps />
    </div>
    <div className="md:min-w-[45%] mx-auto md:py-8 py-4 flex flex-col items-stretch md:gap-8 ">
      <Outlet />
    </div>
  </div>
)

export default LayoutOnboarding

const getCurrent = pathname => {
  if (pathname === '/goals/new/theme') return 0
  if (pathname === '/goals/new/program') return 1
  if (pathname === '/goals/new/risk-appetite') return 2
  if (pathname === '/goals/new/portfolio') return 2
  if (pathname === '/goals/new/calculator') return 3
  return -1
}

export const OnboardingSteps = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { isMobile } = useCheckMobileDevice()

  const [current, setCurrent] = useState(getCurrent(pathname))
  const [open, setOpen] = useState(false)
  const showDrawer = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }
  const params = qs.parse(window.location.search.substring(1))

  const onChange = value => {
    let host = '/goals/new'
    if (value === 0) {
      host = '/goals/new/theme'
    }
    if (value === 1) {
      host = '/goals/new/program'
    }
    if (value === 2) {
      host = '/goals/new/portfolio'
    }
    if (value === 3) {
      host = '/goals/new/calculator'
    }
    if (value === 4 || current < value) {
      return
    }
    navigate(
      generateEndpoint({
        host,
        params
      })
    )
    setCurrent(value)
  }

  useEffect(() => {
    setCurrent(getCurrent(pathname))
  }, [pathname])

  if (current === -1) {
    return null
  }

  if (isMobile) {
    return (
      <>
        <Button onClick={showDrawer} icon={<UnorderedListOutlined />}>
          {items[current].title}
        </Button>
        <Drawer
          width={'fit-content'}
          className="w-fit"
          title={
            <div className="flex md:w-[134px] w-32 aspect-[134/31]">
              <StagLogoSVG />
            </div>
          }
          placement="right"
          onClose={onClose}
          open={open}
        >
          <Steps
            direction="vertical"
            type="navigation"
            current={current}
            onChange={onChange}
            items={items}
          />
        </Drawer>
      </>
    )
  }
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'CustomFont',
          fontSize: 14
        },
        components: {
          Steps: {
            iconSizeSM: 18
          }
        }
      }}
    >
      <div className="custom-steps mx-auto max-w-4xl">
        <Steps
          type="navigation"
          size="small"
          current={current}
          onChange={onChange}
          items={items}
        />
      </div>
    </ConfigProvider>
  )
}

const items = [
  {
    title: 'Mục tiêu'
  },
  {
    title: 'Kỳ vọng'
  },
  {
    title: 'Danh mục'
  },
  {
    title: 'Phân tích'
  },
  {
    title: 'Tạo chương trình'
  }
]
