import { Alert, Form, Input } from 'antd'
import { useEffect, useState } from 'react'

import ValidButton from 'common/ui/form/ValidButton'
import {
  PasswordValidator,
  isContainBothLowercaseAndUpercase,
  isContainNumber,
  isContainSpecialCharacter,
  isEnoughCharacter
} from 'utils/check'

function UpdatePasswordForm({ onFinish, isSubmitting, form, isReset = false }) {
  return (
    <Form
      requiredMark={false}
      className="md:w-96"
      name="updatePassword"
      labelCol={{ span: 24 }}
      form={form}
      onFinish={onFinish}
    >
      {isReset ? null : (
        <Form.Item
          label="Mật khẩu hiện tại"
          name="current_password"
          rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
        >
          <Input.Password />
        </Form.Item>
      )}

      <Form.Item
        label="Mật khẩu mới"
        name="password"
        rules={[
          { required: true, message: 'Vui lòng nhập mật khẩu mới' },
          PasswordValidator
        ]}
      >
        <ValidationPasswordInput />
      </Form.Item>
      <Form.Item
        label="Xác nhận mật khẩu"
        name="password_confirmation"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập xác nhận mật khẩu mới'
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error('Mật khẩu không giống mật khẩu cài đặt!')
              )
            }
          })
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <ValidButton
          type="primary"
          htmlType="submit"
          btnTitle="Lưu lại"
          form={form}
          loading={isSubmitting}
        />
      </Form.Item>
    </Form>
  )
}

export default UpdatePasswordForm

const ValidationPasswordInput = props => {
  const { value } = props || {}

  const [passwordCondition, setPasswordCondition] = useState({
    minimun: false,
    character: false,
    specialCharacter: false
  })

  useEffect(() => {
    const onChange = () => {
      setPasswordCondition({
        minimun: isEnoughCharacter(value),
        character:
          isContainBothLowercaseAndUpercase(value) && isContainNumber(value),
        specialCharacter: isContainSpecialCharacter(value)
      })
    }
    onChange()
  }, [JSON.stringify(value)])

  return (
    <>
      <Input.Password {...props} />

      <Alert
        message="Mật khẩu phải từ 8 đến 20 ký tự"
        type={passwordCondition.minimun ? 'success' : 'error'}
        showIcon
        className="bg-transparent border-0 px-1 py-2"
      />

      <Alert
        message="Bao gồm số, chữ viết hoa, chữ viết thường"
        type={passwordCondition.character ? 'success' : 'error'}
        showIcon
        className="bg-transparent border-0 px-1 py-2"
      />
      <Alert
        message="Bao gồm ít nhất một ký tự đặc biệt"
        type={passwordCondition.specialCharacter ? 'success' : 'error'}
        showIcon
        className="bg-transparent border-0 px-1 py-2"
      />
    </>
  )
}
