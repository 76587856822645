import moment from 'moment'

export const timeAgo = timeData => {
  try {
    return moment(timeData).fromNow()
  } catch (error) {
    throw new Error(error)
  }
}

export const ensureDateFormat = (timeData, dateFormat = 'YYYY-MM-DD') => {
  try {
    return moment(timeData, dateFormat)
  } catch (error) {
    throw new Error(error)
  }
}

export const formatDate = (timeData, dateFormat = 'YYYY-MM-DD') => {
  return ensureDateFormat(timeData, dateFormat).format('DD/MM/YYYY')
}

export const formatDateTime = (timeData, dateFormat = 'YYYY-MM-DD HH:mm') => {
  return ensureDateFormat(timeData, dateFormat).format('HH:mm, DD/MM/YYYY')
}
