import { PageHeader } from '@ant-design/pro-layout'
import { useFetchApiGet } from '@stagapps/redux-utils'
import { Typography } from 'antd'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import apiCall from 'business/apiCalls/programOrder'
import ProgramOrderDetail from 'business/components/programOrder/detail'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'

const { Title } = Typography

function ProgramOrderDetailLayout() {
  const { id: programId, POId } = useParams()
  const {
    data: programOrder,
    load: fetchOrder,
    isLoading: isLoadingOrder,
    error: errorOrder
  } = useFetchApiGet(apiCall.detail, { resourceName: 'data' })

  const isLoading = isLoadingOrder
  const error = errorOrder

  useEffect(() => {
    fetchOrder({ programId, POId })
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  const { code: POCode } = programOrder || {}

  const programOrdersCode = `Chi tiết lệnh ${POCode}`

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={<Title level={2}>{programOrdersCode}</Title>}
      />
      <ProgramOrderDetail {...programOrder} />
    </>
  )
}

export default ProgramOrderDetailLayout
