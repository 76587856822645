import { useFetchApiGet, useFetchApiList } from '@stagapps/redux-utils'
import { Button, Empty, Table } from 'antd'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'

import programOrderApiCall from 'app/apiCalls/userProgramOrder'
import upPortfolioApiCall from 'app/apiCalls/userProgramPortfolio'
import PresentedSimpleEmptySVG from 'common/svg/PresentedSimpleEmptySVG'
import { getChangeValueClassColor } from 'config/color'
import { portColumn } from 'config/columns'
import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { formatPercentage } from 'utils/formatAmount'
import { formatCurrencyWithoutCurrencySymbol } from 'utils/formatAmount/formatUI'
import { formatTableColumns } from 'utils/tableConfig'
import { pendingOrdersColumnsGenerator } from '../userProgramOrder/UserProgramOrderListPage/config'

function UserProgramTransaction() {
  const { id } = useParams()
  const {
    data: pendingOrder,
    load: fetchPendingOrder,
    isLoading: isLoadingPendingOrder,
    refresh: refreshPendingOrder,
    error: errorPendingOrder
  } = useFetchApiList(programOrderApiCall.list, { resourceName: 'data' })

  const {
    data: portfolio,
    load: fetchPort,
    isLoading: isLoadingPort,
    error: errorPort
  } = useFetchApiGet(upPortfolioApiCall.portfolio, { resourceName: 'data' })

  useEffect(() => {
    fetchPendingOrder({
      programId: id,
      'orders.status': 1,
      order_type: 'buy'
    })
    fetchPort({ id })
  }, [])

  const isLoading = isLoadingPendingOrder || isLoadingPort
  const error = errorPendingOrder || errorPort

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  const {
    sum_contributed_amount,
    market_value,
    unrealized_gain_loss,
    unrealized_gain_loss_percentage,
    items = []
  } = portfolio || {}

  return (
    <div className="flex flex-col gap-4">
      <UICard title={'Lệnh cần thanh toán'}>
        <Table
          className="overflow-x-auto"
          dataSource={pendingOrder}
          columns={formatTableColumns(pendingOrdersColumnsGenerator({ id }))}
          pagination={false}
          rowKey={'id'}
          locale={localeGenerator({
            description: 'Chưa có lệnh cần thanh toán'
          })}
          showHeader={pendingOrder?.length > 0 ? true : false}
        />
        <div className="flex justify-center pt-6">
          <Link to={`/goals/${id}/orders`}>
            <Button ghost type="primary">
              Xem tất cả lệnh
            </Button>
          </Link>
        </div>
      </UICard>
      <UICard title={'Số dư tích lũy '}>
        <Table
          className="overflow-x-auto"
          columns={[
            ...formatTableColumns(portColumn),
            {
              title: '',
              dataIndex: 'action',
              render: (value, record) => (
                <Link to={`/goals/${id}/portfolio/${record.id}/sell`}>Bán</Link>
              )
            }
          ]}
          dataSource={items.filter(
            ({ available_quantity }) => available_quantity > 0
          )}
          pagination={false}
          rowKey={'product_id'}
          locale={localeGenerator({
            description: 'Chưa sở hữu chứng chỉ quỹ'
          })}
          summary={pageData => {
            if (pageData.length === 0) return null
            let totalBorrow = 0
            let totalRepayment = 0
            pageData.forEach(({ borrow, repayment }) => {
              totalBorrow += borrow
              totalRepayment += repayment
            })

            return (
              <>
                <Table.Summary.Row className="font-semibold">
                  <Table.Summary.Cell>Tổng</Table.Summary.Cell>
                  <Table.Summary.Cell align="right"></Table.Summary.Cell>
                  <Table.Summary.Cell align="right"></Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    {market_value
                      ? formatCurrencyWithoutCurrencySymbol(market_value)
                      : '-'}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <p
                      className={getChangeValueClassColor(unrealized_gain_loss)}
                    >
                      {formatCurrencyWithoutCurrencySymbol(
                        unrealized_gain_loss
                      )}
                    </p>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <p
                      className={getChangeValueClassColor(
                        unrealized_gain_loss_percentage
                      )}
                    >
                      {formatPercentage(unrealized_gain_loss_percentage)}
                    </p>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )
          }}
        />
      </UICard>
    </div>
  )
}

export default UserProgramTransaction

let localeGenerator = ({ description }) => {
  return {
    emptyText: (
      <Empty
        className="py-6"
        imageStyle={{ height: '117px' }}
        image={<PresentedSimpleEmptySVG />}
        description={description}
      />
    )
  }
}
