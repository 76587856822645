import { useFetchApiGet, useFetchApiList } from '@stagapps/redux-utils'
import { Alert, Badge, Button, ConfigProvider, Space, Tabs } from 'antd'
import { useEffect } from 'react'
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom'

import apiUserProgramsCall from 'app/apiCalls/userProgram'
import programOrderApiCall from 'app/apiCalls/userProgramOrder'
import PageTitle from 'common/layout/PageTitle'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'

function UserProgramLayout() {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { id } = useParams()

  const {
    data: program,
    load: fetchProgram,
    isLoading: isLoadingProgram,
    error: errorProgram
  } = useFetchApiGet(apiUserProgramsCall.detail, { resourceName: 'data' })

  const {
    load: fetchPendingOrder,
    isLoading: isLoadingPendingOrder,
    error: errorPendingOrder,
    paging: pagePendingOrder
  } = useFetchApiList(programOrderApiCall.list, {
    resourceName: 'data',
    apiCallSuffixName: 'PENDING_COUNT'
  })

  const isLoading = isLoadingProgram || isLoadingPendingOrder
  const error = errorProgram || errorPendingOrder

  useEffect(() => {
    fetchProgram({ id })
    fetchPendingOrder({
      programId: id,
      'orders.status': 1,
      order_type: 'buy'
    })
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  const { program_name } = program || {}
  const { count: countPendingOrder } = pagePendingOrder || {}
  const activeKey = pathname.includes('transactions') ? 2 : 1

  const tabItems = [
    {
      label: 'Tổng quan',
      key: 1
    },
    {
      label: (
        <Space>
          <div>{'Giao dịch'}</div>
          <Badge count={countPendingOrder} />
        </Space>
      ),
      key: 2
    }
  ]

  const onChange = key => {
    const path = `/goals/${id}`
    if (key == 1) {
      navigate(path)
    }

    if (key == 2) {
      navigate(path + '/transactions')
    }
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Statistic: {
            contentFontSize: '1rem',
            titleFontSize: '0.75rem'
          }
        }
      }}
    >
      <PageTitle className="site-page-header" title={program_name} />
      <div className="py-5">
        {countPendingOrder > 0 ? (
          <Alert
            className="mb-4"
            type="error"
            message={
              <div className="font-semibold">
                {`Bạn có lệnh đang chờ thanh toán`}
              </div>
            }
            description={
              <div>
                <div className="mb-4">
                  Nhanh chóng thanh toán lệnh mua để đảm bảo mục tiêu đi đúng kế
                  hoạch
                </div>
                <Link to={`/goals/${id}/orders`}>
                  <Button type="primary">Thanh toán ngay</Button>
                </Link>
              </div>
            }
          />
        ) : null}

        <Tabs
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          items={tabItems}
          mode="horizontal"
          onChange={onChange}
          type="card"
        />
        <Outlet />
      </div>
    </ConfigProvider>
  )
}

export default UserProgramLayout
