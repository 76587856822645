import { usePostApi } from '@stagapps/redux-utils'
import { Checkbox, Form, Input, Typography } from 'antd'

import AuthLayout from 'app/components/layouts/AuthLayout'
import apiCall from 'business/apiCalls/signin'
import ValidButton from 'common/ui/form/ValidButton'

const { Title } = Typography

function BusinessLogin() {
  const [form] = Form.useForm()

  const { post, isLoading: isSubmitting, error } = usePostApi(apiCall)
  const { error: errorMessage, error_code } = error || {}

  const onFinish = values => {
    post(values)
  }

  return (
    <AuthLayout imgUrlPath={'/futureflex/images/login_business.jpg'}>
      <Title level={4}>Đăng nhập</Title>

      <Form
        requiredMark={false}
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          labelCol={{ span: 24 }}
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập email',
              type: 'email'
            }
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          validateStatus={!!error ? 'error' : null}
          hasFeedback
          help={errorMessage || error_code}
          labelCol={{ span: 24 }}
          label="Mật khẩu"
          name="password"
          rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <div className="flex justify-between">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Nhớ đăng nhập</Checkbox>
            </Form.Item>

            <a className="login-form-forgot" href="">
              Quên mật khẩu?
            </a>
          </div>
        </Form.Item>

        <Form.Item>
          <ValidButton
            type="primary"
            htmlType="submit"
            className="w-full"
            btnTitle="Đăng nhập"
            form={form}
            loading={isSubmitting}
          />
        </Form.Item>
      </Form>
    </AuthLayout>
  )
}

export default BusinessLogin
