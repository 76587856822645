import { Table } from 'antd'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'

import UICard from 'common/ui/display/UICard'
import UIDate from 'common/ui/display/UIDate'
import { formatNumber } from 'utils/formatAmount'

function ScenarioProgramTable({ data, isSubmitting, error }) {
  if (isSubmitting) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return (
      <UICard title="Minh họa kế hoạch đóng góp">
        <ErrorMessage errors={error} />
      </UICard>
    )
  }
  return (
    <UICard title="Minh họa kế hoạch đóng góp">
      <Table
        size="small"
        dataSource={data?.projected_program_amount}
        columns={scenarioColums}
        pagination={false}
        bordered
        rowKey={'interval'}
      />
    </UICard>
  )
}

export default ScenarioProgramTable

const scenarioColums = [
  {
    title: 'Kỳ đóng góp',
    dataIndex: 'interval'
  },
  {
    title: 'Lịch đóng góp',
    dataIndex: 'order_date',
    render: value => <UIDate timeData={value} />
  },
  {
    title: 'Nhân viên tích lũy',
    dataIndex: 'interval_employee_contributed_amount',
    render: text => <span>{formatNumber(text)}</span>,
    align: 'right'
  },
  {
    title: 'Công ty đóng góp',
    dataIndex: 'interval_company_contributed_amount',
    render: text => <span>{formatNumber(text)}</span>,
    align: 'right'
  }
]
