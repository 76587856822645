import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_DETAIL_API_ID = 'BUSINESS/GET_PROGRAM_ORDER_DETAIL_API'

const apiCall = enhancedMakeFetchAction(
  FETCH_DETAIL_API_ID,
  ({ userId, programId, POId }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/flex/v1/${
      userId ? 'employees/' + userId : 'programs/' + programId
    }/program-orders/${POId}`,
    method: 'GET'
  })
)

export default apiCall
