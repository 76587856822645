import { getChangeValueClassColor } from 'config/color'
import { formatCurrencyWithoutCurrencySymbol } from 'utils/formatAmount/formatUI'
import { formatNumber, formatPercentage } from './formatAmount'
import { formatDate, formatDateTime } from './formatDate'

export function formatTableColumns(columns) {
  return columns.map(object => {
    const { type } = object
    if (type === 'number') {
      return {
        ...object,
        align: 'right'
      }
    }
    if (type === 'formatNumber') {
      return {
        ...object,
        render: value => (!!value || value === 0 ? formatNumber(value) : '-'),
        align: 'right'
      }
    }
    if (type === 'percentage') {
      return {
        ...object,
        render: value =>
          !!value || value === 0 ? (
            <div className={getChangeValueClassColor(value)}>
              {formatPercentage(value)}
            </div>
          ) : (
            '-'
          ),
        align: 'right'
      }
    }
    if (type === 'formatCurrency') {
      return {
        ...object,
        render: value =>
          !!value ? formatCurrencyWithoutCurrencySymbol(value) : '-',
        align: 'right'
      }
    }
    if (type === 'date') {
      return {
        ...object,
        render: value => (value ? formatDate(value) : '-')
      }
    }
    if (type === 'dateTime') {
      return {
        ...object,
        render: value => (value ? formatDateTime(value) : '-')
      }
    }
    return object
  })
}
