import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_DETAIL_API_ID = 'EMPLOYEE_PROGRAM_DETAIL_API'

const apiCall = enhancedMakeFetchAction(
  FETCH_DETAIL_API_ID,
  ({ userId, id }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/flex/v1/employees/${userId}/programs/${id}`,
    method: 'GET'
  })
)

export default apiCall
