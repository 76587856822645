import { Descriptions, Space } from 'antd'

import UICard from 'common/ui/display/UICard'
import UIDate from 'common/ui/display/UIDate'
import UIInfoTooltip from 'common/ui/display/UIInfoTooltip'
import { orderTypeLabels } from 'data/fundOrder'
import { formatCurrency } from 'utils/formatAmount'

const { Item } = Descriptions

function ProgramOrderDetail(props) {
  const {
    order_date,
    company,
    order_type,
    order_side,
    product_code,
    amount,
    employee
  } = props || {}

  const { name } = company || {}
  const { full_name } = employee || {}
  const isEmployee = order_side === 'employee'

  return (
    <div className="flex flex-col gap-4">
      <UICard title="Thông tin lệnh">
        <Descriptions column={1} bordered>
          <Item label="Ngày tạo">{<UIDate timeData={order_date} />}</Item>
          <Item label={'Phân loại lệnh'}>
            {isEmployee ? 'Nhân viên tích lũy' : 'Doanh nghiệp đóng góp'}
          </Item>
          <Item label={isEmployee ? 'Họ Tên' : 'Tên Doanh nghiệp'}>
            {isEmployee ? full_name : name}
          </Item>
          <Item label="Loại lệnh">{orderTypeLabels[order_type]}</Item>
          <Item label="Sản phẩm">{product_code}</Item>
          <Item
            label={
              <Space>
                {isEmployee ? (
                  <>
                    <div>{'Giá trị tích lũy'}</div>
                    <UIInfoTooltip
                      title={
                        'Giá trị Nhân viên cần tích lũy vào chương trình hiện tại.'
                      }
                    />
                  </>
                ) : (
                  <>
                    <div>{'Giá trị đóng góp'}</div>
                    <UIInfoTooltip
                      title={
                        'Giá trị Doanh nghiệp cần đóng góp vào chương trình hiện tại.'
                      }
                    />
                  </>
                )}
              </Space>
            }
          >
            {formatCurrency(amount)}
          </Item>
        </Descriptions>
      </UICard>
    </div>
  )
}

export default ProgramOrderDetail
