import { cloudflareCdn } from 'utils/image'

function StoreDownload() {
  return (
    <div className="flex sm:flex-row flex-col gap-3 my-3">
      <a
        href="https://apps.apple.com/vn/app/stag-invest-t%E1%BB%B1-tin-t%C3%A0i-ch%C3%ADnh/id1612795130"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <img src={cloudflareCdn('/stagvn/assets/svg/app_appstore.svg')} />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=vn.stag"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <img src={cloudflareCdn('/stagvn/assets/svg/app_playstore.svg')} />
      </a>
    </div>
  )
}

export default StoreDownload
