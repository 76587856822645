import { PageHeader } from '@ant-design/pro-layout'
import { useFetchApiGet } from '@stagapps/redux-utils'
import { Typography } from 'antd'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import apiCompanyDetailCall from 'business/apiCalls/company/detail'
import apiEmployeeDetailCall from 'business/apiCalls/employee/detail'
import CreateProgramForm from 'business/components/employee/program/CreateProgramForm'
import MetaDecorator from 'common/layout/MetaDecorator'
import LoadingSpinner from 'common/ui/LoadingSpinner'

const { Title } = Typography

const title = 'Tạo chương trình mới'

function CreateProgram() {
  const { id } = useParams()

  const {
    data,
    load: fetchItems,
    isLoading: isLoadingEmployee
  } = useFetchApiGet(apiEmployeeDetailCall, { resourceName: 'data' })

  const {
    data: company,
    load: loadCompany,
    isLoading: isLoadingCompany,
    error: errorCompany
  } = useFetchApiGet(apiCompanyDetailCall, { resourceName: 'data' })

  useEffect(() => {
    fetchItems({ id })
    loadCompany()
  }, [])

  const isLoading = isLoadingCompany || isLoadingEmployee
  if (isLoading) {
    return <LoadingSpinner />
  }

  const { full_name } = data || {}
  const { monthly_order_payment } = company || {}

  return (
    <div className="max-w-6xl mx-auto">
      <MetaDecorator title={title} />
      <PageHeader
        className="site-page-header"
        title={<Title level={2}>{title}</Title>}
      />
      <CreateProgramForm
        id={id}
        full_name={full_name}
        monthly_order_payment={monthly_order_payment}
        errorCompany={errorCompany}
      />
    </div>
  )
}

export default CreateProgram
