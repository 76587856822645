import { useFetchApiList } from '@stagapps/redux-utils'
import { Descriptions, Space, Tag } from 'antd'
import apiCall from 'app/apiCalls/fundAccount'
import MetaDecorator from 'common/layout/MetaDecorator'
import PageTitle from 'common/layout/PageTitle'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import { fundAccountStatus, fundAccountStatusColors } from 'data/fundAccount'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const { Item } = Descriptions

const PAGE_TITLE = 'Công ty quỹ đã mở tài khoản giao dịch'

function FundAccountDetailPage() {
  const { id } = useParams()
  const {
    data: item = {},
    load: fetchItem,
    isLoading,
    paging,
    error
  } = useFetchApiList(apiCall.detail, { resourceName: 'data' })

  useEffect(() => {
    fetchItem({ id })
  }, [])

  if (isLoading) {
    return (
      <div className="flex flex-col gap-4">
        <LoadingSpinner />
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex flex-col gap-4">
        <ErrorMessage errors={error} />
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      <MetaDecorator title={PAGE_TITLE} />
      <PageTitle
        title={`Tài khoản giao dịch ${item.fund.code.toUpperCase()}`}
      />
      <UICard title={'Thông tin chi tiết'}>
        <Descriptions bordered column={1}>
          <Item label="Tổ chức phát hành">
            <Space>
              <img width={50} src={item.fund.logo} alt={item.fund.name} />
              <span>{item.fund.name}</span>
            </Space>
          </Item>
          <Item label="Trạng thái">
            <Tag color={fundAccountStatusColors[item.status]}>
              {fundAccountStatus[item.status]}
            </Tag>
          </Item>
          <Item label="Tên nhà đầu tư">{item.full_name}</Item>
          <Item label="Số tài khoản giao dịch">{item.trading_code}</Item>
          <Item label="Email đăng ký">{item.email}</Item>
          <Item label="Điện thoại đăng ký">{item.phone}</Item>
          <Item label="Số định danh">{item.id_number}</Item>
          <Item label="Ngày cấp">{item.id_issue_date}</Item>
          <Item label="Nơi cấp">{item.id_issue_place}</Item>
          <Item label="Tài khoản ngân hàng đăng ký"></Item>
          <Item label="Tên ngân hàng">{item.bank_name}</Item>
          <Item label="Chi nhánh">{item.bank_branch_name}</Item>
          <Item label="Số tài khoản">{item.bank_account_number}</Item>
          <Item label="Tên tài khoản">{item.bank_account_name}</Item>
        </Descriptions>
      </UICard>
    </div>
  )
}

export default FundAccountDetailPage
