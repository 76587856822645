import { usePostApi } from '@stagapps/redux-utils'
import { Alert, Form, Input } from 'antd'
import { useNavigate } from 'react-router-dom'

import apiCall from 'app/apiCalls/banks'
import apiPatchCall from 'app/apiCalls/verification/partialUpdateIdInfo'
import SelectListData from 'common/ui/form/SelectListData'
import ValidButton from 'common/ui/form/ValidButton'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import { useDispatch } from 'react-redux'

function UpdateBankAccountForm({ initialValues = {} }) {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    full_name,
    bank_account_name,
    bank_branch_name,
    bank_account_number,
    bank_id
  } = initialValues

  const { data, post, isLoading: isSubmitting, error } = usePostApi(
    apiPatchCall,
    {
      resourceName: 'data'
    }
  )

  const onFinish = values => {
    post(values)
  }

  const { error: errorMessage, error_code } = error || {}

  useEffectSkipFirstRender(() => {
    if (data) {
      navigate('/verification/confirm')
    }
  }, [data])

  return (
    <Form
      requiredMark={false}
      initialValues={{
        bank_account_name: bank_account_name ? bank_account_name : full_name,
        bank_account_number: bank_account_number ? bank_account_number : '',
        bank_branch_name: bank_branch_name ? bank_branch_name : 'Trụ sở chính',
        bank_id: bank_id ? bank_id : null
      }}
      onFinish={onFinish}
      form={form}
      size="large"
      labelCol={{ span: 24 }}
    >
      {!!errorMessage || !!error_code ? (
        <Alert message={errorMessage || error_code} type="error" showIcon />
      ) : null}

      <Form.Item label="Chủ tài khoản" name="bank_account_name">
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Số tài khoản"
        name="bank_account_number"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập'
          }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Tên ngân hàng"
        name="bank_id"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn'
          }
        ]}
      >
        <SelectListData
          showSearch
          apiCall={apiCall}
          filterOption={(input, option) =>
            (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())
          }
          callback={props => {
            const { name, code, id } = props || {}
            return {
              value: id,
              label: name + ' - ' + code
            }
          }}
        />
      </Form.Item>

      <Form.Item
        label="Tên chi nhánh"
        name="bank_branch_name"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập'
          }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <ValidButton
          size="large"
          type="primary"
          htmlType="submit"
          className="w-full"
          btnTitle="Tiếp tục"
          form={form}
          loading={isSubmitting}
        />
      </Form.Item>
    </Form>
  )
}

export default UpdateBankAccountForm
