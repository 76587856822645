import { ACTIONS } from '@stagapps/redux-utils'
import { eq, flow, get } from 'lodash/fp'
import { put, takeEvery } from 'redux-saga/effects'

import { EMPLOYEE_POST_SIGNIN } from 'app/apiCalls/account/signin'

import SessionAction from 'state/action/session'

const isSignin = flow(get('payload.name'), eq(EMPLOYEE_POST_SIGNIN))

function* onSigninSuccess(action) {
  if (isSignin(action)) {
    try {
      const currentToken = get('payload.json.data.access_token')(action)
      const currentUser = get('payload.json.data.user')(action)
      yield put({
        type: SessionAction.SET_STAG_USER,
        payload: { currentToken, currentUser }
      })
    } catch (error) {
      console.log('OnSigninSuccess', error)
    }
  }
}

function* watchOnSignin() {
  yield takeEvery(ACTIONS.COMPLETE, onSigninSuccess)
}

export function* signinSaga() {
  yield watchOnSignin()
}
