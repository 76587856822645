import { usePostApi } from '@stagapps/redux-utils'
import { Checkbox, Form, Input, Typography } from 'antd'
import apiCall from 'app/apiCalls/account/signin'
import AuthLayout from 'app/components/layouts/AuthLayout'
import StagLogoSVG from 'common/svg/logo/stag'
import ValidButton from 'common/ui/form/ValidButton'
import { Link } from 'react-router-dom'

const { Title } = Typography

function EmployeeLogin() {
  const [form] = Form.useForm()

  const { post, isLoading: isSubmitting, error } = usePostApi(apiCall)
  const { error: errorMessage, error_code } = error || {}

  return (
    <AuthLayout imgUrlPath={'/futureflex/images/login_employee_v2.jpeg'}>
      <div className="flex flex-row gap-2 items-center">
        <div className="flex md:w-[134px] w-32 aspect-[134/31]">
          <StagLogoSVG />
        </div>
      </div>
      <div>
        <Title level={4}>Đăng nhập</Title>
      </div>
      <Form
        form={form}
        requiredMark={false}
        initialValues={{ remember: true }}
        onFinish={post}
        labelCol={{ span: 24 }}
      >
        <Form.Item
          label="Email cá nhân"
          name="email"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập email',
              type: 'email'
            }
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          validateStatus={!!error ? 'error' : null}
          hasFeedback
          help={errorMessage || error_code}
          label="Mật khẩu"
          name="password"
          rules={[{ required: true, message: 'Vui lòng nhập mật khẩu' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <div className="flex justify-between">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Nhớ đăng nhập</Checkbox>
            </Form.Item>

            <Link to="/forget-password">Quên mật khẩu?</Link>
          </div>
        </Form.Item>

        <Form.Item>
          <ValidButton
            type="primary"
            size="large"
            htmlType="submit"
            className="w-full"
            btnTitle="Đăng nhập"
            form={form}
            loading={isSubmitting}
          />
        </Form.Item>
      </Form>
    </AuthLayout>
  )
}

export default EmployeeLogin
