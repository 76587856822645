import { useFetchApiGet } from '@stagapps/redux-utils'
import { Alert, Typography } from 'antd'
import { useEffect } from 'react'

import apiCall from 'app/apiCalls/verification/getUserIdentifications'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import KYCSteps from './KYCSteps'
import UpdateBankAccountForm from './UpdateBankAccountForm'

const { Title } = Typography

function CompletingIDInfosPage() {
  const { data, load, isLoading, error, refresh } = useFetchApiGet(apiCall, {
    resourceName: 'data'
  })

  useEffect(() => {
    load()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  return (
    <div>
      <KYCSteps current={2} />
      <UICard title={'Thông tin ngân hàng'}>
        <div className="flex flex-col gap-4">
          <Alert
            message="Tài khoản sử dụng bắt buộc là tài khoản chính chủ"
            type="info"
            showIcon
          />
          <UpdateBankAccountForm initialValues={data} />
        </div>
      </UICard>
    </div>
  )
}

export default CompletingIDInfosPage
