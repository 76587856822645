import ProgramOrderDetailLayout from 'business/components/employeeProgram/ProgramOrderDetailLayout'
import MetaDecorator from 'common/layout/MetaDecorator'

export function ProgramOrderDetaiPage() {
  return (
    <>
      <MetaDecorator title={'Chi tiết lệnh chương trình'} />
      <ProgramOrderDetailLayout />
    </>
  )
}
