// import { useFetchApiList } from '@stagapps/redux-utils'
// import apiCall from 'employee/apiCalls/surveys/list'
// import { useEffect } from 'react'

function SurveyHistory() {
  // const { load } = useFetchApiList(apiCall, {
  //   resourceName: 'data'
  // })
  // useEffect(() => {
  //   load()
  // }, [])
}

export default SurveyHistory
