import { eq, flow, get } from 'lodash/fp'
import { ACTIONS } from 'redux-api-call'
import { put, takeEvery } from 'redux-saga/effects'

import { POST_SIGNUP } from 'app/apiCalls/account/signup'
import SessionAction from 'state/action/session'

const isSignup = flow(get('payload.name'), eq(POST_SIGNUP))

function* onSignupSuccess(action) {
  if (isSignup(action)) {
    try {
      const currentToken = get('payload.json.data.access_token')(action)
      const currentUser = get('payload.json.data.user')(action)

      yield put({
        type: SessionAction.SET_STAG_USER,
        payload: { currentToken, currentUser }
      })
    } catch (error) {
      console.log('OnSignupSuccess', error)
    }
  }
}

function* watchOnSignup() {
  yield takeEvery(ACTIONS.COMPLETE, onSignupSuccess)
}

export function* signupSaga() {
  yield watchOnSignup()
}
