import { usePostApi } from '@stagapps/redux-utils'
import { Card, Form, Input, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'

import apiCall from 'app/apiCalls/verification/requestVerifyPhone'
import ValidButton from 'common/ui/form/ValidButton'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import KYCSteps from './KYCSteps'

const { Title } = Typography

function RequestVerifyPhone() {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const values = Form.useWatch([], form)

  const { data, post, isLoading: isSubmitting, error } = usePostApi(apiCall, {
    resourceName: 'data'
  })
  const { error: errorMessage, error_code } = error || {}

  const onFinish = values => {
    post(values)
  }

  useEffectSkipFirstRender(() => {
    if (data) {
      const { phone } = values
      const { expires_at } = data
      navigate(`/verification/phone/${phone}/${expires_at}`)
    }
  }, [data])

  useEffectSkipFirstRender(() => {
    if (!!error) {
      if (errorMessage === 'Phone is verified') {
        navigate(`/`)
      }
    }
  }, [error])

  return (
    <div>
      <KYCSteps />
      <Card className="w-fit mx-auto">
        <Title level={4}>Xác thực số điện thoại</Title>
        <Form
          onFinish={onFinish}
          form={form}
          className="md:w-96"
          requiredMark={false}
        >
          <Form.Item
            validateStatus={!!error ? 'error' : null}
            hasFeedback
            help={errorMessage || error_code}
            name={'phone'}
            label={'Hãy nhập số điện thoại liên lạc cá nhân để xác thực'}
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập số điện thoại'
              }
            ]}
            labelCol={{ span: 24 }}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item>
            <ValidButton
              type="primary"
              htmlType="submit"
              className="w-full"
              btnTitle="Tiếp tục"
              form={form}
              loading={isSubmitting}
            />
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}

export default RequestVerifyPhone
