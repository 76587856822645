import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils'
import { Button, Form, Input, Select, Space, Typography } from 'antd'
import apiCall from 'app/apiCalls/userProgram'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import UIInputFormatNumber from 'common/ui/display/UIInputFormatNumber'
import qs from 'qs'
import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { convertDateFormData, ensureDateFields } from 'utils/formData'
import { calculateEndDate, getStartDate } from 'utils/formatDate/getDate'
import { cloudflareCdn } from 'utils/image'

const { Title } = Typography
const dateFields = ['start_date']

function UserProgramUpdatePage() {
  const [form] = Form.useForm()
  const { id } = useParams()

  const { monthly_order_payment, months, years } = Form.useWatch([], form) || {}
  const start_date = !!monthly_order_payment
    ? getStartDate({ monthly_order_payment })
    : new Date().toISOString()
  const end = calculateEndDate(start_date, parseInt(years), parseInt(months))
  const queryParams = qs.parse(window.location.search.substring(1))
  const { title, ...initialValues } = queryParams
  const src = cloudflareCdn(`/futureflex/images/onboarding/8.jpg`, 300, 300)

  const {
    data: program,
    load: fetchProgram,
    isLoading,
    error
  } = useFetchApiGet(apiCall.detail, { resourceName: 'data' })

  const {
    post: updateProgram,
    isLoading: isUpdating,
    error: updateError
  } = usePostApi(apiCall.update, {
    resourceName: 'data'
  })

  const {
    post: deleteProgram,
    isLoading: isDeleting,
    error: deleteError
  } = usePostApi(apiCall.delete, {
    resourceName: 'data'
  })

  useEffect(() => {
    fetchProgram({ id })
  }, [])

  if (isLoading) return <LoadingSpinner />
  if (!!error) return <ErrorMessage errors={error} />

  return (
    <>
      <h1>Cập nhật thông tin chương trình</h1>
      <Form
        requiredMark={false}
        form={form}
        initialValues={program}
        onFinish={values => {
          const data = ensureDateFields(values, dateFields)
          const {
            contribute_amount,
            monthly_order_payment,
            program_name,
            target_amount
          } = convertDateFormData(data)

          const payload = {
            contribute_amount,
            monthly_order_payment,
            program_name,
            target_amount
          }

          updateProgram({ id, payload })
        }}
        labelCol={{ span: 24 }}
        size="large"
      >
        <div className="flex items-center flex-col md:gap-10 gap-4">
          <UICard className="text-sm">
            <div>
              <div className="flex md:flex-row flex-col-reverse gap-8">
                <div>
                  <div className="flex gap-4 items-baseline">
                    <Title level={3}>{title}</Title>
                  </div>

                  <div className="flex md:gap-8 md:flex-row flex-col flex-grow">
                    <div className="flex-grow">
                      <Form.Item
                        label="Tên chương trình"
                        name="program_name"
                        rules={[
                          {
                            required: true,
                            message: 'Tiêu đề không được để trống'
                          }
                        ]}
                      >
                        <Input className="w-full" />
                      </Form.Item>
                      <Form.Item
                        label="Số tiền để thực hiện mục tiêu"
                        name="target_amount"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập'
                          }
                        ]}
                      >
                        <UIInputFormatNumber
                          addonAfter="VND"
                          className="w-full"
                        />
                      </Form.Item>
                    </div>

                    <div className="flex-grow">
                      <Form.Item
                        label="Số tiền có thể tích lũy hằng tháng"
                        name="contribute_amount"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập'
                          }
                        ]}
                      >
                        <UIInputFormatNumber
                          addonAfter="VND"
                          className="w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Ngày tích lũy hằng tháng"
                        name="monthly_order_payment"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập'
                          }
                        ]}
                      >
                        <Select
                          className="w-full"
                          options={generateArray(28)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <img src={src} className="md:w-fit w-full rounded-xl" />
              </div>
            </div>
          </UICard>
          <Form.Item className="flex justify-center">
            <Space size={'large'}>
              <Link to={`/goals/${id}`}>
                <Button type="primary" ghost size="large">
                  <div className="px-4">Trở về</div>
                </Button>
              </Link>
              <Button
                size="large"
                danger
                loading={isDeleting}
                onClick={() => {
                  if (
                    confirm('Bạn có chắc chắn muốn xóa chương trình này không?')
                  ) {
                    deleteProgram(id)
                  }
                }}
              >
                Xoá chương trình
              </Button>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={isUpdating}
              >
                Cập nhật
              </Button>
            </Space>
          </Form.Item>
        </div>
      </Form>
    </>
  )
}

function generateArray(count) {
  const result = []
  for (let i = 1; i <= count; i++) {
    result.push({ value: i, label: `Ngày ${i}` })
  }
  return result
}

export default UserProgramUpdatePage
