import { useFetchApiList } from '@stagapps/redux-utils'
import { Select } from 'antd'
import { useEffect } from 'react'

function SelectListData({
  apiCall,
  params,
  callback,
  isDebounceFetcher,
  ...props
}) {
  const { data, load, isLoading, error } =
    useFetchApiList(apiCall, { resourceName: 'data' }) || {}

  const { error: errorMessage, error_code } = error || {}

  useEffect(() => {
    load(params)
  }, [JSON.stringify(params)])

  const list = e => {
    const { id, name } = e || {}
    return {
      value: id,
      label: name
    }
  }

  return (
    <>
      <Select
        status={!error ? null : 'error'}
        allowClear
        loading={isLoading}
        options={
          !!error || isLoading
            ? []
            : data.map(e => (callback ? callback(e) : list(e)))
        }
        {...props}
      />
      {!!error ? (
        <span className="text-red-500">
          {errorMessage || error_code || error}
        </span>
      ) : null}
    </>
  )
}

export default SelectListData
