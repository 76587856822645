import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { usePostApi } from '@stagapps/redux-utils'
import verifyPhoneApiCall from 'app/apiCalls/account/verifyPhone'
import StagLogoSVG from 'common/svg/logo/stag'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

function VerifyPhonePage() {
  let [searchParams] = useSearchParams()
  const userId = searchParams.get('user_id')
  const otpCode = searchParams.get('otp_code')
  const { phone } = useParams()

  const {
    data,
    post: verifyPhone,
    isLoading: isSubmitting,
    error
  } = usePostApi(verifyPhoneApiCall, { resourceName: 'data' })

  useEffect(() => {
    verifyPhone({ phone, user_id: userId, code: otpCode })
  }, [])

  const { error_code } = error || {}

  return (
    <div className="w-full flex relative h-screen justify-center items-center md:bg-transparent md:bg-opacity-0 bg-opacity-70">
      <div className="flex flex-col md:gap-6 gap-2 md:p-0 p-4 md:w-96 items-center">
        <div className="flex flex-row">
          <div className="flex w-200 aspect-[134/31]">
            <StagLogoSVG />
          </div>
        </div>
        <div className="flex flex-col items-center my-5">
          {isSubmitting ? (
            <LoadingSpinner />
          ) : !error_code || error_code === 'phone_already_verified' ? (
            <div className="text-center">
              <CheckCircleOutlined style={{ fontSize: 37, color: 'green' }} />
              <h1>Bạn đã xác thực số điện thoại thành công!</h1>
              <p>{`Vui lòng quay trở lại ứng dụng Stag Invest để hoàn tất quy trình mở tài khoản`}</p>
            </div>
          ) : (
            <div className="text-center">
              <WarningOutlined style={{ fontSize: 37, color: '#faad14' }} />
              <h1>Liên kết không hợp lệ!</h1>
              <p>{`Vui lòng thực hiện xác thực lại số điện thoại`}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default VerifyPhonePage
