import { eq, flow, get } from 'lodash/fp'
import { ACTIONS } from 'redux-api-call'
import { put, takeEvery } from 'redux-saga/effects'

import { POST_VERIFY_EMAIL } from 'app/apiCalls/account/verifyEmail'

import SessionAction from 'state/action/session'

const isVerifyEmail = flow(get('payload.name'), eq(POST_VERIFY_EMAIL))

function* onVerifyEmailSuccess(action) {
  if (isVerifyEmail(action)) {
    try {
      const currentToken = get('payload.json.data.access_token')(action)
      const currentUser = get('payload.json.data.user')(action)

      yield put({
        type: SessionAction.SET_STAG_USER,
        payload: { currentToken, currentUser }
      })
    } catch (error) {
      console.log('OnVerifyEmailSuccess', error)
    }
  }
}

function* watchOnVerifyEmail() {
  yield takeEvery(ACTIONS.COMPLETE, onVerifyEmailSuccess)
}

export function* verifyEmailSaga() {
  yield watchOnVerifyEmail()
}
