import { Button, Table, Tag } from 'antd'
import { useEffect, useState } from 'react'

import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { useFetchApiGet, useFetchApiList } from '@stagapps/redux-utils'
import apiProgramCall from 'app/apiCalls/employeeProgram'
import epPortfolioApiCall from 'app/apiCalls/employeeProgramPortfolio'
import apiCall from 'app/apiCalls/employees'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import {
  employeeProgramStatus,
  employeeProgramStatusColors,
  employeeProgramStatusPending
} from 'data/employeeProgram'

import ContributedColumnChart from 'app/components/employeeProgram/ContributedColumnChart'
import InvestmentDetailDonutChart from 'app/components/employeeProgram/InvestmentDetailDonutChart'
import MetaDecorator from 'common/layout/MetaDecorator'
import PageTitle from 'common/layout/PageTitle'
import { Link, useSearchParams } from 'react-router-dom'
import { cloudflareCdn } from 'utils/image'
import { formatTableColumns } from 'utils/tableConfig'

function EmployeeProgramListPage() {
  let [searchParams, setSearchParams] = useSearchParams()
  const query = searchParams.get('id')

  const [expandedRowKeys, setExpandedRowKeys] = useState(
    query ? [parseInt(query)] : []
  )
  const { data: employees, load, isLoading, error } = useFetchApiList(apiCall, {
    resourceName: 'data'
  })

  useEffect(() => {
    load()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorMessage errors={error} />
  }
  if (employees?.length < 1) {
    return <EmptyEmployeeProgram />
  }

  const handleExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([record.id])
      setSearchParams({ id: record.id })
    } else {
      setExpandedRowKeys([])
    }
  }

  return (
    <>
      <MetaDecorator title={'Danh sách chương trình'} />
      <PageTitle title={'Danh sách chương trình'} className="my-5 p-0" />
      <Table
        className="overflow-x-scroll"
        columns={formatTableColumns(employeeProgramsColumnsGenerator())}
        dataSource={getEmployeePrograms(employees)}
        pagination={false}
        loading={isLoading}
        rowKey="id"
        expandable={{
          expandedRowKeys,
          onExpand: handleExpand,
          expandedRowRender: record => {
            if (expandedRowKeys.includes(record.id)) {
              return <ExpandedRow record={record} />
            } else return null
          },

          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <DownOutlined onClick={e => onExpand(record, e)} />
            ) : (
              <RightOutlined onClick={e => onExpand(record, e)} />
            )
        }}
      />
    </>
  )
}

export default EmployeeProgramListPage

export const employeeProgramsColumnsGenerator = () => [
  {
    title: 'Mã chương trình',
    dataIndex: 'id',
    render: (id, record) => (
      <Link to={`/employees/${record?.employeeId}/programs/${id}`}>
        {record?.code}
      </Link>
    )
  },
  {
    title: 'Doanh nghiệp',
    dataIndex: 'company'
  },
  {
    title: 'Hạn chuyển giao',
    dataIndex: 'endDate',
    type: 'date'
  },
  {
    title: 'Tổng vốn đã góp (VND)',
    dataIndex: 'sumContributedAmount',
    type: 'formatNumber'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: value => {
      return (
        <Tag color={employeeProgramStatusColors[value]}>
          {employeeProgramStatus[value]}
        </Tag>
      )
    }
  }
]

export const ExpandedRow = ({ record }) => {
  const { id: programId, employeeId } = record || {}
  const {
    data: portfolio,
    load: fetchPort,
    isLoading: isLoadingPort,
    error: errorPort
  } = useFetchApiGet(epPortfolioApiCall.portfolio, { resourceName: 'data' })

  const {
    data: program,
    load: fetchProgram,
    isLoading: isLoadingProgram,
    error: errorProgram
  } = useFetchApiGet(apiProgramCall.detail, { resourceName: 'data' })

  useEffect(() => {
    fetchPort({ id: employeeId, programId })
    fetchProgram({ id: employeeId, programId })
  }, [JSON.stringify(record)])

  const isLoading = isLoadingPort || isLoadingProgram
  const error = errorPort || errorProgram

  if (isLoading) {
    return <LoadingSpinner />
  }
  if (!!error) {
    return <ErrorMessage errors={error} />
  }
  const { projected_program_amount, total_summary } = portfolio || {}
  const { contributed_amount } = total_summary || {}
  const { employee_investment_detail = [] } = program
  return (
    <div className="flex md:flex-row flex-col gap-4">
      <UICard title="Biểu đồ giá trị vốn đầu tư" className="flex-grow">
        <ContributedColumnChart
          projected_program_amount={projected_program_amount}
        />
      </UICard>

      <UICard title={'Phân bổ sản phẩm quỹ đã chọn'}>
        <div className="px-16 w-[402px]">
          <InvestmentDetailDonutChart
            contributed_amount={contributed_amount}
            data={employee_investment_detail}
          />
        </div>
      </UICard>
    </div>
  )
}

export const EmptyEmployeeProgram = () => {
  const src = cloudflareCdn(
    `/futureflex/images/employee_program_empty_background_2.jpg`,
    662,
    804
  )
  return (
    <div className="flex md:flex-row flex-col-reverse">
      <div
        style={{ backgroundImage: `url(${src})` }}
        className="md:w-1/2 aspect-[635/483] bg-center bg-no-repeat bg-cover"
      />

      <div className="md:w-1/2 flex flex-col items-center justify-center bg-white p-6">
        <div className="w-36 pt-6">
          <SvgComponent />
        </div>
        <p className="max-w-sm text-center text-gray-400">
          Bạn chưa có lời mời tham gia chương trình phúc lợi nào từ doanh nghiệp
          của mình. Hãy liên hệ bộ phận nhân sự để được hỗ trợ, hoặc kết nối họ
          với chúng tôi để lên chương trình dành riêng cho bạn.
        </p>
        <a target="_blank" href="https://stag.vn/futureflex">
          <Button size="large" type="primary">
            Liên hệ kết nối
          </Button>
        </a>
      </div>
    </div>
  )
}

const SvgComponent = props => (
  <svg
    width={'100%'}
    height={'100%'}
    viewBox="0 0 136 117"
    fill="none"
    {...props}
  >
    <path
      fill="#F1F3F9"
      d="M12.981 26.865a4.04 4.04 0 0 0 4.04-4.041 4.04 4.04 0 1 0-4.04 4.041Z"
    />
    <path
      fill="#EAEEF9"
      d="M123.536 39.204a4.04 4.04 0 0 0 4.039-4.042 4.04 4.04 0 1 0-8.079 0 4.041 4.041 0 0 0 4.04 4.042Z"
    />
    <path
      fill="#F1F3F9"
      d="M21.167 13.145a1.595 1.595 0 1 0-.001-3.19 1.595 1.595 0 0 0 0 3.19ZM118.858 22.079a1.702 1.702 0 1 0-.001-3.403 1.702 1.702 0 0 0 .001 3.403ZM67.408 117.381c32.29 0 58.466-26.192 58.466-58.5 0-32.309-26.176-58.5-58.466-58.5-32.29 0-58.466 26.191-58.466 58.5 0 32.308 26.176 58.5 58.466 58.5Z"
    />
    <path
      fill="#fff"
      stroke="#D6DCE8"
      strokeMiterlimit={10}
      strokeWidth={2.208}
      d="m83.779 92.385-50.813 20.529c-.85.319-1.807-.107-2.126-.851L1.607 39.416c-.319-.85.106-1.808.85-2.127L53.27 16.761c.85-.32 1.807.106 2.126.85l29.233 72.54c.425.958 0 1.916-.85 2.234Z"
    />
    <path
      fill="#F1F3F9"
      d="m61.349 49.415-42.52 17.124c-.426.213-.851 0-1.064-.425-.213-.425 0-.851.425-1.064l42.415-17.124c.425-.213.85 0 1.063.425.212.426 0 .851-.32 1.064ZM62.943 53.563 20.53 70.687c-.425.213-.85 0-1.063-.425-.213-.426 0-.851.425-1.064l42.415-17.124c.425-.213.85 0 1.063.425.212.426 0 .851-.426 1.064ZM64.644 57.605 22.23 74.835c-.425.214-.85 0-1.063-.425-.213-.425 0-.85.425-1.063l42.415-17.125c.425-.213.85 0 1.063.426.212.319 0 .85-.425.957ZM39.876 72.495 23.93 78.984c-.426.212-.85 0-1.063-.426-.213-.425 0-.85.425-1.064l15.945-6.488c.425-.212.85 0 1.063.426.106.425 0 .85-.425 1.063ZM70.172 68.135l1.382 3.51c.212.638 0 1.382-.638 1.595l-41.99 17.018c-.637.213-1.381 0-1.594-.638l-1.382-3.616c-.212-.639 0-1.383.638-1.596l41.99-16.912c.637-.212 1.275.107 1.594.639ZM73.467 76.218l1.382 3.51c.213.638 0 1.383-.638 1.596L32.222 98.341c-.638.212-1.382 0-1.595-.638l-1.488-3.617c-.212-.638 0-1.383.638-1.595l41.99-16.912c.637-.213 1.381.106 1.7.638ZM76.656 84.302l1.382 3.51c.213.638 0 1.382-.638 1.595l-41.989 17.018c-.638.213-1.382 0-1.594-.638l-1.382-3.51c-.213-.638 0-1.383.637-1.595l41.99-16.912c.638-.32 1.382 0 1.594.532Z"
    />
    <path
      fill="#AAB2C5"
      d="m30.947 82.707 2.338 5.85-4.358 1.701c-.638.213-1.382 0-1.595-.638l-1.382-3.616c-.212-.638 0-1.383.638-1.596l4.358-1.701Z"
    />
    <path
      fill="#D6DCE8"
      d="M29.883 92.492 42.64 87.28l2.338 5.85-12.756 5.212c-.638.213-1.382 0-1.594-.638l-1.489-3.616c-.212-.532.107-1.277.744-1.596ZM33.072 100.576l1.808-.745 2.338 5.85-1.807.745c-.638.212-1.382 0-1.594-.639l-1.382-3.51c-.213-.638 0-1.382.637-1.701Z"
    />
    <path
      fill="#F1F3F9"
      d="M33.71 45.798c-.106-5.85-4.89-10.636-10.842-10.636h-.107c-5.953 0-10.736 4.893-10.736 10.85.106 5.956 4.996 10.742 10.949 10.742 3.295 0 6.166-1.49 8.185-3.723 1.701-1.914 2.658-4.36 2.658-7.126-.106 0-.106 0-.106-.107ZM22.975 53.35c-4.04 0-7.335-3.297-7.335-7.339s3.296-7.339 7.335-7.339h.106a7.31 7.31 0 0 1 7.335 7.127v.212c0 1.808-.638 3.51-1.807 4.787-1.488 1.595-3.402 2.552-5.634 2.552Z"
    />
    <path
      fill="#D6DCE8"
      d="M33.71 45.798h-3.508c0-3.935-3.295-7.126-7.228-7.126h-.106v-3.51h.106c5.847 0 10.63 4.787 10.737 10.636Z"
    />
    <path
      fill="#fff"
      stroke="#D6DCE8"
      strokeMiterlimit={10}
      strokeWidth={2.208}
      d="M104.508 108.872 53.164 89.727a1.653 1.653 0 0 1-.957-2.128L79.42 14.315a1.651 1.651 0 0 1 2.126-.958l51.344 19.04a1.652 1.652 0 0 1 .957 2.127l-27.213 73.391a1.651 1.651 0 0 1-2.126.957Z"
    />
    <path
      fill="#E6EDF5"
      d="m97.704 46.33-4.145-1.595 3.401-9.466 4.146 1.489-3.402 9.572Z"
      opacity={0.4}
    />
    <path
      fill="#AAB2C5"
      d="m103.338 48.35-4.252-1.595 5.209-14.465 4.146 1.596-5.103 14.465Z"
    />
    <path
      fill="#D6DCE8"
      d="m114.5 52.286-4.146-1.489 6.803-19.039 4.252 1.49-6.909 19.038Z"
    />
    <path
      fill="#E6EDF5"
      d="m119.815 54.2-4.146-1.488 1.808-5.212 4.252 1.596-1.914 5.105Z"
      opacity={0.4}
    />
    <path
      fill="#F1F3F9"
      d="M116.839 61.966 73.893 46.01c-.426-.106-.638-.638-.532-1.063.106-.426.638-.639 1.063-.532L117.37 60.37c.425.107.638.639.532 1.064-.213.425-.638.638-1.063.532ZM115.244 66.114 72.298 50.159c-.425-.106-.638-.638-.532-1.063.107-.426.638-.639 1.063-.532l42.946 15.954c.426.107.638.638.532 1.064-.106.425-.638.745-1.063.532ZM113.756 70.262 70.81 54.308c-.425-.107-.638-.639-.531-1.064.106-.425.637-.638 1.063-.532l42.946 15.955c.425.106.637.638.531 1.063-.212.532-.638.745-1.063.532Z"
    />
    <path
      fill="#DDD"
      d="m85.48 64.518-16.158-5.956c-.425-.106-.638-.638-.532-1.064.107-.425.638-.638 1.063-.531l16.158 5.956c.425.106.638.638.532 1.064-.213.425-.638.638-1.063.531Z"
    />
    <path
      fill="#F1F3F9"
      d="m110.779 81.749-1.275 3.616c-.213.639-.957.958-1.595.745l-9.142-3.404-33.379-12.444c-.638-.213-.956-.957-.744-1.596l1.276-3.616c.212-.638.956-.957 1.594-.745l33.167 12.339 9.248 3.403c.744.426 1.063 1.064.85 1.702ZM107.697 89.939l-1.276 3.616c-.212.639-.956.958-1.594.745l-3.083-1.17-39.332-14.572c-.638-.213-.956-.957-.744-1.595l1.276-3.617c.212-.638.956-.957 1.594-.744l39.438 14.678 2.977 1.17c.638.213.957.85.744 1.489ZM104.72 98.129l-1.275 3.616c-.213.639-.957.958-1.595.745l-15.732-5.85-26.682-9.892c-.638-.213-.957-.957-.744-1.595l1.275-3.617c.213-.638.957-.957 1.595-.744l27.32 10.104 15.094 5.638c.638.212.957.957.744 1.595Z"
    />
    <path
      fill="#D6DCE8"
      d="m61.562 80.792 27.32 10.104-2.764 5.638-26.682-9.892c-.638-.213-.957-.957-.744-1.596l1.275-3.616c.213-.532.957-.851 1.595-.638ZM64.644 68.773l1.276-3.617c.212-.638.956-.957 1.594-.744l33.167 12.338-2.126 5.85-33.167-12.338a1.15 1.15 0 0 1-.744-1.49ZM103.976 87.28l-2.232 5.85-39.332-14.572c-.638-.213-.956-.957-.744-1.595l1.276-3.617c.212-.638.956-.957 1.594-.744l39.438 14.678Z"
    />
    <path
      fill="#DDD"
      d="m75.487 67.39-2.126 5.85-7.973-2.978c-.638-.213-.956-.957-.744-1.596l1.276-3.616c.212-.638.956-.957 1.594-.745l7.973 3.085ZM64.538 72.602l7.973 2.978-2.126 5.85-7.973-2.978c-.638-.213-.956-.957-.744-1.596l1.276-3.616c.319-.532.956-.85 1.594-.638ZM61.562 80.792l7.972 2.978-2.126 5.85-7.972-2.978c-.638-.213-.957-.957-.744-1.596l1.275-3.616c.213-.532.957-.851 1.595-.638Z"
    />
    <path
      fill="#fff"
      stroke="#D6DCE8"
      strokeMiterlimit={10}
      strokeWidth={2.208}
      d="M95.365 91.11H40.62c-.85 0-1.595-.745-1.595-1.596V11.23c0-.85.745-1.595 1.595-1.595h54.745c.85 0 1.595.744 1.595 1.595v78.178c.106.957-.638 1.701-1.594 1.701Z"
    />
    <path
      fill="#F1F3F9"
      d="M90.582 39.734h-45.71a.838.838 0 0 1-.85-.85c0-.425.318-.85.85-.85h45.71c.425 0 .85.319.85.85 0 .425-.425.85-.85.85ZM90.582 44.095h-45.71a.838.838 0 0 1-.85-.85c0-.425.318-.85.85-.85h45.71c.425 0 .85.318.85.85s-.425.85-.85.85ZM90.582 48.563h-45.71a.838.838 0 0 1-.85-.85c0-.426.318-.851.85-.851h45.71c.425 0 .85.319.85.85 0 .532-.425.85-.85.85ZM90.476 65.262H75.062a.838.838 0 0 1-.85-.85c0-.426.318-.851.85-.851h15.414c.425 0 .85.319.85.85-.106.426-.425.85-.85.85ZM65.6 69.837c-.106-5.85-4.89-10.637-10.842-10.637h-.106c-5.953 0-10.737 4.893-10.737 10.85 0 5.956 4.89 10.848 10.843 10.848 3.295 0 6.165-1.489 8.185-3.722 1.7-1.915 2.658-4.361 2.658-7.127v-.212Zm-10.842 7.445c-4.04 0-7.335-3.297-7.335-7.34 0-4.04 3.295-7.338 7.335-7.338h.106A7.31 7.31 0 0 1 62.2 69.73v.213c0 1.808-.638 3.51-1.807 4.786-1.488 1.596-3.402 2.553-5.634 2.553Z"
    />
    <path
      fill="#D6DCE8"
      d="M65.601 69.837h-3.508a7.31 7.31 0 0 0-7.335-7.127h-.106V59.2h.106c5.953 0 10.737 4.68 10.843 10.637Z"
    />
    <path
      fill="#AAB2C5"
      d="M65.6 69.943c0 2.765-.956 5.212-2.657 7.126l-2.657-2.234c1.169-1.276 1.807-2.978 1.807-4.786v-.213H65.6v.107Z"
    />
    <path
      fill="#F1F3F9"
      d="M71.341 65.582a1.17 1.17 0 1 0 0-2.339 1.17 1.17 0 0 0 0 2.34ZM90.476 70.154H75.062a.838.838 0 0 1-.85-.85c0-.425.318-.85.85-.85h15.414c.425 0 .85.319.85.85-.106.425-.425.85-.85.85Z"
    />
    <path
      fill="#D6DCE8"
      d="M71.341 70.475a1.17 1.17 0 1 0 0-2.34 1.17 1.17 0 0 0 0 2.34Z"
    />
    <path
      fill="#F1F3F9"
      d="M90.476 75.047H75.062a.838.838 0 0 1-.85-.85c0-.425.318-.85.85-.85h15.414c.425 0 .85.319.85.85-.106.425-.425.85-.85.85Z"
    />
    <path
      fill="#AAB2C5"
      d="M71.341 75.367a1.17 1.17 0 1 0 0-2.339 1.17 1.17 0 0 0 0 2.34Z"
    />
    <path
      fill="#D6DCE8"
      d="M44.34 33.885h46.986V14.846l-7.441 3.723-3.934 2.765-7.015-.106-6.166 3.19-5.315.745-1.913 2.128-5.528 3.51-5.422.957-4.252 2.127Z"
    />
    <path
      fill="#F1F3F9"
      d="M44.34 33.885h46.986V19.42l-7.441 2.765-3.934 2.128-7.015-.107-6.166 2.447-5.315.638-1.913 1.489-5.528 2.765-5.422.745-4.252 1.595Z"
    />
    <path
      fill="#AAB2C5"
      d="M44.34 33.885h46.986v-8.19l-7.441 1.596-3.934 1.17h-7.015l-6.166 1.276-5.315.426-1.913.85-5.528 1.49-5.422.425-4.252.957Z"
    />
  </svg>
)

export const getEmployeePrograms = employees => {
  const allEmployeePrograms = []
  employees.map(employee => {
    const { employee_programs, company, id: employeeId } = employee || {}
    employee_programs.length > 0 &&
      employee_programs.map(employee_program => {
        const {
          id,
          end_date: endDate,
          code,
          sum_company_contributed_amount,
          sum_employee_contributed_amount,
          status
        } = employee_program || {}
        allEmployeePrograms.push({
          company: company?.name || company?.name_en || company?.brand_name,
          id,
          employeeId,
          endDate,
          code,
          status,
          sumContributedAmount:
            sum_company_contributed_amount + sum_employee_contributed_amount
        })
      })
  })
  return allEmployeePrograms
}

export const checkFirstPendingEmployeeProgram = employees => {
  return (
    (Array.isArray(employees) &&
      employees.every(
        employee => employee?.status === employeeProgramStatusPending
      )) ||
    false
  )
}
