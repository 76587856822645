import { cloudflareCdn } from 'utils/image'

function QRDownload() {
  return (
    <img
      width={123}
      height={123}
      src={cloudflareCdn('/stagvn/assets/svg/app_qr.svg')}
    />
  )
}

export default QRDownload
