import { useFetchApiGet } from '@stagapps/redux-utils'
import apiUserProfileCall from 'app/apiCalls/verification/getProfile'
import apiUserIDCall from 'app/apiCalls/verification/getUserIdentifications'
import { useEffect } from 'react'

function useAccountVerifiedStatus() {
  let current = 1
  let linkTo = '/verification/phone'

  const {
    data: userID,
    load: loadUserID,
    isLoading: isLoadingUserId,
    error: errorUserID,
    refresh
  } = useFetchApiGet(apiUserIDCall, {
    resourceName: 'data'
  })
  const {
    data: userProfile,
    load: loadUserProfile,
    isLoading: isLoadingUserProfile,
    error: errorUserProfile
  } = useFetchApiGet(apiUserProfileCall, {
    resourceName: 'data'
  })

  useEffect(() => {
    loadUserID()
    loadUserProfile()
  }, [])

  const isLoading = isLoadingUserId || isLoadingUserProfile
  const isError = !!errorUserProfile || !!errorUserID

  const {
    verification_status,
    identification_images,
    address,
    address_city_id,
    address_district_id,
    residential_address,
    residential_address_city_id,
    residential_address_district_id
  } = userID || {}

  const { phone_verified } = userProfile || {}
  const { back_id_card, front_id_card, selfie } = identification_images || {}
  const { status: frontIdCardStatus } = front_id_card || {}
  const { status: backIdCardStatus } = back_id_card || {}
  const { status: selfieStatus } = selfie || {}

  const isCompleteAllKYC =
    verification_status === 'confirmed' || verification_status === 'approved'

  const idImageVerify =
    selfieStatus === 'succeeded' &&
    frontIdCardStatus === 'succeeded' &&
    backIdCardStatus === 'succeeded'

  const idVerified =
    address &&
    address_city_id &&
    address_district_id &&
    residential_address &&
    residential_address_city_id &&
    residential_address_district_id

  if (idVerified) {
    current = 3
    linkTo = '/verification/bank-account'
  }
  if (idImageVerify) {
    current = 2
    linkTo = '/verification/personal-information'
  }
  if (phone_verified) {
    current = 2
    linkTo = '/verification/idcard/confirm'
  }

  return {
    isLoading,
    isError,
    linkTo,
    current,
    user: { ...userProfile, ...userID },
    isCompleteAllKYC,
    error: errorUserID || errorUserProfile
  }
}

export default useAccountVerifiedStatus
