import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

export const STAFF_ACCOUNT_UPDATE_PASSWORD =
  'BUSINESS/STAFF_ACCOUNT_UPDATE_PASSWORD'

const apiCall = enhancedMakeFetchAction(
  STAFF_ACCOUNT_UPDATE_PASSWORD,
  ({ password, password_confirmation, current_password }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/flex/v1/account/password`,
    method: 'PATCH',
    body: {
      password,
      password_confirmation,
      current_password
    }
  })
)

export default apiCall
