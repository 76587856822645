import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_EMPLOYEE_PROGRAM_PORT =
  'EMPLOYEE/FETCH_EMPLOYEE_PROGRAM_EMPLOYEE_FLEX_PORT'

const apiCall = enhancedMakeFetchAction(
  FETCH_EMPLOYEE_PROGRAM_PORT,
  ({ id, programId }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/flex/employee/v1/employees/${id}/employee-programs/${programId}/employee-flex-portfolio`,
    method: 'GET'
  })
)

export default apiCall
