import { ACTIONS } from '@stagapps/redux-utils'
import { eq, flow, get } from 'lodash/fp'
import { put, takeEvery } from 'redux-saga/effects'

import { POST_SIGNIN } from 'business/apiCalls/signin'

import SessionAction from 'state/action/session'

const isSignin = flow(get('payload.name'), eq(POST_SIGNIN))

function* setSigninLoading(boolean) {
  yield put({
    type: SessionAction.SET_LOADING,
    payload: {
      isLoading: boolean
    }
  })
}

function* onSigninSuccess(action) {
  if (isSignin(action)) {
    try {
      const currentToken = get('payload.json.data.access_token')(action)
      const currentUser = get('payload.json.data.staff')(action)
      yield put({
        type: SessionAction.SET_STAG_USER,
        payload: { currentToken, currentUser, isLoading: false }
      })
    } catch (error) {
      console.log('OnSigninSuccess', error)
      yield setSigninLoading(false)
    }
  }
}

function* watchOnSignin() {
  yield takeEvery(ACTIONS.COMPLETE, onSigninSuccess)
}

export function* signinSaga() {
  yield watchOnSignin()
}
