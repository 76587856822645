export const orderStatus = {
  1001: 'N/A',
  1002: 'N/A',
  1003: 'N/A',
  1004: 'Đang xử lý',
  1005: 'Hoàn tất',
  1006: 'Đã hủy',
  1007: 'Đang xử lý'
}
export const orderStatusColors = {
  1001: '',
  1002: '',
  1003: '',
  1004: 'yellow',
  1005: 'lime',
  1006: '',
  1007: 'yellow'
}

// export const employeeProgramFilterTab = [
//   { key: '1004,1005,1006,1007', label: 'Tất cả' },
//   { key: '1004,1007', label: 'Đang xử lý' },
//   { key: 1005, label: 'Hoàn tất' },
//   { key: 1006, label: 'Đã hủy' }
// ]
