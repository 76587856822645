import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { usePostApi } from '@stagapps/redux-utils'
import { Form, Input } from 'antd'
import { useEffect, useState } from 'react'

import apiCall from 'business/apiCalls/employee/program'
import apiScenarioCall from 'business/apiCalls/simulator/program'
import ScenarioProgramTable from 'business/components/ScenarioProgramTable'
import UIDate from 'common/ui/display/UIDate'
import UIInputFormatNumber from 'common/ui/display/UIInputFormatNumber'
import ReviewBeforeSubmitForm from 'common/ui/form/ReviewBeforeSubmitForm'
import { convertDateFormData } from 'utils/formData'
import { formatNumber } from 'utils/formatAmount'
import { ensureDateFormat } from 'utils/formatDate'

const dateFields = ['start_date']
const scenarioDateFields = ['start_date']
const removeList = ['monthly_order_payment']

function EmployeeProgramCreateForm({
  id,
  full_name,
  monthly_order_payment,
  errorCompany
}) {
  const { data, isLoading: isSubmitting, post, error } = usePostApi(
    apiScenarioCall,
    {
      resourceName: 'data'
    }
  )

  const [addData, setAddData] = useState({})

  useEffect(() => {
    if (!isSubmitting) {
      if (!!data && !error) {
        const { employee_program } = data
        const { end_date } = employee_program || {}
        setAddData({ end_date })
      }
    }
  }, [isSubmitting])

  const onReview = values => {
    const {
      start_date,
      investment_interval,
      company_contribute_amount,
      employee_contribute_amount
    } = values

    post({
      payload: convertDateFormData(
        {
          monthly_order_payment,
          investment_interval,
          company_contribute_amount,
          employee_contribute_amount,
          start_date
        },
        scenarioDateFields
      )
    })
  }

  return (
    <div>
      <ReviewBeforeSubmitForm
        initialValues={{ monthly_order_payment }}
        formName={'create-program'}
        apiCall={apiCall}
        submitParams={{ id }}
        config={configGenerator({ full_name, error: errorCompany })}
        reviewTitle={'Kiểm tra lại chi tiết chương trình'}
        formTitle={'Chi tiết chương trình'}
        submitButtonTitle={'Xác nhận tạo chương trình'}
        dateFields={dateFields}
        ExtraComponent={() => (
          <ScenarioProgramTable
            data={data}
            isSubmitting={isSubmitting}
            error={error}
          />
        )}
        extraData={data}
        onReview={onReview}
        extraReviewData={addData}
        confirmText={
          'Bằng nhấn nút, bạn sẽ xác nhận gửi lời mời cho nv tham gia.'
        }
      />
    </div>
  )
}

export default EmployeeProgramCreateForm

const configGenerator = ({ full_name, error }) => {
  const { error: errorMessage, error_code } = error || {}

  return [
    {
      value: full_name,
      label: 'Họ tên nhân viên',
      FormItem: ({ label, name }) => (
        <Form.Item name={name} label={label}>
          <Input disabled defaultValue={full_name} />
        </Form.Item>
      )
    },
    {
      name: 'start_date',
      formatter: t => t.format('DD/MM/YYYY'),
      label: 'Thời gian bắt đầu',
      FormItem: ({ label, name }) => (
        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              required: true,
              type: 'date',
              message: 'Vui lòng nhập'
            }
          ]}
        >
          <DatePicker
            minDate={ensureDateFormat(new Date())}
            sx={{ px: 1 }}
            openTo="year"
            views={['year', 'month', 'day']}
            format="DD/MM/YYYY"
            slotProps={{
              textField: { variant: 'standard' }
            }}
          />
        </Form.Item>
      )
    },
    {
      name: 'monthly_order_payment',
      label: 'Ngày tạo lệnh định kỳ hằng tháng',

      FormItem: ({ label, name }) => (
        <Form.Item
          name={name}
          label={label}
          validateStatus={!!error ? 'error' : null}
          help={errorMessage || error_code}
        >
          <UIInputFormatNumber disabled className="w-full" min={1} max={28} />
        </Form.Item>
      )
    },
    {
      name: 'investment_interval',
      label: 'Thời gian chương trình ( số kỳ tham gia )',
      FormItem: ({ label, name }) => (
        <Form.Item
          name={name}
          label={label}
          tooltip={{
            title:
              'Số kỳ tương đương với số tháng dương lịch. Hệ thống sẽ dựa theo số kỳ tham gia của chương trình để tạo lệnh mua chứng chỉ quỹ cho phần tích lũy của Nhân viên và phần đóng góp của Doanh nghiệp.'
          }}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập số',
              type: 'number'
            }
          ]}
        >
          <UIInputFormatNumber
            className="w-full"
            min={0}
            step={1}
            suffix={<span>kỳ đóng góp</span>}
          />
        </Form.Item>
      )
    },
    {
      name: 'end_date',
      formatter: value => <UIDate timeData={value} />,
      label: 'Thời điểm chuyển giao',
      FormItem: ({ name }) => (
        <Form.Item hidden name={name}>
          <Input />
        </Form.Item>
      )
    },
    {
      name: 'employee_contribute_amount',
      label: 'Nhân viên tích lũy định kỳ',
      formatter: formatNumber,
      FormItem: ({ label, name }) => (
        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập số',
              type: 'number'
            }
          ]}
        >
          <UIInputFormatNumber
            className="w-full"
            addonAfter={'VND'}
            min={0}
            step={1000}
          />
        </Form.Item>
      )
    },
    {
      name: 'company_contribute_amount',
      label: 'Công ty đóng góp định kỳ',
      formatter: formatNumber,
      FormItem: ({ label, name }) => (
        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập số',
              type: 'number'
            }
          ]}
        >
          <UIInputFormatNumber
            className="w-full"
            addonAfter={'VND'}
            min={0}
            step={1000}
          />
        </Form.Item>
      )
    }
  ]
}
