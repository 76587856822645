import { useFetchApiGet } from '@stagapps/redux-utils'
import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import apiEmployeeDetailCall from 'business/apiCalls/employee/detail'
import PageTitle from 'common/layout/PageTitle'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UIQueryTabs from 'common/ui/display/UIQueryTabs'
import PersonalInfoTab from './PersonalInfoTab'
import ProgramListTab from './program/ProgramListTab'

function EmployeeDetail() {
  const params = useParams()
  let [searchParams, setSearchParams] = useSearchParams()
  const key = searchParams.get('key')

  const {
    data,
    load: fetchItems,
    isLoading
  } = useFetchApiGet(apiEmployeeDetailCall, { resourceName: 'data' })

  useEffect(() => {
    fetchItems(params)
  }, [])

  useEffect(() => {
    if (!key) {
      setSearchParams({ key: 'employee-programs' }, { replace: true })
    }
  }, [key])

  if (isLoading) {
    return <LoadingSpinner />
  }

  const { full_name } = data || {}

  const tabItems = [
    {
      key: 'employee-programs',
      label: 'Tổng quan chương trình',
      children: <ProgramListTab id={params.id} />
    },
    {
      key: 'info',
      label: 'Thông tin cá nhân',
      children: <PersonalInfoTab personalInfo={data} />
    }
  ]

  return (
    <div className="flex flex-col gap-4">
      <PageTitle title={full_name} />
      <UIQueryTabs items={tabItems} defaultQuery="employee-programs" />
    </div>
  )
}

export default EmployeeDetail
