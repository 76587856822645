import { Button, Result } from 'antd'
import { Link } from 'react-router-dom'

const App = () => (
  <div className=" text-center flex flex-col gap-2 justify-center items-center h-screen w-screen ">
    <Result
      status="500"
      title="Oops"
      subTitle={
        'Lỗi đã được gửi về hệ thống, Stag sẽ xử lý trong thời gian sớm nhất. Vui lòng thử lại.'
      }
      extra={
        <Button type="primary">
          <Link to={'/'}>Về trang chủ</Link>
        </Button>
      }
    />
  </div>
)
export default App
