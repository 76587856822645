import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

export const UPDATE_USER_PROGRAM_API_ID = 'EMPLOYEE/USER_PROGRAM_UPDATE_API'

const apiCall = enhancedMakeFetchAction(
  UPDATE_USER_PROGRAM_API_ID,
  ({ id, payload }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/app/v2/user-programs/${id}/update`,
    method: 'PATCH',
    body: payload,
    notify: true
  })
)

export default apiCall
