import { useFetchApiList } from '@stagapps/redux-utils'
import { Button, Empty, Table } from 'antd'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import apiCall from 'business/apiCalls/employee/program'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import {
  businessEmployeeProgramDetailLink,
  employeeProgramsColumns
} from 'config/columns'

function ProgramListTab({ id }) {
  const {
    data: items = [],
    load: fetchItems,
    isLoading: isLoadingItems,
    error
  } = useFetchApiList(apiCall.list, { resourceName: 'data' })

  const isLoading = isLoadingItems

  useEffect(() => {
    fetchItems({ id })
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  const locale = {
    emptyText: (
      <Empty
        children={
          <Link to={`/employees/${id}/employee-programs/create`}>
            <Button type="primary">Thêm chương trình</Button>
          </Link>
        }
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={'Nhân viên chưa tham gia chương trình nào'}
      />
    )
  }

  return (
    <div className="flex flex-col gap-4 items-stretch">
      <UICard
        title={'Tất cả chương trình'}
        extra={
          items?.length > 0 ? (
            <Link to={`/employees/${id}/employee-programs/create`}>
              <Button type="primary">+ Thêm chương trình</Button>
            </Link>
          ) : null
        }
      >
        <Table
          rowKey="id"
          locale={locale}
          columns={[
            ...employeeProgramsColumns,
            businessEmployeeProgramDetailLink
          ]}
          dataSource={items}
          pagination={false}
          showHeader={items?.length > 0 ? true : false}
          loading={isLoading}
        />
      </UICard>
    </div>
  )
}

export default ProgramListTab
