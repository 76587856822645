import SessionAction from 'state/action/session'

const initialSessionState = {
  currentUser: null,
  currentToken: null,
  isLoading: false
}

export const sessionSelector = state => state.session

export const sessionReducer = (state = initialSessionState, action) => {
  const { type, payload } = action

  switch (type) {
    case SessionAction.SET_STAG_USER:
      return {
        ...state,
        ...payload
      }
    case SessionAction.UPDATE_STAG_USER:
      return {
        ...state,
        ...payload
      }
    case SessionAction.SET_LOADING:
      return {
        ...state,
        ...payload
      }
    case SessionAction.CLEAR_STAG_USER:
      return {}

    default:
      return { ...state }
  }
}
