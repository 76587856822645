import { useFetchApiGet, useFetchApiList } from '@stagapps/redux-utils'
import { Empty, Table } from 'antd'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import epOrderApiCall from 'app/apiCalls/employeeProgramOrder'
import employeeFlexPortApiCall from 'app/apiCalls/employeeProgramPortfolio/employeeFlexPort'
import MetaDecorator from 'common/layout/MetaDecorator'
import DataTable from 'common/ui/DataTable'
import ResponsiveTable from 'common/ui/DataTable/ResponsiveTable'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import { getChangeValueClassColor } from 'config/color'
import { formatPercentage } from 'utils/formatAmount'
import { formatCurrencyWithoutCurrencySymbol } from 'utils/formatAmount/formatUI'
import { formatTableColumns } from 'utils/tableConfig'
import SellModal from './SellModal'
import { portColumn, sellOrderColumn } from './config'

function EmployeeProgramTransactionPage() {
  const params = useParams()

  const {
    data: portfolio,
    load: fetchPort,
    isLoading: isLoadingPort,
    refresh: refreshPort,
    error: errorPort
  } = useFetchApiGet(employeeFlexPortApiCall, { resourceName: 'data' })

  const {
    data: epSellOrders,
    load: fetchEPOrders,
    isLoading: isLoadingEPOrders,
    refresh: refreshEPOrders,
    error: errorEPOrders,
    paging
  } = useFetchApiList(epOrderApiCall.list, { resourceName: 'data' })

  const isLoading = isLoadingPort || isLoadingEPOrders
  const error = errorPort || errorEPOrders

  useEffect(() => {
    fetchPort(params)
    fetchEPOrders({
      id: params.id,
      programId: params.programId,
      order_type: 'sell'
    })
  }, [])

  const refresh = () => {
    refreshPort(params)
    refreshEPOrders({
      id: params.id,
      programId: params.programId,
      order_type: 'sell'
    })
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  const {
    items,
    sum_contributed_amount,
    sum_market_value,
    sum_unrealized_gain_loss_percentage,
    sum_unrealized_gain_loss
  } = portfolio || {}
  const { count, page, page_size } = paging || {}

  return (
    <>
      <MetaDecorator title={'Chi tiết giao dịch bán chương trình'} />
      <div className="flex flex-col gap-4">
        <UICard title={'Số dư CCQ tích lũy nhân viên có thể bán'}>
          <ResponsiveTable
            className="overflow-x-scroll"
            columns={[
              ...formatTableColumns(portColumn),
              {
                title: '',
                dataIndex: 'product',
                render: (product, portfolioItem) => (
                  <SellModal data={portfolioItem} refresh={refresh} />
                )
              }
            ]}
            dataSource={items}
            pagination={false}
            rowKey={'id'}
            summary={pageData => {
              let totalBorrow = 0
              let totalRepayment = 0

              pageData.forEach(({ borrow, repayment }) => {
                totalBorrow += borrow
                totalRepayment += repayment
              })

              return (
                <>
                  <Table.Summary.Row className="font-semibold">
                    <Table.Summary.Cell>Tổng</Table.Summary.Cell>
                    <Table.Summary.Cell align="right"></Table.Summary.Cell>
                    <Table.Summary.Cell align="right"></Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      {sum_market_value
                        ? formatCurrencyWithoutCurrencySymbol(sum_market_value)
                        : '-'}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <p
                        className={getChangeValueClassColor(
                          sum_unrealized_gain_loss
                        )}
                      >
                        {formatCurrencyWithoutCurrencySymbol(
                          sum_unrealized_gain_loss
                        )}
                      </p>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell align="right">
                      <p
                        className={getChangeValueClassColor(
                          sum_unrealized_gain_loss_percentage
                        )}
                      >
                        {formatPercentage(sum_unrealized_gain_loss_percentage)}
                      </p>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              )
            }}
          />
        </UICard>
        <UICard title={'Danh sách lệnh bán'}>
          <DataTable
            className="overflow-x-scroll"
            columns={formatTableColumns(sellOrderColumn)}
            dataSource={epSellOrders}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={'Chưa có giao dịch nào'}
                />
              )
            }}
            rowKey={'id'}
            showHeader={epSellOrders?.length > 0 ? true : false}
            loading={isLoadingEPOrders}
            totalItems={count}
            currentPage={page}
            defaultCurrent={1}
            defaultPageSize={page_size}
            pageSize={page_size}
            dataFetcher={fetchEPOrders}
            filters={{
              id: params.id,
              'program_orders.employee_program_id': params.programId,
              order_side: 'sell'
            }}
          />
        </UICard>
      </div>
    </>
  )
}

export default EmployeeProgramTransactionPage
