import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'

function GobackButton() {
  const navigate = useNavigate()
  const location = useLocation()

  const goBack = () => {
    const pathArray = location.pathname.split('/')
    pathArray.pop()
    const newPath = pathArray.join('/')
    navigate(newPath)
  }

  return (
    <Button className="mb-4" type="link" onClick={goBack}>
      <Space>
        <ArrowLeftOutlined />
        <div>Trở về</div>
      </Space>
    </Button>
  )
}

export default GobackButton
