import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_DATA_LIST_API_ID = 'EMPLOYEE/FETCH_INVESTCORE_PRODUCTS'

const apiCall = enhancedMakeFetchAction(FETCH_DATA_LIST_API_ID, params => ({
  endpoint: generateEndpoint({
    host: `${API_BASE_URL}/investcore-api/app/v2/products`,
    params
  }),
  method: 'GET'
}))

export default apiCall
