import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_LIST_API_ID = 'BUSINESS/FETCH_PROGRAMS_API'

const apiCall = enhancedMakeFetchAction(FETCH_LIST_API_ID, params => ({
  endpoint: generateEndpoint({
    host: `${API_BASE_URL}/investcore-api/flex/v1/programs`,
    params
  }),
  method: 'GET'
}))

export default apiCall
