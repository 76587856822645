import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

export const FETCH_CREATE_API_ID = 'EMPLOYEE_CREATE_PROGRAM_API'

const apiCall = enhancedMakeFetchAction(
  FETCH_CREATE_API_ID,
  ({ id, ...payload }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/flex/v1/employees/${id}/programs`,
    method: 'POST',
    body: payload,
    notify: true
  })
)

export default apiCall
