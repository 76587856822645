import { usePostApi } from '@stagapps/redux-utils'
import { Form, Select, Space } from 'antd'
import { apiCallGeneratorByName } from 'app/apiCalls/surveys/create'
import ValidButton from 'common/ui/form/ValidButton'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import { useNavigate } from 'react-router-dom'
import { renderErrorMessage } from 'utils/getErrorMessage'
import WelcomeTemplate from './WelcomeTemplate'

function Question1() {
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const { data, post, isLoading, error } = usePostApi(
    apiCallGeneratorByName('survey1'),
    { resourceName: 'data' }
  )

  useEffectSkipFirstRender(() => {
    if (data) {
      navigate(`/welcome/questions/2`)
    }
  }, [data])

  const onFinish = values => {
    post({
      ...values,
      question_code: '1001',
      survey_type: 1001,
      question_type: 1001
    })
  }

  return (
    <WelcomeTemplate
      quotes={
        'Những người đặt mục tiêu cụ thể và theo dõi thực hiện có tỉ lệ hoàn thành cao hơn 150% người chỉ đặt mục tiêu chung chung hoặc không theo dõi.'
      }
      author={'Theo nghiên cứu của DUCA năm 2007'}
    >
      <Form
        form={form}
        requiredMark={false}
        onFinish={onFinish}
        initialValues={{ answer_codes: '1001_001' }}
        labelCol={{ span: 24 }}
      >
        <Form.Item
          validateStatus={!!error ? 'error' : null}
          hasFeedback
          help={error ? renderErrorMessage(error) : ''}
          label={'Bạn làm trong lĩnh vực nào?'}
          className="md:max-w-[78%] mx-auto text-left"
          name="answer_codes"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn 1 câu trả lời'
            }
          ]}
        >
          <Select options={options} />
        </Form.Item>
        <Form.Item>
          <Space>
            <ValidButton
              form={form}
              type="primary"
              htmlType="submit"
              btnTitle="Tiếp tục"
              loading={isLoading}
            />
          </Space>
        </Form.Item>
      </Form>
    </WelcomeTemplate>
  )
}

export default Question1

const options = [
  {
    label: 'Bán lẻ',
    value: '1001_001'
  },
  {
    label: 'Công nghệ thông tin',
    value: '1001_002'
  },
  {
    label: 'Công nghiệp - Sản xuất',
    value: '1001_003'
  },
  {
    label: 'Du lịch - Nhà hàng - Khách sạn',
    value: '1001_004'
  },
  {
    label: 'Giáo dục - Đào tạo',
    value: '1001_005'
  },
  {
    label: 'Nghệ thuật - Giải trí',
    value: '1001_006'
  },
  {
    label: 'Nông nghiệp',
    value: '1001_007'
  },
  {
    label: 'Tài chính - Ngân hàng - Bảo hiểm',
    value: '1001_008'
  },
  {
    label: 'Vận tải - Vận chuyển - Giao nhận',
    value: '1001_009'
  },
  {
    label: 'Xây dựng - Bất động sản',
    value: '1001_010'
  },
  {
    label: 'Y tế - Sức khoẻ - Làm đẹp',
    value: '1001_011'
  },
  {
    label: 'Khác',
    value: '1001_012'
  }
]
