import { Typography } from 'antd'

import EmptySVG from 'common/svg/EmptySVG'

const { Title } = Typography

function EmptyList({ title, desc, CTA }) {
  return (
    <div className="h-[70vh] py-6 w-full flex flex-col gap-6 items-center justify-center text-center">
      <EmptySVG />
      <div>
        <Title level={3}>{title}</Title>
        <p>{desc}</p>
      </div>
      {CTA ? <CTA /> : null}
    </div>
  )
}

export default EmptyList
