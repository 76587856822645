import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils'
import { Button, Form, Input } from 'antd'
import { useEffect } from 'react'

import apiAccountDetailCall from 'business/apiCalls/account/detail'
import apiStaffAccountUpdateCall from 'business/apiCalls/account/update'
import apiUpdatePasswordCall from 'business/apiCalls/account/updatePassword'
import UpdatePasswordForm from 'business/components/setting/UpdatePasswordForm'
import MetaDecorator from 'common/layout/MetaDecorator'
import PageTitle from 'common/layout/PageTitle'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import { openNotificationWithIcon } from 'utils/openNotificationWithIcon'

function BusinessSettingPage() {
  const title = 'Cài đặt chung'

  const [form] = Form.useForm()

  const {
    data: responseData,
    post,
    isLoading: isSubmitting,
    error
  } = usePostApi(apiUpdatePasswordCall, { resourceName: 'success' })

  const {
    data: newStaffAccount,
    post: updateStaffAccount,
    isLoading: isStaffAccountSubmitting,
    error: updateError
  } = usePostApi(apiStaffAccountUpdateCall, { resourceName: 'data' })

  const {
    data: staffAccount,
    load: loadStaffAccount,
    isLoading: isLoadingAccountProfile,
    error: errorAccountProfile
  } = useFetchApiGet(apiAccountDetailCall, {
    resourceName: 'data'
  })

  useEffect(() => {
    loadStaffAccount()
  }, [])

  useEffectSkipFirstRender(() => {
    if (!isSubmitting) {
      if (!error) {
        form.resetFields()
      }
    }
  }, [isSubmitting])

  useEffect(() => {
    if (!isStaffAccountSubmitting && !updateError && newStaffAccount) {
      openNotificationWithIcon({
        type: 'success',
        message: 'Cập nhật tài khoản thành công!',
        duration: 2
      })
    }
  }, [isStaffAccountSubmitting])

  if (isLoadingAccountProfile) {
    return <LoadingSpinner />
  }

  if (!!errorAccountProfile) {
    return <ErrorMessage errors={errorAccountProfile} />
  }

  return (
    <div className="flex flex-col gap-4">
      <MetaDecorator title={title} />
      <PageTitle title={title} />
      <UICard title={'Thông tin tài khoản'}>
        <Form
          className="w-96"
          name="account"
          initialValues={staffAccount}
          labelCol={{ span: 24 }}
          onFinish={values => updateStaffAccount(values)}
        >
          <Form.Item label="Tên" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Email" name="email">
            <Input disabled />
          </Form.Item>
          <Form.Item label="Số điện thoại" name="phone">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isStaffAccountSubmitting}
            >
              Lưu thay đổi
            </Button>
          </Form.Item>
        </Form>
      </UICard>
      <UICard title={'Đổi mật khẩu'}>
        <UpdatePasswordForm
          initialValues={staffAccount}
          onFinish={post}
          isSubmitting={isSubmitting}
          form={form}
        />
      </UICard>
    </div>
  )
}

export default BusinessSettingPage
