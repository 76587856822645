import { isNumber } from 'lodash'
import { exceptions } from 'utils/handleException'

export function sumCountByStatus(data, statusString) {
  exceptions(statusString)

  const statuses = isNumber(statusString)
    ? [statusString]
    : statusString.split(',').map(Number)

  return statuses.reduce(
    (total, status) =>
      total +
      data.reduce(
        (count, item) => (item.status === status ? count + item.count : count),
        0
      ),
    0
  )
}
