export const riskOptions = [
  {
    title: 'Rất thận trọng',
    description:
      'Tôi ưu tiên việc bảo toàn vốn hơn khoản lãi từ đầu tư, nếu có xảy ra lỗ thì phải ở mức thấp nhất. Việc đạt được mục tiêu đúng thời điểm dự kiến rất quan trọng với tôi.',
    value: 'risk_appetite_001'
  },
  {
    title: 'Thận trọng',
    description:
      'Tôi sẵn sàng chấp nhận rủi ro trung bình đi kèm với khoản lãi cao hơn mức lạm phát một chút.',
    value: 'risk_appetite_002'
  },
  {
    title: 'Trung lập',
    description:
      'Tôi sẵn sàng chấp nhận mức rủi ro vừa phải nhằm tìm kiếm khoản lãi từ trung bình đến cao trong quá trình đầu tư tích lũy.',

    value: 'risk_appetite_003'
  },
  {
    title: 'Tương đối mạo hiểm',
    description:
      'Tôi chấp nhận rủi ro khoản vốn có thể bị lỗ tương đối, đi kèm cơ hội có khoản lãi đáng kể nhằm gia tăng giá trị tích lũy giúp đi nhanh đến mục tiêu.',
    value: 'risk_appetite_004'
  },
  {
    title: 'Mạo hiểm',
    description:
      'Tôi muốn có khoản lãi cao nhất có thể và sẵn sàng chấp nhận rủi ro khoản vốn đầu tư có thể bị lỗ sâu, nhằm đạt hoặc vượt mục tiêu đã đề ra trong thời gian sớm nhất.',
    value: 'risk_appetite_005'
  }
]

export const riskValues = {
  risk_appetite_001: {
    defaultSelectedProduct: '68e371e6-be27-4da3-8b53-c5ae46eeb92d',

    defaultInitialValues: [{ product_code: 'VCAMFI', percentage: 100 }],
    defaultConfigValues: {
      VCAMFI: 100
    },
    riskAppetite: 'Rất thận trọng',
    riskAppetiteDesc:
      'Dựa trên đánh giá khẩu vị đầu tư của bạn, hệ thống đề xuất mức phân bổ 100% quỹ VCAMFI để bạn tham khảo. Bạn có thể áp dụng đề xuất này hoặc tùy chọn sản phẩm quỹ bên dưới theo tỉ lệ phân bổ mong muốn.'
  },
  risk_appetite_002: {
    defaultSelectedProduct: '68e371e6-be27-4da3-8b53-c5ae46eeb92d',
    defaultInitialValues: [{ product_code: 'VCAMFI', percentage: 100 }],
    defaultConfigValues: {
      VCAMFI: 100
    },
    riskAppetite: 'Thận trọng',
    riskAppetiteDesc:
      'Dựa trên đánh giá khẩu vị đầu tư của bạn, hệ thống đề xuất mức phân bổ 100% quỹ VCAMFI để bạn tham khảo. Bạn có thể áp dụng đề xuất này hoặc tùy chọn sản phẩm quỹ bên dưới theo tỉ lệ phân bổ mong muốn.'
  },
  risk_appetite_003: {
    defaultSelectedProduct:
      '68e371e6-be27-4da3-8b53-c5ae46eeb92d,7faf8eb7-abad-4f83-8513-654d85589b9c',
    defaultInitialValues: [
      { product_code: 'VCAMFI', percentage: 50 },
      { product_code: 'VCAMBF', percentage: 50 }
    ],
    defaultConfigValues: {
      VCAMFI: 50,
      VCAMBF: 50
    },
    riskAppetite: 'Trung lập',
    riskAppetiteDesc:
      'Dựa trên đánh giá khẩu vị đầu tư của bạn, hệ thống đề xuất mức phân bổ 50% quỹ VCAMFI và 50% VCAMBF để bạn tham khảo. Bạn có thể áp dụng đề xuất này hoặc tùy chọn sản phẩm quỹ bên dưới theo tỉ lệ phân bổ mong muốn.'
  },
  risk_appetite_004: {
    defaultSelectedProduct: '7faf8eb7-abad-4f83-8513-654d85589b9c',
    defaultInitialValues: [{ product_code: 'VCAMBF', percentage: 100 }],
    defaultConfigValues: {
      VCAMBF: 100
    },
    riskAppetite: 'Tương đối mạo hiểm',
    riskAppetiteDesc:
      'Dựa trên đánh giá khẩu vị đầu tư của bạn, hệ thống đề xuất mức phân bổ 100% quỹ VCAMBF để bạn tham khảo. Bạn có thể áp dụng đề xuất này hoặc tùy chọn sản phẩm quỹ bên dưới theo tỉ lệ phân bổ mong muốn.'
  },
  risk_appetite_005: {
    defaultSelectedProduct: '7faf8eb7-abad-4f83-8513-654d85589b9c',
    defaultInitialValues: [{ product_code: 'VCAMBF', percentage: 100 }],
    defaultConfigValues: {
      VCAMBF: 100
    },
    riskAppetite: 'Mạo hiểm',
    riskAppetiteDesc:
      'Dựa trên đánh giá khẩu vị đầu tư của bạn, hệ thống đề xuất mức phân bổ 100% quỹ VCAMBF để bạn tham khảo. Bạn có thể áp dụng đề xuất này hoặc tùy chọn sản phẩm quỹ bên dưới theo tỉ lệ phân bổ mong muốn.'
  }
}
