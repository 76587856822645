export const fundAccountStatus = {
  new: 'Mới tạo',
  pending: 'Đang duyệt',
  miss_info: 'Cần bổ sung thông tin',
  approved: 'Đã duyệt',
  rejected: 'Từ chối',
  suspended: 'Tạm khóa'
}

export const fundAccountStatusColors = {
  new: 'blue',
  pending: 'yellow',
  miss_info: 'orange',
  approved: 'green',
  rejected: 'red',
  suspended: 'grey'
}
