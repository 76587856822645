import { useFetchApiList } from '@stagapps/redux-utils'
import { Tag } from 'antd'
import apiCall from 'app/apiCalls/fundAccount'
import MetaDecorator from 'common/layout/MetaDecorator'
import PageTitle from 'common/layout/PageTitle'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import { fundAccountStatus, fundAccountStatusColors } from 'data/fundAccount'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import AccountNavigation from './AccountNavigation'

const PAGE_TITLE = 'Công ty quỹ đã mở tài khoản giao dịch'

function FundAccountsPage() {
  const {
    data: items = [],
    load: fetchItems,
    isLoading,
    paging,
    error
  } = useFetchApiList(apiCall.list, { resourceName: 'data' })

  useEffect(() => {
    fetchItems()
  }, [])

  if (isLoading) {
    return (
      <div className="flex flex-col gap-4">
        <MetaDecorator title={PAGE_TITLE} />
        <PageTitle title={PAGE_TITLE} />
        <UICard>
          <AccountNavigation key="fund-accounts" />
          <LoadingSpinner />
        </UICard>
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex flex-col gap-4">
        <MetaDecorator title={PAGE_TITLE} />
        <PageTitle title={PAGE_TITLE} />
        <UICard>
          <AccountNavigation key="fund-accounts" />
          <ErrorMessage errors={error} />
        </UICard>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      <MetaDecorator title={PAGE_TITLE} />
      <PageTitle title={PAGE_TITLE} />
      <UICard>
        <AccountNavigation key="fund-accounts" />
        <div className="flex gap-4 flex-wrap py-5">
          {items.map((item, index) => {
            const logo = item.fund.logo
            return (
              <>
                <Link
                  to={`/account/fund-accounts/${item.id}`}
                  className="w-full md:max-w-[32%]"
                >
                  <UICard>
                    <div key={index} className="flex flex-col">
                      <div className="flex flex-row gap-4 justify-between">
                        <img className="h-10" alt={item.fund.name} src={logo} />
                        <div className="">
                          <Tag color={fundAccountStatusColors[item.status]}>
                            {fundAccountStatus[item.status]}
                          </Tag>
                        </div>
                      </div>
                      <div className="">
                        <h3 className="">{item.fund.name}</h3>
                        <p className="">{item.fund.company_name}</p>
                      </div>
                    </div>
                  </UICard>
                </Link>
              </>
            )
          })}
        </div>
      </UICard>
    </div>
  )
}

export default FundAccountsPage
