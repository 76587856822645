import { Button, Form } from 'antd'
import { useEffect, useState } from 'react'

const ValidButton = ({
  form,
  onValid = _ => {},
  btnTitle = 'Tiếp tục',
  disabled = false,
  ...props
}) => {
  const [submittable, setSubmittable] = useState(true)
  const values = Form.useWatch([], form)

  useEffect(() => {
    const review = async () => {
      try {
        await form.validateFields({
          validateOnly: true
        })
        setSubmittable(true)
      } catch (error) {
        if (error?.errorFields?.length > 0) {
          setSubmittable(false)
        } else {
          setSubmittable(true)
        }
      }
    }
    review()
  }, [JSON.stringify(values)])

  return (
    <Button
      onClick={() => {
        onValid(values)
      }}
      disabled={!submittable || disabled}
      {...props}
    >
      {btnTitle}
    </Button>
  )
}

export default ValidButton
