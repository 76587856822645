import MetaDecorator from 'common/layout/MetaDecorator'
import Question1 from './Question1'
import Question2 from './Question2'
import Question3 from './Question3'

export function Question1Page() {
  return (
    <>
      <MetaDecorator title="Khảo sát 1" />
      <Question1 />
    </>
  )
}
export function Question2Page() {
  return (
    <>
      <MetaDecorator title="Khảo sát 2" />
      <Question2 />
    </>
  )
}
export function Question3Page() {
  return (
    <>
      <MetaDecorator title="Khảo sát 3" />
      <Question3 />
    </>
  )
}
