import { enhancedMakeFetchAction } from '@stagapps/redux-utils'

import { API_BASE_URL } from 'config/env.config'

export const POST_VERIFY_EMAIL = 'EMPLOYEE/POST_VERIFY_EMAIL'

const apiCall = enhancedMakeFetchAction(POST_VERIFY_EMAIL, payload => ({
  endpoint: `${API_BASE_URL}/investcore-api/app/v1/users/verify-email`,
  method: 'POST',
  body: payload,
  notify: true,
  isStay: true
}))

export default apiCall
