import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

export const USER_PROGRAM_ORDER_CREATE_BUY =
  'EMPLOYEE/USER_PROGRAM_ORDER_CREATE_BUY'

const apiCall = enhancedMakeFetchAction(
  USER_PROGRAM_ORDER_CREATE_BUY,
  ({ id, payload }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/app/v2/user-programs/${id}/program-orders/create-buy`,
    method: 'POST',
    body: payload,
    notify: true
  })
)

export default apiCall
