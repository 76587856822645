import { useFetchApiGet } from '@stagapps/redux-utils'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { PageHeader } from '@ant-design/pro-layout'
import { Typography } from 'antd'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import ReviewBeforeSubmitForm from 'common/ui/form/ReviewBeforeSubmitForm'
import { formItems } from './formItems'

const { Title } = Typography

function EmployeeCreateForm({ apiCall, title }) {
  const dateFields = ['date_of_birth']
  const params = useParams()

  const {
    data,
    load: fetchItems,
    isLoading,
    error
  } = useFetchApiGet(apiCall.detail, { resourceName: 'data' })

  useEffect(() => {
    fetchItems(params)
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }
  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={<Title level={2}>{title}</Title>}
      />
      <ReviewBeforeSubmitForm
        initialValues={data}
        formName={'edit-employee'}
        apiCall={apiCall}
        config={formItems}
        reviewTitle={'Kiểm tra lại thông tin nhân viên'}
        formTitle={'Thông tin chung'}
        submitButtonTitle={'Lưu chỉnh sửa'}
        dateFields={dateFields}
        action="edit"
      />
    </>
  )
}

export default EmployeeCreateForm
