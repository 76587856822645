import { PageHeader } from '@ant-design/pro-layout'
import { Typography } from 'antd'
import useCheckMobileDevice from 'hooks/useCheckMobileDevice'

const { Title } = Typography

const PageTitle = ({ title, subTitle, customName = [], backUrl, ...props }) => {
  const { isMobile } = useCheckMobileDevice()
  return (
    <PageHeader
      className="site-page-header my-4"
      title={<Title level={isMobile ? 3 : 2}>{title}</Title>}
      subTitle={subTitle}
      {...props}
    />
  )
}

export default PageTitle
