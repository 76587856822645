import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'
export const POST_CHECKING_USER_PROGRAM_SELL_ORDER_PRICE_ESTIMATE =
  'POST_CHECKING_USER_PROGRAM_SELL_ORDER_PRICE_ESTIMATE'

const apiCall = enhancedMakeFetchAction(
  POST_CHECKING_USER_PROGRAM_SELL_ORDER_PRICE_ESTIMATE,
  ({ id, ...payload }) => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/investcore-api/app/v2/user-programs/${id}/program-orders/redeem-estimate`
    }),
    method: 'POST',
    body: payload
  })
)

export default apiCall
