import OnboardingAnalytic from 'app/components/userProgramNew/OnboardingAnalytic'
import OnboardingGoals from 'app/components/userProgramNew/OnboardingGoals'
import OnboardingInvestmentPlan from 'app/components/userProgramNew/OnboardingInvestmentPlan'
import OnboardingRiskAppetite from 'app/components/userProgramNew/OnboardingRiskAppetite'
import OnboardingStepsDetail from 'app/components/userProgramNew/OnboardingStepsDetail'
import OnboardingTarget from 'app/components/userProgramNew/OnboardingTarget'
import MetaDecorator from 'common/layout/MetaDecorator'

export function OnboardingStepsDetailPage() {
  return (
    <>
      <MetaDecorator title="Xây dựng kế hoạch đạt mục tiêu tài chính" />
      <OnboardingStepsDetail />
    </>
  )
}

export function OnboardingGoalsPage() {
  return (
    <>
      <MetaDecorator title="Đặt mục tiêu tài chính" />
      <OnboardingGoals />
    </>
  )
}

export function OnboardingTargetPage() {
  return (
    <>
      <MetaDecorator title="Xác định kỳ vọng với mục tiêu" />
      <OnboardingTarget />
    </>
  )
}
export function OnboardingRiskAppetitePage() {
  return (
    <>
      <MetaDecorator title="Đánh giá mức độ chịu đựng rủi ro" />
      <OnboardingRiskAppetite />
    </>
  )
}
export function OnboardingInvestmentPlanPage() {
  return (
    <>
      <MetaDecorator title="Gợi ý tỉ lệ danh mục phù hợp" />
      <OnboardingInvestmentPlan />
    </>
  )
}
export function OnboardingAnalyticPage() {
  return (
    <>
      <MetaDecorator title="Kế hoạch chương trình tích lũy phù hợp" />
      <OnboardingAnalytic />
    </>
  )
}
