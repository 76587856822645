import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_PRODUCT_DETAIL_API = 'EMPLOYEE/FETCH_PRODUCT_DETAIL_API'

const apiCall = enhancedMakeFetchAction(FETCH_PRODUCT_DETAIL_API, ({ id }) => ({
  endpoint: `${API_BASE_URL}/investcore-api/app/v2/products/${id}`,
  method: 'GET'
}))

export default apiCall
