import { Tag } from 'antd'

function UserVerificationStatus({ status }) {
  if (status === 'confirmed') {
    return <Tag color="yellow">Chờ duyệt</Tag>
  }
  if (!status) {
    return <Tag color="red">Chưa xác thực</Tag>
  }

  return <Tag color="green">Đã xác thực</Tag>
}

export default UserVerificationStatus
