import _ from 'lodash'

const defaultLocale = 'en-US'

// Documentation, please refer to this for more options
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat

export const formatNumber = (value, options = {}) => {
  // setting default locale is US. TODO: set default locale by user's localization.
  // It means that user's language is en, locale will be en-US. Otherwise, it will be vi-VN if the language is VN
  const { locale = defaultLocale, ...others } = options
  return new Intl.NumberFormat(locale, {
    ...others // other options
  }).format(value)
}

export const formatCurrency = (value, options = {}) => {
  // setting default locale is US. TODO: set default locale by user's localization.
  // It means that user's language is en, locale will be en-US. Otherwise, it will be vi-VN if the language is VN

  if (!value && value !== 0) return null

  const {
    locale = defaultLocale,
    currency = 'VND',
    currencyDisplay = 'code',
    ...others // other options
  } = options

  // if (suffixCurrencySymbol) {
  //   const resolvedOptions = new Intl.NumberFormat(locale, {
  //     style: 'currency',
  //     currency
  //   }).resolvedOptions()
  //   const currencyOptions = {
  //     minimumFractionDigits: resolvedOptions.minimumFractionDigits,
  //     maximumFractionDigits: resolvedOptions.maximumFractionDigits
  //   }
  //   return value.toLocaleString(locale, currencyOptions)
  // }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    currencyDisplay: currencyDisplay,
    ...others
  }).format(value)
}

export const roudingDownNumber = (value, digits) => {
  return _.floor(value, digits)
}

export const roudingUpNumber = (value, digits) => {
  return _.round(value, digits)
}

export const formatUnitQuantity = (value, options = {}) =>
  formatNumber(roudingDownNumber(value, 2), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    trailingZeroDisplay: 'auto',
    ...options
  })

export const formatPercentage = (value, options = {}) =>
  formatNumber(value / 100, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options
  })

export const nFormatter = value =>
  //use vi-VN for VN text (triệu) and replace comma with Dot
  new Intl.NumberFormat('vi-VN', {
    notation: 'compact',
    compactDisplay: 'long'
  })
    .format(value)
    ?.replace(/\,/g, '.')
