import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

export const POST_VERIFY_RESET_PASSWORD = 'EMPLOYEE/POST_VERIFY_RESET_PASSWORD'

const apiCall = enhancedMakeFetchAction(
  POST_VERIFY_RESET_PASSWORD,
  payload => ({
    endpoint: `${API_BASE_URL}/investcore-api/app/v1/users/verify-reset-pwd-verification-code`,
    method: 'POST',
    body: payload,
    notify: true
  })
)

export default apiCall
