import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

export const USER_PROGRAM_ORDER_CREATE_BUY_OTP =
  'EMPLOYEE/USER_PROGRAM_ORDER_CREATE_BUY_OTP'

const apiCall = enhancedMakeFetchAction(
  USER_PROGRAM_ORDER_CREATE_BUY_OTP,
  ({ id }) => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/investcore-api/app/v2/user-programs/${id}/program-orders/buy-otp`
    }),
    method: 'POST',
    notify: true
  })
)

export default apiCall
