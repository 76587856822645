import apiCall from 'business/apiCalls/employee'
import MetaDecorator from 'common/layout/MetaDecorator'
import PageTitle from 'common/layout/PageTitle'
import CreateForm from './CreateForm'
import EditForm from './EditForm'
import EmployeeDetail from './EmployeeDetail'

export const EmployeeCreatePage = () => {
  const title = 'Tạo nhân viên mới'
  return (
    <div className="max-w-6xl mx-auto">
      <MetaDecorator title={title} />
      <PageTitle title={title} />
      <CreateForm apiCall={apiCall} />
    </div>
  )
}
export const EmployeeEditPage = () => {
  const title = 'Chỉnh sửa thông tin nhân viên'
  return (
    <div className="max-w-6xl mx-auto">
      <MetaDecorator title={title} />
      <EditForm apiCall={apiCall} title={title} />
    </div>
  )
}

export const EmployeeDetailPage = () => {
  return (
    <>
      <MetaDecorator title={'Chi tiết nhân viên'} />
      <EmployeeDetail />
    </>
  )
}
