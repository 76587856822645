import { useFetchApiGet } from '@stagapps/redux-utils'
import { Button, Typography } from 'antd'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import apiCall from 'app/apiCalls/verification/getUserIdentifications'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import KYCSteps from './KYCSteps'
import ModalCamera from './ModalCamera'

const { Title } = Typography

function ConfirmIDPage() {
  const { data, load, isLoading, error, refresh } = useFetchApiGet(apiCall, {
    resourceName: 'data'
  })

  useEffect(() => {
    load()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  const { identification_images } = data || {}
  const { front_id_card, back_id_card, selfie } = identification_images || {}
  const selfieStatus = selfie?.status
  const frontIdCardStatus = front_id_card?.status
  const backIdCardStatus = back_id_card?.status

  const disabled = !(
    selfieStatus === 'succeeded' &&
    frontIdCardStatus === 'succeeded' &&
    backIdCardStatus === 'succeeded'
  )

  return (
    <div>
      <KYCSteps current={1} />
      <UICard className="w-fit mx-auto">
        <Title level={4}>Xác thực khuôn mặt</Title>

        <ModalCamera
          title={'CMND/CCCD mặt trước'}
          type={'2'}
          initialData={front_id_card}
          callback={refresh}
        />
        <ModalCamera
          title={'CMND/CCCD mặt sau'}
          type={'3'}
          initialData={back_id_card}
          callback={refresh}
        />
        <ModalCamera
          title={'Hình chân dung'}
          type={'1'}
          initialData={selfie}
          callback={refresh}
          isUpload={false}
        />
        <Link to="/verification/personal-information">
          <Button disabled={disabled} type="primary" className="w-full">
            Tiếp tục
          </Button>
        </Link>
      </UICard>
    </div>
  )
}

export default ConfirmIDPage
