import apiCall from 'business/apiCalls/fundOrder'
import MetaDecorator from 'common/layout/MetaDecorator'
import PageTitle from 'common/layout/PageTitle'
import FundOrderDetail from './FundOrderDetail'
import FundOrderList from './FundOrderList'

export const FundOrderListPage = () => {
  const title = 'Quản lý lệnh'
  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle title={title} />
      <FundOrderList apiCall={apiCall} />
    </>
  )
}

export const FundOrderDetailPage = () => {
  return (
    <>
      <MetaDecorator title={'Chi tiết lệnh của quỹ'} />
      <FundOrderDetail apiCall={apiCall} />
    </>
  )
}
