import { Tabs } from 'antd'
import { useSearchParams } from 'react-router-dom'

function UIQueryTabs({
  defaultQuery = '',
  onChange = () => {},
  keyName = 'key',
  ...props
}) {
  let [searchParams, setSearchParams] = useSearchParams()
  const query = searchParams.get(keyName) || defaultQuery

  return (
    <Tabs
      defaultActiveKey={query}
      activeKey={query}
      onChange={key => {
        const params = { [keyName]: key }
        setSearchParams(key ? params : '', { replace: true })
        onChange(key)
      }}
      {...props}
    />
  )
}

export default UIQueryTabs
