import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'

import { SimpleHeader } from 'app/components/AppHeader'

const LayoutRegister = () => (
  <Layout>
    <SimpleHeader />
    <div className="min-h-screen md:min-w-[40%] md:max-w-[45%] mx-auto md:py-10 py-4 md:px-6 px-2 flex flex-col items-stretch md:gap-8 gap-4">
      <Outlet />
    </div>
  </Layout>
)

export default LayoutRegister
