export function convertIntervalToMonthAndYear(interval) {
  if (isNaN(interval) || interval === null || interval === undefined) {
    return { years: 0, months: 0 }
  }
  const years = Math.floor(interval / 12)
  const months = interval % 12
  return { years, months }
}

export function convertYearMonthToInterval(years, months) {
  if (
    [years, months].some(
      value => isNaN(value) || value === null || value === undefined
    )
  ) {
    return 0
  }
  const interval = years * 12 + months
  return interval
}
