export function nullException(value) {
  if (value === null) {
    throw {
      name: 'null',
      message: 'Null! Please input string or number'
    }
  }
}

export function undefinedException(value) {
  if (value === undefined) {
    throw {
      name: 'undefined',
      message: 'Undefined! Please input string or number'
    }
  }
}

export function exceptions(value) {
  nullException(value)
  undefinedException(value)
}
