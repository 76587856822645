import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, Select, Space, Typography } from 'antd'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { generateEndpoint } from '@stagapps/redux-utils'
import UICard from 'common/ui/display/UICard'
import UIInputFormatNumber from 'common/ui/display/UIInputFormatNumber'
import ValidButton from 'common/ui/form/ValidButton'
import { isEmpty } from 'lodash'
import qs from 'qs'
import { useState } from 'react'
import { convertDateFormData, ensureDateFields } from 'utils/formData'
import {
  convertIntervalToMonthAndYear,
  convertYearMonthToInterval
} from 'utils/formatDate/convertInterval'
import { calculateEndDate, getStartDate } from 'utils/formatDate/getDate'
import { cloudflareCdn } from 'utils/image'

const { Title } = Typography
const dateFields = ['start_date']

function OnboardingTarget() {
  const [form] = Form.useForm()
  const { monthly_order_payment, months, years } = Form.useWatch([], form) || {}
  const start_date = !!monthly_order_payment
    ? getStartDate({ monthly_order_payment })
    : new Date().toISOString()
  const end = calculateEndDate(start_date, parseInt(years), parseInt(months))
  const investment_interval = convertYearMonthToInterval(years, months)
  const checkInterval = investment_interval > 0

  const navigate = useNavigate()
  let [_, setSearchParams] = useSearchParams()
  const queryParams = qs.parse(window.location.search.substring(1))
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  if (isEmpty(queryParams)) {
    navigate('/goals/new/theme')
  }

  const { title, ...initialValues } = {
    ...queryParams
  }
  const { investment_interval: initInvestmentInterval } = initialValues
  const src = cloudflareCdn(`/futureflex/images/onboarding/8.jpg`, 300, 300)
  const onFinish = values => {
    navigate(
      generateEndpoint({
        host: '/goals/new/portfolio',
        params: convertDateFormData(
          ensureDateFields(
            {
              ...queryParams,
              ...values,
              start_date,
              investment_interval
            },
            dateFields
          ),
          dateFields
        )
      })
    )
  }

  const timeRange = initInvestmentInterval
    ? convertIntervalToMonthAndYear(initInvestmentInterval)
    : { years: 1, months: 0 }

  return (
    <>
      <Form
        requiredMark={false}
        form={form}
        initialValues={{
          ...initialValues,
          ...timeRange
        }}
        onFinish={onFinish}
        labelCol={{ span: 24 }}
        size="large"
      >
        <div className="flex items-center flex-col md:gap-10 gap-4">
          <div className="text-center">
            <Title level={3}>Xác định kỳ vọng với mục tiêu</Title>
            <p className="text-sm">Mô tả chi tiết hơn về mục tiêu của bạn</p>
          </div>

          <UICard className="text-sm">
            <div>
              <Link to="/goals/new/theme">
                <Space>
                  <ArrowLeftOutlined />
                  <div>Trở về</div>
                </Space>
              </Link>

              <div className="flex md:flex-row flex-col-reverse gap-8">
                <div>
                  <div className="flex gap-4 items-baseline">
                    <Title level={3}>{title}</Title>
                    <Button onClick={showModal} type="text">
                      <EditOutlined
                        style={{ fontSize: 27, color: '#0066F5' }}
                      />
                    </Button>
                  </div>

                  <div className="flex md:gap-8 md:flex-row flex-col flex-grow">
                    <div className="flex-grow">
                      <Form.Item
                        label="Số tiền để thực hiện mục tiêu"
                        name="target_amount"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập'
                          }
                        ]}
                      >
                        <UIInputFormatNumber
                          addonAfter="VND"
                          className="w-full"
                        />
                      </Form.Item>
                      <div className="flex md:flex-row flex-col md:items-end gap-4">
                        <Form.Item
                          validateStatus={checkInterval ? null : 'error'}
                          hasFeedback
                          className="md:w-1/2"
                          label="Thời hạn đạt mục tiêu"
                          name="years"
                        >
                          <UIInputFormatNumber
                            className="w-full"
                            addonAfter="Năm"
                            placeholder="0-99"
                            min={0}
                            max={99}
                          />
                        </Form.Item>
                        <Form.Item
                          validateStatus={checkInterval ? null : 'error'}
                          hasFeedback
                          className="md:w-1/2"
                          label=""
                          name="months"
                        >
                          <UIInputFormatNumber
                            className="w-full"
                            addonAfter="Tháng"
                            placeholder="0-11"
                            min={0}
                            max={11}
                          />
                        </Form.Item>
                      </div>
                      <div className="-mt-4">
                        {checkInterval ? (
                          `đến tháng ${end}`
                        ) : (
                          <div className="text-red-500 text-xs">
                            Thời hạn đạt mục tiêu ít nhất là 1 tháng
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="flex-grow">
                      <Form.Item
                        label="Số tiền có thể tích lũy hằng tháng"
                        name="contribute_amount"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập'
                          }
                        ]}
                      >
                        <UIInputFormatNumber
                          addonAfter="VND"
                          className="w-full"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Ngày tích lũy hằng tháng"
                        name="monthly_order_payment"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập'
                          }
                        ]}
                      >
                        <Select
                          className="w-full"
                          options={generateArray(28)}
                        />
                      </Form.Item>
                      {/* <Form.Item
                        label={
                          <Space>
                            <div>Số vốn ban đầu</div>
                            <UIInfoTooltip
                              size={12}
                              title={
                                'Số tiền vốn bạn đang có sẵn để có thể tham gia vào mục tiêu tích lũy này ngay lập tức.'
                              }
                            />
                          </Space>
                        }
                        name="initial_amount"
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập'
                          }
                        ]}
                      >
                        <UIInputFormatNumber
                          addonAfter="VND"
                          className="w-full"
                        />
                      </Form.Item> */}
                    </div>
                  </div>
                </div>
                <img src={src} className="md:w-fit w-full rounded-xl" />
              </div>
            </div>
          </UICard>

          <Form.Item>
            <Space size={'large'}>
              <Link to="/goals/new/theme">
                <Button type="primary" ghost size="large">
                  <div className="px-4">Trở về</div>
                </Button>
              </Link>
              <ValidButton
                disabled={!checkInterval}
                form={form}
                btnTitle={<div className="px-4">Tiếp tục</div>}
                size="large"
                type="primary"
                htmlType="submit"
              />
            </Space>
          </Form.Item>
        </div>
      </Form>
      <Modal
        title="Đổi tên mục tiêu"
        open={isModalOpen}
        autoFocusButton={null}
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          requiredMark={false}
          initialValues={{ title }}
          onFinish={values => {
            setSearchParams({ ...queryParams, ...values })
            handleCancel()
          }}
        >
          <Form.Item name="title">
            <Input autoFocus />
          </Form.Item>
          <div className="py-4 flex justify-end">
            <Form.Item>
              <Button htmlType="submit" type="primary" className="">
                Xác nhận đổi tên
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  )
}

function generateArray(count) {
  const result = []
  for (let i = 1; i <= count; i++) {
    result.push({ value: i, label: `Ngày ${i}` })
  }
  return result
}

export default OnboardingTarget
