import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import debounce from 'lodash/debounce'
import { useMemo, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

function DebounceSearchInput({ debounceTimeout = 300, load, ...props }) {
  let [filterParams, setSearchParams] = useSearchParams()

  const fetchRef = useRef(0)
  const debounceFetcher = useMemo(() => {
    const loadOptions = value => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      if (value) {
        const params = { search: value }
        const p = JSON.parse(JSON.stringify(params))
        load(params)
        setSearchParams(p)
      } else {
        load()
        setSearchParams()
      }
    }
    return debounce(loadOptions, debounceTimeout)
  }, [load, debounceTimeout])

  return (
    <div>
      <Input
        className="w-1/4"
        onChange={e => debounceFetcher(e.target.value)}
        placeholder="Tìm tên nhân viên"
        prefix={
          <SearchOutlined style={{ fontSize: '0.75rem', color: '#5A6F93' }} />
        }
        {...props}
      />
    </div>
  )
}

export default DebounceSearchInput
