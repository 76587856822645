import { Alert, Button } from 'antd'

const ErrorMessage = props => {
  const { errors, history } = props

  if (!errors) {
    return (
      <div id="content">
        <div className="container">Something went wrong. Please try again.</div>
      </div>
    )
  }

  const { error } = errors

  return (
    <Alert
      message={error || errors}
      type="warning"
      showIcon
      action={<Button onClick={() => window.location.reload()}>Tải lại</Button>}
    />
  )
}

export default ErrorMessage
