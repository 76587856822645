import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'

import { API_BASE_URL } from 'config/env.config'

export const GET_USER_ID = 'EMPLOYEE/GET_USER_ID'

const apiCall = enhancedMakeFetchAction(GET_USER_ID, () => ({
  endpoint: generateEndpoint({
    host: `${API_BASE_URL}/investcore-api/app/v2/identifications`
  }),

  method: 'GET'
}))

export default apiCall
