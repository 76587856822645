import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { useFetchApiGet, useFetchApiList } from '@stagapps/redux-utils'
import { Divider, Table, Tag, Typography } from 'antd'
import apiEmployeeProgramPortfolioCall from 'app/apiCalls/employeeProgram/portfolio'
import epPortfolioApiCall from 'app/apiCalls/employeeProgramPortfolio'
import apiEmployeeProgramOrdersCall from 'app/apiCalls/orders/employeeProgramOrders'
import apiPortProductsCall from 'app/apiCalls/port/products'
import QRDownload from 'common/cta/QRDownload'
import StoreDownload from 'common/cta/StoreDownload'
import MetaDecorator from 'common/layout/MetaDecorator'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import UIInfoTooltip from 'common/ui/display/UIInfoTooltip'
import { getChangeValueClassColor } from 'config/color'
import {
  employeeProgramStatus,
  employeeProgramStatusColors
} from 'data/employeeProgram'
import { employeeProgramOrderStatusColor } from 'data/employeeProgramOrder'
import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { formatCurrency, formatPercentage } from 'utils/formatAmount'
import { formatCurrencyWithoutCurrencySymbol } from 'utils/formatAmount/formatUI'
import { formatDate } from 'utils/formatDate'
import { cloudflareCdn } from 'utils/image'
import { formatTableColumns } from 'utils/tableConfig'
import ContributedColumnChart from './employeeProgram/ContributedColumnChart'
import { EmptyEmployeeProgram } from './employeeProgram/EmployeeProgramListPage'
import { EmptyEmployeeProgramOderListSVG } from './svg'

const { Title } = Typography

export function HomePage() {
  return (
    <>
      <MetaDecorator title="Tổng quan" />
      <Home />
    </>
  )
}

const Home = () => {
  let [searchParams, setSearchParams] = useSearchParams()
  const query = searchParams.get('id')

  const [expandedRowKeys, setExpandedRowKeys] = useState(
    query ? [parseInt(query)] : []
  )

  const handleExpand = (expanded, record) => {
    const { id } = record?.employee_program || {}
    if (expanded) {
      setExpandedRowKeys([id])
      setSearchParams({ id })
    } else {
      setExpandedRowKeys([])
    }
  }

  const {
    data: port,
    load: loadPort,
    isLoading: isLoadingPort,
    error: errorPort
  } = useFetchApiGet(apiPortProductsCall, {
    resourceName: 'data'
  })

  const {
    data: employeeProgramPortfolio,
    load: loadEmployeeProgramPortfolio,
    isLoading: isLoadingEmployeeProgramPortfolio,
    error: errorEmployeeProgramPortfolio
  } = useFetchApiList(apiEmployeeProgramPortfolioCall, {
    resourceName: 'data'
  })

  const {
    data: employeeProgramOrders,
    load: loadEmployeeProgramOrders,
    isLoading: isLoadingEmployeeProgramOrders,
    error: errorEmployeeProgramOrders
  } = useFetchApiList(apiEmployeeProgramOrdersCall, {
    resourceName: 'data'
  })

  useEffect(() => {
    loadPort()
    // loadEmployees()
    loadEmployeeProgramPortfolio()
    loadEmployeeProgramOrders()
  }, [])

  const isLoading =
    isLoadingPort ||
    isLoadingEmployeeProgramPortfolio ||
    isLoadingEmployeeProgramOrders
  const error =
    errorPort || errorEmployeeProgramOrders || errorEmployeeProgramPortfolio

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorMessage errors={error} />
  }

  const {
    total_market_value,
    total_gain_loss_amount,
    total_gain_loss_percentage
  } = port || {}

  const changeColor = total_market_value
    ? getChangeValueClassColor(total_gain_loss_amount)
    : ''

  const hasEmployeeProgram =
    employeeProgramPortfolio && employeeProgramPortfolio.length > 0

  return (
    <div className="flex flex-col gap-4">
      <div className="flex md:flex-row flex-col gap-4">
        <UICard
          className="md:w-2/5 w-full"
          title={'Tổng danh mục (VND)'}
          extraTitle={
            <UIInfoTooltip
              title={
                'Tổng danh mục bao gồm phần tài sản thuộc chương trình FutureFlex và phần sở hữu cá nhân ở các kế hoạch tích lũy của riêng bạn. Vui lòng truy cập app Stag Invest để theo dõi chi tiết.'
              }
            />
          }
        >
          <Title level={3}>
            {formatCurrencyWithoutCurrencySymbol(total_market_value)}
          </Title>
          <Divider />
          <div className="text-base flex flex-col gap-3">
            <div className="flex flex-row justify-between gap-4">
              <div>Lãi/lỗ (%)</div>
              <div className={'font-semibold ' + changeColor}>
                {total_market_value
                  ? formatPercentage(total_gain_loss_percentage)
                  : '-'}
              </div>
            </div>
            <div className="flex flex-row justify-between gap-4">
              <div>Lãi/lỗ (VND)</div>
              <div className={'font-semibold ' + changeColor}>
                {total_market_value
                  ? formatCurrencyWithoutCurrencySymbol(total_gain_loss_amount)
                  : '-'}
              </div>
            </div>
          </div>
        </UICard>
        {hasEmployeeProgram ? (
          <UICard
            className="md:w-3/5 w-full"
            title={'Lệnh FutureFlex gần nhất'}
          >
            {employeeProgramOrders?.length > 0 ? (
              <EmployeeProgramOderList orders={employeeProgramOrders} />
            ) : (
              <EmptyEmployeeProgramOderList />
            )}
          </UICard>
        ) : (
          <UICard className="md:w-3/5 w-full" title={'FutureFlex là gì'}>
            <OnboardEmployeeProgramOderList />
          </UICard>
        )}
      </div>

      <UICard title={'Danh sách chương trình'}>
        {hasEmployeeProgram ? (
          <Table
            className="overflow-x-scroll"
            columns={formatTableColumns(employeeProgramsColumns)}
            dataSource={employeeProgramPortfolio}
            pagination={false}
            loading={isLoading}
            rowKey="program_id"
            expandable={{
              expandedRowKeys,
              onExpand: handleExpand,
              expandedRowRender: record => {
                const { id } = record?.employee_program || {}
                if (expandedRowKeys.includes(id)) {
                  return <ExpandedRow record={record} />
                } else return null
              },

              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <DownOutlined onClick={e => onExpand(record, e)} />
                ) : (
                  <RightOutlined onClick={e => onExpand(record, e)} />
                )
            }}
          />
        ) : (
          <EmptyEmployeeProgram />
        )}
      </UICard>

      <div className="bg-[#ECEFFE] flex md:flex-row flex-col justify-between shadow-custom border rounded-lg md:px-12 px-4 pt-6 gap-4">
        <div className="">
          <Title level={2}>Đầu tư an toàn, bền vững, tự động</Title>
          <p className="md:w-[555px] text-base">
            <>
              {'Công cụ đặt mục tiêu, lập kế hoạch tích lũy định kỳ dễ dàng'}
              <br />
              {
                'Tăng trưởng hiệu quả với gợi ý đầu tư thông minh được cá nhân hóa'
              }
            </>
          </p>
          <div className="flex flex-row gap-4 items-center">
            <QRDownload />
            <StoreDownload />
          </div>
        </div>
        <img
          className="md:w-[409px] aspect-[409/324] md:px-2"
          src={cloudflareCdn(
            '/futureflex/images/home/CTA_download.png',
            409,
            324
          )}
          alt={'download_app'}
        />
      </div>
    </div>
  )
}

const OnboardEmployeeProgramOderList = () => (
  <div className="flex flex-col gap-6">
    <div>
      Chương trình phúc lợi đầu tư giúp doanh nghiệp giữ chân nhân sự tài năng,
      gắn kết và đồng hành cùng nhân viên xây dựng sức khỏe tài chính bền vững.
    </div>
    <div className="flex md:flex-nowrap	gap-1 justify-between flex-wrap">
      {onboardItems.map(({ text, url }, index) => (
        <div key={index} className="flex flex-col gap-4 w-44 items-center">
          <img
            width={56}
            height={56}
            src={cloudflareCdn(url, 56, 56)}
            alt={'flex_onboarding'}
          />

          <div className="text-description text-center">{text}</div>
        </div>
      ))}
    </div>
  </div>
)

const EmptyEmployeeProgramOderList = () => (
  <div className="flex py-4 justify-center">
    <div className="flex flex-col gap-2 max-w-xs items-center">
      <div className="w-48 aspect-square">
        <EmptyEmployeeProgramOderListSVG />
      </div>
      <p className="text-description text-center">
        Hiện tại chưa có lệnh. Bạn có thể theo dõi lệnh từ chương trình
        FutureFlex tại đây.
      </p>
    </div>
  </div>
)

const EmployeeProgramOderList = ({ orders }) => {
  return (
    <div className="flex flex-col gap-4">
      {orders.splice(0, 2).map((order, index) => {
        const {
          id,
          product,
          product_code,
          order_side,
          amount,
          created_at,
          status,
          status_label
        } = order || {}
        const { logo } = product || {}

        return (
          <Link key={index} to={`/program-orders/${id}`}>
            <UICard size="small">
              <div className="flex justify-between gap-4">
                <div className="flex gap-2 items-center">
                  <img width={40} className="object-contain" src={logo} />
                  <div>
                    <div className="text-base font-semibold">
                      {product_code}
                    </div>
                    {order_side === 'company' ? (
                      <div className="text-secondary">
                        Doanh nghiệp đóng góp
                      </div>
                    ) : null}
                    {order_side === 'employee' ? (
                      <div className="text-secondary">Nhân viên tích lũy</div>
                    ) : null}
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex md:flex-row flex-col-reverse text-right md:gap-4 md:items-center items-end">
                    <div>
                      <div className="text-base font-semibold">
                        {amount ? formatCurrency(amount) : '-'}
                      </div>
                      {created_at ? (
                        <div className="text-secondary">
                          {formatDate(created_at)}
                        </div>
                      ) : null}
                    </div>
                    <Tag color={employeeProgramOrderStatusColor[status]}>
                      {status_label}
                    </Tag>
                  </div>

                  <RightOutlined className="text-blue-primary-500 text-xl" />
                </div>
              </div>
            </UICard>
          </Link>
        )
      })}
    </div>
  )
}

const onboardItems = [
  {
    text: 'Giúp nhân viên xây dựnng thói quen tích lũy đều đặn',
    url: '/futureflex/icons/flex_onboarding_1.jpg'
  },
  {
    text:
      'Có sự đồng hành của doanh nghiệp trong hành trình thực hiện mục tiêu tài chính',
    url: '/futureflex/icons/flex_onboarding_2.jpg'
  },
  {
    text: 'Tiếp cận kênh đầu tư an toàn, bền vững và tự động vào chứng chỉ quỹ',
    url: '/futureflex/icons/flex_onboarding_3.jpg'
  },
  {
    text:
      'Được tham gia miễn phí workshop bổ ích về quản lý tài chính và đầu tư thông minh',
    url: '/futureflex/icons/flex_onboarding_4.jpg'
  }
]

export const employeeProgramsColumns = [
  {
    title: 'Mã chương trình',
    dataIndex: 'employee_program',
    render: employeeProgram => (
      <Link
        to={`/employees/${employeeProgram?.employee_id}/programs/${employeeProgram?.id}`}
      >
        {employeeProgram?.code}
      </Link>
    )
  },
  {
    title: 'Doanh nghiệp',
    dataIndex: ['employee_program', 'company', 'name']
  },
  {
    title: 'Hạn chuyển giao',
    dataIndex: ['employee_program', 'end_date'],
    type: 'date'
  },
  {
    title: 'Giá trị hiện tại',
    dataIndex: ['total_summary', 'market_value'],
    render: value => (!!value ? formatCurrency(value) : '-'),
    align: 'right'
  },
  {
    title: 'Trạng thái',
    dataIndex: ['employee_program', 'status'],
    render: status => {
      return (
        <Tag color={employeeProgramStatusColors[status]}>
          {employeeProgramStatus[status]}
        </Tag>
      )
    }
  }
]

const ExpandedRow = ({ record }) => {
  const { id: programId, employee_id: employeeId } =
    record?.employee_program || {}
  const {
    data: portfolio,
    load: fetchPort,
    isLoading: isLoadingPort,
    error: errorPort
  } = useFetchApiGet(epPortfolioApiCall.portfolio, { resourceName: 'data' })

  useEffect(() => {
    fetchPort({ id: employeeId, programId })
  }, [JSON.stringify(record)])

  const isLoading = isLoadingPort
  const error = errorPort

  if (isLoading) {
    return <LoadingSpinner />
  }
  if (!!error) {
    return <ErrorMessage errors={error} />
  }
  const { projected_program_amount } = portfolio || {}
  return (
    <div className="flex md:flex-row flex-col gap-4">
      <UICard title="Biểu đồ giá trị vốn đầu tư" className="flex-grow">
        <ContributedColumnChart
          projected_program_amount={projected_program_amount}
        />
      </UICard>
    </div>
  )
}
