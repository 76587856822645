import { select, takeEvery } from 'redux-saga/effects'
import SessionAction from 'state/action/session'
import { sessionSelector } from 'state/reducer/session.reducers'

function* onSessionChange() {
  try {
    const session = yield select(sessionSelector)
    yield localStorage.setItem('session', JSON.stringify(session))
  } catch (error) {
    console.error(error)
  }
}

function* watchOnSessionChange() {
  yield takeEvery(
    [SessionAction.SET_STAG_USER, SessionAction.UPDATE_STAG_USER],
    onSessionChange
  )
}

export function* sessionSaga() {
  yield watchOnSessionChange()
}
