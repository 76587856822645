import { ConfigProvider, Steps } from 'antd'
import useCheckMobileDevice from 'hooks/useCheckMobileDevice'

const KYCSteps = ({ current = 0, size, ...props }) => {
  const { isMobile } = useCheckMobileDevice()
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#52C41A'
        }
      }}
    >
      <Steps
        type={isMobile ? 'inline' : 'default'}
        size={size}
        className="mb-8"
        current={current}
        labelPlacement="vertical"
        progressDot
        items={[
          {
            title: 'Số điện thoại'
          },
          {
            title: 'Định danh'
          },
          {
            title: 'Thông tin ngân hàng'
          },
          {
            title: 'Hoàn tất'
          }
        ]}
        {...props}
      />
    </ConfigProvider>
  )
}
export default KYCSteps
