import { InfoCircleOutlined } from '@ant-design/icons'
import { usePostApi } from '@stagapps/redux-utils'
import {
  Button,
  ConfigProvider,
  Divider,
  Form,
  InputNumber,
  Modal,
  Result,
  Space,
  Statistic,
  Steps
} from 'antd'
import redeemEstimateApiCall from 'app/apiCalls/employeeProgramOrder/redeemEstimate'
import sellApiCall from 'app/apiCalls/employeeProgramOrder/sell'
import sellOtpApiCall from 'app/apiCalls/employeeProgramOrder/sellOtp'
import ValidButton from 'common/ui/form/ValidButton'
import { getChangeValueColor } from 'config/color'
import useCheckAuth from 'hooks/auth/useCheckAuth'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { formatCurrency, formatUnitQuantity } from 'utils/formatAmount'
import { convertEmailCharacters } from 'utils/hideCharacter'

const { Countdown } = Statistic

const SELL_STEPS = [
  {
    title: 'Nhập số lượng',
    content: 'First-content'
  },
  {
    title: 'Xác thực OTP',
    content: 'Second-content'
  },
  {
    title: 'Hoàn tất',
    content: 'Last-content'
  }
]

const SellModal = ({ data, refresh }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [current, setCurrent] = useState(0)
  const params = useParams()
  const { programId } = params || {}
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const {
    data: sellOrder,
    post: createSellOrder,
    isLoading: isSubmitting,
    error: errorSell
  } = usePostApi(sellApiCall, {
    resourceName: 'data'
  })

  useEffect(() => {
    if (current === 0) {
      dispatch(redeemEstimateApiCall.disposer())
      dispatch(sellOtpApiCall.disposer())
    }

    dispatch(sellApiCall.disposer())
  }, [current])

  useEffectSkipFirstRender(() => {
    if (!isSubmitting && !!sellOrder) {
      setCurrent(2)
      refresh()
    }
  }, [isSubmitting])

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Bán
      </Button>
      <Modal
        title="Đăt lệnh bán"
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        autoFocusButton={null}
      >
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#52C41A'
            }
          }}
        >
          <Steps
            className="mb-3"
            size="small"
            current={current}
            items={SELL_STEPS.map(item => ({
              key: item.title,
              title: item.title
            }))}
            progressDot
          />
        </ConfigProvider>
        <Form
          className="relative"
          requiredMark={false}
          onFinish={values => {
            createSellOrder({
              ...values,
              id: params.id,
              programId,
              employee_program_id: parseInt(params.programId),
              product_code: data.product_code,
              product_id: data.product_id
            })
          }}
          labelCol={{ span: 24 }}
          form={form}
        >
          <div style={{ display: current !== 0 ? 'none' : '' }}>
            <SellDetail
              setCurrent={setCurrent}
              handleCancel={handleCancel}
              form={form}
              {...data}
            />
          </div>
          <div style={{ display: current !== 1 ? 'none' : '' }}>
            <InputOTP
              setCurrent={setCurrent}
              handleCancel={handleCancel}
              form={form}
              error={errorSell}
              isSubmitting={isSubmitting}
              {...data}
            />
          </div>
          <div style={{ display: current !== 2 ? 'none' : '' }}>
            <Completed
              setCurrent={setCurrent}
              handleCancel={handleCancel}
              form={form}
              {...data}
            />
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default SellModal

const SellDetail = ({
  handleCancel,
  product,
  product_code,
  market_value,
  unrealized_gain_loss,
  available_quantity,
  form,
  setCurrent
}) => {
  const params = useParams()
  const { programId } = params || {}
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current.focus({
      cursor: 'start'
    })
  }, [])

  const {
    data: estimate,
    post: postRedeemEstimate,
    isLoading: isSubmittingRedeemEstimate,
    error: errorEstimate
  } = usePostApi(redeemEstimateApiCall, {
    resourceName: 'data'
  })
  const {
    data: sellOTP,
    post: postSellOTP,
    isLoading: isPostSellOTP,
    error: errorSellOTP
  } = usePostApi(sellOtpApiCall, {
    resourceName: 'data'
  })

  useEffectSkipFirstRender(() => {
    if (!isPostSellOTP && !!sellOTP) {
      setCurrent(1)
    }
  }, [isPostSellOTP])

  const error = errorEstimate || errorSellOTP
  const { error: errorMessage, error_code } = error || {}

  const { logo, alias, fund_type_name, redemption_fee, id: product_id } =
    product || {}

  const { estimate_amount, fee_amount, estimate_net_amount } = estimate || {}
  const smallGrayTextClass = 'text-sm text-[#5A6F93]'

  const handleInputChange = value => {
    const quantity = parseFloat(value)
    postRedeemEstimate({
      id: params.id,
      programId,
      quantity,
      product_code,
      product_id,
      employee_program_id: parseInt(programId)
    })
  }

  return (
    <div className="py-4 flex flex-col gap-4 text-base">
      <div className="flex md:flex-row flex-col gap-2 justify-between">
        <div>
          <Space>
            <img className="object-contain	w-10 " src={logo} />
            <div>
              <div className="font-semibold text-blue-primary-500">
                {alias || product_code}
              </div>
              <div className={smallGrayTextClass}>{fund_type_name}</div>
            </div>
          </Space>
        </div>
        <ConfigProvider
          theme={{
            components: {
              Statistic: {
                contentFontSize: 16
              }
            }
          }}
        >
          <Space size={'large'}>
            <Statistic
              title={
                <div className={smallGrayTextClass + 'w-full text-right'}>
                  {'Sở hữu'}
                </div>
              }
              value={market_value}
              formatter={e => formatCurrency(e, { currencyDisplay: 'symbol' })}
            />
            <Statistic
              valueStyle={{
                color: getChangeValueColor(unrealized_gain_loss)
              }}
              title={
                <div className={smallGrayTextClass + 'w-full text-right'}>
                  {'Lời/Lỗ'}
                </div>
              }
              value={unrealized_gain_loss}
              formatter={e => formatCurrency(e, { currencyDisplay: 'symbol' })}
            />
          </Space>
        </ConfigProvider>
      </div>

      <Form.Item
        validateStatus={!!error ? 'error' : null}
        hasFeedback
        help={errorMessage || error_code}
        label="Số lượng CCQ cần bán"
        name={'quantity'}
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập',
            type: 'quantity'
          }
        ]}
      >
        <InputNumber
          ref={inputRef}
          className="w-full"
          onChange={handleInputChange}
          placeholder={`Tối đa ${formatUnitQuantity(available_quantity)}`}
        />
      </Form.Item>

      <div className="flex flex-row justify-between">
        <div>Tương đương</div>
        <div>
          {estimate_amount
            ? formatCurrency(estimate_amount, { currencyDisplay: 'symbol' })
            : '-'}
        </div>
      </div>

      <div className="flex flex-row justify-between">
        <Space size={'small'}>
          <div> Phí bán</div>
          <InfoCircleOutlined />
        </Space>
        <div>
          {fee_amount
            ? formatCurrency(fee_amount, { currencyDisplay: 'symbol' })
            : '-'}
        </div>
      </div>

      <div className="overflow-hidden rounded-2xl text-sm">
        <div className="flex flex-row justify-between font-semibold text-white bg-blue-400 p-4 mb-0.5">
          <div>Thời gian nắm giữ</div>
          <div>Phí bán</div>
        </div>
        {redemption_fee.map(({ label, fee_text }) => (
          <div
            key={label}
            className="flex flex-row justify-between bg-blue-50 p-4 mb-0.5"
          >
            <div>{label}</div>
            <div>{fee_text}</div>
          </div>
        ))}
      </div>

      <div>
        <div className="text-gray-500 text-sm">
          *Phí bán khác nhau tuỳ thuộc Công ty quản lý quỹ
        </div>
        <Divider />
        <div className="flex flex-row justify-between">
          <div>Ước tính thực nhận</div>
          <div>
            {estimate_net_amount
              ? formatCurrency(estimate_net_amount, {
                  currencyDisplay: 'symbol'
                })
              : '-'}
          </div>
        </div>
      </div>
      <Form.Item className="self-end">
        <Space>
          <Button onClick={handleCancel}>Hủy</Button>
          <Button
            disabled={!!error}
            type="primary"
            loading={isPostSellOTP || isSubmittingRedeemEstimate}
            onClick={() => postSellOTP({ id: params.id, programId })}
          >
            Bán
          </Button>
        </Space>
      </Form.Item>
    </div>
  )
}

const InputOTP = ({ setCurrent, handleCancel, form, error, isSubmitting }) => {
  const { email } = useCheckAuth().currentUser || {}

  const { data: sellOTP } = usePostApi(sellOtpApiCall, {
    resourceName: 'data'
  })

  const { expires_at } = sellOTP || {}
  const { error: errorMessage, error_code } = error || {}

  return (
    <div className="text-center flex flex-col gap-6 py-4">
      <div>
        <div>Nhập mã OTP được gửi đến email của bạn</div>
        <div>{convertEmailCharacters(email)}</div>
      </div>
      <Form.Item
        validateStatus={!!error ? 'error' : null}
        hasFeedback
        help={errorMessage || error_code}
        name="otp"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập OTP'
          }
        ]}
      >
        <MuiOtpInput
          autoFocus
          length={6}
          sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
        />
      </Form.Item>
      <CountDownAndResendRequest expires={expires_at} setCurrent={setCurrent} />

      <div className="self-end">
        <Form.Item>
          <Space>
            <Button onClick={e => setCurrent(0)}>Trở về</Button>
            <ValidButton
              btnTitle="Xác thực OTP"
              form={form}
              htmlType="submit"
              type="primary"
              loading={isSubmitting}
            />
          </Space>
        </Form.Item>
      </div>
    </div>
  )
}

const Completed = ({ handleCancel }) => {
  return (
    <>
      <Result
        status="success"
        title="Hoàn tất đặt lệnh"
        subTitle="Theo dõi trạng thái tại Sổ lệnh"
      />
      <div className="flex justify-end">
        <Space>
          <Button onClick={handleCancel}>Trở về</Button>
          <Button type="primary" onClick={handleCancel}>
            Xem giao dịch
          </Button>
        </Space>
      </div>
    </>
  )
}

const CountDownAndResendRequest = ({ expires, setCurrent }) => {
  const [isTimeOut, setIsTimeOut] = useState(
    new Date(expires * 1000) <= new Date()
  )

  useEffect(() => {
    setIsTimeOut(new Date(expires * 1000) <= new Date())
  }, [expires])

  return (
    <div className="flex flex-col justify-center items-center">
      {isTimeOut ? (
        <p onClick={() => setCurrent(0)}>Mã OTP đã hết hạn</p>
      ) : (
        <Countdown
          value={expires * 1000}
          format="s"
          prefix={<p className="text-[#5A6F93]">Mã OTP hết hạn trong</p>}
          suffix="giây"
          valueStyle={{ color: '#EF4444', fontSize: '0.875rem' }}
          onFinish={() => setIsTimeOut(true)}
        />
      )}
    </div>
  )
}
