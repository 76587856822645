import { useFetchApiList } from '@stagapps/redux-utils'
import { Descriptions, Empty, Typography } from 'antd'
import { useEffect } from 'react'

import apiFundAccountCall from 'business/apiCalls/company/fundAccounts'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'

const { Title } = Typography
const { Item } = Descriptions

export function CompanyDetail({ data }) {
  const { name, name_en, tax_number, address, phone, email } = data || {}

  return (
    <UICard title="Thông tin doanh nghiệp" bordered={false}>
      <Descriptions bordered column={1}>
        <Item label="Tên doanh nghiệp">{name}</Item>
        <Item label="Tên nước ngoài">{name_en}</Item>
        <Item label="Mã số thuế Việt Nam">{tax_number}</Item>
        <Item label="Địa chỉ đăng ký của doanh nghiệp">{address}</Item>
        <Item label="Địa chỉ liên hệ">{address}</Item>
        <Item label="Số điện thoại">{phone}</Item>
        <Item label="Thư điện tử">{email}</Item>
      </Descriptions>
    </UICard>
  )
}

export function BankDetail({ data }) {
  const { bank_account_number, bank_name, bank_branch_name } = data || {}

  return (
    <UICard title="Thông tin tài khoản ngân hàng" bordered={false}>
      <Descriptions bordered column={1}>
        <Item label="Số TKNH">{bank_account_number}</Item>
        <Item label="Tên ngân hàng">{bank_name}</Item>
        <Item label="Chi nhánh">{bank_branch_name}</Item>
      </Descriptions>
    </UICard>
  )
}

export function AdminDetail() {
  return (
    <UICard title="Tài khoản quản lý" bordered={false}>
      <Empty description="Chưa có dữ liệu" />
    </UICard>
  )
}

export function FundAccountList() {
  const { data, load, isLoading, error } = useFetchApiList(apiFundAccountCall, {
    resourceName: 'data'
  })

  useEffect(() => {
    load()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  if (data?.length < 1) {
    return (
      <UICard title="Công ty quỹ đã mở tài khoản giao dịch" bordered={false}>
        <Empty description="Chưa có dữ liệu" />
      </UICard>
    )
  }

  return (
    <UICard title="Công ty quỹ đã mở tài khoản giao dịch" bordered={false}>
      <Descriptions bordered column={1}>
        {data.map(({ fund_manager_code, fund_manager_name }) => (
          <Item key={fund_manager_code} label={fund_manager_code.toUpperCase()}>
            {fund_manager_name}
          </Item>
        ))}
      </Descriptions>
    </UICard>
  )
}
