import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils'
import { Form, Input } from 'antd'
import { useEffect, useState } from 'react'

import apiCompanyDetailCall from 'business/apiCalls/company/detail'
import apiEmployeesCall from 'business/apiCalls/employee/list'
import apiCall from 'business/apiCalls/employee/program'
import apiScenarioCall from 'business/apiCalls/simulator/program'

import ScenarioProgramTable from 'business/components/ScenarioProgramTable'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UIDate from 'common/ui/display/UIDate'
import UIInputFormatNumber from 'common/ui/display/UIInputFormatNumber'
import DebounceSearchSelectList from 'common/ui/form/DebounceSearchSelectList'
import ReviewBeforeSubmitForm from 'common/ui/form/ReviewBeforeSubmitForm'
import { convertDateFormData } from 'utils/formData'
import { formatNumber } from 'utils/formatAmount'
import { ensureDateFormat } from 'utils/formatDate'

const dateFields = ['start_date']
const scenarioDateFields = ['start_date']
const removeList = ['full_name', 'monthly_order_payment']

function EmployeeProgramCreateForm() {
  const {
    data,
    isLoading: isSubmitting,
    post,
    error
  } = usePostApi(apiScenarioCall, { resourceName: 'data' })

  const {
    data: company,
    load: loadCompany,
    isLoading: isLoadingCompany,
    error: errorCompany
  } = useFetchApiGet(apiCompanyDetailCall, { resourceName: 'data' })

  const [addData, setAddData] = useState({})

  useEffect(() => {
    loadCompany()
  }, [])

  useEffect(() => {
    if (!isSubmitting) {
      if (!!data && !error) {
        const { employee_program } = data
        const { end_date } = employee_program || {}
        setAddData(prev => ({ ...prev, end_date }))
      }
    }
  }, [isSubmitting])

  if (isLoadingCompany) {
    return <LoadingSpinner />
  }
  const { monthly_order_payment } = company || {}
  const onReview = values => {
    const {
      start_date,
      monthly_order_payment,
      investment_interval,
      company_contribute_amount,
      employee_contribute_amount,
      id
    } = values

    post({
      payload: convertDateFormData(
        {
          monthly_order_payment,
          investment_interval,
          company_contribute_amount,
          employee_contribute_amount,
          start_date
        },
        scenarioDateFields
      )
    })
  }

  return (
    <div>
      <ReviewBeforeSubmitForm
        formName={'create-program'}
        initialValues={{ monthly_order_payment }}
        apiCall={apiCall}
        config={configGenerator({ setAddData, error: errorCompany })}
        reviewTitle={'Kiểm tra lại chi tiết chương trình'}
        formTitle={'Chi tiết chương trình'}
        submitButtonTitle={'Xác nhận tạo chương trình'}
        dateFields={dateFields}
        ExtraComponent={() => (
          <ScenarioProgramTable
            data={data}
            isSubmitting={isSubmitting}
            error={error}
          />
        )}
        extraData={data}
        onReview={onReview}
        extraReviewData={addData}
        removeList={removeList}
        confirmText={
          'Bằng nhấn nút, bạn sẽ xác nhận gửi lời mời cho nv tham gia.'
        }
      />
    </div>
  )
}

export default EmployeeProgramCreateForm

const configGenerator = ({ setAddData, error }) => {
  const { error: errorMessage, error_code } = error || {}

  return [
    {
      name: 'full_name',
      label: 'Họ tên nhân viên',
      FormItem: ({ name }) => (
        <Form.Item hidden name={name}>
          <Input disabled />
        </Form.Item>
      )
    },
    {
      name: 'id',
      FormItem: ({ name }) => (
        <Form.Item name={name} label={'Họ tên Nhân viên'}>
          <DebounceSearchSelectList
            onChange={(_, { selectedLabel }) =>
              setAddData(prev => ({ ...prev, full_name: selectedLabel }))
            }
            optionLabelProp="selectedLabel"
            placeholder="Chọn nhân viên"
            apiCall={apiEmployeesCall}
            displayFn={props => {
              const { full_name, department, email, id } = props || {}

              return {
                selectedLabel: full_name,
                value: id,
                label: (
                  <>
                    <div className="flex flex-row justify-between">
                      <span className="w-1/2">{full_name}</span>
                      <span className="w-1/4">{department}</span>
                      <span className="w-1/4">{email}</span>
                    </div>
                    <div className="border-solid border-0 border-b mt-3 border-b-gray-200" />
                  </>
                )
              }
            }}
          />
        </Form.Item>
      )
    },
    {
      name: 'start_date',
      formatter: t => t.format('DD/MM/YYYY'),
      label: 'Thời gian bắt đầu',
      FormItem: ({ label, name }) => (
        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              required: true,
              type: 'date',
              message: 'Vui lòng nhập'
            }
          ]}
        >
          <DatePicker
            minDate={ensureDateFormat(new Date())}
            sx={{ px: 1 }}
            openTo="year"
            views={['year', 'month', 'day']}
            format="DD/MM/YYYY"
            slotProps={{
              textField: { variant: 'standard' }
            }}
          />
        </Form.Item>
      )
    },
    {
      name: 'monthly_order_payment',
      label: 'Ngày tạo lệnh định kỳ hằng tháng',

      FormItem: ({ label, name }) => (
        <Form.Item
          name={name}
          label={label}
          validateStatus={!!error ? 'error' : null}
          help={errorMessage || error_code}
        >
          <UIInputFormatNumber disabled className="w-full" min={1} max={28} />
        </Form.Item>
      )
    },
    {
      name: 'investment_interval',
      label: 'Thời gian chương trình ( số kỳ tham gia )',
      FormItem: ({ label, name }) => (
        <Form.Item
          name={name}
          label={label}
          tooltip={{
            title:
              'Số kỳ tương đương với số tháng dương lịch. Hệ thống sẽ dựa theo số kỳ tham gia của chương trình để tạo lệnh mua chứng chỉ quỹ cho phần tích lũy của Nhân viên và phần đóng góp của Doanh nghiệp.'
          }}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập số',
              type: 'number'
            }
          ]}
        >
          <UIInputFormatNumber
            className="w-full"
            min={0}
            step={1}
            suffix={<span>kỳ đóng góp</span>}
          />
        </Form.Item>
      )
    },
    {
      name: 'end_date',
      formatter: value => <UIDate timeData={value} />,
      label: 'Thời điểm chuyển giao',
      FormItem: ({ name }) => (
        <Form.Item hidden name={name}>
          <Input disabled />
        </Form.Item>
      )
    },
    {
      name: 'employee_contribute_amount',
      label: 'Nhân viên tích lũy định kỳ',
      formatter: formatNumber,
      FormItem: ({ label, name }) => (
        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập số',
              type: 'number'
            }
          ]}
        >
          <UIInputFormatNumber
            className="w-full"
            addonAfter={'VND'}
            min={0}
            step={1000}
          />
        </Form.Item>
      )
    },
    {
      name: 'company_contribute_amount',
      label: 'Công ty đóng góp định kỳ',
      formatter: formatNumber,
      FormItem: ({ label, name }) => (
        <Form.Item
          name={name}
          label={label}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập số',
              type: 'number'
            }
          ]}
        >
          <UIInputFormatNumber
            className="w-full"
            addonAfter={'VND'}
            min={0}
            step={1000}
          />
        </Form.Item>
      )
    }
  ]
}
