import { ACTIONS } from '@stagapps/redux-utils'
import { eq, flow, get } from 'lodash/fp'
import { takeEvery } from 'redux-saga/effects'

import { POST_USER_UPDATE_PASSWORD } from 'app/apiCalls/account/updatePassword'

import { getErrorMessage } from 'utils/getErrorMessage'
import { openNotificationWithIcon } from 'utils/openNotificationWithIcon'

const isUpdatePassword = flow(
  get('payload.name'),
  eq(POST_USER_UPDATE_PASSWORD)
)

function* onUpdatePasswordSuccess(action) {
  if (isUpdatePassword(action)) {
    try {
      yield openNotificationWithIcon({
        type: 'success',
        message: 'Cập nhật mật khẩu thành công!'
      })
    } catch (error) {
      console.log('OnUpdatePasswordSuccessError', error)
    }
  }
}

function* onUpdatePasswordFailed(action) {
  if (isUpdatePassword(action)) {
    const error = get('payload.json')(action)

    yield openNotificationWithIcon({
      type: 'error',
      message: getErrorMessage(error)
    })
  }
}

function* watchOnUpdatePassword() {
  yield takeEvery(ACTIONS.COMPLETE, onUpdatePasswordSuccess)
  yield takeEvery(ACTIONS.FAILURE, onUpdatePasswordFailed)
}

export function* updatePasswordSaga() {
  yield watchOnUpdatePassword()
}
