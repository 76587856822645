import { ACTIONS } from '@stagapps/redux-utils'

import { POST_RESET_PASSWORD } from 'app/apiCalls/account/resetPassword'
import { sessionSelector } from 'state/reducer/session.reducers'

const getFromSessionStoreage = () => {
  const resetPassword = sessionStorage.getItem('resetPassword')
  return resetPassword ? JSON.parse(resetPassword) : {}
}
const addRequestHeadersMiddleware = ({ getState }) => next => action => {
  const { type, payload } = action
  const { name } = payload || {}
  const isFormData = payload.body instanceof FormData

  if (type === ACTIONS.START) {
    const defaultHeaders = !isFormData
      ? { 'Content-Type': 'application/json' }
      : {}

    const isResetPassword = name === POST_RESET_PASSWORD

    const { currentToken } =
      (isResetPassword && getFromSessionStoreage()) ||
      sessionSelector(getState()) ||
      {}

    const authHeaders = currentToken
      ? { Authorization: `Bearer ${currentToken}` }
      : {}

    const withHeadersAction = {
      type: ACTIONS.START,
      payload: {
        ...payload,
        body: isFormData ? payload.body : JSON.stringify(payload.body),
        headers: {
          Accept: 'application/json',
          ...defaultHeaders,
          ...payload.headers,
          ...authHeaders
        }
      }
    }
    next(withHeadersAction)
  } else {
    next(action)
  }
}

export default addRequestHeadersMiddleware
