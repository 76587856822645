import { useFetchApiGet } from '@stagapps/redux-utils'
import { Alert } from 'antd'
import { useEffect } from 'react'

import apiCall from 'app/apiCalls/verification/getUserIdentifications'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import CompletingIDInfosForm from './CompletingIDInfosForm'
import KYCSteps from './KYCSteps'

function CompletingIDInfosPage() {
  const { data, load, isLoading, error, refresh } = useFetchApiGet(apiCall, {
    resourceName: 'data'
  })

  useEffect(() => {
    load()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  return (
    <div>
      <KYCSteps current={1} />
      <UICard className="w-fit mx-auto" title={'Hoàn thiện thông tin cá nhân'}>
        <div className="flex flex-col gap-4">
          <Alert
            message="Vui lòng kiểm tra và điền đầy đủ các thông tin bên dưới để hoàn thiện hồ sơ"
            type="info"
            showIcon
          />
          <CompletingIDInfosForm initialValues={data} />
        </div>
      </UICard>
    </div>
  )
}

export default CompletingIDInfosPage
