import { PageHeader } from '@ant-design/pro-layout'
import { useFetchApiGet } from '@stagapps/redux-utils'
import { Button, List, Space, Tabs, Tag, Tooltip, Typography } from 'antd'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import { checkIsSellAvalible } from 'app/components/employeeProgram/checkEmployeeProgramProgress'
import apiCall from 'business/apiCalls/program'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import {
  employeeProgramStatus,
  employeeProgramStatusColors
} from 'data/employeeProgram'

const { Title } = Typography

function EmployeeProgramLayout() {
  const { id, userId } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const userPath = userId ? `/employees/${userId}` : ''

  const {
    data: program,
    load: fetchProgram,
    isLoading: isLoadingProgram,
    error: errorProgram
  } = useFetchApiGet(apiCall.detail, { resourceName: 'data' })

  const isLoading = isLoadingProgram
  const error = errorProgram

  useEffect(() => {
    fetchProgram({ id })
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  const { code, status, progress } = program || {}
  const isSellAvalible = checkIsSellAvalible(progress)

  const isProgramOrders = pathname.includes('program-orders')

  const programCode = `Chương trình ${code}`

  const tabItems = [
    {
      label: 'Tổng quan',
      key: 1
    },
    {
      label: 'Lịch sử đóng góp',
      key: 2
    }
  ]

  const onChange = key => {
    const path = `${userPath}/employee-programs/${id}`

    if (key == 1) {
      navigate(path)
    }
    if (key == 2) {
      navigate(path + '/program-orders')
    }
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        title={
          <Space>
            <Title level={2}>{programCode}</Title>
            <Tag color={employeeProgramStatusColors[status]}>
              <span className="font-normal">
                {employeeProgramStatus[status]}
              </span>
            </Tag>
          </Space>
        }
        extra={
          isSellAvalible ? null : (
            <Tooltip
              title={
                <List
                  size="small"
                  dataSource={vestingDescriptionText}
                  renderItem={item => (
                    <List.Item>
                      <div className="text-white">{item}</div>
                    </List.Item>
                  )}
                />
              }
              color="#0A1A41"
              className="my-0"
            >
              <Button type="primary" danger>
                Kết thúc chương trình
              </Button>
            </Tooltip>
          )
        }
      />
      <Tabs
        defaultActiveKey={isProgramOrders ? 2 : 1}
        activeKey={isProgramOrders ? 2 : 1}
        items={tabItems}
        mode="horizontal"
        onChange={onChange}
      />
      <Outlet />
    </>
  )
}

export default EmployeeProgramLayout

const vestingDescriptionText = [
  'Vui lòng liên hệ Stag để được hỗ trợ huỷ chương trình trước hạn.',
  'Chương trình khi bị hủy sẽ không thể khôi phục lại được. Bằng việc xác nhận huỷ, Doanh nghiệp đảm bảo về việc thông báo và có sự đồng thuận với nhân viên tham gia chương trình.',
  'Sau khi xác nhận hủy, toàn bộ khoản tích lũy của nhân viên và khoản đóng góp của doanh nghiệp sẽ được phân bổ về sở hữu riêng của từng bên tương ứng.'
]
