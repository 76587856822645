import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_EMPLOYEE_PROGRAM_FLEX_PORTFOLIO =
  'BUSINESS/FETCH_EMPLOYEE_PROGRAM_FLEX_PORTFOLIO'

const apiCall = enhancedMakeFetchAction(
  FETCH_EMPLOYEE_PROGRAM_FLEX_PORTFOLIO,
  ({ id }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/flex/v1/programs/${id}/flex-portfolio`,
    method: 'GET'
  })
)

export default apiCall
