import EmployeeRoute from 'app/route'
import BusinessRoute from 'business/route'
import { FLEX_APP } from 'config/env.config'

const routes = {
  business: BusinessRoute,
  employee: EmployeeRoute
}

const AppRouter = () => {
  const AppRoute = routes[FLEX_APP]
  return <AppRoute />
}

export default AppRouter
