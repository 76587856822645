import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'
export const CREATE_EP_SELL_ORDER = 'EMPLPYEE/CREATE_EP_SELL_ORDER'

const apiCall = enhancedMakeFetchAction(
  CREATE_EP_SELL_ORDER,
  ({ id, programId, ...payload }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/flex/employee/v1/employees/${id}/employee-programs/${programId}/program-orders/create-sell`,
    method: 'POST',
    body: payload
  })
)

export default apiCall
