import { UserOutlined } from '@ant-design/icons'
import { Avatar, Menu, Modal } from 'antd'
import useSignOut from 'hooks/auth/useSignOut'
import useCheckMobileDevice from 'hooks/useCheckMobileDevice'

const UserMenu = ({ user }) => {
  const { picture, name, email } = user || {}
  const signOut = useSignOut()
  const { isMobile } = useCheckMobileDevice()

  return (
    <Menu
      style={{
        padding: 0,
        margin: 0
      }}
      disabledOverflow
      mode="horizontal"
      items={[
        {
          key: 'account',
          icon: (
            <div className="flex gap-2">
              {isMobile ? null : (
                <div className="font-medium md:text-sm text-xs">
                  {name ? (
                    <>
                      <strong>{name}</strong>
                      {` (${email})`}
                    </>
                  ) : (
                    email
                  )}
                </div>
              )}
              <Avatar
                size="small"
                className="flex items-center justify-center bg-blue-primary-400"
                src={picture ? picture : null}
                icon={<UserOutlined />}
              />
            </div>
          ),
          children: [
            isMobile
              ? {
                  label: (
                    <p className="font-medium text-gray-900 text-xs flex">
                      {name ? (
                        <>
                          <strong>{name}</strong>
                          {` (${email})`}
                        </>
                      ) : (
                        email
                      )}
                    </p>
                  ),
                  disabled: true
                }
              : undefined,
            {
              key: 'logout',
              label: (
                <div
                  onClick={() =>
                    Modal.confirm({
                      title: 'Bạn muốn thoát tài khoản?',
                      onOk: () => {
                        signOut()
                      }
                    })
                  }
                >
                  Thoát
                </div>
              )
            }
          ]
        }
      ]}
    />
  )
}

export default UserMenu
