import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

export const POST_RESET_PASSWORD = 'EMPLOYEE/POST_RESET_PASSWORD'

const apiCall = enhancedMakeFetchAction(POST_RESET_PASSWORD, payload => ({
  endpoint: `${API_BASE_URL}/investcore-api/app/v1/users/reset-password`,
  method: 'POST',
  body: payload
}))

export default apiCall
