import { USER_PROGRAM_ORDER_CREATE_BUY } from 'app/apiCalls/userProgramOrder/createBuy'
import { USER_PROGRAM_ORDER_CREATE_SELL } from 'app/apiCalls/userProgramOrder/createSell'
import { eq, flow, get } from 'lodash/fp'
import { ACTIONS } from 'redux-api-call'
import { takeEvery } from 'redux-saga/effects'
import { history } from 'routerHistory'

function* onUserProgramOrderSuccess(action) {
  if (
    flow(get('payload.name'), eq(USER_PROGRAM_ORDER_CREATE_BUY))(action) ||
    flow(get('payload.name'), eq(USER_PROGRAM_ORDER_CREATE_SELL))(action)
  ) {
    try {
      const programOrder = get('payload.json.data')(action)
      const { user_program_id, order_id } = programOrder
      if (order_id && user_program_id) {
        history.push(`/orders/${order_id}`)
      } else {
        history.push(`/goals`)
      }
    } catch (error) {
      console.log('onUserProgramOrderSuccess', error)
    }
  }
}

function* watchOnUserProgramOrder() {
  yield takeEvery(ACTIONS.COMPLETE, onUserProgramOrderSuccess)
}

export function* userProgramOrderSaga() {
  yield watchOnUserProgramOrder()
}
