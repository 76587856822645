const stagImg = 'https://img.stag.vn/cdn-cgi/image'
const stagImgHost = 'https://img.stag.vn'

export function convertImageFormData(file, fieldName = 'photo') {
  const formData = new FormData()
  formData.append(fieldName, file)

  return formData
}

export const cloudflareCdn = (url, width, height) => {
  if (!url) return null
  url = url.replace('https://img.stag.vn', '')

  if (!width && !height) {
    return `${stagImgHost}${url}`
  }

  let arr = []
  if (height) arr.push(`height=${height}`)
  if (width) arr.push(`width=${width}`)
  arr.push('format=auto')

  const params = arr.join(',')

  if (url.startsWith('/')) {
    return `${stagImg}/${params}${url}`
  } else if (!url.startsWith('http')) {
    return `${stagImg}/${params}/${url}`
  }

  return url
}
