export const orderTypeColors = {
  buy: 'green',
  sell: 'blue'
}

export const orderTypeLabels = {
  buy: 'MUA',
  sell: 'BÁN'
}

export const orderStatusColors = {
  1: 'yellow',
  2: 'green',
  3: 'blue',
  4: 'blue',
  5: 'green',
  6: 'grey'
}

export const employeeProgramFilters = [
  { status__in: '1', label: 'Chờ thanh toán' },
  { status__in: '3,4', label: 'Chờ khớp' },
  { status__in: '5', label: 'Đã phân phối' },
  { status__in: '6', label: 'Đã hủy' }
]

export const orderSourceColors = {
  0: 'red',
  1001: 'blue',
  1002: 'orange'
}
