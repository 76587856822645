const SessionAction = {
  SET_STAG_USER: 'SET_STAG_USER',
  CLEAR_STAG_USER: 'CLEAR_STAG_USER',
  UPDATE_STAG_USER: 'UPDATE_STAG_USER',
  SET_LOADING: 'SET_LOADING',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_DARK_MODE: 'SET_DARK_MODE',
  SET_FIREBASE_STATE: 'SET_FIREBASE_STATE',
  RESET_STATE: 'RESET_STATE'
}

export default SessionAction
