import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'

import { API_BASE_URL } from 'config/env.config'

const apiCallGeneratorByName = (name = '') => {
  const apiName = `EMPLOYEE/POST_REQUEST_ID_CARD_VERIFICATION_${name}`

  return enhancedMakeFetchAction(apiName, payload => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/investcore-api/app/v1/identifications/upload`
    }),

    method: 'POST',
    body: payload
  }))
}

export default apiCallGeneratorByName
