export const EmptyEmployeeProgramOderListSVG = props => (
  <svg width="100%" height="100%" viewBox="0 0 184 117" fill="none" {...props}>
    <path
      fill="#000"
      fillOpacity={0.06}
      d="M25.875 83C9.857 86.595 0 91.492 0 96.888c0 11.033 41.19 19.976 92 19.976s92-8.943 92-19.976c0-5.396-9.857-10.293-25.875-13.888v8.708c0 6.058-3.795 11.024-8.481 11.024H34.356c-4.686 0-8.481-4.969-8.481-11.024V83Z"
    />
    <path
      fill="#000"
      fillOpacity={0.04}
      stroke="#DCE2EC"
      d="M119.637 48.315c0-4.58 2.858-8.361 6.403-8.364h32.085v51.757c0 6.058-3.795 11.024-8.481 11.024H34.356c-4.686 0-8.481-4.969-8.481-11.024V39.951H57.96c3.545 0 6.403 3.776 6.403 8.356v.063c0 4.58 2.889 8.278 6.431 8.278h42.412c3.542 0 6.431-3.733 6.431-8.313v-.02Z"
    />
    <path
      stroke="#DCE2EC"
      d="m158.125 40.266-29.17-32.823c-1.4-2.237-3.444-3.59-5.597-3.59H60.642c-2.153 0-4.197 1.353-5.597 3.588l-29.17 32.828"
    />
  </svg>
)
