import MetaDecorator from 'common/layout/MetaDecorator'
import PageTitle from 'common/layout/PageTitle'
import RiskAppetite from './RiskAppetite'
import RiskAppetiteOptions from './RiskAppetiteOptions'

export function RiskAppetitePage() {
  const title = 'Khẩu vị đầu tư'
  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle title={title} />
      <RiskAppetite />
    </>
  )
}
export function RiskAppetiteOptionsPage() {
  return (
    <>
      <MetaDecorator title="Chọn khẩu vị đầu tư" />
      <RiskAppetiteOptions />
    </>
  )
}
