import { Button, Descriptions } from 'antd'
import UICard from 'common/ui/display/UICard'
import UIDate from 'common/ui/display/UIDate'
import { Link, useParams } from 'react-router-dom'

const { Item } = Descriptions

function PersonalInfoTab({ personalInfo = {} }) {
  const { id } = useParams()
  const {
    role,
    email,
    full_name,
    department,
    phone,
    date_of_birth,
    nationality,
    id_number
  } = personalInfo

  return (
    <UICard
      title={'Thông tin cá nhân'}
      extra={
        <Link to={`/employees/${id}/edit`}>
          <Button>Chỉnh sửa thông tin</Button>
        </Link>
      }
    >
      <Descriptions
        bordered
        column={1}
        size="middle"
        labelStyle={{ maxWidth: 80 }}
      >
        <Item label="Họ và tên">{full_name}</Item>
        <Item label="Ngày tháng năm sinh">
          {<UIDate timeData={date_of_birth} />}
        </Item>
        <Item label="Số CMND/CCCD">{id_number}</Item>
        <Item label="Bộ phận">{department}</Item>
        <Item label="Chức vụ">{role}</Item>
        <Item label="Quốc tịch">{nationality}</Item>
        <Item label="Số điện thoại">{phone}</Item>
        <Item label="Email">{email}</Item>
      </Descriptions>
    </UICard>
  )
}

export default PersonalInfoTab
