import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_LIST_API_ID = 'EMPLOYEE/FETCH_USER_SERVEYS_HISTORY_API'

const apiCall = enhancedMakeFetchAction(FETCH_LIST_API_ID, ({ ...params }) => ({
  endpoint: generateEndpoint({
    host: `${API_BASE_URL}/investcore-api/app/v2/surveys/history`,
    params
  }),
  method: 'GET'
}))

export default apiCall
