import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_DETAIL_API_ID = 'EMPLOYEE_DETAIL_API'

const apiCall = enhancedMakeFetchAction(FETCH_DETAIL_API_ID, ({ id }) => ({
  endpoint: `${API_BASE_URL}/investcore-api/flex/v1/employees/${id}`,
  method: 'GET'
}))

export default apiCall
