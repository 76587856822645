import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useFetchApiGet } from '@stagapps/redux-utils'
import apiCall from 'app/apiCalls/employeeProgram'
import epPortfolioApiCall from 'app/apiCalls/employeeProgramPortfolio'
import InvestmentDetail from 'app/components/InvestmentDetail'
import MetaDecorator from 'common/layout/MetaDecorator'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'

function EmployeeProgramDetailPage() {
  const { id, programId } = useParams()

  const {
    data: program,
    load: fetchProgram,
    isLoading: isLoadingProgram,
    error: errorProgram
  } = useFetchApiGet(apiCall.detail, { resourceName: 'data' })

  const {
    data: portfolio,
    load: fetchPort,
    isLoading: isLoadingPort,
    error: errorPort
  } = useFetchApiGet(epPortfolioApiCall.portfolio, { resourceName: 'data' })
  const {
    data: flexPort,
    load: fetchFlexPort,
    isLoading: isLoadingFlexPort,
    error: errorFlexPort
  } = useFetchApiGet(epPortfolioApiCall.flexPort, { resourceName: 'data' })

  const isLoading = isLoadingProgram || isLoadingPort || isLoadingFlexPort
  const error = errorProgram || errorPort || errorFlexPort

  useEffect(() => {
    fetchProgram({ id, programId })
    fetchPort({ id, programId })
    fetchFlexPort({ id, programId })
  }, [id, programId])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  return (
    <>
      <MetaDecorator title={'Chi tiết chương trình'} />
      <InvestmentDetail
        program={program}
        portfolio={portfolio}
        flexPort={flexPort}
      />
    </>
  )
}

export default EmployeeProgramDetailPage
