import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const apiCallGenerator = type => {
  const FETCH_DATA_LIST_API_ID = `DATA/FETCH_DATA_PROVINCES_${type}`

  return enhancedMakeFetchAction(FETCH_DATA_LIST_API_ID, params => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/investcore-api/app/v2/provinces`,
      params
    }),
    method: 'GET'
  }))
}

export default apiCallGenerator
