import { useFetchApiGet, usePostApi } from '@stagapps/redux-utils'
import { Button, Form, Input, Tooltip } from 'antd'
import { useEffect } from 'react'

import apiUpdatePasswordCall from 'app/apiCalls/account/updatePassword'
import apiUserProfileCall from 'app/apiCalls/verification/getProfile'
import UpdatePasswordForm from 'business/components/setting/UpdatePasswordForm'
import MetaDecorator from 'common/layout/MetaDecorator'
import PageTitle from 'common/layout/PageTitle'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'

const title = 'Cài đặt chung'

function AccountSettingPage() {
  const [form] = Form.useForm()

  const {
    post,
    isLoading: isSubmitting,
    error
  } = usePostApi(apiUpdatePasswordCall, { resourceName: 'success' })

  const {
    data: userProfile,
    load: loadUserProfile,
    isLoading: isLoadingUserProfile,
    error: errorUserProfile
  } = useFetchApiGet(apiUserProfileCall, {
    resourceName: 'data'
  })

  useEffect(() => {
    loadUserProfile()
  }, [])

  useEffectSkipFirstRender(() => {
    if (!isSubmitting) {
      if (!error) {
        form.resetFields()
      }
    }
  }, [isSubmitting])

  if (isLoadingUserProfile) {
    return <LoadingSpinner />
  }

  if (!!errorUserProfile) {
    return <ErrorMessage errors={errorUserProfile} />
  }

  return (
    <div className="flex flex-col gap-4">
      <MetaDecorator title={title} />
      <PageTitle title={title} />
      <UICard
        title={'Thông tin tài khoản'}
        extra={
          <Tooltip
            title={
              'Vui lòng liên hệ Stag để được hỗ trợ thay đổi thông tin tài khoản.'
            }
            color="#0A1A41"
            className="my-0 "
          >
            <Button disabled>Chỉnh sửa</Button>
          </Tooltip>
        }
      >
        <Form
          className="md:w-96"
          name="account"
          initialValues={userProfile}
          labelCol={{ span: 24 }}
        >
          <Form.Item label="Email" name="email">
            <Input disabled />
          </Form.Item>
          <Form.Item label="Số điện thoại" name="phone">
            <Input disabled />
          </Form.Item>
        </Form>
      </UICard>
      <UICard title={'Đổi mật khẩu'}>
        <UpdatePasswordForm
          initialValues={userProfile}
          onFinish={post}
          isSubmitting={isSubmitting}
          form={form}
        />
      </UICard>
    </div>
  )
}

export default AccountSettingPage
