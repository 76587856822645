import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_DETAIL_API_ID = 'EMPLOYEE/FETCH_EMPLOYEE_PROGRAM_DETAIL_API'

const apiCall = enhancedMakeFetchAction(
  FETCH_DETAIL_API_ID,
  ({ id, programId }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/flex/employee/v1/employees/${id}/employee-programs/${programId}`,
    method: 'GET'
  })
)

export default apiCall
