import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'

import AccountVerificationStatus from 'app/components/auth/VerificationStatus'
import Footer from 'common/layout/Footer'
import SideMenu from 'common/layout/SideMenu'
import AppHeader from '../AppHeader'
import SurveyHistory from '../SurveyHistory'

const EmployeeLayout = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <AppHeader />
      <Layout>
        <SideMenu />
        <Layout>
          <Layout.Content className="md:p-4 p-2">
            <SurveyHistory />
            <AccountVerificationStatus />
            <Outlet />
          </Layout.Content>
          <Footer />
        </Layout>
      </Layout>
    </Layout>
  )
}

export default EmployeeLayout
