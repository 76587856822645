import { useFetchApiGet } from '@stagapps/redux-utils'
import apiCall from 'business/apiCalls/company/detail'
import { useEffect } from 'react'

import MetaDecorator from 'common/layout/MetaDecorator'
import PageTitle from 'common/layout/PageTitle'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UIQueryTabs from 'common/ui/display/UIQueryTabs'
import {
  // AdminDetail,
  BankDetail,
  CompanyDetail,
  FundAccountList
} from './detail'

const TITLE = 'Thông tin doanh nghiệp'

function index() {
  const { data, load, isLoading, error } = useFetchApiGet(apiCall, {
    resourceName: 'data'
  })

  useEffect(() => {
    load()
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }
  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  return (
    <>
      <MetaDecorator title={TITLE} />
      <PageTitle title={TITLE} />
      <UIQueryTabs
        items={tabItemsGenerator({ data })}
        defaultQuery="info"
        keyName="key"
      />
    </>
  )
}

export default index

const tabItemsGenerator = ({ data }) => {
  return [
    {
      key: 'info',
      label: 'Thông tin doanh nghiệp',
      children: <CompanyDetail data={data} />
    },
    {
      key: 'bank',
      label: 'Thông tin tài khoản ngân hàng',
      children: <BankDetail data={data} />
    },
    // {
    //   key: '3',
    //   label: 'Tài khoản quản lý',
    //   children: <AdminDetail />
    // },
    {
      key: 'fund-account',
      label: 'Tài khoản giao dịch đã mở',
      children: <FundAccountList />
    }
  ]
}
