import createApiCall from './create'
import deleteApiCall from './delete'
import detailApiCall from './detail'
import investmentPlans from './investmentPlans'
import listApiCall from './list'
import projectionApiCall from './projection'
import updateApiCall from './update'

const ApiCall = {
  create: createApiCall,
  list: listApiCall,
  detail: detailApiCall,
  update: updateApiCall,
  delete: deleteApiCall,
  projection: projectionApiCall,
  investmentPlans
}

export default ApiCall
