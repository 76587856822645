import { Descriptions, Space } from 'antd'

import UIDate from 'common/ui/display/UIDate'
import UIInfoTooltip from 'common/ui/display/UIInfoTooltip'
import { formatCurrency } from 'utils/formatAmount'

const { Item } = Descriptions

function ContributedDetail({ program }) {
  const {
    monthly_order_payment,
    investment_interval,
    start_date,
    end_date,
    employee,
    employee_investment_detail,
    company_investment_detail
  } = program
  const { full_name } = employee || {}

  return (
    <Descriptions bordered column={1}>
      <Item label="Họ tên nhân viên">{full_name}</Item>
      <Item
        label={
          <Space>
            <div>Số kỳ tham gia</div>
            <UIInfoTooltip
              title={
                'Số kỳ tương đương với số tháng dương lịch. Hệ thống sẽ dựa theo số kỳ tham gia của chương trình để tạo lệnh mua chứng chỉ quỹ cho phần tích lũy của Nhân viên và phần đóng góp của Doanh nghiệp.'
              }
            />
          </Space>
        }
      >{`${investment_interval} kỳ`}</Item>

      <Item
        label={
          <Space>
            <div>Ngày tạo lệnh định kỳ hàng tháng</div>
            <UIInfoTooltip
              title={
                'Doanh nghiệp lựa chọn một ngày trong tháng để hệ thống tạo lệnh mua chứng chỉ quỹ cho phần tích lũy của Nhân viên và phần đóng góp của Doanh nghiệp theo đúng cam kết của chương trình.'
              }
            />
          </Space>
        }
      >
        {monthly_order_payment}
      </Item>

      <Item label="Bắt đầu chương trình">
        <UIDate timeData={start_date} />
      </Item>
      <Item label="Thời gian kết thúc">
        <UIDate timeData={end_date} />
      </Item>

      <Item label="Nhân viên tích lũy định kỳ">
        {employee_investment_detail?.length > 0
          ? employee_investment_detail.map((item, index) => {
              const { product_code, amount } = item || {}
              return (
                <div key={index}>{`${product_code}: ${formatCurrency(
                  amount
                )}`}</div>
              )
            })
          : '-'}
      </Item>

      <Item label="Doanh nghiệp đóng góp định kỳ">
        {company_investment_detail?.length > 0
          ? company_investment_detail.map((item, index) => {
              const { product_code, amount } = item || {}
              return (
                <div key={index}>{`${product_code}: ${formatCurrency(
                  amount
                )}`}</div>
              )
            })
          : '-'}
      </Item>
    </Descriptions>
  )
}

export default ContributedDetail
