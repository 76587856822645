export default async function copyTextToClipboard(text) {
  try {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  } catch (error) {
    console.log('CopyTextToClipboardError:', error)
  }
}
