import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

export const DELETE_USER_PROGRAM_API_ID = 'EMPLOYEE/USER_PROGRAM_DELETE_API'

const apiCall = enhancedMakeFetchAction(DELETE_USER_PROGRAM_API_ID, id => ({
  endpoint: `${API_BASE_URL}/investcore-api/app/v2/user-programs/${id}/delete`,
  method: 'DELETE',
  notify: true
}))

export default apiCall
