import { Table } from 'antd'
import { useSearchParams } from 'react-router-dom'

const DataTable = ({
  dataFetcher,
  dataSource,
  currentPage,
  totalItems,
  filters,
  loading,
  pageSize,
  rowKey,
  columns,
  ...props
}) => {
  let [searchParams, setSearchParams] = useSearchParams()

  const handleTableChange = (pagination, filters, sorter) => {
    const params = {}
    for (let entry of searchParams.entries()) {
      params[entry[0]] = entry[1]
    }

    if (sorter.order == 'ascend' || sorter.order == 'descend') {
      setSearchParams({
        ...params,
        ...filters,
        page: pagination.current,
        ordering: sorter.order == 'ascend' ? sorter.field : `-${sorter.field}`
      })
    } else {
      setSearchParams({
        ...params,
        ...filters,
        page: pagination.current
      })
    }
  }

  return (
    <Table
      style={{ marginTop: 20, marginBottom: 20 }}
      rowKey={rowKey || 'id'}
      bordered
      dataSource={dataSource}
      columns={columns}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        position: 'both',
        defaultCurrent: 1,
        current: searchParams.get('page') || currentPage,
        total: totalItems,
        hideOnSinglePage: true,
        pageSize: pageSize,
        pageSizeOptions: [20]
      }}
      {...props}
    />
  )
}

export default DataTable
