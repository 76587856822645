import UIDate from 'common/ui/display/UIDate'
import { Link } from 'react-router-dom'

export const columns = [
  {
    title: 'Mã nhân viên',
    dataIndex: 'code'
  },
  {
    title: 'Họ tên',
    dataIndex: 'full_name',
    render: (text, record) => (
      <div>
        <p className="font-semibold">{text}</p>
        <p>{record?.email}</p>
      </div>
    )
  },
  {
    title: 'Ngày sinh',
    dataIndex: 'date_of_birth',
    render: value => <UIDate timeData={value} />
  },
  {
    title: 'Bộ phận',
    dataIndex: 'department'
  },

  {
    render: (text, record) => (
      <Link className="text-blue-primary-500" to={`/employees/${record.id}`}>
        Chi tiết
      </Link>
    )
  }
]
