import { useFetchApiList } from '@stagapps/redux-utils'
import { Button, ConfigProvider, Space, Table } from 'antd'
import userInvestmentPlanssInvestmentPlansApiCall from 'app/apiCalls/userProgram/investmentPlans'
import VerificationRequiredCard from 'app/components/cards/VerificationRequiredCard'
import useAccountVerifiedStatus from 'app/hooks/useAccountVerifiedStatus'
import PageTitle from 'common/layout/PageTitle'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import { getChangeValueClassColor } from 'config/color'
import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { formatPercentage } from 'utils/formatAmount'
import { formatCurrencyWithoutCurrencySymbol } from 'utils/formatAmount/formatUI'
import { formatTableColumns } from 'utils/tableConfig'

const OrderBuyFundSelectorPage = () => {
  const { id } = useParams()

  const {
    data: investmentPlans,
    load: fetchInvestmentPlans,
    isLoading: isLoadingInvestmentPlans,
    error: errorInvestmentPlans
  } = useFetchApiList(userInvestmentPlanssInvestmentPlansApiCall.list, {
    resourceName: 'data'
  })

  const { isCompleteAllKYC } = useAccountVerifiedStatus()

  useEffect(() => {
    fetchInvestmentPlans({ id })
  }, [])

  if (!isCompleteAllKYC) {
    return <VerificationRequiredCard />
  }

  if (isLoadingInvestmentPlans) {
    return <LoadingSpinner />
  }

  if (errorInvestmentPlans) {
    return <ErrorMessage errors={errorInvestmentPlans} />
  }

  if (
    !investmentPlans ||
    investmentPlans.length === 0 ||
    !investmentPlans[0]?.user_investment_details
  ) {
    return <ErrorMessage errors={'Chưa thiết lập kế hoạch đầu tư'} />
  }
  const latestInvestmentPlan = investmentPlans[0]?.user_investment_details || {}

  return (
    <div>
      <PageTitle title={'Tăng tốc'} />
      <UICard
        title={'Mua sản phẩm quỹ theo phân bổ danh mục'}
        className="md:w-fit md:mx-auto"
      >
        <p>
          Bên dưới là sản phẩm nằm trong phân bổ danh mục bạn đã chọn cho mục
          tiêu này.
        </p>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBg: '#ffffff'
              }
            }
          }}
        >
          <Table
            className="overflow-x-auto"
            dataSource={latestInvestmentPlan}
            columns={[...formatTableColumns(columnsGenerator({ id }))]}
            pagination={false}
            rowKey={'product_id'}
            bordered={false}
          />
        </ConfigProvider>
      </UICard>
    </div>
  )
}

export default OrderBuyFundSelectorPage

const columnsGenerator = ({ id }) => {
  return [
    {
      title: '',
      dataIndex: 'product',
      render: e => {
        const { logo, code, alias, fund_type_name } = e || {}

        return (
          <Space>
            <img className="w-8" src={logo} />
            <Space direction="vertical" size="small">
              <div className="font-semibold">{alias || code}</div>
              <div className="text-gray-500">{fund_type_name}</div>
            </Space>
          </Space>
        )
      }
    },
    {
      title: 'Mua tối thiểu (VND)',
      dataIndex: 'product',
      align: 'right',
      render: e =>
        e?.buy_minimum_amount
          ? formatCurrencyWithoutCurrencySymbol(e.buy_minimum_amount)
          : '-'
    },
    {
      title: '12 tháng gần nhất',
      dataIndex: 'product',
      align: 'right',
      render: e => {
        const { change_percentage_1y } = e || {}
        return change_percentage_1y ? (
          <div className={getChangeValueClassColor(change_percentage_1y)}>
            {(change_percentage_1y > 0 ? '+' : '') +
              formatPercentage(change_percentage_1y)}
          </div>
        ) : (
          '-'
        )
      }
    },
    {
      title: '',
      dataIndex: 'product_id',
      align: 'center',
      render: productId => (
        <Link to={`/goals/${id}/buy/${productId}`}>
          <Button type="primary">Mua</Button>
        </Link>
      )
    }
  ]
}
