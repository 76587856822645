import { usePostApi } from '@stagapps/redux-utils'
import { Form, Input, Spin, Statistic, Typography } from 'antd'
import apiRequestCall from 'app/apiCalls/account/requestResetPassword'
import AuthLayout from 'app/components/layouts/AuthLayout'
import StagLogoSVG from 'common/svg/logo/stag'
import ValidButton from 'common/ui/form/ValidButton'
import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { renderErrorMessage } from 'utils/getErrorMessage'

const { Title } = Typography

function ForgetPasswordPage() {
  let [searchParams] = useSearchParams()
  const urlQuery = searchParams.get('url')
  const query = urlQuery ? `?url=${urlQuery}` : ''

  const [form] = Form.useForm()

  const {
    post: postRequest,
    isLoading: isSubmittingRequest,
    error: errorRequest
  } = usePostApi(apiRequestCall, {
    resourceName: 'data'
  })

  const onFinish = values => {
    const { email } = values || {}
    postRequest({ email })
  }

  return (
    <AuthLayout imgUrlPath={'/futureflex/images/login_employee_v2.jpeg'}>
      <div className="flex flex-row gap-2 items-center">
        <div className="flex md:w-[134px] w-32 aspect-[134/31]">
          <StagLogoSVG />
        </div>
      </div>
      <div>
        <Title level={4}>Quên mật khẩu</Title>
        <p>
          Đã có tài khoản?<Link to={`/login${query}`}> Đăng nhập</Link>
        </p>
      </div>

      <Form
        requiredMark={false}
        initialValues={{
          remember: true
        }}
        onFinish={onFinish}
        form={form}
        labelCol={{ span: 24 }}
      >
        <Form.Item
          className="mb-10"
          validateStatus={!!errorRequest ? 'error' : null}
          hasFeedback
          help={
            errorRequest ? (
              renderErrorMessage(errorRequest)
            ) : (
              <div className="mt-1 text-xs text-gray-500">
                Bạn sẽ nhận được OTP xác thực qua email.
              </div>
            )
          }
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập email cá nhân của bạn!',
              type: 'email'
            }
          ]}
        >
          <Input placeholder="Vd: user@gmail.com" />
        </Form.Item>

        <Form.Item>
          <ValidButton
            size="large"
            type="primary"
            htmlType="submit"
            className="w-full"
            form={form}
            loading={isSubmittingRequest}
          />
        </Form.Item>
      </Form>
    </AuthLayout>
  )
}

export default ForgetPasswordPage

const { Countdown } = Statistic

const CountDownAndResendRequest = ({ expires, email }) => {
  const [isTimeOut, setIsTimeOut] = useState(new Date(expires) <= new Date())

  useEffect(() => {
    setIsTimeOut(new Date(expires) <= new Date())
  }, [expires])

  const { post, isLoading: isSubmitting, error } = usePostApi(apiRequestCall, {
    resourceName: 'data'
  })

  return (
    <div className="flex flex-col justify-center items-center">
      {isTimeOut ? (
        isSubmitting ? (
          <div className="py-2">
            <Spin />
          </div>
        ) : (
          <>
            <Link>
              <p onClick={() => post({ email })}>Gửi lại OTP</p>
            </Link>
            <p className="text-red-500">{renderErrorMessage(error)}</p>
          </>
        )
      ) : (
        <Countdown
          value={expires * 1000}
          format="s"
          prefix={<p className="text-[#5A6F93]">Mã OTP hết hạn trong</p>}
          suffix="giây"
          valueStyle={{ color: '#EF4444', fontSize: '0.875rem' }}
          onFinish={() => setIsTimeOut(true)}
        />
      )}
    </div>
  )
}
