import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_FUND_ACCOUNTS_API = 'EMPLOYEE/FETCH_FUND_ACCOUNTS_API'

const apiCall = enhancedMakeFetchAction(FETCH_FUND_ACCOUNTS_API, () => ({
  endpoint: `${API_BASE_URL}/investcore-api/app/v1/fund-accounts`,
  method: 'GET'
}))

export default apiCall
