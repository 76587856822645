import { Button, Table, Tooltip } from 'antd'

import ContributedColumnChart from 'app/components/employeeProgram/ContributedColumnChart'
import ContributedDetail from 'app/components/employeeProgram/ContributedDetail'
import PortfolioDonutChart from 'app/components/employeeProgram/PortfolioDonutChart'
import Summary from 'app/components/employeeProgram/Summary'
import { checkIsSellAvalible } from 'app/components/employeeProgram/checkEmployeeProgramProgress'
import UICard from 'common/ui/display/UICard'
import { getChangeValueClassColor } from 'config/color'
import {
  formatCurrency,
  formatPercentage,
  formatUnitQuantity
} from 'utils/formatAmount'

const portColumn = [
  {
    title: 'Mã CCQ',
    dataIndex: 'product_code'
  },
  {
    title: 'Số lượng CCQ',
    dataIndex: 'total_quantity',
    render: value => formatUnitQuantity(value),
    align: 'right'
  },
  {
    title: 'Giá trị hiện tại',
    dataIndex: 'market_value',
    render: value => <p>{formatCurrency(value)}</p>,
    align: 'right'
  },
  {
    title: 'Lãi / Lỗ (%)',
    dataIndex: 'unrealized_gain_loss_percentage',
    render: value => (
      <p className={getChangeValueClassColor(value)}>
        {formatPercentage(value)}
      </p>
    ),
    align: 'right'
  },
  {
    title: 'Lãi / Lỗ (VND)',
    dataIndex: 'unrealized_gain_loss',
    render: value => (
      <p className={getChangeValueClassColor(value)}>{formatCurrency(value)}</p>
    ),
    align: 'right'
  }
]

const endPortColumn = [
  {
    title: 'Mã CCQ',
    dataIndex: 'product_code'
  },
  {
    title: 'Số lượng CCQ',
    dataIndex: 'total_quantity',
    render: value => formatUnitQuantity(value),
    align: 'right'
  },
  {
    title: 'Tổng vốn đã đầu tư',
    dataIndex: 'total_contributed_amount',
    render: value => formatCurrency(value),
    align: 'right'
  }
]

function InvestmentDetail({ program, portfolio, flexPortfolio }) {
  const { employee_investment_detail = [], progress } = program

  const { projected_program_amount, total_summary } = portfolio || {}
  const { items: portfolio_item } = flexPortfolio || {}
  const { contributed_amount, market_value } = total_summary || {}
  const isSellAvalible = checkIsSellAvalible(progress)

  return (
    <div className="flex flex-col gap-4">
      <div className="flex md:flex-row flex-col gap-4">
        <UICard className="md:w-1/2">
          <Summary isSellAvalible={isSellAvalible} {...portfolio} />
        </UICard>
        <UICard title="Biểu đồ giá trị vốn đầu tư" className="md:w-1/2">
          <ContributedColumnChart
            projected_program_amount={projected_program_amount}
          />
        </UICard>
      </div>

      <div className="flex md:flex-row flex-col gap-4">
        <UICard title={'Phân bổ danh mục hiện tại'}>
          <div className="md:px-16 p-2 md:w-[402px] flex justify-center">
            <PortfolioDonutChart
              totalMarketValue={market_value}
              data={portfolio_item}
            />
          </div>
        </UICard>

        {isSellAvalible ? (
          <UICard
            title="Sản phẩm đã đầu tư"
            className="flex-grow overflow-x-scroll"
          >
            <Table
              columns={endPortColumn}
              dataSource={portfolio_item}
              pagination={false}
              rowKey={'product_code'}
            />
          </UICard>
        ) : (
          <UICard
            title="Hiệu suất đầu tư"
            className="flex-grow overflow-x-scroll"
          >
            <Table
              columns={portColumn}
              dataSource={portfolio_item}
              pagination={false}
              rowKey={'product_code'}
            />
          </UICard>
        )}
      </div>

      <UICard title="Chi tiết đóng góp">
        <ContributedDetail program={program} />
        <div className="pt-4 flex justify-center">
          <Tooltip
            title={
              'Vui lòng liên hệ Stag để được hỗ trợ điều chỉnh chương trình'
            }
            color="#0A1A41"
            className="my-0"
          >
            <Button disabled>Chỉnh sửa chương trình</Button>
          </Tooltip>
        </div>
      </UICard>
    </div>
  )
}

export default InvestmentDetail
