import LoadingSpinner from 'common/ui/LoadingSpinner'
import useCheckAuth from 'hooks/auth/useCheckAuth'
import useAnalytics from 'hooks/useAnalytics'
import { useEffect } from 'react'
import { Navigate, useLocation, useSearchParams } from 'react-router-dom'

const PrivateRoute = ({ children }) => {
  const { currentUser, isAuthenticated, isLoading } = useCheckAuth()
  const { analytics, logPageViewed } = useAnalytics()
  const { pathname } = useLocation()

  useEffect(() => {
    if (isAuthenticated) {
      logPageViewed()
    }
  }, [pathname])

  useEffect(() => {
    if (isAuthenticated) {
      analytics.identify(currentUser.id, currentUser)
    }
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!isAuthenticated) {
    return <Navigate to={`/login?url=${pathname}`} />
  }

  return children
}

const GuestRoute = ({ children }) => {
  const { isAuthenticated, isLoading, currentUser } = useCheckAuth()
  const { is_set_password: isSetPassword } = currentUser || {}

  let [searchParams] = useSearchParams()

  if (isLoading) {
    return <LoadingSpinner />
  }
  if (isSetPassword === false) {
    const urlQuery = searchParams.get('url')
    const query = urlQuery ? `?url=${urlQuery}` : ''
    return <Navigate to={'/create-password' + query} />
  }

  if (isAuthenticated) {
    const urlQuery = searchParams.get('url')
    const query = urlQuery ? urlQuery : '/'
    return <Navigate to={query} />
  }

  return children
}

export { GuestRoute, PrivateRoute }
