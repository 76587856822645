import { usePostApi } from '@stagapps/redux-utils'
import { Button, Descriptions, Form, Space, Typography } from 'antd'
import { useEffect, useState } from 'react'

import UICard from 'common/ui/display/UICard'
import { useParams } from 'react-router-dom'
import {
  convertDateFormData,
  ensureDateFields,
  removeKeyInObjectByKeyList
} from 'utils/formData'
import ValidButton from './ValidButton'

const { Title } = Typography
const { Item } = Descriptions

function ReviewBeforeSubmitForm({
  initialValues,
  apiCall,
  config,
  reviewTitle,
  formTitle,
  submitButtonTitle,
  formName,
  dateFields,
  submitParams = {},
  ExtraComponent,
  extraReviewData,
  onReview = _ => {},
  action = 'create',
  removeList,
  confirmText
}) {
  const [form] = Form.useForm()

  const [isReview, setIsReview] = useState(false)
  const [formData, setFormData] = useState({})
  const params = useParams()

  const ensureDateFieldsInitialValues = !!initialValues
    ? ensureDateFields(initialValues, dateFields)
    : {}

  const { post: submitItem, isLoading: isSubmitting } = usePostApi(
    apiCall[action],
    {
      resourceName: 'data'
    }
  )

  const onFinish = () => {
    const submit = () => {
      const payload = removeKeyInObjectByKeyList(
        convertDateFormData(formData, dateFields),
        removeList
      )
      console.log({
        ...params,
        ...payload,
        ...submitParams
      })
      submitItem({
        ...params,
        ...payload,
        ...submitParams
      })
    }
    if (confirmText) {
      if (confirm(confirmText)) {
        submit()
      }
    } else submit()
  }

  const onValid = values => {
    setFormData(values)
    setIsReview(true)
    onReview(values)
  }

  useEffect(() => {
    if (!!extraReviewData) {
      setFormData({ ...formData, ...extraReviewData })
    }
  }, [extraReviewData])

  useEffect(() => {
    if (!isReview) {
      form.resetFields()
    }
  }, [isReview])

  return (
    <Form
      initialValues={{
        ...ensureDateFieldsInitialValues,
        ...formData
      }}
      form={form}
      name={formName}
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      requiredMark={false}
      size="large"
    >
      {!!isReview ? (
        <UICard title={reviewTitle}>
          <Descriptions bordered column={1}>
            {config.map(
              ({ name, label, formatter, value }) =>
                name &&
                label && (
                  <Item key={name} label={label}>
                    {value
                      ? value
                      : formatter
                      ? formatter(formData[name])
                      : formData[name]}
                  </Item>
                )
            )}
          </Descriptions>
        </UICard>
      ) : (
        <UICard>
          <div className="flex flex-row gap-16">
            <Title className="shrink-0" level={4}>
              {formTitle}
            </Title>
            <div className="w-full">
              {config.map(
                ({ FormItem, ...props }, index) =>
                  !!FormItem && <FormItem key={index} {...props} />
              )}
            </div>
          </div>
        </UICard>
      )}

      <Form.Item className="flex justify-end mt-4 ">
        {!!isReview ? (
          <Space>
            <Button onClick={() => setIsReview(false)}>Trở về</Button>
            <Button loading={isSubmitting} type="primary" htmlType="submit">
              {submitButtonTitle}
            </Button>
          </Space>
        ) : (
          <Space>
            <Button
              onClick={() => {
                form.resetFields()
              }}
            >
              Hủy
            </Button>
            <ValidButton type="primary" form={form} onValid={onValid} />
          </Space>
        )}
      </Form.Item>

      {!!isReview && !!ExtraComponent ? <ExtraComponent /> : null}
    </Form>
  )
}

export default ReviewBeforeSubmitForm
