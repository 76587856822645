import { PageHeader } from '@ant-design/pro-layout'
import { useFetchApiGet } from '@stagapps/redux-utils'
import { Descriptions, Typography } from 'antd'
import apiCall from 'app/apiCalls/employeeProgramOrder'
import MetaDecorator from 'common/layout/MetaDecorator'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
const { Item } = Descriptions
const { Title } = Typography

function ProgramOrderCancelPage() {
  const { id, programId, programOrderId } = useParams()
  const {
    data: programOrder,
    load: fetchOrder,
    isLoading: isLoadingOrder,
    error: errorOrder
  } = useFetchApiGet(apiCall.detail, { resourceName: 'data' })

  const isLoading = isLoadingOrder
  const error = errorOrder

  useEffect(() => {
    fetchOrder({ id, programId, programOrderId })
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  const { code: POCode } = programOrder || {}
  const programOrdersCode = `Chi tiết lệnh ${POCode}`

  return (
    <>
      <MetaDecorator title={'Lịch sử giao dịch'} />
      <PageHeader
        className="site-page-header"
        title={
          <Title ellipsis level={2}>
            {programOrdersCode}
          </Title>
        }
      />
      <div className="flex flex-col gap-4"></div>
    </>
  )
}

export default ProgramOrderCancelPage
