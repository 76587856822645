import { Button, Card, Empty } from 'antd'
import { Link } from 'react-router-dom'

const VerificationRequiredCard = ({ children }) => {
  return (
    <Card>
      <Empty
        imageStyle={{
          height: 200
        }}
        description={
          <>
            <span>Bạn cần hoàn tất định danh tài khoản </span>
          </>
        }
      >
        <Button type="primary">
          <Link to={'/verification/idcard/confirm'}>Định danh ngay</Link>
        </Button>
      </Empty>
    </Card>
  )
}

export default VerificationRequiredCard
