import { useFetchApiList } from '@stagapps/redux-utils'
import { Empty, Menu, Tag } from 'antd'
import { eq, flow, get } from 'lodash/fp'
import { useEffect } from 'react'

import DataTable from 'common/ui/DataTable'
import ErrorMessage from 'common/ui/ErrorMessage'
import UICard from 'common/ui/display/UICard'
import UIDate from 'common/ui/display/UIDate'
import {
  employeeProgramFilters,
  orderStatusColors,
  orderTypeLabels
} from 'data/fundOrder'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { formatNumber } from 'utils/formatAmount'
import { sumCountByStatus } from '../employeeProgram/EmployeeProgramListPage/sumStatus'

const isIndividual = flow(get('user.account_type'), eq('individual'))

const columns = [
  {
    title: 'Ngày tạo',
    dataIndex: 'created_at',
    render: value => <UIDate timeData={value} />
  },
  {
    title: 'Kỳ thanh toán',
    dataIndex: 'intervals',
    align: 'center',
    render: (arr, record) =>
      isIndividual(record) && arr?.length > 0 ? <p> {arr.join(', ')}</p> : null
  },
  {
    title: 'Họ Tên',
    dataIndex: 'user_name'
  },
  {
    title: 'Email',
    dataIndex: 'user_email'
  },

  {
    title: 'Loại lệnh',
    dataIndex: 'order_side',
    render: text => <p>{orderTypeLabels[text]}</p>
  },
  {
    title: 'Sản phẩm',
    dataIndex: 'fund_code'
  },
  {
    title: 'Giá trị (VND)',
    dataIndex: 'amount',
    align: 'right',
    render: text => <p> {formatNumber(text)}</p>
  },
  {
    title: 'Tên ngân hàng',
    dataIndex: 'fund_payment_bank_name',
    render: text => <p>{text}</p>
  },
  {
    title: 'Chi nhánh',
    dataIndex: 'fund_payment_bank_branch_name',
    render: text => <p>{text}</p>
  },
  {
    title: 'Nội dung chuyển khoản',
    dataIndex: 'fund_payment_message',
    render: text => <p>{text}</p>
  },
  {
    title: 'Tên người thụ hưởng',
    dataIndex: 'fund_payment_bank_account_name',
    render: text => <p>{text}</p>
  },
  {
    title: 'Số tài khoản thụ hưởng',
    dataIndex: 'fund_payment_bank_account_number',
    align: 'right',
    render: text => <p>{text}</p>
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    render: (value, record) => {
      return <Tag color={orderStatusColors[value]}>{record?.status_label}</Tag>
    }
  },
  {
    title: '',
    dataIndex: 'id',
    render: value => {
      return <Link to={`/fund-orders/${value}`}>Chi tiết</Link>
    }
  }
]

let locale = {
  emptyText: (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={'Hiện tại chưa có lệnh nào.'}
    />
  )
}

const FundOrderList = ({ apiCall }) => {
  const location = useLocation()
  let [searchParams] = useSearchParams()
  const {
    rawData,
    data: items = [],
    load: fetchItems,
    isLoading,
    paging,
    error
  } = useFetchApiList(apiCall.list, { resourceName: 'data' })

  useEffect(() => {
    fetchItems(Object.fromEntries(new URLSearchParams(location.search)))
  }, [searchParams])

  const { meta_data } = rawData || {}
  const { status_count = [] } = meta_data || {}
  const statusItems = employeeProgramFilters.map(({ status__in, label }) => {
    const count = sumCountByStatus(status_count, status__in)
    return {
      key: status__in,
      label: (
        <Link to={`/fund-orders?status__in=${status__in}`}>
          {count ? `${label} (${count})` : label}
        </Link>
      )
    }
  })

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  return (
    <UICard title={'Danh sách lệnh'}>
      <Menu
        mode="horizontal"
        items={statusItems}
        selectedKeys={searchParams.get('status__in')}
      />
      <DataTable
        scroll={{ x: 1500 }}
        locale={locale}
        columns={columns}
        dataSource={items}
        showHeader={items?.length > 0 ? true : false}
        loading={isLoading}
        totalItems={paging.count}
        currentPage={paging.page}
        defaultCurrent={1}
        defaultPageSize={paging.page_size}
        pageSize={paging.page_size}
        dataFetcher={fetchItems}
        size="small"
      />
    </UICard>
  )
}

export default FundOrderList
