import moment from 'moment'

export const convertFormData = values => {
  const keys = Object.keys(values)
  const data = new FormData()
  keys.forEach(key => {
    if (values[key]) {
      data.append(key, values[key])
    } else {
      data.append(key, '')
    }
  })
  return data
}

const DATE_FORTMAT = 'YYYY-MM-DD'

export const ensureDateFields = (values, fields = []) => {
  return {
    ...values,
    ...Object.assign(
      {},
      ...fields.map(key =>
        values[key]
          ? { [key]: moment(values[key], DATE_FORTMAT) }
          : { [key]: null }
      )
    )
  }
}

export const convertDateFormData = (values, fields = []) => {
  return {
    ...values,
    ...Object.assign(
      {},
      ...fields.map(key =>
        values[key]
          ? { [key]: values[key].format(DATE_FORTMAT) }
          : { [key]: null }
      )
    )
  }
}

export const removeKeyInObjectByKeyList = (object, list = []) => {
  list.map(e => delete object[e])
  return object
}
