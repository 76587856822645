import { Line } from '@ant-design/plots'
import { useFetchApiGet, useFetchApiList } from '@stagapps/redux-utils'
import { Button, Divider, Menu, Progress, Statistic, Typography } from 'antd'
import userProgramApiCall from 'app/apiCalls/userProgram'
import upPortfolioApiCall from 'app/apiCalls/userProgramPortfolio'
import MetaDecorator from 'common/layout/MetaDecorator'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import { getChangeValueClassColor } from 'config/color'
import { userProgramConfig } from 'config/lineChart'
import moment from 'moment'
import { useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import {
  formatCurrency,
  formatPercentage,
  formatUnitQuantity
} from 'utils/formatAmount'
import {
  formatAmountChangeWithPrefix,
  formatCurrencyWithoutCurrencySymbol
} from 'utils/formatAmount/formatUI'
import { formatDate } from 'utils/formatDate'
import { convertMonthsToYearsAndMonths } from 'utils/formatDate/formatUI'
import { cloudflareCdn } from 'utils/image'
import { getSimulatorUserProgramData } from 'utils/simulatorUserProgram'
import InvestmentDetailDonutChart from '../employeeProgram/InvestmentDetailDonutChart'

const { Title } = Typography

function UserProgramDetailPage() {
  const params = useParams()
  const { id } = params
  const { pathname } = useLocation()

  const {
    data: program,
    load: fetchProgram,
    isLoading: isLoadingProgram,
    error: errorProgram
  } = useFetchApiGet(userProgramApiCall.detail, { resourceName: 'data' })

  const {
    data: portfolio,
    load: fetchPort,
    isLoading: isLoadingPort,
    error: errorPort
  } = useFetchApiGet(upPortfolioApiCall.portfolio, { resourceName: 'data' })

  const {
    data: projection,
    load: fetchProjection,
    isLoading: isLoadingProjection,
    error: errorProjection
  } = useFetchApiList(userProgramApiCall.projection, { resourceName: 'data' })

  const isLoading = isLoadingProgram || isLoadingPort || isLoadingProjection
  const error = errorProgram || errorPort || errorProjection

  useEffect(() => {
    fetchProgram(params)
    fetchPort(params)
    fetchProjection(params)
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (!!error) {
    return <ErrorMessage errors={error} />
  }

  const {
    program_name,
    target_amount,
    investment_interval,
    end_date,
    contribute_amount,
    start_date,
    target_return_ratio,
    user_investment_detail,
    logo
  } = program || {}

  const {
    total_contributed_amount,
    user_program_progress_percentage,
    pending_buy_amount,
    unrealized_gain_loss,
    unrealized_gain_loss_percentage,
    pending_buy_percentage,
    market_value
  } = portfolio || {}

  const getLineData = () => {
    const { listDate, listTotalAmount } = getSimulatorUserProgramData(
      moment(start_date, 'YYYY-MM-DD').toDate(),
      contribute_amount,
      0,
      investment_interval,
      target_return_ratio
    )

    return listDate.map((value, index) => ({
      date: moment(value, 'YYYY-MM-DD').format('MM/YYYY'),
      total_amount: listTotalAmount[index],
      name: 'Dự phóng'
    }))
  }

  const lineData = getLineData() || []
  const lastItem = lineData[lineData.length - 1]
  const lastItemAmount = lastItem ? lastItem.total_amount : 0
  console.log(user_investment_detail)

  const programPhoto =
    cloudflareCdn(logo, 150, 150) ||
    cloudflareCdn(`/futureflex/images/onboarding/8.jpg`, 150, 150)
  const quoteBg = cloudflareCdn(`/app/images/goal-quote.jpg`, 500, 500)
  const topupBg = cloudflareCdn(`/app/images/topup-banner.jpg`, 500, 500)
  const annotations = target_amount
    ? [
        {
          type: 'text',
          position: ['max', target_amount],
          content: `ĐẠT MỤC TIÊU ${formatCurrencyWithoutCurrencySymbol(
            target_amount
          )}  🚩`,

          offsetY: -9,
          offsetX: -180,
          style: {
            textBaseline: 'bottom',
            fill: '#52C41A'
          }
        },

        {
          type: 'line',
          start: ['min', target_amount],
          end: ['max', target_amount],
          style: {
            stroke: '#52C41A',
            lineDash: [6, 6]
          }
        }
      ]
    : []

  return (
    <>
      <MetaDecorator title={'Chương trình cá nhân'} />
      <div className="flex flex-col gap-4">
        <div className="flex md:flex-row flex-col gap-4">
          <div className="grow">
            <UICard className="shadow first:mt-0 my-5">
              <div>
                <div className="flex gap-4 items-start">
                  <div>
                    <img
                      className="md:w-[111px] w-[51px] rounded-lg"
                      src={programPhoto}
                    />
                  </div>
                  <div className="w-full">
                    <div className="flex justify-between items-start">
                      <div className="w-full flex-grow">
                        <Title level={3}>{program_name}</Title>
                        <div className="font-medium text-gray-500">
                          Giá trị hiện tại (VND)
                        </div>
                        <div className="flex md:flex-row flex-col md:gap-4 items-baseline">
                          <div className="font-semibold text-2xl">
                            {formatCurrencyWithoutCurrencySymbol(market_value)}
                          </div>
                          <div
                            className={
                              'font-semibold ' +
                              getChangeValueClassColor(unrealized_gain_loss)
                            }
                          >
                            {`${formatAmountChangeWithPrefix(
                              unrealized_gain_loss
                            )} (${formatPercentage(
                              unrealized_gain_loss_percentage
                            )})`}
                          </div>
                        </div>
                        {target_amount ? (
                          <div className="flex md:flex-row flex-col md:gap-4 items-baseline">
                            <div className="w-full flex-grow">
                              {pending_buy_percentage ? (
                                <Progress
                                  strokeColor={'#FFD591'}
                                  trailColor={'#E8F7DE'}
                                  format={percent =>
                                    `${formatUnitQuantity(percent)}%`
                                  }
                                  percent={
                                    user_program_progress_percentage +
                                    pending_buy_percentage
                                  }
                                  success={{
                                    percent: user_program_progress_percentage,
                                    strokeColor: '#52C41A'
                                  }}
                                />
                              ) : (
                                <Progress
                                  strokeColor={'#52C41A'}
                                  trailColor={'#E8F7DE'}
                                  format={percent =>
                                    `${formatUnitQuantity(percent)}%`
                                  }
                                  percent={user_program_progress_percentage}
                                />
                              )}
                            </div>
                          </div>
                        ) : null}

                        {pending_buy_amount ? (
                          <div className="">
                            <span>
                              (Giao dịch chờ khớp lệnh trị giá{' '}
                              {formatCurrencyWithoutCurrencySymbol(
                                pending_buy_amount
                              )}
                              )
                            </span>
                          </div>
                        ) : null}
                      </div>
                      <Menu
                        style={{
                          border: 'none'
                        }}
                        disabledOverflow
                        mode="horizontal"
                        items={[
                          {
                            key: 'options',
                            icon: <OtionsSvgComponent />,
                            children: [
                              {
                                key: 'edit',
                                label: (
                                  <Link to={pathname + '/edit'}>
                                    Chỉnh sửa kế hoạch
                                  </Link>
                                )
                              }
                            ]
                          }
                        ]}
                      />
                    </div>
                  </div>
                </div>
                <Divider />
                <div className="flex flex-wrap gap-2 justify-between">
                  {target_amount > 0 && (
                    <Statistic
                      valueStyle={{ fontWeight: 600 }}
                      title="Số tiền mục tiêu (VND)"
                      value={target_amount}
                      formatter={formatCurrencyWithoutCurrencySymbol}
                    />
                  )}
                  {user_investment_detail > 0 && (
                    <div>
                      <Statistic
                        valueStyle={{ fontWeight: 600 }}
                        title="Thời gian"
                        value={investment_interval}
                        formatter={convertMonthsToYearsAndMonths}
                      />
                      <div>đến {formatDate(end_date)}</div>
                    </div>
                  )}
                  {user_investment_detail > 0 && (
                    <Statistic
                      valueStyle={{ fontWeight: 600 }}
                      title="Số tiền tích lũy hằng tháng (VND)"
                      value={contribute_amount}
                      formatter={formatCurrencyWithoutCurrencySymbol}
                    />
                  )}
                </div>
              </div>
            </UICard>
            {target_amount ? (
              <UICard
                className="shadow first:mt-0 my-5"
                title="Quá trình tích lũy"
              >
                <div className="flex md:flex-row flex-col gap-2">
                  <div className="flex-grow">
                    <Line
                      {...userProgramConfig}
                      data={lineData}
                      fill={true}
                      legend={{ position: 'bottom', offsetY: 10 }}
                      annotations={annotations}
                      yAxis={{
                        max:
                          lastItemAmount < target_amount
                            ? target_amount * 1.1
                            : undefined,
                        label: {
                          style: {
                            fill: '#5A6F93',
                            fontWeight: 'regular'
                          },
                          formatter: a => formatCurrencyWithoutCurrencySymbol(a)
                        }
                      }}
                    />
                  </div>
                  <div className="flex md:flex-col flex-row justify-around">
                    {target_amount > 0 && (
                      <div className="text-gray-400 text-xs">
                        <div>Đạt mục tiêu:</div>
                        <div>{formatCurrency(target_amount)}</div>
                      </div>
                    )}
                    <div className="flex md:flex-col flex-row gap-6">
                      <div>
                        <div>Giá trị hiện tại</div>
                        <div className="font-semibold">
                          {formatCurrency(market_value)}
                        </div>
                        {unrealized_gain_loss ? (
                          <div
                            className={
                              'font-semibold ' +
                              getChangeValueClassColor(unrealized_gain_loss)
                            }
                          >
                            {`${formatAmountChangeWithPrefix(
                              unrealized_gain_loss
                            )} (${formatPercentage(
                              unrealized_gain_loss_percentage
                            )})`}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </UICard>
            ) : null}

            {user_investment_detail ? (
              <UICard
                className="shadow first:mt-0 my-5"
                title={'Phân bổ quỹ đã chọn'}
              >
                <div className="md:px-16 p-2 md:w-[402px]">
                  <InvestmentDetailDonutChart
                    contributed_amount={total_contributed_amount}
                    data={user_investment_detail}
                  />
                </div>
              </UICard>
            ) : null}
          </div>

          <div className="max-w-[402px] w-full flex-grow-0">
            <div
              className="bg-grey-100 shadow w-full aspect-square rounded-lg mb-4 flex flex-col items-center text-center p-10 justify-end"
              style={{ backgroundImage: `url(${topupBg})` }}
            >
              <Link to={`/goals/${id}/buy`}>
                <Button type="primary" size="large">
                  Tăng tốc tích lũy ngay
                </Button>
              </Link>
            </div>
            <div
              className="bg-grey-100 shadow w-full aspect-square rounded-lg mb-4 flex text-center px-10 py-12 "
              style={{ backgroundImage: `url(${quoteBg})` }}
            >
              <div>
                <p className="max-w-xs">
                  Các nhà đầu tư chuyên nghiệp thường rất kỷ luật, nhất quán
                  trong suy nghĩ và cách họ hành động.
                  <br />
                </p>
                <p>Benjamin Graham</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserProgramDetailPage

const OtionsSvgComponent = props => (
  <div className="w-6 aspect-square">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="#0A1A41"
        d="M4.125 11.977a1.312 1.312 0 1 0 2.624 0 1.312 1.312 0 0 0-2.624 0Zm6.563 0a1.312 1.312 0 1 0 2.624 0 1.312 1.312 0 0 0-2.624 0Zm6.562 0a1.312 1.312 0 1 0 2.625 0 1.312 1.312 0 0 0-2.625 0Z"
      />
    </svg>
  </div>
)
