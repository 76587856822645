import { Navigate, Route, Routes } from 'react-router-dom'

import BusinessLogin from 'business/components/login/pages'

import FlexSimulationBundlePage from 'app/components/FlexSimulationBundlePage'
import FlexSimulationPage from 'app/components/FlexSimulationPage'
import CompanyDetail from 'business/components/account/CompanyInfoPage'
import EmployeeListPage from 'business/components/employee/EmployeeListPage'
import {
  EmployeeCreatePage,
  EmployeeDetailPage,
  EmployeeEditPage
} from 'business/components/employee/pages'
import EmployeeProgramCreatePage from 'business/components/employee/program/create'
import EmployeeProgramLayout from 'business/components/employeeProgram/EmployeeProgramLayout'
import { EmployeeProgramListPage } from 'business/components/employeeProgram/EmployeeProgramListPage'
import {
  EmployeeProgramCreateWithoutEmployeePage,
  EmployeeProgramDetailPage,
  ProgramOrdersPage
} from 'business/components/employeeProgram/pages'
import {
  FundOrderDetailPage,
  FundOrderListPage
} from 'business/components/fundOrder/pages'
import LayoutGuest from 'business/components/layouts/LayoutGuest'
import { ProgramOrderDetaiPage } from 'business/components/programOrder/pages'
import BusinessSettingPage from 'business/components/setting'
import AppLayout from 'common/layout/Layout'
import PageNotFound from 'common/ui/error/404'
import ServerErrorPage from 'common/ui/error/server'
import { GuestRoute, PrivateRoute } from './AuthRoute'

function BusinessRoute() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <AppLayout />
          </PrivateRoute>
        }
      >
        <Route path="" element={<Navigate to="/employees" />} />

        <Route path="/employees" element={<EmployeeListPage />} />
        <Route path="/employees/create" element={<EmployeeCreatePage />} />
        <Route path="/employees/:id/edit" element={<EmployeeEditPage />} />
        <Route path="/employees/:id" element={<EmployeeDetailPage />} />
        <Route
          path="/employees/:id/employee-programs/create"
          element={<EmployeeProgramCreatePage />}
        />

        <Route
          path="/employee-programs"
          element={<EmployeeProgramListPage />}
        />
        <Route
          path="/employee-programs/create"
          element={<EmployeeProgramCreateWithoutEmployeePage />}
        />

        <Route element={<EmployeeProgramLayout />}>
          <Route
            path="/employees/:userId/employee-programs/:id"
            element={<EmployeeProgramDetailPage />}
          />
          <Route
            path="/employees/:userId/employee-programs/:id/program-orders"
            element={<ProgramOrdersPage />}
          />
          <Route
            path="/employee-programs/:id"
            element={<EmployeeProgramDetailPage />}
          />
          <Route
            path="/employee-programs/:id/program-orders"
            element={<ProgramOrdersPage />}
          />
        </Route>

        <Route
          path="/employees/:userId/employee-programs/:id/program-orders/:POId"
          element={<ProgramOrderDetaiPage />}
        />
        <Route
          path="/employee-programs/:id/program-orders/:POId"
          element={<ProgramOrderDetaiPage />}
        />

        <Route path="/fund-orders" element={<FundOrderListPage />} />
        <Route path="/fund-orders/:id" element={<FundOrderDetailPage />} />

        <Route path="/company" element={<CompanyDetail />} />
        <Route path="/settings" element={<BusinessSettingPage />} />

        <Route path="/404" element={<PageNotFound />} />
      </Route>
      <Route
        element={
          <GuestRoute>
            <LayoutGuest />
          </GuestRoute>
        }
      >
        <Route path="/login" element={<BusinessLogin />} />
      </Route>

      <Route element={<AppLayout isSideMenu={false} />}>
        <Route path="/simulation" element={<FlexSimulationPage />} />
      </Route>
      <Route path="/simulation-bundle" element={<FlexSimulationBundlePage />} />
      <Route path="/error" element={<ServerErrorPage />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}

export default BusinessRoute
