import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const GET_EMPLOYEES = 'BUSINESS/GET_EMPLOYEES'

const apiCall = enhancedMakeFetchAction(GET_EMPLOYEES, params => ({
  endpoint: generateEndpoint({
    host: `${API_BASE_URL}/investcore-api/flex/v1/employees`,
    params
  }),
  method: 'GET'
}))

export default apiCall
