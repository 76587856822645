import { Pie } from '@ant-design/plots'

import { formatCurrency } from 'utils/formatAmount'

function InvestmentDetailDonutChart({
  data = [],
  contributed_amount,
  ...props
}) {
  if (!data || data.length < 1) {
    return (
      <div className="flex justify-center flex-col gap-6 items-center">
        <div className="w-[300px] aspect-square	rounded-full bg-[#EEF2F7]" />
        <div className="h-4 w-20 rounded bg-[#EEF2F7] " />
      </div>
    )
  }
  return (
    <Pie
      {...config}
      data={data}
      statistic={
        !!contributed_amount || contributed_amount === 0
          ? {
              title: {
                offsetY: -12,
                style: {
                  fontSize: '0.9rem',
                  lineHeight: '1.1rem',
                  fontWeight: 400,
                  color: '#5A6F93'
                },
                content: 'Tổng vốn'
              },
              content: {
                offsetY: -3,
                style: {
                  fontSize: '1.1rem',
                  lineHeight: '1.3rem',
                  fontWeight: 500
                },
                content: formatCurrency(contributed_amount)
              }
            }
          : null
      }
      {...props}
    />
  )
}

export default InvestmentDetailDonutChart

export const pieColors = ['#E378E4', '#031467', '#a8ddb5', '#d62728', '#2ca02c']

const config = {
  style: { marginTop: -42 },
  radius: 1,
  innerRadius: 0.63,
  padding: 0,
  angleField: 'percentage',
  colorField: 'product_code',
  color: pieColors,
  label: {
    autoRotate: false,
    product_code: 'inner',
    offset: '-48%',
    content: '{percentage}',
    style: {
      textAlign: 'center',
      fontSize: 12,
      lineHeight: 20,
      fontWeight: 400
    }
  },

  legend: {
    position: 'bottom',
    showContent: true
  },
  tooltip: {
    formatter: datum => {
      return { name: datum.product_code, value: datum.percentage + '%' }
    }
  }
}
