import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_LIST_API_ID = 'EMPLOYEE/FETCH_EMPLOYEE_ID_LIST'

const apiCall = enhancedMakeFetchAction(FETCH_LIST_API_ID, () => ({
  endpoint: generateEndpoint({
    host: `${API_BASE_URL}/investcore-api/flex/employee/v1/employees`
  }),
  method: 'GET'
}))

export default apiCall
