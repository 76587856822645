import { useEffect, useState } from 'react'

function useCheckMobileDevice() {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia('(max-width: 767px)') // Adjust the breakpoint as needed

    const handleMobileChange = event => {
      setIsMobile(event.matches)
    }

    mobileMediaQuery.addEventListener('change', handleMobileChange)
    setIsMobile(mobileMediaQuery.matches)

    return () => {
      mobileMediaQuery.removeEventListener('change', handleMobileChange)
    }
  }, [])

  return { isMobile }
}

export default useCheckMobileDevice
