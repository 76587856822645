import { Button } from 'antd'
import EmptyProfileCard from 'app/components/cards/EmptyProfileCard'
import useAccountVerifiedStatus from 'app/hooks/useAccountVerifiedStatus'
import MetaDecorator from 'common/layout/MetaDecorator'
import PageTitle from 'common/layout/PageTitle'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import UIDate from 'common/ui/display/UIDate'
import { Item } from 'common/ui/display/UIDescriptions'
import { genderLabels } from 'data'
import { Link } from 'react-router-dom'
import AccountNavigation from './AccountNavigation'

const PAGE_TITLE = 'Thông tin cơ bản'

function AccountBankPage() {
  const {
    isLoading,
    isError,
    error,
    linkTo,
    user,
    isCompleteAllKYC
  } = useAccountVerifiedStatus()
  if (isLoading) {
    return (
      <div className="flex flex-col gap-4">
        <MetaDecorator title={PAGE_TITLE} />
        <PageTitle title={PAGE_TITLE} />
        <UICard>
          <AccountNavigation key="info" />
          <LoadingSpinner />
        </UICard>
      </div>
    )
  }

  if (isError) {
    return (
      <div className="flex flex-col gap-4">
        <MetaDecorator title={PAGE_TITLE} />
        <PageTitle title={PAGE_TITLE} />
        <UICard>
          <AccountNavigation key="info" />
          <ErrorMessage errors={error} />
        </UICard>
      </div>
    )
  }

  if (!isCompleteAllKYC) {
    return (
      <div className="flex flex-col gap-4">
        <MetaDecorator title={PAGE_TITLE} />
        <PageTitle title={PAGE_TITLE} />
        <UICard>
          <AccountNavigation key="info" />
          <EmptyProfileCard>
            <Button type="primary">
              <Link to={linkTo}>Định danh ngay</Link>
            </Button>
          </EmptyProfileCard>
        </UICard>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      <MetaDecorator title={PAGE_TITLE} />
      <PageTitle title={PAGE_TITLE} />
      <UICard>
        <AccountNavigation key="info" />
        <Item label="Họ và tên">
          <div className="lowercase ">
            <div className="first-line:capitalize">{user.full_name}</div>
          </div>
        </Item>
        <Item label="Ngày tháng năm sinh">
          {<UIDate timeData={user.date_of_birth} />}
        </Item>

        <Item label="Giới tính">{genderLabels[user.gender]}</Item>
        <Item label="Số điện thoại">{user.phone}</Item>
        <Item label="Email">{user.email}</Item>
      </UICard>
    </div>
  )
}

export default AccountBankPage
