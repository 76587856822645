import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_UP_PORTFOLIO_ITEM_DETAIL_API_ID =
  'EMPLOYEE/FETCH_UP_PORTFOLIO_ITEM_DETAIL_API_ID'

const apiCall = enhancedMakeFetchAction(
  FETCH_UP_PORTFOLIO_ITEM_DETAIL_API_ID,
  ({ id, userProgramId, ...params }) => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/investcore-api/app/v2/user-programs/${userProgramId}/portfolio-items/${id}`,
      params
    }),
    method: 'GET'
  })
)

export default apiCall
