import MetaDecorator from 'common/layout/MetaDecorator'
import EmployeeProgramRiskAppetite from './EmployeeProgramRiskAppetite'

export function EmployeeProgramRiskAppetitePage() {
  return (
    <>
      <MetaDecorator title="Đánh giá mức độ chịu đựng rủi ro" />
      <EmployeeProgramRiskAppetite />
    </>
  )
}
