import { Menu } from 'antd'
import { Link } from 'react-router-dom'

function AccountNavigation({ key = 'info' }) {
  return (
    <Menu
      selectedKeys={key}
      mode="horizontal"
      items={[
        {
          label: <Link to={'/account'}>Thông tin cơ bản</Link>,
          key: ''
        },
        {
          label: <Link to={'/account/identification'}>Giấy tờ tuỳ thân</Link>,
          key: 'identification'
        },
        {
          label: <Link to={'/account/bank'}>Tài khoản ngân hàng</Link>,
          key: 'bank'
        },
        {
          label: <Link to={'/account/fund-accounts'}>Tài khoản giao dịch</Link>,
          key: 'fund-accounts'
        }
      ]}
    />
  )
}

export default AccountNavigation
