import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

const FETCH_EDIT_API_ID = 'EMPLOYEE_EDIT_API'

const apiCall = enhancedMakeFetchAction(
  FETCH_EDIT_API_ID,
  ({ id, payload }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/flex/v1/employees/${id}`,
    method: 'PUT',
    body: payload,
    notify: true
  })
)

export default apiCall
