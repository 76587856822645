import { ArrowRightOutlined, ReloadOutlined } from '@ant-design/icons'
import { Line } from '@ant-design/plots'
import { generateEndpoint, usePostApi } from '@stagapps/redux-utils'
import {
  Alert,
  Button,
  ConfigProvider,
  Divider,
  Form,
  Input,
  Popconfirm,
  Space,
  Statistic,
  Table,
  Tag,
  Typography
} from 'antd'
import apiCreateUserProgramCall from 'app/apiCalls/userProgram/create'
import UICard from 'common/ui/display/UICard'
import UIInfoTooltip from 'common/ui/display/UIInfoTooltip'
import UIInputFormatNumber from 'common/ui/display/UIInputFormatNumber'
import ValidButton from 'common/ui/form/ValidButton'
import { getChangeValueClassColor } from 'config/color'
import useCheckAuth from 'hooks/auth/useCheckAuth'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import { maxBy } from 'lodash'
import moment from 'moment'
import qs from 'qs'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  formatCurrency,
  formatNumber,
  formatPercentage
} from 'utils/formatAmount'
import { formatCurrencyWithoutCurrencySymbol } from 'utils/formatAmount/formatUI'
import {
  convertIntervalToMonthAndYear,
  convertYearMonthToInterval
} from 'utils/formatDate/convertInterval'
import { getEndDate } from 'utils/formatDate/getDate'
import { cloudflareCdn } from 'utils/image'
import {
  getAdjustmentCriteriaSimulatorUserProgram,
  getSimulatorUserProgramData
} from 'utils/simulatorUserProgram'
import { formatTableColumns } from 'utils/tableConfig'

const { Title } = Typography
function findBiggestValue(data) {
  return maxBy(data, 'total_amount')
}

function OnboardingAnalytic() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const { currentUser } = useCheckAuth()
  let [searchParams, _] = useSearchParams()

  const {
    data: responseData,
    post,
    isLoading: isSubmitting,
    error
  } = usePostApi(apiCreateUserProgramCall, { resourceName: 'data' })

  useEffectSkipFirstRender(() => {
    if (responseData) {
      dispatch(apiCreateUserProgramCall.disposer())
      navigate(`/goals`)
    }
  }, [responseData])

  const formData = Form.useWatch([], form) || {}

  const queryParams = qs.parse(window.location.search.substring(1)) || {}

  const {
    title: program_name,
    start_date,
    monthly_order_payment,
    suggest_port,
    investment_details
  } = queryParams

  const src = cloudflareCdn(`/futureflex/images/onboarding/8.jpg`, 44, 44)

  const [tableData, setTableData] = useState([])
  const [chartData, setChartData] = useState([])
  const [analyticObject, setAnalyticObject] = useState({})
  const { adjustmentCriterias, targetAchievementPercentage } = analyticObject
  const { monthlyContributedAmount, numberOfMonth, targetAmount } =
    adjustmentCriterias || {}

  const suggestPortArray = suggest_port ? JSON.parse(suggest_port) : []
  const initialValues = {
    ...convertIntervalToMonthAndYear(
      parseInt(searchParams.get('investment_interval'))
    ),
    target_amount: parseInt(searchParams.get('target_amount')),
    contribute_amount: parseInt(searchParams.get('contribute_amount')),
    target_percentage: parseFloat(searchParams.get('target_percentage'))
  }

  const { years, months, company_contribute_amount } = formData
  const contribute_amount = Form.useWatch('contribute_amount', form)
  const target_amount = parseInt(Form.useWatch('target_amount', form))
  const investment_interval = convertYearMonthToInterval(years, months)
  const target_percentage = parseInt(Form.useWatch('target_percentage', form))

  useEffect(() => {
    const adjustedCriterias = getAdjustmentCriteriaSimulatorUserProgram(
      moment(queryParams.start_date, 'YYYY-MM-DD').toDate(),
      parseInt(contribute_amount),
      parseInt(0),
      parseInt(investment_interval),
      target_percentage,
      parseInt(target_amount)
    )

    setAnalyticObject(adjustedCriterias)

    const data = getSimulatorUserProgramData(
      moment(queryParams.start_date, 'YYYY-MM-DD').toDate(),
      parseInt(contribute_amount),
      parseInt(0),
      parseInt(investment_interval),
      target_percentage
    )

    const {
      listDate,
      listMonthlyContributedAmount,
      listAccumulatedAmount,
      listTotalAmount
    } = data || {}

    const mergedArray = listDate.map((value, index) => ({
      date: moment(value, 'YYYY-MM-DD').format('MM/YYYY'),
      contribute_amount: listMonthlyContributedAmount[index],
      accumulated_amount: listAccumulatedAmount[index],
      total_amount: listTotalAmount[index]
    }))

    setTableData(mergedArray)
    const tempChartData = [...mergedArray].map(({ date, total_amount }) => ({
      date,
      total_amount,
      name: 'Cá nhân'
    }))
    if (company_contribute_amount) {
      const { listDate, listTotalAmount } = getSimulatorUserProgramData(
        moment(queryParams.start_date, 'YYYY-MM-DD').toDate(),
        parseInt(contribute_amount + company_contribute_amount),
        parseInt(0),
        parseInt(investment_interval),
        target_percentage
      )
      const companyMergedArray = listDate.map((value, index) => ({
        date: moment(value, 'YYYY-MM-DD').format('MM/YYYY'),
        total_amount: listTotalAmount[index],
        name: 'Doanh nghiệp'
      }))
      setChartData([...tempChartData, ...companyMergedArray])
    } else {
      setChartData(tempChartData)
    }
  }, [
    company_contribute_amount,
    contribute_amount,
    target_amount,
    investment_interval,
    target_percentage
  ])

  const { total_amount: maxBufferValue, accumulated_amount } =
    findBiggestValue(tableData) || {}

  const isAmountSufficient = analyticObject && analyticObject.requireAmount > 0
  return (
    <Form
      className="w-full"
      requiredMark={false}
      form={form}
      initialValues={initialValues}
      labelCol={{ span: 24 }}
      onFinish={values => {
        const { id: userId } = currentUser || {}

        const {
          contribute_amount,
          target_amount,
          target_percentage: target_return_ratio
        } = values
        const end_month = chartData[chartData.length - 1].date
        const end_date = getEndDate({ monthly_order_payment, end_month })
        const payload = {
          userId,
          program_name,
          start_date,
          end_date,
          investment_interval,
          contribute_amount,
          target_amount,
          target_return_ratio,
          monthly_order_payment: parseInt(monthly_order_payment),
          investment_details: investment_details
            ? JSON.parse(investment_details)
            : []
        }
        console.debug('payload', payload)
        post(payload)
      }}
    >
      <div className="flex flex-col md:gap-8 gap-4 w-full">
        <div className="text-center">
          <Title level={3}>Kế hoạch chương trình tích lũy phù hợp</Title>
          <p className="text-sm">
            Dựa trên mục tiêu và khả năng của bạn, chúng tôi xây dựng kế hoạch
            tích lũy cho bạn như sau
          </p>
        </div>
        <div className="flex gap-4 md:flex-row flex-col-reverse">
          <div className="flex-shrink-0">
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    defaultBorderColor: 'transparent',
                    defaultBg: '#EAF1FD',
                    defaultColor: '#0066F5'
                  }
                }
              }}
            >
              <div className="flex flex-col gap-4 md:w-[18.5rem] w-[96vw]">
                <UICard>
                  <div>
                    <Space>
                      <img
                        className="w-10 h-10 rounded-lg"
                        src={src}
                        alt={program_name}
                      />
                      <div className="text-base font-semibold">
                        {program_name}
                      </div>
                    </Space>
                    <Divider />

                    <Form.Item
                      label="Số tiền để thực hiện mục tiêu"
                      name="target_amount"
                    >
                      <UIInputFormatNumber
                        addonAfter="VND"
                        className="w-full"
                      />
                    </Form.Item>
                    <div className="mb-2">Thời gian đạt mục tiêu</div>
                    <div className="flex md:flex-row flex-col md:items-end gap-4">
                      <Form.Item className="md:w-1/2" label="" name="years">
                        <UIInputFormatNumber
                          className="w-full"
                          addonAfter="Năm"
                          placeholder="0-99"
                          min={0}
                          max={99}
                        />
                      </Form.Item>
                      <Form.Item className="md:w-1/2" label="" name="months">
                        <UIInputFormatNumber
                          className="w-full"
                          addonAfter="Tháng"
                          placeholder="0-11"
                          min={0}
                          max={11}
                        />
                      </Form.Item>
                    </div>
                    <Form.Item
                      name="contribute_amount"
                      label={'Số tiền tích lũy hàng tháng'}
                    >
                      <UIInputFormatNumber
                        className="w-full"
                        min={0}
                        step={100000}
                        addonAfter="VND"
                      />
                    </Form.Item>
                  </div>
                  <Button
                    onClick={() => {
                      form.setFieldsValue({
                        ...initialValues,
                        target_percentage
                      })
                    }}
                    block
                  >
                    <Space>
                      <ReloadOutlined />
                      Thiết lập lại
                    </Space>
                  </Button>
                </UICard>
                <UICard>
                  <div>
                    <p className="text-base font-semibold">Đề xuất danh mục</p>

                    {suggestPortArray.map(e => {
                      const { fund_type_display, percentage } = e || {}
                      return (
                        <div
                          key={fund_type_display}
                          className="flex justify-between"
                        >
                          <p>{fund_type_display}</p>
                          <p>{formatPercentage(percentage)}</p>
                        </div>
                      )
                    })}
                    <Divider className="my-0" />
                    <div className="flex justify-between">
                      <p className="text-xs w-32">
                        {
                          'Lợi nhuận trung bình của danh mục đã chọn trong quá khứ'
                        }
                      </p>
                      <p
                        className={
                          'font-semibold ' +
                          getChangeValueClassColor(
                            initialValues.target_percentage
                          )
                        }
                      >
                        {formatPercentage(initialValues.target_percentage)}/năm
                      </p>
                    </div>
                    <Form.Item name="target_percentage">
                      <Input type="hidden" />
                    </Form.Item>
                  </div>
                  <Button
                    onClick={() => {
                      navigate(
                        generateEndpoint({
                          host: '/goals/new/portfolio',
                          params: { ...queryParams, ...formData }
                        })
                      )
                    }}
                    block
                  >
                    Điều chỉnh danh mục
                  </Button>
                </UICard>
                <UICard
                  title={
                    <p className="text-base">
                      Mô phỏng kế hoạch khi có doanh ngiệp đóng góp
                    </p>
                  }
                  extraTitle={
                    <UIInfoTooltip
                      title={
                        <p>
                          Chỉ áp dụng đối với những nhân viên được doanh nghiệp
                          đóng góp trong chương trình FutureFlex.
                          <br />
                          Đường mô phỏng kế hoạch khi có doanh nghiệp sẽ cộng
                          phàn đóng góp của doanh nghiệp để tính lại thời gian
                          sớm nhất bạn có thể đạt được mục tiêu.
                        </p>
                      }
                    />
                  }
                >
                  <Form.Item
                    name={'company_contribute_amount'}
                    label={'Doanh nghiệp góp hằng tháng'}
                  >
                    <UIInputFormatNumber
                      className="w-full"
                      min={0}
                      step={100000}
                      addonAfter="VND"
                    />
                  </Form.Item>
                </UICard>
              </div>
            </ConfigProvider>
          </div>

          <div className="flex flex-col gap-4 md:w-fit md:flex-shrink w-[96vw]">
            <UICard>
              <div>
                <Space>
                  <div className="text-xl font-semibold">
                    Quá trình tích lũy{' '}
                  </div>
                  {isAmountSufficient ? (
                    <Tag color="red">Chậm hơn kế hoạch</Tag>
                  ) : (
                    <Tag color="green">Theo kế hoạch</Tag>
                  )}
                </Space>
              </div>
              <div>
                <div className="md:flex overflow-x-scroll ">
                  <div className="flex-grow">
                    <Line
                      data={chartData}
                      {...config}
                      annotations={[
                        {
                          type: 'text',
                          position: ['max', target_amount],
                          content: `ĐẠT MỤC TIÊU ${formatCurrency(
                            target_amount
                          )}  🚩`,

                          offsetY: -9,
                          offsetX: -222,
                          style: {
                            textBaseline: 'bottom',
                            fill: '#52C41A'
                          }
                        },
                        {
                          type: 'text',
                          position: ['min', 'min'],
                          content: 'Đơn vị: VND',
                          offsetY: -10,
                          offsetX: -50,
                          style: {
                            textBaseline: 'bottom',
                            fill: '#5A6F93',
                            fontWeight: 'regular'
                          }
                        },
                        {
                          type: 'line',
                          start: ['min', target_amount],
                          end: ['max', target_amount],
                          style: {
                            stroke: '#52C41A',
                            lineDash: [6, 6]
                          }
                        }
                      ]}
                      yAxis={{
                        max:
                          maxBufferValue < target_amount
                            ? target_amount + target_amount * 0.1
                            : undefined,
                        label: {
                          style: {
                            fill: '#5A6F93',
                            fontWeight: 'regular'
                          },
                          formatter: a => formatCurrencyWithoutCurrencySymbol(a)
                        }
                      }}
                    />
                  </div>
                  <div className="flex md:flex-col flex-row md:self-end md:mb-12 md:gap-6 gap-4 mb-6 text-xs">
                    <div>
                      <Statistic
                        title={
                          <Space className="text-xs">
                            <div className="w-3 h-3 rounded-sm bg-[#52C41A]" />
                            <div>Tổng vốn và lãi</div>
                          </Space>
                        }
                        value={maxBufferValue}
                        formatter={formatCurrencyWithoutCurrencySymbol}
                        valueStyle={{
                          fontWeight: 600,
                          fontSize: 16
                        }}
                      />
                      <div
                        className={getChangeValueClassColor(
                          maxBufferValue - accumulated_amount
                        )}
                      >
                        {maxBufferValue - accumulated_amount > 0
                          ? `+${formatCurrencyWithoutCurrencySymbol(
                              maxBufferValue - accumulated_amount
                            )}`
                          : formatCurrencyWithoutCurrencySymbol(
                              maxBufferValue - accumulated_amount
                            )}
                      </div>
                    </div>
                    <div className="text-[#5A6F93] text-xs w-40">
                      *Tổng vốn và lãi dựa trên lợi nhuận mục tiêu{' '}
                      <span>{formatPercentage(target_percentage)}</span>
                      /năm
                    </div>
                  </div>
                </div>
              </div>

              {analyticObject ? (
                analyticObject.requireAmount > 0 ? (
                  <Alert
                    type="warning"
                    showIcon
                    message={
                      <>
                        <div className="text-base">
                          Thiếu{' '}
                          <span className="font-semibold text-red-500">
                            {formatCurrency(analyticObject.requireAmount)}
                          </span>{' '}
                          để thực hiện "
                          <span className="font-semibold">
                            {queryParams.title}
                          </span>
                          "
                        </div>
                        <p>
                          {'Với '}
                          <span className="text-blue-primary-500">
                            {formatCurrencyWithoutCurrencySymbol(
                              contribute_amount
                            )}
                          </span>
                          {` tích lũy định kỳ trong ${monthsYearsIntervalGenerator(
                            { months, years }
                          )}, bạn sẽ đạt được ${formatPercentage(
                            targetAchievementPercentage
                          )} mục tiêu đã đề ra.`}
                        </p>

                        <p className="italic">
                          Chúng tôi gợi ý bạn thực hiện một số điều chỉnh nhanh
                          bên dưới hoặc tùy chỉnh để đạt được mục tiêu:
                        </p>
                        <div className="flex md:flex-row flex-col gap-4">
                          <SuggestCard
                            title={'Tăng số tiền tích lũy hằng tháng lên'}
                            value={formatCurrency(
                              rounding(monthlyContributedAmount)
                            )}
                            action={() =>
                              form.setFieldValue(
                                'contribute_amount',
                                rounding(monthlyContributedAmount)
                              )
                            }
                          />
                          <SuggestCard
                            title={'Giảm giá trị mục tiêu tài chính xuống còn'}
                            value={formatCurrency(rounding(targetAmount))}
                            action={() =>
                              form.setFieldValue('target_amount', targetAmount)
                            }
                          />
                          <SuggestCard
                            title={'Tăng thời gian thực hiện mục tiêu lên'}
                            value={monthsYearsIntervalGenerator(
                              convertIntervalToMonthAndYear(numberOfMonth)
                            )}
                            action={() => {
                              const { months, years } =
                                convertIntervalToMonthAndYear(numberOfMonth) ||
                                {}
                              form.setFieldValue('months', months)
                              form.setFieldValue('years', years)
                            }}
                          />
                        </div>
                      </>
                    }
                  />
                ) : (
                  <Alert
                    message={
                      <Space direction="vertical">
                        <div>
                          {'Với '}
                          <span className="text-blue-primary-500">
                            {formatCurrencyWithoutCurrencySymbol(
                              contribute_amount
                            )}
                          </span>
                          {` tích lũy định kỳ trong ${monthsYearsIntervalGenerator(
                            { months, years }
                          )}, bạn sẽ đạt được ${formatPercentage(
                            targetAchievementPercentage
                          )} mục tiêu đã đề ra.`}
                        </div>
                        {rounding(monthlyContributedAmount) !=
                        contribute_amount ? (
                          <div>
                            <p className="italic">
                              Bạn vẫn có thể hoàn thành mục tiêu
                            </p>
                            <div className="flex md:flex-row flex-col gap-4">
                              {numberOfMonth == investment_interval ? null : (
                                <SuggestCard
                                  title={'Với thời gian thực hiện trong'}
                                  value={monthsYearsIntervalGenerator(
                                    convertIntervalToMonthAndYear(numberOfMonth)
                                  )}
                                  action={() => {
                                    const { months, years } =
                                      convertIntervalToMonthAndYear(
                                        numberOfMonth
                                      ) || {}
                                    form.setFieldValue('months', months)
                                    form.setFieldValue('years', years)
                                  }}
                                />
                              )}
                              <SuggestCard
                                title={'Với tích lũy hằng tháng'}
                                value={formatCurrency(
                                  rounding(monthlyContributedAmount)
                                )}
                                action={() =>
                                  form.setFieldValue(
                                    'contribute_amount',
                                    rounding(monthlyContributedAmount)
                                  )
                                }
                              />
                            </div>
                          </div>
                        ) : null}
                      </Space>
                    }
                    type="success"
                    showIcon
                  />
                )
              ) : null}
            </UICard>
            <UICard
              className="md:w-full w-[96vw] h-[90vh] overflow-hidden"
              title={'Mô tả tích lũy hằng tháng'}
            >
              <Table
                rowKey="date"
                className="overflow-x-scroll overflow-y-auto h-[90vh] pb-4"
                pagination={false}
                dataSource={tableData}
                columns={formatTableColumns(columns)}
              />
            </UICard>
          </div>
        </div>
      </div>

      <Form.Item>
        <div className="-mx-4 bg-white fixed bottom-0 p-4 w-screen flex justify-center md:gap-4 gap-1">
          <Popconfirm
            title="Lưu lại bản nháp cho lần sau"
            description="Bản nháp sẽ được lưu lại khi bạn tạo mục tiêu mới."
            onConfirm={() => {
              saveGoalDraft(
                generateEndpoint({
                  host: '/goals/new/calculator',
                  params: { ...queryParams, ...formData }
                })
              )
              navigate(`/goals`)
            }}
            onCancel={() => {
              localStorage.removeItem('goalDraft')
              navigate(`/goals`)
            }}
            onFinish
            okText="Lưu nháp"
            cancelText="Xoá bản nháp"
          >
            <Button type="primary" ghost>
              Lưu nháp kế hoạch
            </Button>
          </Popconfirm>

          {isAmountSufficient ? (
            <Popconfirm
              title="Tiếp tục tạo kế hoạch tích lũy?"
              description="Bạn chưa đạt được mục tiêu với kế hoạch này, bạn vẫn muốn tiếp tục khởi tạo?"
              onConfirm={() => form.submit()}
              okText="Xác nhận"
              cancelText="Xem lại"
            >
              <Button type="primary">Tạo kế hoạch tích lũy</Button>
            </Popconfirm>
          ) : (
            <ValidButton
              form={form}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              btnTitle="Tạo kế hoạch tích lũy"
            />
          )}
        </div>
      </Form.Item>
    </Form>
  )
}

export default OnboardingAnalytic

const rounding = input => Math.ceil(input / 100000) * 100000

const columns = [
  { title: 'Tháng', dataIndex: 'date' },
  {
    title: 'Giá trị tích lũy hằng tháng (VND)',
    dataIndex: 'contribute_amount',
    type: 'formatCurrency'
  },
  {
    title: 'Tổng vốn tích lũy (VND)',
    dataIndex: 'accumulated_amount',
    type: 'formatCurrency'
  },
  {
    title: 'Tổng giá trị vốn và lãi (VND)',
    dataIndex: 'total_amount',
    type: 'formatCurrency'
  }
]

const config = {
  xField: 'date',
  yField: 'total_amount',
  seriesField: 'name',
  color: ['#52C41A', '#E378E4', '#031467', '#a8ddb5', '#d62728', '#2ca02c'],

  style: {
    marginTop: 24,
    marginBottom: 24,
    marginRight: 12,

    height: 369
  },
  xAxis: {
    range: [0, 1],
    tickCount: 6,
    label: {
      offsetY: 5
    }
  },
  tooltip: {
    position: 'top',

    formatter: e => {
      const { total_amount, name } = e || {}
      return { name, value: formatCurrency(total_amount) }
    },
    domStyles: {
      'g2-tooltip': {
        backgroundColor: '#ffffffaa',
        borderRadius: '0.25rem'
      }
    }
  },
  legend: false
}

const SuggestCard = ({ title, value, action }) => {
  return (
    <UICard
      onClick={action}
      hoverable
      size="small"
      bordered
      className={'border-blue-primary-500'}
    >
      <div>
        <div>{title}</div>
        <div className="font-semibold">{value}</div>
        <div className="mt-2 flex gap-1 text-blue-primary-500">
          Áp dụng
          <ArrowRightOutlined />
        </div>
      </div>
    </UICard>
  )
}

const monthsYearsIntervalGenerator = ({ years, months }) => {
  return (
    (years ? formatNumber(years) + ' năm ' : '') +
    (months ? formatNumber(months) + ' tháng ' : '')
  )
}

const saveGoalDraft = async location => {
  localStorage.setItem('goalDraft', JSON.stringify(location))
}
