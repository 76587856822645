import { Card, Typography } from 'antd'

import UIInfoTooltip from 'common/ui/display/UIInfoTooltip'
import { isNumber } from 'lodash'
import { formatCurrency } from 'utils/formatAmount'

const { Title } = Typography

const ContributeCard = ({ title, value, tooltipTitle }) => {
  return (
    <Card size="small" className="grow">
      <div className="px-2">
        <div className="flex justify-between">
          <div>{title}</div>
          {tooltipTitle ? <UIInfoTooltip title={tooltipTitle} /> : null}
        </div>
        <Title level={3}>{isNumber(value) && formatCurrency(value)}</Title>
      </div>
    </Card>
  )
}

export default ContributeCard
