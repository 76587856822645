import { Pie } from '@ant-design/plots'
import { formatCurrency } from 'utils/formatAmount'

function PortfolioDonutChart({ data = [], totalMarketValue, ...props }) {
  if (!data || data.length < 1 || totalMarketValue === 0) {
    return (
      <div className="flex justify-center flex-col gap-6 items-center">
        <div className="w-[300px] aspect-square	rounded-full bg-[#EEF2F7]" />
        <div className="h-4 w-20 rounded bg-[#EEF2F7] " />
      </div>
    )
  }

  return (
    <div className=" md:w-full w-[70%]">
      <Pie
        {...config}
        data={data}
        statistic={
          !!totalMarketValue || totalMarketValue === 0
            ? {
                title: {
                  offsetY: -12,
                  style: {
                    fontSize: '0.9rem',
                    lineHeight: '1.1rem',
                    fontWeight: 400,
                    color: '#5A6F93'
                  },
                  content: 'Giá trị hiện tại'
                },
                content: {
                  offsetY: -3,
                  style: {
                    fontSize: '1.1rem',
                    lineHeight: '1.3rem',
                    fontWeight: 500
                  },
                  content: formatCurrency(totalMarketValue)
                }
              }
            : null
        }
        {...props}
      />
    </div>
  )
}

export default PortfolioDonutChart

export const pieColors = [
  '#5FDDAE',
  '#8C74E4',
  '#E378E4',
  '#031467',
  '#a8ddb5',
  '#d62728',
  '#2ca02c'
]

const config = {
  style: { marginTop: -42 },
  radius: 1,
  innerRadius: 0.63,
  padding: 0,
  angleField: 'market_value',
  colorField: 'product_code',
  color: pieColors,
  label: {
    autoRotate: false,
    product_code: 'inner',
    offset: '-48%',
    content: '{percentage}',
    style: {
      textAlign: 'center',
      fontSize: 12,
      lineHeight: 20,
      fontWeight: 400
    }
  },

  legend: {
    position: 'bottom',
    showContent: true
  },

  tooltip: {
    formatter: datum => {
      const { product_code, market_value } = datum || {}

      return {
        name: product_code,
        value: formatCurrency(market_value)
      }
    }
  }
}
