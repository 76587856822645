import { MenuOutlined } from '@ant-design/icons'
import { Drawer, FloatButton, Layout, Menu } from 'antd'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import StagLogoSVG from 'common/svg/logo/stag'
import { sideMenuItems } from 'config/data'
import { FLEX_APP } from 'config/env.config'
import useCheckMobileDevice from 'hooks/useCheckMobileDevice'

const SideMenu = () => {
  const [collapsed, setCollapsed] = useState(false)
  const { isMobile } = useCheckMobileDevice()

  const { pathname } = useLocation()

  const [open, setOpen] = useState(false)
  const showDrawer = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  const pathnames = pathname.split('/').filter(item => item)

  const selectedKeys = `/${pathnames.slice(0, 1).join('/')}`

  const items = sideMenuItems[FLEX_APP]()

  if (isMobile) {
    return (
      <>
        <FloatButton
          style={{
            top: '4rem',
            right: '1rem'
          }}
          type="primary"
          onClick={showDrawer}
          icon={<MenuOutlined />}
        />

        <Drawer
          width={'fit-content'}
          styles={{
            body: { padding: '16px 4px', backgroundColor: '#001529' },
            wrapper: {
              backgroundColor: '#001529'
            }
          }}
          className="w-fit"
          title={
            <div className="flex md:w-[134px] w-32 aspect-[134/31]">
              <StagLogoSVG />
            </div>
          }
          placement="right"
          onClose={onClose}
          open={open}
        >
          <Menu
            onClick={onClose}
            theme="dark"
            mode="inline"
            selectedKeys={selectedKeys}
            items={items}
            className="text-sm"
          />
        </Drawer>
      </>
    )
  }

  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={value => setCollapsed(value)}
      theme="dark"
      className="py-2"
      breakpoint="xl"
      reverseArrow={false}
      width={240}
    >
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={selectedKeys}
        items={items}
        className="text-sm"
      />
    </Layout.Sider>
  )
}

export default SideMenu
