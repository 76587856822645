import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

export const GET_USER_PORTFOLIOS_PRODUCTS = 'GET_USER_PORTFOLIOS_PRODUCTS'

const apiCall = enhancedMakeFetchAction(GET_USER_PORTFOLIOS_PRODUCTS, () => ({
  endpoint: `${API_BASE_URL}/investcore-api/app/v2/portfolios/products`,
  method: 'GET'
}))

export default apiCall
