import buyEstimateApiCall from './buyEstimate'
import buyApiCall from './createBuy'
import buyOTPApiCall from './createBuyOTP'
import sellApiCall from './createSell'
import sellOtpApiCall from './createSellOtp'
import detailApiCall from './detail'
import listApiCall from './list'
import sellEstimateApiCall from './sellEstimate'

const ApiCall = {
  list: listApiCall,
  detail: detailApiCall,
  sellEstimate: sellEstimateApiCall,
  buyEstimate: buyEstimateApiCall,
  createSell: sellApiCall,
  createBuy: buyApiCall,
  createBuyOTP: buyOTPApiCall,
  sellOtp: sellOtpApiCall
}

export default ApiCall
