import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { usePostApi } from '@stagapps/redux-utils'
import { Alert, Checkbox, Form, Input, Radio, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'

import apiDistrictsCallGenerator from 'app/apiCalls/districts'
import apiProvincesCallGenerator from 'app/apiCalls/provinces'
import apiPatchCall from 'app/apiCalls/verification/partialUpdateIdInfo'
import SelectListData from 'common/ui/form/SelectListData'
import ValidButton from 'common/ui/form/ValidButton'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import { useDispatch } from 'react-redux'
import { convertDateFormData, ensureDateFields } from 'utils/formData'

const { Title } = Typography

const dateFields = ['date_of_birth', 'issue_date']

function CompletingIDInfosForm({ initialValues = {} }) {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    residential_address_city_id,
    residential_address_district_id,
    residential_address_ward,
    residential_address,
    address_city_id
  } = Form.useWatch([], form) || {}

  const { data, post, isLoading: isSubmitting, error } = usePostApi(
    apiPatchCall,
    {
      resourceName: 'data'
    }
  )

  const onFinish = values => {
    post({ ...convertDateFormData(values, dateFields) })
  }

  const { error: errorMessage, error_code } = error || {}

  useEffectSkipFirstRender(() => {
    if (data) {
      navigate('/verification/bank-account')
      dispatch(apiPatchCall.disposer())
    }
  }, [data])

  const {
    full_name,
    residential_address_city_id: initialResidentialAddressCityId,
    residential_address_district_id: initialResidentialAddressDistrictId,
    address_city_id: initialAddressCityId,
    address_district_id: initialAddressDistrictId
  } = initialValues || {}

  return (
    <Form
      requiredMark={false}
      initialValues={ensureDateFields(
        {
          ...initialValues,
          residential_address_city_id:
            initialResidentialAddressCityId === 0
              ? undefined
              : initialResidentialAddressCityId,

          initialResidentialAddressDistrictId:
            initialResidentialAddressDistrictId === 0
              ? undefined
              : initialResidentialAddressDistrictId,

          address_city_id:
            initialAddressCityId === 0 ? undefined : initialAddressCityId,

          initialAddressDistrictId:
            initialAddressDistrictId === 0
              ? undefined
              : initialAddressDistrictId
        },
        dateFields
      )}
      onFinish={onFinish}
      form={form}
      size="large"
      labelCol={{ span: 24 }}
    >
      {!!errorMessage || !!error_code ? (
        <Alert message={errorMessage || error_code} type="error" showIcon />
      ) : null}
      <Title level={5}>1. Thông tin cơ bản</Title>
      <Form.Item
        label="Giới tính"
        name="gender"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn'
          }
        ]}
      >
        <Radio.Group>
          <Radio value={'male'}>Nam</Radio>
          <Radio value={'female'}>Nữ</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label="Họ và tên (đầy đủ theo CMND/CCCD)"
        name="full_name"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập'
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={'date_of_birth'}
        label={'Ngày tháng năm sinh'}
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn'
          }
        ]}
      >
        <DatePicker
          sx={{ px: 1 }}
          openTo="year"
          views={['year', 'month', 'day']}
          slotProps={{
            textField: { variant: 'standard' }
          }}
        />
      </Form.Item>
      <Title level={5}>2. Thông tin chứng từ</Title>
      <Form.Item
        label="Số CMND/CCCD"
        name="id_number"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập'
          }
        ]}
      >
        <Input />
      </Form.Item>
      <div className="md:flex w-full gap-6">
        <Form.Item
          name={'issue_date'}
          label={'Ngày cấp'}
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn'
            }
          ]}
        >
          <DatePicker
            sx={{ px: 1 }}
            openTo="year"
            views={['year', 'month', 'day']}
            slotProps={{
              textField: { variant: 'standard' }
            }}
            formatDensity="spacious"
          />
        </Form.Item>

        <Form.Item
          className="w-full"
          label="Nơi cấp"
          name="issue_place"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập'
            }
          ]}
        >
          <Input />
        </Form.Item>
      </div>
      <Title level={5}>3. Địa chỉ thường trú</Title>
      <div className="md:flex w-full gap-4 ">
        <div className="md:min-w-[10rem]">
          <Form.Item
            label="Thành phố"
            name="residential_address_city_id"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn'
              }
            ]}
          >
            <SelectListData
              onClear={_ =>
                form.setFieldValue('residential_address_district_id', undefined)
              }
              apiCall={apiProvincesCallGenerator('residential_address_city_id')}
            />
          </Form.Item>
        </div>

        <div className="md:min-w-[10rem]">
          <Form.Item
            label="Quận/Huyện"
            name="residential_address_district_id"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn'
              }
            ]}
          >
            <SelectListData
              params={{ province_id: residential_address_city_id }}
              apiCall={apiDistrictsCallGenerator(
                'residential_address_district_id'
              )}
            />
          </Form.Item>
        </div>

        <div className="md:min-w-[10rem]">
          <Form.Item
            label="Phường/Xã/Thị trấn"
            name="residential_address_ward"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập'
              }
            ]}
          >
            <Input />
          </Form.Item>
        </div>
      </div>
      <Form.Item
        label="Địa chỉ thường trú"
        name="residential_address"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập'
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Title level={5}>4. Địa chỉ liên hệ</Title>
      <Checkbox
        onChange={e => {
          if (e.target.checked) {
            form.setFieldValue('address_city_id', residential_address_city_id)
            form.setFieldValue(
              'address_district_id',
              residential_address_district_id
            )
            form.setFieldValue('address_ward', residential_address_ward)
            form.setFieldValue('address', residential_address)
          } else {
            form.setFieldValue('address_city_id', undefined)
            form.setFieldValue('address_district_id', undefined)
            form.setFieldValue('address_ward', undefined)
            form.setFieldValue('address', undefined)
          }
        }}
      >
        {'Giống địa chỉ thường trú'}
      </Checkbox>
      <div className="md:flex w-full gap-4 ">
        <div className="md:min-w-[10rem]">
          <Form.Item
            label="Thành phố"
            name="address_city_id"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn'
              }
            ]}
          >
            <SelectListData
              onClear={_ =>
                form.setFieldValue('address_district_id', undefined)
              }
              apiCall={apiProvincesCallGenerator('address_city_id')}
            />
          </Form.Item>
        </div>
        <div className="md:min-w-[10rem]">
          <Form.Item
            label="Quận/Huyện"
            name="address_district_id"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn'
              }
            ]}
          >
            <SelectListData
              params={{ province_id: address_city_id }}
              apiCall={apiDistrictsCallGenerator('address_district_id')}
            />
          </Form.Item>
        </div>
        <div className="md:min-w-[10rem]">
          <Form.Item
            label="Phường/Xã/Thị trấn"
            name="address_ward"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập'
              }
            ]}
          >
            <Input />
          </Form.Item>
        </div>
      </div>
      <Form.Item
        label="Địa chỉ liên hệ"
        name="address"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập'
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <ValidButton
          size="large"
          type="primary"
          htmlType="submit"
          className="w-full"
          btnTitle="Tiếp tục"
          form={form}
          loading={isSubmitting}
        />
      </Form.Item>
    </Form>
  )
}

export default CompletingIDInfosForm
