const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={252}
    height={294}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#E4EBF7"
        fillRule="evenodd"
        d="M.5 129.023v-2.084C.5 58.364 56.091 2.774 124.665 2.774h2.085c68.574 0 124.165 55.59 124.165 124.165v2.084c0 68.575-55.59 124.166-124.165 124.166h-2.085C56.091 253.189.5 197.598.5 129.023Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M41.917 132.92a8.227 8.227 0 0 1-5.818 7.057 8.228 8.228 0 0 1-10.254-5.51 8.23 8.23 0 0 1 2.674-8.747 8.231 8.231 0 0 1 13.398 7.2Z"
        clipRule="evenodd"
      />
      <path
        stroke="#fff"
        strokeWidth={2}
        d="m50.49 148.505-12.58 10.73m1.242-22.875 10.425 5.91-10.425-5.91Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M42.036 161.28a5.638 5.638 0 0 1-8.276 4.4 5.634 5.634 0 0 1-2.94-5.53 5.638 5.638 0 0 1 9.973-3.001 5.637 5.637 0 0 1 1.243 4.131Zm17.618-16.019a5.67 5.67 0 0 1-2.057 3.827 5.676 5.676 0 0 1-9.24-4.965 5.675 5.675 0 0 1 6.218-5.079 5.673 5.673 0 0 1 5.079 6.217ZM100.86 29.516l29.66-.013a4.563 4.563 0 1 0-.004-9.126l-29.66.013a4.564 4.564 0 0 0 .005 9.126h-.001Zm11.345 18.238 29.659-.013a4.563 4.563 0 1 0-.004-9.126l-29.66.013a4.562 4.562 0 1 0 .005 9.126Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M114.566 29.503V29.5l15.698-.007a4.563 4.563 0 1 0 .004 9.126l-15.698.007v-.002a4.56 4.56 0 0 0 4.465-4.563 4.566 4.566 0 0 0-4.469-4.559v.001Zm71.339 108.22c-.55 5.455-5.418 9.432-10.873 8.882-5.456-.55-9.432-5.418-8.882-10.873.55-5.455 5.418-9.432 10.873-8.882 5.455.55 9.432 5.418 8.882 10.873Z"
        clipRule="evenodd"
      />
      <path
        stroke="#fff"
        strokeWidth={2}
        d="m194.341 158.42-15.171 12.94m1.5-27.588 12.572 7.129-12.572-7.129Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M186.05 171.926a6.798 6.798 0 0 1-13.528-1.363 6.794 6.794 0 0 1 2.48-4.543 6.8 6.8 0 0 1 11.047 5.906h.001Zm18.57-16.641a6.847 6.847 0 1 1-13.627-1.375 6.846 6.846 0 0 1 7.493-6.052 6.846 6.846 0 0 1 6.133 7.427"
        clipRule="evenodd"
      />
      <path
        stroke="#fff"
        strokeWidth={2}
        d="M153.488 194.074c0 .586-.233 1.148-.647 1.563a2.213 2.213 0 0 1-3.126 0 2.213 2.213 0 0 1 0-3.126 2.213 2.213 0 0 1 3.126 0c.414.415.647.977.647 1.563Zm72.943-75.857a2.212 2.212 0 0 1-4.421 0 2.21 2.21 0 1 1 4.421 0Zm-8.841 34.834a2.212 2.212 0 0 1-4.421 0c0-.586.233-1.148.647-1.563a2.213 2.213 0 0 1 3.126 0c.414.415.647.977.647 1.563h.001Zm-39.25-43.209a2.209 2.209 0 0 1-2.211 2.211 2.209 2.209 0 0 1-2.211-2.211 2.21 2.21 0 0 1 3.774-1.563c.414.415.647.977.647 1.563h.001Zm18.274-15.388a2.213 2.213 0 0 1-2.21 2.21 2.212 2.212 0 0 1-2.211-2.21 2.212 2.212 0 0 1 2.211-2.21 2.211 2.211 0 0 1 2.21 2.21Zm6.73 88.069a2.213 2.213 0 0 1-1.364 2.042 2.213 2.213 0 0 1-3.056-2.042c0-.586.233-1.148.647-1.563a2.213 2.213 0 0 1 3.126 0c.414.415.647.977.647 1.563Z"
        clipRule="evenodd"
      />
      <path
        stroke="#fff"
        strokeWidth={2}
        d="m222.01 119.404-12.77 7.859-15.228-7.86V96.668m21.613 58.594-1.902 20.075-10.87 5.958 12.772-26.033Zm-40.524 21.374-6.322 9.761H157.48l-4.484 6.449 22.105-16.21Zm1.273-49.356v-15.72 15.72Z"
      />
      <path
        fill="#0066F5"
        d="M195.75 3.073a6.756 6.756 0 0 0-6.76 6.76v39.334c0 3.712 3.023 6.76 6.76 6.76h29.5c3.712 0 6.76-3.048 6.76-6.76V21.904c0-1.991-.885-3.86-2.384-5.138L215.515 4.67a6.785 6.785 0 0 0-4.4-1.647h-15.414l.049.049Z"
      />
      <path
        fill="#0958D9"
        d="M216.179 5.236c-2.016-1.72-5.089-.295-5.089 2.311v7.154c0 3.712 3.024 6.76 6.761 6.76h8.948c2.852 0 4.154-3.564 1.991-5.408L216.13 5.187l.049.05Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M211.115 30.73c0-1.033.811-1.845 1.843-1.845h9.834a1.84 1.84 0 0 1 1.843 1.844 1.856 1.856 0 0 1-1.843 1.844h-9.834a1.84 1.84 0 0 1-1.843-1.844ZM211.115 44.25c0-1.032.811-1.844 1.843-1.844h9.834a1.84 1.84 0 0 1 1.843 1.844 1.857 1.857 0 0 1-1.843 1.844h-9.834a1.841 1.841 0 0 1-1.843-1.844Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M201.896 25.198a5.508 5.508 0 0 0-5.531 5.531 5.524 5.524 0 0 0 5.531 5.531 5.54 5.54 0 0 0 5.531-5.53 5.524 5.524 0 0 0-5.531-5.532ZM201.896 38.719a5.508 5.508 0 0 0-5.531 5.531 5.524 5.524 0 0 0 5.531 5.531 5.54 5.54 0 0 0 5.531-5.531 5.524 5.524 0 0 0-5.531-5.531Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M168.066 68.367h-3.93a4.73 4.73 0 0 1-4.717-4.717 4.727 4.727 0 0 1 4.717-4.717h3.93a4.73 4.73 0 0 1 4.717 4.717 4.731 4.731 0 0 1-4.717 4.717Z"
        clipRule="evenodd"
      />
      <path
        fill="#5BA02E"
        fillRule="evenodd"
        d="M168.714 248.838a6.61 6.61 0 0 1-6.61-6.611v-66.108a6.61 6.61 0 0 1 13.221 0v66.108a6.613 6.613 0 0 1-6.61 6.61"
        clipRule="evenodd"
      />
      <path
        fill="#92C110"
        fillRule="evenodd"
        d="M176.647 248.176a6.613 6.613 0 0 1-6.61-6.61v-33.054a6.598 6.598 0 0 1 1.907-4.727 6.604 6.604 0 0 1 4.704-1.965c.875 0 1.741.173 2.548.511a6.594 6.594 0 0 1 3.582 3.626c.328.812.491 1.68.48 2.555v33.053a6.607 6.607 0 0 1-6.61 6.611"
        clipRule="evenodd"
      />
      <path
        fill="#F2D7AD"
        fillRule="evenodd"
        d="M186.494 293.89h-27.376a3.173 3.173 0 0 1-2.242-.928 3.173 3.173 0 0 1-.928-2.242v-45.887c0-.841.334-1.647.928-2.242a3.173 3.173 0 0 1 2.242-.928h27.376c.841 0 1.647.334 2.242.928.594.595.928 1.401.928 2.242v45.886c0 .841-.334 1.647-.928 2.242a3.173 3.173 0 0 1-2.242.928"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M82.472 147.673s6.377-.927 17.566-1.28c11.729-.371 17.57 1.086 17.57 1.086s3.697-3.855.968-8.424c1.278-12.077 5.982-32.827.335-48.273-1.116-1.339-3.743-1.512-7.536-.62-1.337.315-7.147-.149-7.983-.1l-15.311-.347s-3.487-.17-8.035-.508c-1.512-.113-4.227-1.683-5.458-.338-.406.443-2.425 5.669-1.97 16.077l8.635 35.642s-3.141 3.61 1.219 7.085Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFC6A0"
        fillRule="evenodd"
        d="m76.268 73.325-.9-6.397 11.982-6.52s7.302-.118 8.038 1.205c.737 1.324-5.616.993-5.616.993s-1.836 1.388-2.615 2.5c-1.654 2.363-.986 6.471-8.318 5.986-1.708.284-2.57 2.233-2.57 2.233"
        clipRule="evenodd"
      />
      <path
        fill="#FFB594"
        fillRule="evenodd"
        d="M52.94 77.672s14.217 9.406 24.973 14.444c1.061.497-2.094 16.183-11.892 11.811-7.436-3.318-20.162-8.44-21.482-14.496-.71-3.258 2.543-7.643 8.401-11.76v.001Zm89.422 2.441s-6.693 2.999-13.844 6.876c-3.894 2.11-10.137 4.704-12.33 7.988-6.224 9.314 3.536 11.22 12.947 7.503 6.71-2.651 28.999-12.127 13.227-22.367Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFC6A0"
        fillRule="evenodd"
        d="m76.666 66.36 3.06 3.881s-2.783 2.67-6.31 5.747c-7.103 6.195-12.803 14.296-15.995 16.44-3.966 2.662-9.754 3.314-12.177-.118-3.553-5.032.464-14.628 31.422-25.95Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M65.174 85.116s-2.34 8.413-8.912 14.447c.652.548 18.586 10.51 22.144 10.056 5.238-.669 6.417-18.968 1.145-20.531-.702-.208-5.901-1.286-8.853-2.167-.87-.26-1.611-1.71-3.545-.936l-1.98-.869h.001Zm63.688.71s5.318 1.956 7.325 13.734c-.546.274-17.55 12.35-21.829 7.805-6.534-6.94-.766-17.393 4.275-18.61 4.646-1.121 5.03-1.37 10.23-2.929"
        clipRule="evenodd"
      />
      <path
        stroke="#E4EBF7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.051}
        d="M78.68 94.656s.911 7.41-4.914 13.078"
      />
      <path
        stroke="#E4EBF7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.932}
        d="M87.897 94.68s3.124 2.572 10.263 2.572c7.14 0 9.074-3.437 9.074-3.437"
      />
      <path
        fill="#FFC6A0"
        fillRule="evenodd"
        d="m117.684 68.639-6.781-6.177s-5.355-4.314-9.223-.893c-3.867 3.422 4.463 2.083 5.653 4.165 1.19 2.082.848 1.143-2.083.446-5.603-1.331-2.082.893 2.975 5.355 2.091 1.845 6.992.955 6.992.955l2.467-3.851Z"
        clipRule="evenodd"
      />
      <path
        fill="#FFB594"
        fillRule="evenodd"
        d="m105.782 91.315-.297-10.937-15.918-.027-.53 10.45c-.026.403.17.788.515.999 2.049 1.251 9.387 5.093 15.799.424.287-.21.443-.554.431-.91"
        clipRule="evenodd"
      />
      <path
        fill="#5C2552"
        fillRule="evenodd"
        d="M108.073 74.24c.817-1.147.982-9.118 1.015-11.928a1.048 1.048 0 0 0-.965-1.055l-4.62-.365c-7.71-1.044-17.07.624-18.253 6.346-5.482 5.813-.42 13.244-.42 13.244s1.962 3.566 4.304 6.791c.756 1.041.398-3.731 3.04-5.929 5.524-4.594 15.899-7.103 15.899-7.103"
        clipRule="evenodd"
      />
      <path
        fill="#FFC6A0"
        fillRule="evenodd"
        d="M88.926 83.206s2.685 6.202 11.602 6.522c7.82.28 8.973-7.008 7.434-17.505l-.909-5.483c-6.118-2.897-15.478.54-15.478.54s-.576 2.044-.19 5.504c-2.276 2.066-1.824 5.618-1.824 5.618s-.905-1.922-1.98-2.321c-.86-.32-1.897.089-2.322 1.98-1.04 4.632 3.667 5.145 3.667 5.145Z"
        clipRule="evenodd"
      />
      <path
        stroke="#DB836E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.145}
        d="m101.343 77.099 1.701-.928-1.015-4.324.674-1.406"
      />
      <path
        fill="#552950"
        fillRule="evenodd"
        d="M106.046 74.092c-.022.713-.452 1.279-.96 1.263-.51-.016-.904-.607-.882-1.32.021-.713.452-1.278.96-1.263.51.016.904.607.882 1.32Zm-7.954.257c-.022.713-.452 1.278-.961 1.263-.509-.016-.904-.607-.882-1.32.022-.713.452-1.279.961-1.263.51.016.904.606.882 1.32Z"
        clipRule="evenodd"
      />
      <path
        stroke="#DB836E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.145}
        d="M91.632 86.786s5.269 4.957 12.679 2.327"
      />
      <path
        fill="#DB836E"
        fillRule="evenodd"
        d="M100.276 81.903s-3.592.232-1.44-2.79c1.59-1.496 4.897-.46 4.897-.46s1.156 3.906-3.457 3.25Z"
        clipRule="evenodd"
      />
      <path
        stroke="#5C2552"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.526}
        d="M94.383 71.975s2.492-1.144 4.778-1.073m4.219-.302s2.483.84 3.402.715l-3.402-.715Z"
      />
      <path
        stroke="#DB836E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.145}
        d="M99.837 83.719s1.911.151 2.509-.254M86.82 77.374s.96.879 1.458 2.106c-.377.48-1.033 1.152-.236 1.809l-1.222-3.915Z"
      />
      <path
        stroke="#E4EBF7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.051}
        d="m100.665 115.821 10.04-2.008m-22.423 2.008 15.73-3.012-15.73 3.012Z"
      />
      <path
        stroke="#E4EBF7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.114}
        d="M67.008 86.763s-1.598 8.83-6.697 14.078"
      />
      <path
        stroke="#E4EBF7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.051}
        d="M128.81 87.934s3.013 4.121 4.06 11.785"
      />
      <path
        stroke="#DB836E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.795}
        d="M64.59 84.816s-6.03 9.912-13.607 9.903"
      />
      <path
        fill="#FFC6A0"
        fillRule="evenodd"
        d="m112.866 65.909-.142 5.32s5.993 4.472 11.945 9.202c4.482 3.562 8.888 7.455 10.985 8.662 4.804 2.766 8.9 3.355 11.076 1.808 4.071-2.894 4.373-9.878-8.136-15.263-4.271-1.838-16.144-6.36-25.728-9.73"
        clipRule="evenodd"
      />
      <path
        stroke="#DB836E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.75}
        d="M131.032 85.488s4.588 5.757 11.619 6.214"
      />
      <path
        stroke="#E4EBF7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.051}
        d="M122.208 105.73s-.393 8.564-1.34 13.612"
      />
      <path
        stroke="#648BD8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.051}
        d="M116.284 161.512s-3.57-1.488-2.678-7.14"
      />
      <path
        fill="#CBD1D1"
        fillRule="evenodd"
        d="M102.02 290.246s4.326 2.057 7.408 1.03c2.842-.948 4.564.673 7.132 1.186 2.57.514 6.925 1.108 11.772-1.269-.104-5.551-6.939-4.01-12.048-6.763-2.582-1.39-3.812-4.757-3.625-8.863h-9.471s-1.402 10.596-1.169 14.68"
        clipRule="evenodd"
      />
      <path
        fill="#2B0849"
        fillRule="evenodd"
        d="M101.996 290.073s2.447 1.281 6.809.658c3.081-.44 3.74.485 7.479 1.039 3.739.554 10.802-.07 11.91-.9.415 1.108-.347 2.077-.347 2.077s-1.523.608-4.847.831c-2.045.137-5.843.293-7.663-.507-1.8-1.385-5.286-1.917-5.77-.243-3.947.958-7.41-.288-7.41-.288l-.16-2.667h-.001Z"
        clipRule="evenodd"
      />
      <path
        fill="#A4AABA"
        fillRule="evenodd"
        d="M109.324 276.19h3.116s-.103 6.751 4.57 8.62c-4.673.624-8.62-2.32-7.686-8.62Z"
        clipRule="evenodd"
      />
      <path
        fill="#CBD1D1"
        fillRule="evenodd"
        d="M58.15 272.52s-2.122 7.47-4.518 12.396c-1.811 3.724-4.255 7.548 5.505 7.548 6.698 0 9.02-.483 7.479-6.648-1.541-6.164.268-13.296.268-13.296H58.15Z"
        clipRule="evenodd"
      />
      <path
        fill="#2B0849"
        fillRule="evenodd"
        d="M52.04 290.04s2.111 1.178 6.682 1.178c6.128 0 8.31-1.662 8.31-1.662s.605 1.122-.624 2.18c-1 .862-3.624 1.603-7.444 1.559-4.177-.049-5.876-.57-6.786-1.177-.831-.554-.692-1.593-.138-2.078Z"
        clipRule="evenodd"
      />
      <path
        fill="#A4AABA"
        fillRule="evenodd"
        d="M59.033 274.438s.034 1.529-.315 2.95c-.352 1.431-1.087 3.127-1.139 4.17-.058 1.16 4.57 1.592 5.194.035.623-1.559 1.303-6.475 1.927-7.306.622-.831-4.94-2.135-5.667.15"
        clipRule="evenodd"
      />
      <path
        fill="#7BB2F9"
        fillRule="evenodd"
        d="m101.385 277.015 13.306.092s1.291-54.228 1.843-64.056c.552-9.828 3.756-43.13.997-62.788l-12.48-.64-22.725.776s-.433 3.944-1.19 9.921c-.062.493-.677.838-.744 1.358-.075.582.42 1.347.318 1.956-2.35 14.003-6.343 32.926-8.697 46.425-.116.663-1.227 1.004-1.45 2.677-.04.3.21 1.516.112 1.785-6.836 18.643-10.89 47.584-14.2 61.551l14.528-.014s2.185-8.524 4.008-16.878c2.796-12.817 22.987-84.553 22.987-84.553l3-.517 1.037 46.1s-.223 1.228.334 2.008c.558.782-.556 1.117-.39 2.233l.39 1.784s-.446 7.14-.892 11.826c-.446 4.685-.092 38.954-.092 38.954Z"
        clipRule="evenodd"
      />
      <path
        stroke="#648BD8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.051}
        d="M108.05 223.931s2.758-1.103 6.069-3.862m-36.181.365c1.146.094 4.016-2.008 6.916-4.91l-6.916 4.91Z"
      />
      <path
        stroke="#648BD8"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M108.959 220.905s2.759-1.104 6.07-3.863"
      />
      <path
        stroke="#648BD8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.051}
        d="M58.08 272.94h13.238m5.281-49.383s2.608-.587 6.47-3.346l-6.47 3.346ZM87.83 150.82c-.27 3.088.297 8.478-4.315 9.073l4.315-9.073Zm17.499-1.745s.11 13.936-1.286 14.983c-2.207 1.655-2.975 1.934-2.975 1.934l4.261-16.917Zm-3.815.555s.035 12.81-1.19 24.245l1.19-24.245Zm-6.084 25.335s7.174-1.655 9.38-1.655l-9.38 1.655Zm-19.259 29.789c-.316 1.55-.64 3.067-.973 4.535 0 0-1.45 1.822-1.003 3.756.446 1.934-.943 2.034-4.96 15.273-1.686 5.559-4.464 18.49-6.313 27.447-.078.38-4.018 18.06-4.093 18.423l17.342-69.434Zm1.372-8.011c-.28 1.579-.573 3.155-.877 4.729l.877-4.729Zm6.865-45.329-1.19 10.413s-1.091.148-.496 2.23c.111 1.34-2.66 15.692-5.153 30.267l6.839-42.91Z"
      />
      <path
        fill="#192064"
        fillRule="evenodd"
        d="M117.877 147.423s-16.955-3.087-35.7.199c.157 2.501-.002 4.128-.002 4.128s14.607-2.802 35.476-.31c.251-2.342.226-4.017.226-4.017Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m108.011 150.353.004-4.885a.807.807 0 0 0-.774-.81 108.48 108.48 0 0 0-7.795-.014.815.815 0 0 0-.784.81l-.003 4.88c0 .456.37.82.827.808 2.562-.064 5.126-.059 7.688.017a.817.817 0 0 0 .589-.226.812.812 0 0 0 .248-.58Z"
        clipRule="evenodd"
      />
      <path
        fill="#192064"
        fillRule="evenodd"
        d="m106.902 149.426.002-3.06a.64.64 0 0 0-.616-.643 94.566 94.566 0 0 0-5.834-.009.646.646 0 0 0-.626.643l-.001 3.056c0 .36.291.648.651.64 1.78-.04 3.708-.041 5.762.012a.644.644 0 0 0 .662-.64"
        clipRule="evenodd"
      />
      <path
        stroke="#648BD8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.051}
        d="m102.704 174.024.893 44.402s.669 1.561-.224 2.677c-.892 1.116 2.455.67.893 2.231-1.562 1.562.893 1.116 0 3.347-.592 1.48-.988 20.987-1.09 34.956m-1.191 12.296h12.272-12.272Zm1.167-4.858c.006 3.368.04 5.759.11 6.47l-.11-6.47Zm.015-5.95c-.009 1.53-.015 2.98-.016 4.313l.016-4.313Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.5 0h251v294H.5z" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
