import { generateEndpoint } from '@stagapps/redux-utils'
import { Button, Typography } from 'antd'
import Meta from 'antd/es/card/Meta'
import UICard from 'common/ui/display/UICard'
import { Link } from 'react-router-dom'
import { cloudflareCdn } from 'utils/image'

const { Title } = Typography

function OnboardingGoals() {
  return (
    <div className="flex items-center flex-col md:gap-10 gap-4 md:max-w-5xl md:mx-auto">
      <div className="text-center">
        <Title level={3}>Đặt mục tiêu tài chính</Title>
        <p className="text-sm">
          Hãy bắt đầu với việc lựa chọn một mục tiêu tài chính bạn mong muốn
          thực hiện
        </p>
      </div>

      <div className="flex flex-wrap md:gap-4 gap-2 justify-center">
        {data.map(({ title, description }, index) => {
          const src = cloudflareCdn(
            `/futureflex/images/onboarding/8.jpg`,
            111,
            111
          )
          const to = generateEndpoint({
            host: '/goals/new/program',
            params: { title }
          })

          return (
            <Link to={to} key={index}>
              <UICard size="small" hoverable>
                <div className="flex gap-4 items-center">
                  <Meta
                    title={<div className="text-base">{title}</div>}
                    description={
                      <div className="max-w-xs text-xs line-clamp-3	">
                        {description}
                      </div>
                    }
                  />
                  <img width={98} src={src} />
                </div>
              </UICard>
            </Link>
          )
        })}
      </div>
      <Link to="/goals/new">
        <Button size="large" type="primary" ghost>
          <div className="px-4">Trở về</div>
        </Button>
      </Link>
    </div>
  )
}

export default OnboardingGoals

const data = [
  {
    title: 'Xây dựng quỹ khẩn cấp',
    description:
      'Xây dựng "bảo hiểm tài chính" để đối mặt với những chi phí bất ngờ trong cuộc sống.'
  },
  {
    title: 'Tổ chức đám cưới',
    description:
      'Xây dựng tài chính chuẩn bị cho sự kiện quan trọng trong cuộc đời và đầu tư cho hạnh phúc.'
  },
  {
    title: 'Mua sắm xe hơi',
    description:
      'Sở hữu xe hơi giúp tiết kiệm thời gian, cung cấp sự thoải mái và tiện lợi trong việc di chuyển.'
  },
  {
    title: 'Chuyến du lịch mơ ước',
    description:
      'Biến những địa điểm mơ ước thành hành trình thực tế mà không phải đau đầu với ngân sách.'
  },
  {
    title: 'Giáo dục cho con cái',
    description:
      'Tạo ra cơ hội học tập tốt nhất cho con cái và giúp phát triển khả năng định hình tương lai của con bạn.'
  },
  {
    title: 'Mua nhà, bất động sản',
    description:
      'Đảm bảo nơi an cư cho gia đình và là nền tảng cho kế hoạch hưu trí, tài chính bền vững.'
  },
  {
    title: 'Nghỉ hưu sớm',
    description:
      'Mục tiêu duy trì lối sống thoải mái và không bị ràng buộc bởi nguồn thu nhập hàng tháng.'
  },
  {
    title: 'Khác',
    description:
      'Tự tạo một mục tiêu tài chính phù hợp với nhu cầu cá nhân của riêng bạn.'
  }
]
