import { useFetchApiList } from '@stagapps/redux-utils'
import { Select, Spin } from 'antd'
import debounce from 'lodash/debounce'
import { useEffect, useMemo, useRef } from 'react'

function DebounceSelect({
  apiCall,
  debounceTimeout = 300,
  displayFn,
  ...props
}) {
  const { data = [], load, isLoading, error, refresh } =
    useFetchApiList(apiCall, { resourceName: 'data' }) || {}

  const { error: errorMessage, error_code } = error || {}

  useEffect(() => {
    load()
  }, [])

  const fetchRef = useRef(0)
  const debounceFetcher = useMemo(() => {
    const loadOptions = value => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      if (value) load({ search: value })
      else load()
    }
    return debounce(loadOptions, debounceTimeout)
  }, [load, debounceTimeout])

  const list = e => {
    const { id, name } = e || {}
    return {
      value: id,
      label: name
    }
  }

  return (
    <>
      <Select
        showSearch={true}
        onSearch={debounceFetcher}
        filterOption={false}
        notFoundContent={
          isLoading ? (
            <div className="text-center py-2">
              <Spin />
            </div>
          ) : null
        }
        options={
          !!error || isLoading
            ? []
            : data.map(e => (displayFn ? displayFn(e) : list(e)))
        }
        allowClear
        {...props}
      />
      {!!error ? (
        <span className="text-red-500">
          {errorMessage || error_code || error}
        </span>
      ) : null}
    </>
  )
}

export default DebounceSelect
