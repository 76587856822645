import { Typography } from 'antd'
import { Link, useLocation } from 'react-router-dom'

const { Title } = Typography

function UnauthorizedStatePage() {
  const { pathname } = useLocation()

  return (
    <div className=" text-center flex flex-col gap-4 justify-center items-center">
      <Title level={5}>Vui lòng đăng nhập để tham gia chương trình</Title>
      <Title level={2}>
        <Link to={`/login?url=${pathname}`}>Đăng nhập</Link>
      </Title>
      <Title level={5}>Chưa có tài khoản ?</Title>

      <Title level={2}>
        <Link to={`/signup?url=${pathname}`}>Đăng ký</Link>
      </Title>
    </div>
  )
}

export default UnauthorizedStatePage
