import {
  Alert,
  Button,
  ConfigProvider,
  Image,
  Space,
  Steps,
  Table,
  Tooltip
} from 'antd'

import ContributedColumnChart from 'app/components/employeeProgram/ContributedColumnChart'
import ContributedDetail from 'app/components/employeeProgram/ContributedDetail'
import Summary from 'app/components/employeeProgram/Summary'
import UICard from 'common/ui/display/UICard'
import { getChangeValueClassColor } from 'config/color'
import {
  employeeProgramStatusCanceled,
  employeeProgramStatusCanceling,
  employeeProgramStatusCompleted,
  employeeProgramStatusVesting
} from 'data/employeeProgram'
import { Link, useParams } from 'react-router-dom'
import { formatPercentage, formatUnitQuantity } from 'utils/formatAmount'
import {
  formatAmountChangeWithPrefix,
  formatCurrencyWithoutCurrencySymbol
} from 'utils/formatAmount/formatUI'
import { formatDateTime } from 'utils/formatDate'
import { checkIsSellAvalible } from './employeeProgram/checkEmployeeProgramProgress'
import PortfolioDonutChart from './employeeProgram/PortfolioDonutChart'

function Program({ program, portfolio, flexPort }) {
  const { id, programId } = useParams()
  const {
    employee_investment_detail = [],
    progress,
    status,
    canceling_at,
    is_set_investment_plan
  } = program

  const { projected_program_amount, total_summary } = portfolio || {}
  const { contributed_amount, market_value } = total_summary || {}
  const { items: flexPortItems } = flexPort || {}
  const isSellAvalible = checkIsSellAvalible(progress)

  return (
    <div className="flex flex-col gap-4">
      {status === employeeProgramStatusVesting ||
      status === employeeProgramStatusCompleted ? (
        <VestingSteps progress={progress} isSellAvalible={isSellAvalible} />
      ) : status === employeeProgramStatusCanceling ||
        status === employeeProgramStatusCanceled ? (
        <CancelingSteps
          progress={progress}
          timeData={canceling_at}
          isSellAvalible={isSellAvalible}
        />
      ) : null}

      {is_set_investment_plan ? (
        <>
          <div className="flex md:flex-row flex-col gap-4">
            <UICard className="md:w-1/2">
              <Summary isSellAvalible={isSellAvalible} {...portfolio} />
            </UICard>
            <UICard title="Biểu đồ giá trị vốn đầu tư" className="md:w-1/2">
              <ContributedColumnChart
                projected_program_amount={projected_program_amount}
              />
            </UICard>
          </div>

          <div className="flex md:flex-row flex-col gap-4">
            <UICard title={'Phân bổ danh mục hiện tại'}>
              <div className="md:px-16 p-2 md:w-[402px] flex justify-center">
                <PortfolioDonutChart
                  totalMarketValue={market_value}
                  data={flexPortItems}
                />
              </div>
              <div className="pt-4 flex justify-center">
                <Tooltip
                  title={
                    'Vui lòng liên hệ Stag để được hỗ trợ điều chỉnh chương trình'
                  }
                  color="#0A1A41"
                  className="my-0"
                >
                  <Button disabled>Thay đổi</Button>
                </Tooltip>
              </div>
            </UICard>
            {isSellAvalible ? (
              <UICard
                title="Hiệu suất đầu tư"
                className="flex-grow overflow-x-scroll"
              >
                <Table
                  columns={endPortColumn}
                  dataSource={flexPortItems}
                  pagination={false}
                  rowKey={'product_code'}
                />
              </UICard>
            ) : (
              <UICard
                title="Hiệu suất đầu tư"
                className="flex-grow overflow-x-scroll"
              >
                <Table
                  columns={portColumn}
                  dataSource={flexPortItems}
                  pagination={false}
                  rowKey={'product_code'}
                />
              </UICard>
            )}
          </div>
        </>
      ) : null}

      <UICard title="Chi tiết đóng góp">
        <ContributedDetail program={program} />
        {is_set_investment_plan ? null : (
          <div className="flex md:flex-row flex-col md:justify-between mt-4">
            <div className="text-gray-500 max-w-3xl">
              {
                '(*) Nếu thông tin Chương Trình có sự khác biệt với thỏa thuận giữa bạn và doanh nghiệp, vui lòng liên hệ bộ phận Quản trị nhân sự doanh nghiệp để được hỗ trợ giải đáp.'
              }
            </div>
            <Link to={`/${id}/employee-programs/${programId}/confirm`}>
              <Button type="primary"> Tiếp tục đăng ký </Button>
            </Link>
          </div>
        )}
      </UICard>
    </div>
  )
}

export default Program

const renderProductCodeAndLogo = value => {
  const { code, logo } = value || {}
  return (
    <Space>
      {logo ? <Image width={20} className="object-contain	" src={logo} /> : null}
      <div className="font-semibold text-blue-primary-500">{code}</div>
    </Space>
  )
}

const endPortColumn = [
  {
    title: 'Mã CCQ',
    dataIndex: 'product',
    render: value => renderProductCodeAndLogo(value)
  },
  {
    title: 'Số lượng CCQ',
    dataIndex: 'total_quantity',
    render: value => formatUnitQuantity(value),
    align: 'right'
  },
  {
    title: 'Tổng vốn đã đầu tư',
    dataIndex: 'total_contributed_amount',
    render: value => formatCurrencyWithoutCurrencySymbol(value),
    align: 'right'
  }
]

const portColumn = [
  {
    title: 'Mã CCQ',
    dataIndex: 'product',
    render: value => renderProductCodeAndLogo(value)
  },
  {
    title: 'Số lượng CCQ',
    dataIndex: 'total_quantity',
    render: value => formatUnitQuantity(value),
    align: 'right'
  },
  {
    title: 'Giá trị hiện tại',
    dataIndex: 'market_value',
    render: value => <p>{formatCurrencyWithoutCurrencySymbol(value)}</p>,
    align: 'right'
  },
  {
    title: 'Lãi/Lỗ (%)',
    dataIndex: 'unrealized_gain_loss_percentage',
    render: value => (
      <p className={getChangeValueClassColor(value)}>
        {formatPercentage(value)}
      </p>
    ),
    align: 'right'
  },
  {
    title: 'Lãi/Lỗ (VND)',
    dataIndex: 'unrealized_gain_loss',
    render: value => (
      <p className={getChangeValueClassColor(value)}>
        {formatAmountChangeWithPrefix(value)}
      </p>
    ),
    align: 'right'
  }
]

const VestingSteps = ({ progress, isSellAvalible }) => {
  const current = progress.map(e => e?.is_active).indexOf(false)
  return (
    <UICard title={'Quy trình chuyển giao'}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#52C41A'
          },
          components: {
            Steps: {
              descriptionMaxWidth: 210
            }
          }
        }}
      >
        <Steps
          current={current}
          items={[
            {
              title: (
                <div className="font-semibold">
                  <div>{`Kích hoạt chuyển giao`}</div>
                  <div>{'chương trình'}</div>
                </div>
              ),
              description:
                'Chương trình đến thời hạn chuyển giao và các lệnh đóng góp đều đã hoàn tất.'
            },
            {
              title: (
                <div className="font-semibold">
                  <div>{`Mở khoá phần`}</div>
                  <div>{'nhân viên tích lũy'}</div>
                </div>
              ),
              description:
                'Mở khoá phần tích lũy của nhân viên, cho phép nhân viên bán.'
            },
            {
              title: (
                <div className="font-semibold">
                  <div>{`Bán phần doanh nghiệp`}</div>
                  <div>{'đóng góp'}</div>
                </div>
              ),
              description:
                'Phần CCQ của doanh nghiệp được bán ra để chuyển giao cho nhân viên'
            },
            {
              title: (
                <div className="font-semibold">
                  <div>{`Hoàn tất chuyển giao`}</div>
                  <div>{'chương trình'}</div>
                </div>
              ),
              description: (
                <Space direction="vertical">
                  <div>
                    {
                      'Doanh nghiệp chuyển tiền cho nhân viên và Nhân viên có thể thực hiện lệnh bán.'
                    }
                  </div>
                  <SellButton isSellAvalible={isSellAvalible} />
                </Space>
              )
            }
          ]}
        />
      </ConfigProvider>
    </UICard>
  )
}
const CancelingSteps = ({ progress, timeData, isSellAvalible }) => {
  const current = progress.map(e => e?.is_active).indexOf(false)
  return (
    <>
      <Alert
        type="error"
        message={'Chương trình đã kết thúc sớm hơn kết hoạch'}
        description={`Thời điểm thực hiện huỷ: ${formatDateTime(timeData)}`}
        showIcon
      />
      <UICard title={'Quy trình huỷ'}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#52C41A'
            },
            components: {
              Steps: {
                descriptionMaxWidth: 210
              }
            }
          }}
        >
          <Steps
            current={current}
            items={[
              {
                title: (
                  <div className="font-semibold">
                    <div>{`Kích hoạt huỷ`}</div>
                    <div>{'chương trình'}</div>
                  </div>
                ),
                description:
                  'Doanh nghiệp kích hoạt huỷ chương trình theo yêu cầu của nhân viên.'
              },
              {
                title: (
                  <div className="font-semibold">
                    <div>{`Mở khoá phần`}</div>
                    <div>{'nhân viên tích lũy'}</div>
                  </div>
                ),
                description:
                  'Mở khoá phần tích lũy của nhân viên, cho phép nhân viên bán.'
              },
              {
                title: (
                  <div className="font-semibold">
                    <div>{`Hoàn tất hủy`}</div>
                    <div>{'chương trình'}</div>
                  </div>
                ),
                description: (
                  <Space direction="vertical">
                    <div>{'Nhân viên tự do bán phần CCQ đã tích lũy.'}</div>
                    <SellButton isSellAvalible={isSellAvalible} />
                  </Space>
                )
              }
            ]}
          />
        </ConfigProvider>
      </UICard>
    </>
  )
}

const SellButton = ({ isSellAvalible }) => {
  const { id, programId } = useParams()

  return (
    <Link to={`/employees/${id}/programs/${programId}/transactions`}>
      <Button disabled={!isSellAvalible} type="primary">
        Thực hiện bán
      </Button>
    </Link>
  )
}
