import { enhancedMakeFetchAction } from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'
export const POST_REQUEST_SELL_OTP = 'POST_REQUEST_SELL_OTP'

const apiCall = enhancedMakeFetchAction(
  POST_REQUEST_SELL_OTP,
  ({ id, programId, ...payload }) => ({
    endpoint: `${API_BASE_URL}/investcore-api/flex/employee/v1/employees/${id}/employee-programs/${programId}/program-orders/sell-otp`,
    method: 'POST',
    body: payload
  })
)

export default apiCall
