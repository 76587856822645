import { Layout } from 'antd'

import { Outlet } from 'react-router-dom'

const AppLayout = () => (
  <Layout>
    <Outlet />
  </Layout>
)

export default AppLayout
