import { useFetchApiGet, useFetchApiList } from '@stagapps/redux-utils'
import { Button, Card, Empty, Menu, Table, Typography } from 'antd'
import { useEffect } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'

import ContributeCard from 'app/components/cards/ContributeCard'
import apiCall from 'business/apiCalls/program'
import MetaDecorator from 'common/layout/MetaDecorator'
import PageTitle from 'common/layout/PageTitle'
import DataTable from 'common/ui/DataTable'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import UIInfoTooltip from 'common/ui/display/UIInfoTooltip'
import { employeeProgramStatus } from 'data/employeeProgram'
import { columns, redistributionColumns } from './config'
import { sumCountByStatus } from './sumStatus'

const { Title } = Typography

const vesting =
  'Mục tái phân phối bao gồm những phần CCQ mà Doanh nghiệp đã mua để đóng góp cho chương trình của Nhân viên nhưng sau đó những chương trình này bị huỷ. Để giúp Doanh nghiệp tối ưu phí mua bán, hệ thống sẽ sử dụng số dư này để khớp tiếp cho các chương trình còn đang tham gia vào các kỳ tiếp theo.'

const CreateEmployeeProgramButton = () => (
  <Link to="/employee-programs/create">
    <Button type="primary">Tạo chương trình mới</Button>
  </Link>
)

export const EmployeeProgramListPage = (props, { params }) => {
  const location = useLocation()
  let [searchParams] = useSearchParams()
  const status = searchParams.get('status')

  const title = 'Quản lý chương trình'
  const {
    rawData,
    data: items = [],
    load: loadEmployeePrograms,
    isLoading,
    refreshing,
    error: errorEmployeePrograms,
    paging
  } = useFetchApiList(apiCall.list, { resourceName: 'data' })
  const {
    data: statistics,
    load: loadStatistics,
    error: errorStatistics
  } = useFetchApiGet(apiCall.statistics, { resourceName: 'data' })
  const {
    data: redistribution,
    load: loadRedistribution,
    error: errorRedistribution
  } = useFetchApiGet(apiCall.redistribution, { resourceName: 'data' })

  useEffect(() => {
    loadStatistics()
    loadRedistribution()
  }, [])

  useEffect(() => {
    let queryParams = Object.fromEntries(new URLSearchParams(location.search))
    loadEmployeePrograms({ ...queryParams, status })
  }, [searchParams])

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (status === '' && (!items || items.length < 1) && !errorEmployeePrograms) {
    return (
      <>
        <MetaDecorator title={title} />
        <PageTitle title={title} extra={<CreateEmployeeProgramButton />} />
        <UICard title={'Tất cả chương trình'}>
          <Empty
            image={Empty.PRESENTED_IMAGE_DEFAULT}
            description={
              <>
                <Title level={4}>Chưa có chương trình</Title>
                <p>
                  Bạn có thể theo dõi tất cả chương trình phúc lợi cho nhân viên
                  tại đây
                </p>
              </>
            }
          />
        </UICard>
      </>
    )
  }
  const { meta_data } = rawData || {}
  const { status_count: statusCountList = [] } = meta_data || {}
  const {
    total_company_contributed_amount,
    total_employee_contributed_amount
  } = statistics || {}
  const { company_redistribution_items = [] } = redistribution || {}

  const statusItems = [
    ['', 'Tất cả'],
    ...Object.entries(employeeProgramStatus)
  ].map(e => {
    const [key, label] = e || []
    const counting = key
      ? sumCountByStatus(statusCountList, key)
      : statusCountList[0]?.count

    return {
      key,
      label: (
        <Link to={`/employee-programs?status=${key}`}>
          {counting ? `${label} (${counting})` : label}
        </Link>
      )
    }
  })

  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle title={title} extra={<CreateEmployeeProgramButton />} />
      {!!errorStatistics ? (
        <ErrorMessage errors={errorStatistics} />
      ) : (
        <div className="flex flex-row gap-4 mb-4">
          <div className="w-full flex flex-col gap-4">
            <ContributeCard
              title="Vốn nhân viên đã tích lũy"
              tooltipTitle="Tổng vốn Nhân viên đã tích lũy trong tất cả chương trình Nhân viên đang tham gia"
              value={total_employee_contributed_amount}
            />
            <ContributeCard
              title="Vốn Công ty đã góp"
              tooltipTitle="Tổng vốn Doanh nghiệp đã đóng góp trong tất cả chương trình Nhân viên đang tham gia"
              value={total_company_contributed_amount}
            />
          </div>

          <Card
            size="small"
            style={{ width: '100%' }}
            title={'Mục tái phân phối'}
            extra={<UIInfoTooltip title={vesting} />}
            headStyle={{ backgroundColor: '#fafafa' }}
          >
            <RedistributionTable
              items={company_redistribution_items}
              error={errorRedistribution}
            />
          </Card>
        </div>
      )}

      <UICard title={'Tất cả chương trình'}>
        <Menu mode="horizontal" items={statusItems} selectedKeys={status} />
        <FilteredTable
          items={items}
          isLoading={refreshing}
          error={errorEmployeePrograms}
          paging={paging}
          fetchItems={loadEmployeePrograms}
        />
      </UICard>
    </>
  )
}

const FilteredTable = ({ items, isLoading, error, paging, fetchItems }) => {
  if (!!error) {
    return <ErrorMessage errors={error} />
  }
  const { count, page, page_size } = paging || {}
  return (
    <DataTable
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_DEFAULT}
            description={<p>Chưa có chương trình </p>}
          />
        )
      }}
      columns={columns}
      dataSource={items}
      loading={isLoading}
      showHeader={items?.length > 0 ? true : false}
      rowKey={'id'}
      totalItems={count}
      currentPage={page}
      defaultCurrent={1}
      defaultPageSize={page_size}
      pageSize={page_size}
      dataFetcher={fetchItems}
    />
  )
}

const RedistributionTable = ({ items, error }) => {
  if (!!error) {
    return <ErrorMessage errors={error} />
  }
  return (
    <Table
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_DEFAULT}
            description={<p>Không có số dư</p>}
          />
        )
      }}
      columns={redistributionColumns}
      dataSource={items}
      showHeader={items?.length > 0 ? true : false}
      pagination={false}
      rowKey={record => record?.company_id}
    />
  )
}

export default EmployeeProgramListPage
