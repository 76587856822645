import {
  enhancedMakeFetchAction,
  generateEndpoint
} from '@stagapps/redux-utils'
import { API_BASE_URL } from 'config/env.config'

export const FETCH_CREATE_API_ID = 'EMPLOYEE/SURVEY_CREATE_API'

const apiCall = enhancedMakeFetchAction(FETCH_CREATE_API_ID, payload => ({
  endpoint: `${API_BASE_URL}/investcore-api/app/v2/surveys/create`,
  method: 'POST',
  body: payload,
  notify: true,
  isStay: true
}))

export const apiCallGeneratorByName = (name = '') => {
  const apiName = `EMPLOYEE/SURVEY_CREATE_API_${name}`

  return enhancedMakeFetchAction(apiName, payload => ({
    endpoint: generateEndpoint({
      host: `${API_BASE_URL}/investcore-api/app/v2/surveys/create`
    }),
    method: 'POST',
    body: payload,
    notify: true
  }))
}

export default apiCall
