import { useFetchApiGet } from '@stagapps/redux-utils'
import { Layout, Space } from 'antd'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import apiUserProfileCall from 'app/apiCalls/verification/getProfile'
import apiUserIDCall from 'app/apiCalls/verification/getUserIdentifications'
import StagLogoSVG from 'common/svg/logo/stag'
import useCheckAuth from 'hooks/auth/useCheckAuth'

import UserVerificationStatus from 'app/components/verification/UserVerificationStatus'
import UserMenu from 'common/layout/UserMenu'

const { Header } = Layout

function AppHeader() {
  const { currentUser } = useCheckAuth()

  const {
    data: userProfile,
    load: loadUserProfile,
    isLoading: isLoadingUserProfile,
    error: errorUserProfile
  } = useFetchApiGet(apiUserProfileCall, {
    resourceName: 'data'
  })
  const {
    data: userID,
    load: loadUserID,
    isLoading: isLoadingUserId,
    error: errorUserID,
    refresh
  } = useFetchApiGet(apiUserIDCall, {
    resourceName: 'data'
  })

  useEffect(() => {
    loadUserProfile()
    loadUserID()
  }, [])

  const isLoading = isLoadingUserId || isLoadingUserProfile
  const { ekyc_verified } = userProfile || {}
  const { verification_status } = userID || {}

  return (
    <SimpleHeader>
      <div className="flex">
        <Space size="small">
          {!isLoading ? (
            <UserVerificationStatus
              status={ekyc_verified || verification_status}
            />
          ) : null}
          {!!currentUser ? <UserMenu user={currentUser} /> : null}
        </Space>
      </div>
    </SimpleHeader>
  )
}

export default AppHeader

export const SimpleHeader = ({ children }) => (
  <Header className="bg-white flex flex-row items-center justify-between md:px-4 px-2 gap-2">
    <Link to={'/'}>
      <div className="flex md:w-[134px] w-32 aspect-[134/31]">
        <StagLogoSVG />
      </div>
    </Link>
    {children}
  </Header>
)
