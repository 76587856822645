export function convertEmailCharacters(inputString) {
  if (!inputString) {
    return
  }
  let convertedString = ''
  let index = inputString.indexOf('@')

  if (index !== -1) {
    let leftCharacters = inputString.slice(index - 6, index)
    let remainingCharacters = inputString.slice(0, index - 6)
    let convertedRemaining = remainingCharacters.replace(/./g, '*')

    convertedString +=
      convertedRemaining + leftCharacters + inputString.slice(index)
  } else {
    convertedString = inputString
  }

  return convertedString
}

export function convertPhoneCharacters(phoneNumber) {
  // Extract the last four digits
  const lastFourDigits = phoneNumber.substring(phoneNumber.length - 4)

  // Replace the rest of the digits with asterisks
  const hiddenPart = '*'.repeat(phoneNumber.length - 4)

  // Combine the hidden part and the last four digits
  const maskedPhoneNumber = hiddenPart + lastFourDigits

  return maskedPhoneNumber
}
