import { all } from 'redux-saga/effects'

import employeeSagas from 'app/sagas'
import businessSagas from 'business/sagas'
import { FLEX_APP } from 'config/env.config'
import errorsSaga from 'sagas/errors'
import notificationSaga from 'sagas/notification'
import { sessionSaga } from 'sagas/session'

const sagas = {
  business: businessSagas,
  employee: employeeSagas
}

export default function* rootSaga() {
  const appSagas = sagas[FLEX_APP]

  yield all([notificationSaga(), appSagas(), sessionSaga(), errorsSaga()])
}
