import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { usePostApi } from '@stagapps/redux-utils'
import { Alert, Button, Form, Input, Radio, Space, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'

import apiBanksCall from 'app/apiCalls/banks'
import apiDistrictsCallGenerator from 'app/apiCalls/districts'
import apiProvincesCallGenerator from 'app/apiCalls/provinces'
import apiPatchCall from 'app/apiCalls/verification/partialUpdateIdInfo'
import SelectListData from 'common/ui/form/SelectListData'
import ValidButton from 'common/ui/form/ValidButton'
import useEffectSkipFirstRender from 'hooks/useEffectSkipFirstRender'
import { convertDateFormData, ensureDateFields } from 'utils/formData'

const { Title } = Typography

const dateFields = ['date_of_birth', 'issue_date']

function EditKYCForm({ initialValues = {} }) {
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const { residential_address_city_id, address_city_id } =
    Form.useWatch([], form) || {}

  const { data, post, isLoading: isSubmitting, error } = usePostApi(
    apiPatchCall,
    {
      resourceName: 'data'
    }
  )

  const onFinish = values => {
    post({ ...convertDateFormData(values, dateFields) })
  }

  const { error: errorMessage, error_code } = error || {}

  useEffectSkipFirstRender(() => {
    if (data) {
      navigate('/verification/confirm')
    }
  }, [data])

  return (
    <Form
      requiredMark={false}
      initialValues={ensureDateFields(initialValues, dateFields)}
      onFinish={onFinish}
      form={form}
      size="large"
      labelCol={{ span: 24 }}
    >
      {!!errorMessage || !!error_code ? (
        <Alert message={errorMessage || error_code} type="error" showIcon />
      ) : null}
      <Title level={5}>1. Thông tin cơ bản</Title>

      <Form.Item
        label="Giới tính"
        name="gender"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn'
          }
        ]}
      >
        <Radio.Group>
          <Radio value={'male'}>Nam</Radio>
          <Radio value={'female'}>Nữ</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Họ và tên (đầy đủ theo CMND/CCCD)" name="full_name">
        <Input disabled />
      </Form.Item>

      <Form.Item
        name={'date_of_birth'}
        label={'Ngày tháng năm sinh'}
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn'
          }
        ]}
      >
        <DatePicker
          sx={{ px: 1 }}
          openTo="year"
          views={['year', 'month', 'day']}
          slotProps={{
            textField: { variant: 'standard' }
          }}
        />
      </Form.Item>

      <Title level={5}>2. Thông tin chứng từ</Title>

      <Form.Item
        label="Số CMND/CCCD"
        name="id_number"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập'
          }
        ]}
      >
        <Input />
      </Form.Item>

      <div className="md:flex w-full gap-6">
        <Form.Item
          name={'issue_date'}
          label={'Ngày cấp'}
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn'
            }
          ]}
        >
          <DatePicker
            sx={{ px: 1 }}
            openTo="year"
            views={['year', 'month', 'day']}
            slotProps={{
              textField: { variant: 'standard' }
            }}
            formatDensity="spacious"
          />
        </Form.Item>

        <Form.Item
          className="w-full"
          label="Nơi cấp"
          name="issue_place"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập'
            }
          ]}
        >
          <Input />
        </Form.Item>
      </div>

      <Title level={5}>3. Địa chỉ thường trú</Title>
      <div className="md:flex w-full gap-4 ">
        <div className="md:min-w-[10rem]">
          <Form.Item
            label="Thành phố"
            name="residential_address_city_id"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn'
              }
            ]}
          >
            <SelectListData
              onClear={_ =>
                form.setFieldValue('residential_address_district_id', undefined)
              }
              apiCall={apiProvincesCallGenerator('residential_address_city_id')}
            />
          </Form.Item>
        </div>

        <div className="md:min-w-[10rem]">
          <Form.Item
            label="Quận/Huyện"
            name="residential_address_district_id"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn'
              }
            ]}
          >
            <SelectListData
              params={{ province_id: residential_address_city_id }}
              apiCall={apiDistrictsCallGenerator(
                'residential_address_district_id'
              )}
            />
          </Form.Item>
        </div>
        <div className="md:min-w-[10rem]">
          <Form.Item
            label="Phường/Xã/Thị trấn"
            name="residential_address_ward"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập'
              }
            ]}
          >
            <Input />
          </Form.Item>
        </div>
      </div>

      <Form.Item
        label="Địa chỉ thường trú"
        name="residential_address"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập'
          }
        ]}
      >
        <Input />
      </Form.Item>

      <Title level={5}>4. Địa chỉ liên hệ</Title>
      <div className="md:flex w-full gap-4 ">
        <div className="md:min-w-[10rem]">
          <Form.Item
            label="Thành phố"
            name="address_city_id"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn'
              }
            ]}
          >
            <SelectListData
              onClear={_ =>
                form.setFieldValue('address_district_id', undefined)
              }
              apiCall={apiProvincesCallGenerator('address_city_id')}
            />
          </Form.Item>
        </div>
        <div className="md:min-w-[10rem]">
          <Form.Item
            label="Quận/Huyện"
            name="address_district_id"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn'
              }
            ]}
          >
            <SelectListData
              params={{ province_id: address_city_id }}
              apiCall={apiDistrictsCallGenerator('address_district_id')}
            />
          </Form.Item>
        </div>
        <div className="md:min-w-[10rem]">
          <Form.Item
            label="Phường/Xã/Thị trấn"
            name="address_ward"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập'
              }
            ]}
          >
            <Input />
          </Form.Item>
        </div>
      </div>
      <Form.Item
        label="Địa chỉ liên hệ"
        name="address"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập'
          }
        ]}
      >
        <Input />
      </Form.Item>

      <Title level={5}>5. Thông tin ngân hàng</Title>
      <Form.Item label="Chủ tài khoản" name="bank_account_name">
        <Input disabled />
      </Form.Item>
      <Form.Item
        label="Số tài khoản"
        name="bank_account_number"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập'
          }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Tên ngân hàng"
        name="bank_id"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn'
          }
        ]}
      >
        <SelectListData apiCall={apiBanksCall} />
      </Form.Item>

      <Form.Item
        label="Tên chi nhánh"
        name="bank_branch_name"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập'
          }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Space className="flex justify-end">
          <Button
            size="medium"
            onClick={() => {
              navigate('/verification/confirm')
            }}
          >
            Hủy
          </Button>
          <ValidButton
            size="medium"
            type="primary"
            htmlType="submit"
            btnTitle="Lưu lại"
            form={form}
            loading={isSubmitting}
          />
        </Space>
      </Form.Item>
    </Form>
  )
}

export default EditKYCForm
