import { Button, Result, Space } from 'antd'

const SellOrderSuccess = () => {
  return (
    <>
      <Result
        status="success"
        title="Hoàn tất đặt lệnh"
        subTitle="Theo dõi trạng thái tại Sổ lệnh"
      />
      <div className="flex justify-end">
        <Space>
          <Button>Trở về</Button>
          <Button type="primary">Xem giao dịch</Button>
        </Space>
      </div>
    </>
  )
}

export default SellOrderSuccess
