import { Layout } from 'antd'
import { Outlet } from 'react-router-dom'

import SideMenu from 'common/layout/SideMenu'
import Header from './Header'

const AppLayout = ({ isSideMenu = true }) => (
  <Layout>
    <Header />
    <Layout style={{ minHeight: '100vh' }}>
      {isSideMenu ? <SideMenu /> : null}
      <Layout className="p-4">
        <Layout.Content>
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  </Layout>
)

export default AppLayout
