import { Button } from 'antd'
import EmptyProfileCard from 'app/components/cards/EmptyProfileCard'
import useAccountVerifiedStatus from 'app/hooks/useAccountVerifiedStatus'
import MetaDecorator from 'common/layout/MetaDecorator'
import PageTitle from 'common/layout/PageTitle'
import ErrorMessage from 'common/ui/ErrorMessage'
import LoadingSpinner from 'common/ui/LoadingSpinner'
import UICard from 'common/ui/display/UICard'
import { Item } from 'common/ui/display/UIDescriptions'
import { Link } from 'react-router-dom'
import AccountNavigation from './AccountNavigation'

const PAGE_TITLE = 'Thông tin ngân hàng'

function AccountBankPage() {
  const {
    isLoading,
    isError,
    error,
    linkTo,
    user,
    isCompleteAllKYC
  } = useAccountVerifiedStatus()

  if (isLoading) {
    return (
      <div className="flex flex-col gap-4 ">
        <MetaDecorator title={PAGE_TITLE} />
        <PageTitle title={PAGE_TITLE} />
        <UICard>
          <AccountNavigation key="bank" />
          <LoadingSpinner />
        </UICard>
      </div>
    )
  }

  if (isError) {
    return (
      <div className="flex flex-col gap-4 ">
        <MetaDecorator title={PAGE_TITLE} />
        <PageTitle title={PAGE_TITLE} />
        <UICard>
          <AccountNavigation key="bank" />
          <ErrorMessage errors={error} />
        </UICard>
      </div>
    )
  }

  if (!isCompleteAllKYC) {
    return (
      <div className="flex flex-col gap-4">
        <MetaDecorator title={PAGE_TITLE} />
        <PageTitle title={PAGE_TITLE} />
        <UICard>
          <AccountNavigation key="bank" />
          <EmptyProfileCard>
            <Button type="primary">
              <Link to={linkTo}>Định danh ngay</Link>
            </Button>
          </EmptyProfileCard>
        </UICard>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      <MetaDecorator title={PAGE_TITLE} />
      <PageTitle title={PAGE_TITLE} />
      <UICard>
        <AccountNavigation key="bank" />
        <Item label="Chủ tài khoản">
          <div className="lowercase ">
            <div className="first-line:capitalize">
              {user.bank_account_name}
            </div>
          </div>
        </Item>
        <Item label="Số tài khoản">{user.bank_account_number}</Item>
        <Item label="Tên ngân hàng">{user.bank_name}</Item>
        <Item label="Chi nhánh">{user.bank_branch_name}</Item>
      </UICard>
    </div>
  )
}

export default AccountBankPage
