import { useDispatch } from 'react-redux'
import SessionAction from 'state/action/session'

const useSignOut = () => {
  const dispatch = useDispatch()

  const clearSession = () => {
    localStorage.removeItem('goalDraft')
    dispatch({
      type: SessionAction.UPDATE_STAG_USER,
      payload: {
        currentUser: null,
        currentToken: null,
        isLoading: false
      }
    })
  }

  return () => {
    try {
      clearSession()
    } catch (error) {
      console.log('OnSignOutError', error)
    }
  }
}

export default useSignOut
