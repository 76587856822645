import employeeFlexPortApiCall from './employeeFlexPort'
import flexPortApiCall from './flexPort'
import portfolioApiCall from './portfolio'

const ApiCall = {
  portfolio: portfolioApiCall,
  flexPort: flexPortApiCall,
  employeeFlexPort: employeeFlexPortApiCall
}

export default ApiCall
